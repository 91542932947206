<template>
	<AppLayout>
		<TopBar slot="top-bar">
			<img slot="logo" src="../../public/images/pimienta-logo-white.svg" />
			<template slot="account">
				<AccountSwitcher
					class="mr-2"
					:current-account="currentAccount"
					:accounts="accounts"
					:on-account-selection="onAccountChange"
				>
					<template slot="current-account-title" slot-scope="{}">
						{{ $t('Account') }}
					</template>
				</AccountSwitcher>
			</template>
			<template slot="user">
				<UserCard v-if="user" :user="user" @logout="logOut" />
			</template>
		</TopBar>
		<template slot="mobile-menu">
			<MobileMenu />
			<MobilePanel />
		</template>
		<div slot="content">
			<slot></slot>
			<VersionNotification />
		</div>
	</AppLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AppLayout, AccountSwitcher, MobileMenu } from '@oneflow/ofs-vue-layout';
import { TopBar, MobilePanel } from './layout/index';
import UserCard from './UserCard.vue';
import VersionNotification from './VersionNotification/VersionNotification';

export default {
	components: {
		AppLayout,
		TopBar,
		MobileMenu,
		MobilePanel,
		AccountSwitcher,
		UserCard,
		VersionNotification
	},
	computed: {
		...mapGetters('account', ['currentAccount', 'accounts']),
		...mapGetters('auth0', ['user'])
	},
	methods: {
		...mapActions('account', ['setCurrentAccount']),
		...mapActions('auth0', ['logOut']),
		onAccountChange(account) {
			this.setCurrentAccount(account);
			this.$nextTick(() => window.location.reload());
		}
	}
};
</script>
