<template>
	<Layout>
		<b-form class="h-100" novalidate @submit.prevent="onSave">
			<Page class="ScrappingRuleEdit">
				<ContentHeader :title="headerTitle" :breadcrumbs="breadcrumbs" class="mb-2" no-padding>
					<b-dropdown class="mx-1" right text="Actions">
						<b-dropdown-item :disabled="!scrapingRuleId" @click="onRemove">
							<icon name="trash" class="mr-2" />
							{{ $t('Delete') }}
						</b-dropdown-item>
					</b-dropdown>
				</ContentHeader>

				<b-row>
					<b-col lg="6" md="8">
						<OfFormInput name="name" :label="$t('Name')" show-errors />
					</b-col>
					<b-col lg="3">
						<OfFormRadio name="type" :label="$t('Rule Type')" :options="typeOptions" required />
					</b-col>
					<b-col lg="3">
						<OfFormRadio name="subject" :label="$t('Rule Subject')" :options="subjectOptions" required />
					</b-col>
				</b-row>

				<b-row v-if="isJsonRule">
					<b-col lg="6" md="8">
						<OfFormInput name="rules.dataPath" :label="$t('Data path')" />
					</b-col>
				</b-row>

				<Section v-if="isJsonRule" :title="$t('Data Transformations')" collapsible>
					<template #actions>
						<b-button variant="primary" size="sm" @click="addTransformation">
							{{ $t('Add Transformation') }}
						</b-button>
					</template>

					<b-row>
						<b-col>
							<FormTable :fields="transformationFields" :items="formData.dataTransformations">
								<template #cell(selector)="{ index }">
									<OfFormInput :name="`dataTransformations[${index}].selector`" no-label required />
								</template>
								<template #cell(type)="{ index }">
									<OfFormSelect
										:name="`dataTransformations[${index}].type`"
										:options="transformationTypeOptions"
										no-label
										required
									/>
								</template>
								<template #cell(value)="{ index }">
									<OfFormInput :name="`dataTransformations[${index}].value`" no-label required />
								</template>
								<template #cell(actions)="{ index }">
									<b-button size="sm">
										<icon name="trash" @click="removeTransformation(index)" />
									</b-button>
								</template>
							</FormTable>
						</b-col>
					</b-row>
				</Section>

				<Section :title="$t('Sections')" collapsible>
					<b-row v-if="isRecipeRule" class="RulesRow">
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Details') }}</h3>
								<OfFormInput :label="$t('Name')" name="rules.name" />
								<OfFormInput :label="$t('Author')" name="rules.author" />
								<OfFormInput :label="$t('Description')" name="rules.description" />
								<OfFormInput :label="$t('Category')" name="rules.category" />
								<OfFormInput :label="$t('Cuisine')" name="rules.cuisine" />
								<OfFormInput :label="$t('Number of servings')" name="rules.yield" />
								<OfFormInput :label="$t('Tags')" name="rules.tags" />
								<OfFormInput v-if="isJsonRule" :label="$t('Unique Id')" name="rules.uniqueId" />
							</div>
						</b-col>
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Nutrition') }}</h3>
								<OfFormInput :label="$t('Calories')" name="rules.nutrition.calories" />
								<OfFormInput :label="$t('Fat')" name="rules.nutrition.fatContent" />
								<OfFormInput :label="$t('Protein')" name="rules.nutrition.proteinContent" />
								<OfFormInput :label="$t('Carbohydrates')" name="rules.nutrition.carbohydrateContent" />
							</div>
						</b-col>
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Cooking Time') }}</h3>
								<OfFormInput :label="$t('Preparation Time')" name="rules.prepTime" />
								<OfFormInput :label="$t('Cooking Time')" name="rules.cookTime" />
								<OfFormInput :label="$t('Total Time')" name="rules.totalTime" />
							</div>
						</b-col>
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Ingredients') }}</h3>
								<OfFormInput :label="$t('Ingredients item')" name="rules.ingredients.ingredients" />
								<OfFormInput :label="$t('Ingredients section')" name="rules.ingredients.section" />
								<OfFormInput
									v-if="isJsonRule"
									:label="$t('Ingredients item text')"
									name="rules.ingredients.text"
								/>
								<OfFormInput
									v-if="isJsonRule"
									:label="$t('Ingredients item quantity')"
									name="rules.ingredients.quantity"
								/>
								<OfFormInput
									v-if="isJsonRule"
									:label="$t('Ingredients item units')"
									name="rules.ingredients.units"
								/>
							</div>
						</b-col>
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Instructions') }}</h3>
								<OfFormInput :label="$t('Instructions item')" name="rules.instructions.instructions" />
								<OfFormInput :label="$t('Instructions section')" name="rules.instructions.section" />
								<OfFormInput :label="$t('Notes')" name="rules.notes" />
							</div>
						</b-col>
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Images') }}</h3>
								<OfFormInput :label="$t('Images')" name="rules.images" />
							</div>
						</b-col>
					</b-row>
					<b-row v-else class="RulesRow">
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('General') }}</h3>
								<OfFormInput :label="$t('Author')" name="rules.author" />
								<OfFormInput :label="$t('Headline')" name="rules.headline" />
								<OfFormInput :label="$t('Subtitle 1')" name="rules.subtitle1" />
								<OfFormInput :label="$t('Subtitle 2')" name="rules.subtitle2" />
							</div>
						</b-col>
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Covers') }}</h3>
								<OfFormInput :label="$t('Front Cover Image')" name="rules.cover.imageFront" />
								<OfFormInput
									:label="$t('Back cover text')"
									name="rules.cover.description"
									show-errors
								/>
								<OfFormInput :label="$t('Logo')" name="rules.cover.logo" />
							</div>
						</b-col>
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Title') }}</h3>
								<OfFormInput :label="$t('Logo')" name="rules.title.logo" />

								<h3>{{ $t('TableOfContents') }}</h3>
								<OfFormInput :label="$t('Title')" name="rules.tableOfContents.title" />
								<OfFormInput :label="$t('Image')" name="rules.tableOfContents.images" show-errors />
							</div>
						</b-col>
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Introduction') }}</h3>
								<OfFormInput :label="$t('Personalised Message')" name="rules.introduction.message" />
								<OfFormInput :label="$t('Headline')" name="rules.introduction.headline" />
								<OfFormInput
									:label="$t('Body copy')"
									name="rules.introduction.paragraphs"
									show-errors
								/>
								<OfFormInput :label="$t('Image')" name="rules.introduction.images" show-errors />
								<OfFormInput :label="$t('QR Code')" name="rules.introduction.qrCode" />
							</div>
						</b-col>
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Chapter') }}</h3>
								<OfFormInput :label="$t('Chapter Title')" name="rules.chapters.name" show-errors />
								<OfFormInput :label="$t('Path')" name="rules.chapters.path" show-errors />
								<OfFormInput
									:label="$t('Documents Ids')"
									name="rules.chapters.documentIds"
									show-errors
								/>
								<OfFormInput :label="$t('Opening Page Image')" name="rules.chapters.images" />
							</div>
						</b-col>
						<b-col xl="2" lg="4">
							<div class="RulesColumn">
								<h3>{{ $t('Acknowledgements') }}</h3>
								<OfFormInput :label="$t('Headline')" name="rules.acknowledgements.headline" />
								<OfFormInput
									:label="$t('Message')"
									name="rules.acknowledgements.paragraphs"
									show-errors
								/>
								<OfFormInput :label="$t('Logo/Icon')" name="rules.acknowledgements.logo" />
							</div>
						</b-col>
					</b-row>
				</Section>

				<Section v-if="isRecipeRule" :title="$t('Additional fields')" collapsible>
					<template #actions>
						<b-button variant="primary" size="sm" @click="addAdditionalField">
							{{ $t('Add field') }}
						</b-button>
					</template>

					<FormTable :fields="additionalFields" :items="formData.additionalFields">
						<template #cell(fieldName)="{ index }">
							<OfFormInput :name="`additionalFields[${index}].fieldName`" no-label required />
						</template>
						<template #cell(selector)="{ index }">
							<OfFormInput :name="`additionalFields[${index}].selector`" no-label required />
						</template>
						<template #cell(actions)="{ index }">
							<b-button size="sm">
								<icon name="trash" @click="removeAdditionalField(index)" />
							</b-button>
						</template>
					</FormTable>
				</Section>

				<template #actions>
					<b-button v-t="$t('Cancel')" class="mr-2" @click="onCancel" />
					<OfSubmitButton v-t="$t('Save')" variant="primary" />
				</template>
			</Page>
		</b-form>
	</Layout>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import {
	ContentHeader,
	OfFormInput,
	OfFormRadio,
	OfFormSelect,
	OfSubmitButton,
	withForm,
	validateWithMessage
} from '@oneflow/ofs-vue-layout';
import Layout from '../../../components/Layout.vue';
import Page from '../../../components/Page.vue';
import Section from '../../../components/Section.vue';
import FormTable from '../../../components/FormTable.vue';
import notifications from '../../../mixins/notifications';
import { scrapingRuleSubjects, scrapingRuleTypes, transformationType } from '@/constants';

const initialFormData = {
	type: scrapingRuleTypes.HTML,
	subject: scrapingRuleSubjects.Recipe,
	rules: {}
};

export default {
	components: {
		Layout,
		Page,
		ContentHeader,
		OfFormRadio,
		OfFormSelect,
		OfFormInput,
		OfSubmitButton,
		Section,
		FormTable
	},
	mixins: [withForm('scrapingRuleForm'), notifications],
	data() {
		return {
			typeOptions: _.map(scrapingRuleTypes, value => ({ text: value, value: value })),
			transformationTypeOptions: _.map(transformationType, (value, key) => ({ text: key, value })),
			subjectOptions: _.map(scrapingRuleSubjects, value => ({ text: value, value: value })),
			transformationFields: [
				{ label: this.$t('Selector'), key: 'selector' },
				{ label: this.$t('Type'), key: 'type' },
				{ label: this.$t('Transformation Configuration'), key: 'value' },
				{ label: '', key: 'actions' }
			],
			additionalFields: [
				{ label: this.$t('Field Name'), key: 'fieldName' },
				{ label: this.$t('Selector'), key: 'selector' },
				{ label: '', key: 'actions' }
			]
		};
	},
	computed: {
		...mapGetters({
			scrapingRule: 'scraping-rule/scraping-rule'
		}),
		headerTitle() {
			return this.scrapingRuleId ? _.get(this.formData, 'name') : this.$t('New scraping rule');
		},
		breadcrumbs() {
			const listRoute = this.$router.resolve({ name: 'scraping-rules.list' });

			return [
				{ text: this.$t('Scraping rules'), href: listRoute.href },
				{ text: this.headerTitle, href: '#' }
			];
		},
		scrapingRuleId() {
			const id = _.get(this.$route, 'params.id', 'new');
			return id !== 'new' ? id : null;
		},
		isJsonRule() {
			return this.formData.type === scrapingRuleTypes.JSON;
		},
		isRecipeRule() {
			return _.get(this.formData, 'subject') === scrapingRuleSubjects.Recipe;
		},
		bookRulesValidators() {
			return [
				{
					sectionPath: 'rules.acknowledgements',
					validationRules: {
						paragraphs: {
							required: validateWithMessage(this.$t('Message is required'), required)
						}
					}
				},
				{
					sectionPath: 'rules.chapters',
					validationRules: {
						path: {
							required: validateWithMessage(this.$t('Path is required'), required)
						},
						name: {
							required: validateWithMessage(this.$t('Chapter Title is required'), required)
						},
						documentIds: {
							required: validateWithMessage(this.$t('Documents Ids is required'), required)
						}
					}
				},
				{
					sectionPath: 'rules.introduction',
					validationRules: {
						paragraphs: {
							required: validateWithMessage(this.$t('Body copy is required'), required)
						},
						images: {
							required: validateWithMessage(this.$t('Images is required'), required)
						}
					}
				},
				{
					sectionPath: 'rules.tableOfContents',
					validationRules: {
						images: {
							required: validateWithMessage(this.$t('Images is required'), required)
						}
					}
				},
				{
					sectionPath: 'rules.cover',
					validationRules: {
						description: {
							required: validateWithMessage(this.$t('Back cover text is required'), required)
						}
					}
				}
			];
		},
		validationRules() {
			const rules = {
				formData: {
					name: {
						required: validateWithMessage(this.$t('Name is required'), required)
					}
				}
			};
			if (!this.isRecipeRule) {
				const { formData } = this;
				_.forEach(this.bookRulesValidators, ({ sectionPath, validationRules }) => {
					const section = _.get(formData, sectionPath);
					if (section && !this.isEmptySection(section)) {
						_.set(rules.formData, sectionPath, validationRules);
					}
				});
			}
			return rules;
		}
	},
	watch: {
		async scrapingRuleId() {
			await this.initialize();
		}
	},
	async mounted() {
		await this.initialize();
	},
	methods: {
		...mapActions({
			findScrapingRuleById: 'scraping-rule/findById',
			updateScrapingRule: 'scraping-rule/update',
			createScrapingRule: 'scraping-rule/create',
			deleteScrapingRuleById: 'scraping-rule/deleteById'
		}),
		async initialize() {
			let formData = initialFormData;
			if (this.scrapingRuleId) {
				formData = await this.findScrapingRuleById({ id: this.scrapingRuleId });
			}
			this.initFormData(formData);
		},
		addTransformation() {
			const dataTransformations = _.get(this.formData, 'dataTransformations', []);
			const transformation = { selector: '', type: '', value: '' };

			this.updateField('dataTransformations', [...dataTransformations, transformation]);
		},
		removeTransformation(index) {
			const dataTransformations = _.filter(this.formData.dataTransformations, (_, idx) => idx !== index);
			this.updateField('dataTransformations', dataTransformations);
		},
		addAdditionalField() {
			const additionalFields = _.get(this.formData, 'additionalFields', []);
			const field = { fieldName: '', selector: '' };

			this.updateField('additionalFields', [...additionalFields, field]);
		},
		removeAdditionalField(index) {
			const additionalFields = _.filter(this.formData.additionalFields, (_, idx) => idx !== index);
			this.updateField('additionalFields', additionalFields);
		},
		async onRemove() {
			const isConfirmed = await this.confirmAction(this.$t('Are you sure you want to delete?'));
			if (!isConfirmed) return;

			try {
				await this.deleteScrapingRuleById({ id: this.scrapingRuleId });
				this.$router.push({ name: 'scraping-rules.list' });
			} catch (err) {
				this.notifyError(error);
			}
		},
		async onSave() {
			try {
				if (this.formData._id) {
					await this.submitUpdate();
				} else {
					await this.submitCreate();
				}
			} catch (error) {
				this.notifyError(error);
			}
		},
		async submitCreate() {
			const dataToSave = this.isRecipeRule ? this.formData : this.prepareDataToSave(this.formData);
			const scrapingRule = await this.dispatchSubmit(this.createScrapingRule(dataToSave));
			this.$router.push({ name: 'scraping-rules.edit', params: { id: scrapingRule._id } });

			this.notifySuccess({
				title: this.$t('Success'),
				text: this.$t('Scraping Rule has been created successfully')
			});
		},
		async submitUpdate() {
			const dataToSave = this.isRecipeRule ? this.formData : this.prepareDataToSave(this.formData);
			await this.dispatchSubmit(this.updateScrapingRule({ id: this.formData._id, data: dataToSave }));

			this.notifySuccess({
				title: this.$t('Success'),
				text: this.$t('Scraping Rule has been updated')
			});
		},
		onCancel() {
			this.$router.push({ name: 'scraping-rules.list' });
		},
		isEmptySection(section) {
			return _.every(_.omit(section, '_id'), v => _.isEmpty(v));
		},
		prepareDataToSave(orginalData) {
			const dataToSave = _.cloneDeep(orginalData);
			_.forEach(this.bookRulesValidators, ({ sectionPath }) => {
				const section = _.get(dataToSave, sectionPath);
				if (!section || this.isEmptySection(section)) {
					_.set(dataToSave, sectionPath, undefined);
				}
			});
			return dataToSave;
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/_variables.scss';

.ScrappingRuleEdit {
	.form-row > .col {
		padding: 0;
	}

	section {
		margin-bottom: 20px;
	}

	.RulesRow {
		margin: 0;

		.RulesColumn {
			height: 100%;
			padding: 10px 13px;
			background-color: $color-hp-light;
			border-radius: $of-border-radius;
		}
	}
}
</style>
