module.exports = {
	name: 'Page Number',
	template: [
		{
			'?': {
				mergeObjects: [
					{
						type: 'text',
						props: {
							value: { '?': { var: 'pageNumber' } },
							fontFamily: 'Poppins-Bold',
							position: 'absolute',
							left: {
								'?': {
									if: [
										{
											'===': [{ '%': [{ var: 'pageNumber' }, 2] }, 0]
										},
										'63px',
										'initial'
									]
								}
							},
							right: {
								'?': {
									if: [
										{
											'===': [{ '%': [{ var: 'pageNumber' }, 2] }, 0]
										},
										'initial',
										'63px'
									]
								}
							},
							bottom: '33px',
							fontSize: '8pt',
							color: 'black'
						}
					}
				]
			}
		}
	]
};
