<template>
	<Layout>
		<Page>
			<ListTable
				bordered
				hover
				:table-title="$t('Books')"
				:config="config"
				:items="productContents.data"
				:fields="fields"
				:sort="sort"
				:total-items="productContents.total"
				:current-page="currentPage"
				:per-page="perPage"
				:fetch-data="fetchData"
				:is-busy="isProductContentsFetching"
				@row-clicked="handleRowClick"
				@table-change="handleTableChange"
			>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<SearchInput v-model="searchString" :placeholder="$t('Search books by name...')" class="mr-2" />
					<OfInlineFilter class="mr-2" :filters="filters" :values="filterValues" @change="filtersChanged" />
				</template>

				<template slot="TableButtons-Slot-right" slot-scope="{}">
					<div class="TableButtons-Right">
						<b-button variant="primary" @click="handleAdd">{{ $t('New book') }}</b-button>
					</div>
				</template>

				<template slot="TableHeader" slot-scope="{}">
					<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
				</template>

				<template #cell(type)="{ item }">
					{{ item.product ? getBookType(item.product.formats) : '' }}
				</template>

				<template #cell(documents)="{ item }">
					{{ getDocumentsCount(item) }}
				</template>

				<template #cell(status)="{ item }">
					<OfsBadge :text="item.status" :status="item.status" />
				</template>

				<template #cell(updatedAt)="{ item }">
					{{ fromNow(item.updatedAt) }}
				</template>

				<template #cell(actions)="{ item }">
					<b-button
						size="sm"
						class="mr-2"
						:disabled="!enableBookPreview(item)"
						@click.stop="redirectToBookPreviewPage(item._id)"
					>
						{{ $t('Preview') }}
					</b-button>

					<b-dropdown size="sm" toggle-class="p-1" right no-caret>
						<template #button-content>
							<icon name="ellipsis-h" />
						</template>
						<b-dropdown-item @click.stop="handleDelete(item._id)">
							<icon name="trash" class="mr-2" /> {{ $t('Delete') }}
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</ListTable>
		</Page>
	</Layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ListTable, OfInlineFilter, OfFilterBar, OfsBadge } from '@oneflow/ofs-vue-layout';
import Layout from '../../../components/Layout';
import Page from '../../../components/Page';
import SearchInput from '../../../components/SearchInput';
import notifications from '../../../mixins/notifications';
import { fromNow } from '../../../lib/dateFormatters';
import { productContentStatusOptions } from '../../../constants';

export default {
	components: {
		Page,
		Layout,
		ListTable,
		OfInlineFilter,
		OfFilterBar,
		SearchInput,
		OfsBadge
	},
	mixins: [notifications],
	data() {
		const fields = [
			{ label: this.$t('Name'), key: 'content.headline', sortable: true },
			{ label: this.$t('Type'), key: 'type' },
			{ label: this.$t('Recipes'), key: 'documents' },
			{ label: this.$t('Status'), key: 'status' },
			{ label: this.$t('Last modified'), key: 'updatedAt', sortable: true },
			{ label: '', key: 'actions', tdClass: 'text-right' }
		];
		const filters = [
			{
				header: this.$t('Status'),
				key: 'status',
				type: 'radio',
				items: _.map(productContentStatusOptions, ({ text, value }) => ({ title: text, value }))
			}
		];
		return {
			items: [],
			currentPage: 1,
			perPage: 10,
			fields,
			filters,
			filterValues: {},
			sort: {
				updatedAt: -1
			},
			config: {},
			searchString: '',
			bookSpecifications: []
		};
	},
	computed: {
		...mapGetters({
			productContents: 'product-content/product-contents',
			isProductContentsFetching: 'product-content/isFetching'
		})
	},
	watch: {
		searchString: _.debounce(async function(searchString, oldSearchString) {
			if (searchString !== oldSearchString) {
				this.currentPage = 1;
				await this.fetchData();
			}
		}, 800)
	},
	async mounted() {
		await this.fetchData();
		await this.fetchSpecifications();
	},
	methods: {
		fromNow,
		...mapActions({
			findProductContents: 'product-content/find',
			deleteProductContentById: 'product-content/deleteById',
			findProductSpecifications: 'product-content/findSpecifications'
		}),
		async fetchData() {
			try {
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$where: {}
				};
				if (!_.isEmpty(this.filterValues)) {
					query.$where = _.reduce(
						this.filterValues,
						($where, value, key) => ({
							...$where,
							[key]: Array.isArray(value) ? { $in: value } : value
						}),
						{}
					);
				}
				if (!_.isEmpty(this.searchString)) {
					query.$where['content.headline'] = { $regex: this.searchString, $options: 'i' };
				}
				query.$sort = !_.isEmpty(this.sort) ? this.sort : { name: 1 };
				query.$sort._id = 1;
				query.$populate = { path: 'product' };
				await this.findProductContents({ query: { query } });
			} catch (error) {
				this.notifyError(error, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching books')
				});
			}
		},
		async filtersChanged(filters) {
			this.currentPage = 1;
			this.filterValues = filters;

			await this.fetchData();
		},
		async fetchSpecifications() {
			try {
				const specifications = await this.findProductSpecifications();
				this.bookSpecifications = _.map(specifications, ({ name, label, sku }, code) => ({
					name,
					label,
					sku,
					code
				}));
			} catch (error) {
				this.notifyError(error, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching available specifications')
				});
			}
		},
		handleTableChange({ currentPage, perPage, filter, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (filter !== undefined) {
				this.filter = filter;
			}
			if (sort) {
				this.sort = sort;
			}
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({ name: 'books.edit', params: { id: item._id } });
		},
		async handleAdd() {
			this.$router.push({ name: 'books.create' });
		},
		async handleDelete(id) {
			const isConfirmed = await this.confirmAction(this.$t('Are you sure you want to delete?'));

			if (isConfirmed) {
				await this.deleteProductContentById({ id });
				await this.fetchData();
			}
		},
		getDocumentsCount(productContent) {
			return _.sumBy(productContent.content.chapters, chapter => _.get(chapter, 'documentIds.length', 0));
		},
		getBookType(formats) {
			const formatsSku = formats.filter(format => format.siteflowSKU).map(format => format.siteflowSKU);
			if (formatsSku.length > 1) {
				return formatsSku?.join(', ');
			} else {
				const bookSpecification = _.find(
					this.bookSpecifications,
					specification => specification.sku === formats[0].siteflowSKU
				);
				return _.get(bookSpecification, 'name', 'Custom book');
			}
		},
		enableBookPreview(item) {
			return _.get(item, 'product.templateId');
		},
		redirectToBookPreviewPage(id) {
			this.$router.push({ name: 'books.preview', params: { id } });
		}
	}
};
</script>

<style lang="scss">
@import 'src/style/index.scss';

.PresetDropdown {
	&-Item a {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.dropdown-label .b-dropdown-text {
		@include ofTextStyleFormLabels();

		font-weight: $of-font-weight-bold;
		text-transform: uppercase;
	}
}
</style>
