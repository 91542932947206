<template>
	<FullscreenLayout>
		<b-form novalidate @submit.prevent="onSave">
			<b-container class="BookCreate">
				<h1 class="BookCreate-header">{{ $t('Create a new recipe book') }}</h1>

				<section class="BookCreate-section">
					<label>{{ $t('1. Your books title') }}</label>
					<OfFormInput name="name" :placeholder="$t('The name of your book')" no-label required />
				</section>

				<section class="BookCreate-section BoundSection">
					<label>{{ $t('2. How would you like your book bound?') }}</label>
					<div class="BookOptionsGrid" :class="{ 'BookOptionsGrid--selected': !!formData.specificationCode }">
						<div
							v-for="specification in bookSpecifications"
							:key="specification.code"
							class="OptionItem"
							:class="{ 'OptionItem--active': specification.code === formData.specificationCode }"
							@click="selectSpecification(specification.code)"
						>
							<div class="OptionItem-image">
								<div class="OptionImage-mask"></div>
								<img :src="specification.image" :alt="specification.name" />
							</div>
							<div class="OptionItem-title text-center mt-3">{{ specification.name }}</div>
						</div>
					</div>
				</section>

				<section class="BookCreate-section">
					<label>{{ $t('3. Select a template for your book') }}</label>
					<div class="BookOptionsGrid" :class="{ 'BookOptionsGrid--selected': !!formData.layoutId }">
						<div v-for="layout in layouts" :key="layout.id">
							<div
								class="OptionItem"
								:class="{ 'OptionItem--active': layout.id === formData.layoutId }"
								@click="selectLayout(layout.id)"
							>
								<img :src="getLayoutThumbnail(layout)" />
								<div class="OptionItem-title text-center mt-3">{{ layout.name }}</div>
							</div>
						</div>
					</div>
				</section>

				<OfSubmitButton
					:disabled="!canSubmit"
					variant="primary"
					class="CreateBtn"
					:class="{ 'CreateBtn--hidden': isInvalid }"
				>
					{{ $t('Create Book') }}
				</OfSubmitButton>
			</b-container>
		</b-form>
	</FullscreenLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { OfFormInput, OfSubmitButton, withForm } from '@oneflow/ofs-vue-layout';
import FullscreenLayout from '../../../../components/layouts/FullscreenLayout.vue';
import notifications from '../../../../mixins/notifications';
import { templateFormat, specificationImage } from '../../../../constants';
import placeholder from '../../../../assets/placeholder.svg';

export default {
	components: {
		FullscreenLayout,
		OfFormInput,
		OfSubmitButton
	},
	mixins: [notifications, withForm('bookCreate')],
	data() {
		return {
			activeLayoutId: null,
			bookSpecifications: []
		};
	},
	computed: {
		...mapGetters({
			layouts: 'layout/layouts'
		}),
		validationRules() {
			return {
				formData: {
					layoutId: { required },
					specificationCode: { required }
				}
			};
		}
	},
	async mounted() {
		await Promise.all([this.fetchLayouts(), this.fetchSpecifications()]);
	},
	methods: {
		...mapActions({
			findAllLayouts: 'layout/findAll',
			createTemplateFromLayout: 'template/createFromLayout',
			createProductContent: 'product-content/create',
			findProductSpecifications: 'product-content/findSpecifications'
		}),
		async fetchLayouts() {
			const query = {
				$where: { format: templateFormat.Json, active: true, name: { $not: { $regex: 'separation' } } },
				$select: ['name', 'previewImageUrl']
			};
			await this.findAllLayouts({ query: { query } });
		},
		async fetchSpecifications() {
			let specifications = [];
			try {
				specifications = await this.findProductSpecifications();
			} catch (error) {
				this.notifyError(error, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching available specifications')
				});
			}

			this.bookSpecifications = _.reduce(
				specifications,
				(result, spec, code) => [...result, { code, ...spec, image: specificationImage[code] }],
				[]
			);
		},
		getLayoutThumbnail(layout) {
			return _.get(layout, 'previewImageUrl', placeholder);
		},
		selectLayout(layoutId) {
			const value = this.formData.layoutId === layoutId ? null : layoutId;
			this.updateField('layoutId', value);
		},
		selectSpecification(code) {
			const value = this.formData.specificationCode === code ? null : code;
			this.updateField('specificationCode', value);
		},
		getTemplateName() {
			const { name, layoutId } = this.formData;
			const layout = _.find(this.layouts, ['_id', layoutId]);

			return `${name} / ${layout.name} / ${new Date().toISOString()}`;
		},
		async onSave() {
			try {
				const productContent = await this.dispatchSubmit(this.createBookWithTemplate());
				this.$router.push({ name: 'books.edit', params: { id: productContent._id } });

				this.notifySuccess({
					title: this.$t('Success'),
					text: this.$t('Book has been created successfully')
				});
			} catch (error) {
				this.notifyError(error);
			}
		},
		async createBookWithTemplate() {
			const { name, layoutId, specificationCode } = this.formData;
			const template = await this.createTemplateFromLayout({
				name: this.getTemplateName(),
				layoutId
			});
			return this.createProductContent({
				name,
				specificationCode,
				templateId: template.id,
				content: { headline: name, subtitle1: name }
			});
		}
	}
};
</script>

<style lang="scss">
@import '../../../../style/_variables';

.BookCreate {
	position: relative;
	margin-top: 45px;

	&.container {
		height: calc(100vh - 95px);
		overflow-y: scroll;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: $color-hp-grey-2;
			border-radius: 6px;
			height: 300px;
			max-height: 300px;
		}

		&::-webkit-scrollbar-track-piece {
			margin-bottom: 60px;
		}
	}

	@media (min-width: 1200px) {
		&.container {
			max-width: 940px;
			padding: 0 30px;
		}
	}

	&-header {
		margin-bottom: 80px;
		font-size: 36px;
		font-weight: 300;
		text-align: center;
	}

	&-section {
		margin-top: 60px;

		&:last-of-type {
			margin-bottom: 60px;
		}

		label {
			font-weight: bold;
			font-size: 20px;
			line-height: 22px;
			margin-bottom: 18px;
		}

		input[type='text'] {
			height: 50px;
			font-size: 22px;
			text-align: center;

			&:focus {
				background-color: $color-hp-light;
			}
		}
	}

	.BookOptionsGrid {
		display: grid;
		grid-gap: 30px;
		grid-template-columns: repeat(auto-fill, 250px);
		justify-content: space-between;
		margin-top: 20px;

		&--selected img {
			opacity: 0.75;
			filter: grayscale(100%);
		}

		.OptionItem {
			display: flex;
			justify-content: center;
			flex-direction: column;
			cursor: pointer;

			img {
				width: 100%;
				background: $color-hp-grey-3;
				border-radius: 5px;
				border: 2px solid transparent;
			}

			&-title {
				font-weight: 600;
				font-size: 18px;
				line-height: 22px;
			}

			&--active img {
				border-color: #fff;
				outline: 2px solid $color-pimienta;
				opacity: 1;
				filter: none;
			}
		}
	}

	section.BoundSection {
		.BookOptionsGrid {
			grid-template-columns: repeat(auto-fill, 170px);
			grid-template-rows: repeat(auto-fill, 170px);
			grid-gap: 20px;

			.OptionItem {
				&-image {
					position: relative;
					height: 100%;

					img {
						height: 100%;
						border-radius: 50%;
						object-position: 20px 15px;
					}

					&:hover {
						.OptionImage-mask {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							position: absolute;
							background: rgba(0, 0, 0, 0.2);
						}
					}
				}
			}
		}
	}

	.CreateBtn {
		position: fixed;
		bottom: 25px;
		right: 30px;
		width: 200px;

		&--hidden {
			visibility: hidden;
		}

		@media (max-width: 1400px) {
			position: initial;
			width: 100%;
			margin-bottom: 20px;
		}
	}
}
</style>
