const layout = require('./layout');
const blocks = require('./blocks');
const theme = require('./theme');
const fonts = require('./fonts');
const data = require('./data');

module.exports = {
	name: 'artisan',
	layout,
	theme,
	fonts,
	blocks,
	data
};
