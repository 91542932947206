<template>
	<div class="input-area" @paste.prevent.stop="pasteHandler($event)" @drop.prevent.stop="onInput" @dragover.prevent>
		<div class="background-container">
			<slot name="background"> </slot>
		</div>
		<div class="content-container">
			<slot name="content"></slot>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		pasteHandler(event) {
			const text = (event.clipboardData || window.clipboardData).getData('text');
			if (text) {
				this.$emit('textInput', text);
			}
		},
		onInput(event) {
			const text = event.dataTransfer.getData('Text');
			if (text) {
				this.$emit('textInput', text);
				return;
			}

			if (event.dataTransfer.files.length > 0) {
				this.$emit('fileInput', event.dataTransfer.files);
			}
		}
	}
};
</script>

<style lang="scss">
@import '../../style/_variables.scss';
.background-container {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.content-container {
	position: relative;
}
.input-area {
	position: relative;
	width: 100%;
	min-height: 300px;
	overflow: hidden;
	display: block;
	background-color: $color-hp-light;
	z-index: 2;
}
</style>
