import Vue from 'vue';

export const fetchBaseAccountData = async ({ dispatch, rootGetters, getters }) => {
	const currentAccount = getters['currentAccount'];
	const isAuthenticated = rootGetters['auth0/isAuthenticated'];
	if (isAuthenticated) {
		await dispatch('account/getUserAccounts', null, { root: true });
		const accounts = getters['accounts'];
		let account = currentAccount;

		if (!account) {
			account = accounts[0];
			if (!account) {
				await dispatch('auth0/logOut', null, { root: true });
			}
			await dispatch('account/setCurrentAccount', account, { root: true });
		}

		return account;
	}

	return null;
};

export const getUserAccounts = async ({ commit, dispatch }) => {
	const { data } = await dispatch('request', { method: 'GET', path: 'accounts/user-accounts' });
	commit('SET_USER_ACCOUNTS', data);
};

export const setCurrentAccount = ({ commit }, account) => {
	commit('SET_CURRENT_ACCOUNT', account);
};
