const $t = str => str;

export const sectionComponentType = 'SECTION';
export const instructionComponentType = 'INSTRUCTION';
export const ingredientComponentType = 'INGREDIENT';

export const componentSchemas = {
	[sectionComponentType]: {
		name: null,
		elements: []
	},
	[instructionComponentType]: {
		text: null
	},
	[ingredientComponentType]: {
		quantity: 0,
		unit: '',
		name: null
	}
};

export const defaultRecipeSchema = {
	_id: '',
	sourceURL: '',
	name: '',
	author: '',
	category: '',
	cuisine: '',
	notes: '',
	description: '',
	ingredients: {
		name: 'Ingredients',
		elements: []
	},
	instructions: {
		name: 'Instructions',
		elements: []
	},
	tags: [],
	yield: '',
	images: [],
	nutrition: {
		calories: 0,
		fatContent: 0,
		proteinContent: 0,
		carbohydrateContent: 0
	},
	prepTime: '',
	cookTime: '',
	totalTime: '',
	qrCode: '',
	size: null,
	calculateSize: true,
	isImageWithParagraph: false,
	imageParagraph: '',
	imageOrientation: 'landscape',
	links: []
};

export const scrapingRuleTypes = {
	HTML: 'HTML',
	JSON: 'JSON'
};

export const scrapingRuleSubjects = {
	Recipe: 'recipe',
	Book: 'book'
};

export const exportTypes = {
	PDF: 'PDF',
	JSON: 'JSON'
};

export const exportTypesOptions = [
	{ text: $t('PDF'), value: exportTypes.PDF },
	{ text: $t('JSON'), value: exportTypes.JSON }
];

export const transformationType = {
	HTML2Array: 'HTML2Array',
	SetValue: 'SetValue',
	AddProperty: 'AddProperty',
	ConcatWithField: 'ConcatWithField'
};

export const productContentStatus = {
	Draft: 'draft',
	Live: 'live'
};

export const productContentStatusOptions = [
	{ text: $t('Draft'), value: productContentStatus.Draft },
	{ text: $t('Live'), value: productContentStatus.Live }
];

export const templateFormat = {
	Html: 'html',
	Json: 'json'
};

export const specificationImage = {
	'hardcover-210x210': require('./assets/images/hardcover.png'),
	'wire-bound-210x210': require('./assets/images/wirebound.png'),
	'softcase-210x210': require('./assets/images/softcase.png')
};

export const downloadStatuses = {
	DOWNLOADING: 'DOWNLOADING',
	PROCESSING_IMAGES: 'PROCESSING_IMAGES',
	WRITING_TO_PDF: 'WRITING_TO_PDF'
};

export const productType = {
	Personalised: 'personalised',
	Standard: 'standard'
};

export const documentSizes = {
	SHORT: 'SHORT',
	STANDARD: 'STANDARD',
	STANDARD_WITH_IMAGE: 'STANDARD_WITH_IMAGE',
	LONG: 'LONG'
};

export const documentSizesOptions = [
	{ text: $t('Short'), value: documentSizes.SHORT },
	{ text: $t('Without image'), value: documentSizes.STANDARD },
	{ text: $t('Standard'), value: documentSizes.STANDARD_WITH_IMAGE },
	{ text: $t('Long'), value: documentSizes.LONG }
];

export const productComponents = {
	cover: 'cover',
	coverFront: 'cover-front',
	coverBack: 'cover-back'
};
export const recipeImagePositions = {
	LEFT: 'left',
	RIGHT: 'right'
};

export const recipeImagePositionOptions = [
	{ text: $t('Left'), value: recipeImagePositions.LEFT },
	{ text: $t('Right'), value: recipeImagePositions.RIGHT }
];

export const coverComponentTypes = [productComponents.cover, productComponents.coverFront, productComponents.coverBack];

export const coverFields = {
	author: 'author',
	subtitle: 'subtitle'
};

export const coverTypes = {
	primary: 'primary',
	secondary: 'secondary'
};

export const imageOrientations = {
	landscape: 'landscape',
	vertical: 'vertical'
};

export const imageOrientationOptions = [
	{ text: $t('Landscape'), value: imageOrientations.landscape },
	{ text: $t('Vertical'), value: imageOrientations.vertical }
];

export const featureFlags = {
	coverInnerSeparation: 'cover-inner-separation'
};

export const sourceOptions = [
	{ text: $t('Instagram'), value: 'instagram' },
	{ text: $t('Facebook'), value: 'facebook' },
	{ text: $t('Twitter'), value: 'twitter' },
	{ text: $t('Youtube'), value: 'youtube' },
	{ text: $t('Tiktok'), value: 'tiktok' },
	{ text: $t('Website'), value: 'website' }
];

export const itemTypes = {
	recipe: 'Recipe'
};
