module.exports = {
    "name": "Cover Spine",
    "template": [
        {
            "name": "Cover Spine",
            "type": "container",
            "props": {
                "width": {
                    "?": {
                        "var": "$theme.coverSpineWidth"
                    }
                },
                "height": {
                    "?": {
                        "var": "$theme.coverPageHeight"
                    }
                },
                "display": "flex",
                "alignItems": "center",
                "flexDirection": "column",
                "backgroundColor": {
                    "?": {
                        "var": "$theme.cover.spine.backgroundColor"
                    }
                },
                "color": {
                    "?": {
                        "var": "$theme.cover.spine.textColor"
                    }
                },
                "padding": "45px 0"
            },
            "children": [
                {
                    "name": "Title",
                    "type": "text",
                    "props": {
                        "fontSize": "23pt",
                        "fontWeight": "900",
                        "letterSpacing": "2.5px",
                        "fontFamily": {
                            "?": {
                                "var": "$theme.cover.front.titleFont"
                            }
                        },
                        "textTransform": "uppercase",
                        "value": {
                            "?": {
                                "var": "$props.title"
                            }
                        },
                        "writingMode": "vertical-rl"
                    }
                }
            ]
        }
    ]
}