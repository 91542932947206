<template>
	<div>
		<b-col>
			<draggable
				:value="localValue"
				class="treeview"
				ghost-class="ghost"
				handle=".drag-handle"
				@input="updateValue"
			>
				<draggable-tree-view-node
					v-for="(nodeItem, index) in value"
					:key="index"
					:section-index="index"
					:value="nodeItem"
					:tab="tab"
					@input="value => updateItem(value, index)"
					@remove="removeElement(index)"
				>
					<template #prepend="{ item, open }">
						<slot name="prepend" v-bind="{ item, open }"> </slot>
					</template>
					<template #label="{ item, open }">
						<slot name="label" v-bind="{ item, open }"> </slot>
					</template>
					<template #append="{ item, open }">
						<slot name="append" v-bind="{ item, open }"> </slot>
					</template>
				</draggable-tree-view-node>
			</draggable>
		</b-col>

		<b-col>
			<b-button variant="outline-primary" class="AddSectionBtn mt-1 btn-block" @click="onAddSection">
				<icon name="plus" />
				{{ $t('Add Section') }}
			</b-button>
		</b-col>
	</div>
</template>

<script>
import Vue, { PropType } from 'vue';
import draggable from 'vuedraggable';
import DraggableTreeViewNode from './DraggableTreeviewNode.vue';
import { componentSchemas, sectionComponentType } from '../../constants';

export default Vue.extend({
	components: {
		draggable,
		DraggableTreeViewNode
	},
	props: {
		value: {
			type: Array,
			default: () => []
		},
		tab: {
			type: String,
			default: () => 'ingredients'
		}
	},
	data() {
		return {
			localValue: [...this.value]
		};
	},
	watch: {
		value(value) {
			this.localValue = [...value];
		}
	},
	methods: {
		onAddSection() {
			this.addComponentItem(sectionComponentType);
		},
		updateValue(value) {
			this.localValue = value;
			this.$emit('input', this.localValue);
		},
		removeElement(index) {
			this.localValue.splice(index, 1);
			this.$emit('input', this.localValue);
		},
		updateItem(value, index) {
			if (value) {
				this.$set(this.localValue, index, value);
			} else {
				this.localValue.splice(index, 1);
			}
			this.$emit('input', this.localValue);
		},
		addComponentItem(componentType) {
			const newItem = _.cloneDeep(componentSchemas[componentType]);
			this.localValue.push(newItem);
			this.$emit('input', this.localValue);
		}
	}
});
</script>

<style lang="scss">
.AddSectionBtn {
	.fa-icon {
		margin-right: 7px;
		vertical-align: -0.25em;
	}
}
</style>
