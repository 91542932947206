module.exports = [
	{
		fontFamily: 'Poppins',
		url: 'https://oneflow-file-core-api-dev-new-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/52c323b6-366c-44e4-81d9-31e7df1bd6ca?AWSAccessKeyId=AKIAJXKUBB3FVPIZFSRA&Expires=1940162980&Signature=kRogiD%2BQaJ%2FNmNzHocmy%2FXr7hy8%3D&response-content-disposition=attachment%3B%20filename%3D%22Poppins-ExtraLight.ttf%22'
	},
	{
		fontFamily: 'PoppinsLight',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/5a050df7-87e5-475f-a2c3-8748195e57e4?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941200839&Signature=SShDODBsLaG2apzRUg5n8H0xMmY%3D&response-content-disposition=attachment%3B%20filename%3D%22Poppins-Light.ttf%22'
	},
	{
		fontFamily: 'Poppins-bold',
		url: 'https://oneflow-file-core-api-dev-new-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/8ccb5f1e-b579-48fa-bd1e-69e034e8ac09?AWSAccessKeyId=AKIAJXKUBB3FVPIZFSRA&Expires=1940163330&Signature=cNhcBnNf6SpK23uX8lefN5RdFm0%3D&response-content-disposition=attachment%3B%20filename%3D%22Poppins-Bold.ttf%22'
	},
	{
		fontFamily: 'PoppinsMedium',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/bd125e98-6b61-4ce1-97e6-214c4e332b3e?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941202198&Signature=JIPsp6KzQ%2B7dXB9tYveOPuQXoNY%3D&response-content-disposition=attachment%3B%20filename%3D%22Poppins-Medium.ttf%22'
	},
	{
		fontFamily: 'MachoMedium',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/be6688d7-4a7d-4c3f-91a0-3709b02985a3?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941200852&Signature=ssTCrigX80LulrGj8bn6AhJYb6U%3D&response-content-disposition=attachment%3B%20filename%3D%22Macho-Medium.ttf%22'
	},
	{
		fontFamily: 'GothamCondensedBook',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/d1529aef-fa83-42d0-81b6-165599ca91db?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941200865&Signature=YXE5t7aAHlyjyhP45PyMq7kbw8w%3D&response-content-disposition=attachment%3B%20filename%3D%22GothamCondensed-Book.otf%22'
	},
	{
		fontFamily: 'GothamCondensedMedium',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/42d2664e-9946-4ff3-a833-b9efc2245d6e?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941200875&Signature=keC3UOw567p1a1L7kOedmn5F6BQ%3D&response-content-disposition=attachment%3B%20filename%3D%22GothamCondensed-Medium.otf%22'
	},
	{
		fontFamily: 'GothamBook',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/0a8d28de-94d8-4057-a0cf-9cec2a1b54c7?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941564194&Signature=imGivDj48qnnPsZJMClST0samMk%3D&response-content-disposition=attachment%3B%20filename%3D%22Gotham%20Condensed%20Book%20Regular.otf%22'
	},
	{
		fontFamily: 'FlowersScript',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/e5ceab62-c3fb-40bb-8bcc-aa398da7bb5e?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941564171&Signature=XkEy0w7qtNl3Zpx3r5fda8tk7bc%3D&response-content-disposition=attachment%3B%20filename%3D%22LookingFlowers-Script.ttf%22'
	},
	{
		fontFamily: 'ACaslonPro-bold',
		url: 'https://oneflow-file-core-api-dev-new-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/56446d6a-a931-47e7-af03-faa8ee05de96?AWSAccessKeyId=AKIAJXKUBB3FVPIZFSRA&Expires=1940158427&Signature=VOjEKq8QegGKtmWGqtrDXJL1aSM%3D&response-content-disposition=attachment%3B%20filename%3D%22ACaslonPro-Bold.otf%22'
	},
	{
		fontFamily: 'CrimsonPro-Bold',
		url: 'https://oneflow-file-core-api-dev-new-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/77e4f7e9-6bee-4b12-8bfe-e99a8126344b?AWSAccessKeyId=AKIAJXKUBB3FVPIZFSRA&Expires=1939102091&Signature=fq01f5aWeCJIEcwBmdAweMly3rE%3D&response-content-disposition=attachment%3B%20filename%3D%22CrimsonPro-Bold.ttf%22'
	},
	{
		fontFamily: 'AshemoreNormLight',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/3e531bb6-e66d-4c23-adf6-b24176e3d558?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941201878&Signature=VtPbnBX2W8XBxhAlu3KRMIosrck%3D&response-content-disposition=attachment%3B%20filename%3D%22Ashemore-NormLight.ttf%22'
	},
	{
		fontFamily: 'AzoSansThin',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/a42443bd-76f8-46a2-91bd-0838b5f1b224?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941202208&Signature=St1JudM11igsxQpF6ewZNqiLXro%3D&response-content-disposition=attachment%3B%20filename%3D%22AzoSans-Thin.ttf%22'
	}
];
