<template>
	<b-modal
		:id="modalId"
		ref="importModal"
		body-class="p-0"
		:title="modalTitle || $t('Import Recipes')"
		size="xl"
		scrollable
		no-close-on-esc
		no-close-on-backdrop
		modal-class="Import-modal"
	>
		<template #modal-header-close>
			<b-btn-close @click.prevent.stop="handleCancel"></b-btn-close>
		</template>
		<b-tabs
			v-model="tabIndex"
			card
			pills
			vertical
			class="import-modal-tabs tabs"
			nav-wrapper-class="Import-modal__nav-tabs"
			active-nav-item-class="Import-modal__tab-active"
			content-class="Import-modal__content"
			nav-class="Import-modal__nav"
		>
			<b-tab
				:title-item-class="{ 'Import-modal__tab-invisible': !isRecipeMode }"
				:disabled="!isRecipeMode"
				no-body
				title="From URL"
				class="pr-0"
			>
				<ImportArea @textInput="textInputHandler('htmlItems', $event)">
					<template slot="background">
						<div
							v-show="Array.isArray(htmlItems) && htmlItems.length === 0"
							class="Background-container"
							contenteditable="false"
						>
							<img src="../../../public/images/upload-icon.svg" alt="upload-icon" />
							<p class="Background-container__paragraph--bold">{{ $t('Paste URL links to import') }}</p>
							<p class="Background-container__paragraph--tiny">
								{{ $t('You can import multiple links') }}
							</p>
						</div>
					</template>
					<template slot="content">
						<div class="w-100 Import-modal__scraping-rules">
							<b-form-group
								style="flex-grow: 1; padding-right: 30px"
								class=""
								label-size="lg"
								label-cols="2"
								label="Scraping rule"
								label-for="scraping-rules-select"
							>
								<OfFormSelect
									id="scraping-rules-select"
									v-model="scrapingRuleId"
									no-label
									:options="htmlImportRulesOptions"
									:show-errors="false"
									required
								/>
							</b-form-group>
							<a class="Import-modal__rules-clarification"> {{ $t('What’s this?') }}</a>
						</div>

						<UploadCard
							v-for="(item, index) in htmlItems"
							:key="item.id"
							:card-item="item"
							:upload-url="item.data"
							:status="item.status"
							contenteditable
							show-scraping-rule-selector
							@itemChange="changeHandler($event, 'htmlItems', index)"
							@remove="removeItem('htmlItems', index)"
							@retry="rescrapeItem('htmlItems', index)"
						>
						</UploadCard>
					</template>
				</ImportArea>
			</b-tab>
			<b-tab
				:title-item-class="{ 'Import-modal__tab-invisible': !isRecipeMode }"
				:disabled="!isRecipeMode"
				no-body
				lazy
				title="From File"
			>
				<ImportArea @fileInput="fileInputHandler">
					<template slot="background">
						<div v-show="Array.isArray(fileItems) && fileItems.length === 0" class="Background-container">
							<img src="../../../public/images/upload-icon.svg" alt="upload-icon" />
							<p class="Background-container__paragraph--bold">{{ $t('Drop file here to import') }}</p>
							<p class="Background-container__paragraph--tiny">{{ $t('or') }}</p>
							<p class="Background-container__paragraph--tiny">
								<a class="Background-container__link" @click="$refs.fileInput.click()">
									{{ $t('Select File') }}
								</a>
							</p>
							<input
								ref="fileInput"
								style="display: none"
								type="file"
								multiple="multiple"
								accept="application/json"
								@change="fileInputHandler($event.target.files)"
							/>
						</div>
					</template>
					<template slot="content">
						<UploadCard
							v-for="(item, index) in fileItems"
							:key="item.id"
							:card-item="item"
							:upload-url="item.data"
							:status="item.status"
							:custom-statuses="fileUploadStatuses"
							@remove="removeItem('fileItems', index)"
							@retry="rescrapeItem('fileItems', index)"
						>
						</UploadCard>
					</template>
				</ImportArea>
			</b-tab>
			<b-tab
				:title-item-class="{ 'Import-modal__tab-invisible': !isRecipeMode }"
				:disabled="!isRecipeMode"
				no-body
				lazy
				title="JSON"
			>
				<OfsFormGenerator
					class="set-height"
					:fields="[jsonEditor]"
					:show-errors="true"
					:model="inputJSON"
					@change="jsonInputHandler"
				/>
			</b-tab>
			<b-tab
				:title-item-class="{ 'Import-modal__tab-invisible': !isRecipeMode }"
				:disabled="!isRecipeMode"
				no-body
				lazy
				title="From API"
			>
				<ImportArea @textInput="textInputHandler('apiItems', $event)">
					<template slot="background">
						<div
							v-show="Array.isArray(apiItems) && apiItems.length === 0"
							class="Background-container"
							contenteditable="false"
						>
							<img src="../../../public/images/upload-icon.svg" alt="upload-icon" />
							<p class="Background-container__paragraph--bold">{{ $t('Paste URL links to import') }}</p>
							<p class="Background-container__paragraph--tiny">
								{{ $t('You can import multiple links') }}
							</p>
						</div>
					</template>
					<template slot="content">
						<div class="w-100 Import-modal__scraping-rules">
							<b-form-group
								class="Import-modal__rules-selector"
								label-size="lg"
								label-cols="2"
								label="Scraping rule"
								label-for="scraping-rules-select"
							>
								<OfFormSelect
									id="scraping-rules-select"
									v-model="scrapingRuleId"
									no-label
									:options="apiImportRulesOptions"
									:show-errors="false"
									required
								/>
							</b-form-group>
							<a class="Import-modal__rules-clarification">{{ $t('What’s this?') }}</a>
						</div>
						<UploadCard
							v-for="(item, itemIndex) in apiItems"
							:key="item.id"
							:card-item="item"
							:upload-url="item.data"
							:status="item.status"
							contenteditable
							class="Upload-card--extended"
							@itemChange="changeHandler($event, 'apiItems', itemIndex)"
							@remove="removeItem('apiItems', itemIndex)"
							@retry="rescrapeItem('apiItems', itemIndex)"
						>
							<template #additional>
								<b-row class="Additional-data__row">
									<Section
										:title="$t('Request headers')"
										:label="_get(apiItems[itemIndex], 'requestHeaders.length')"
										class="w-100"
										collapsible
									>
										<template #actions>
											<b-button
												variant="primary"
												size="sm"
												@click="addHeader('apiItems', itemIndex)"
											>
												<icon name="plus" class="mr-1" />{{ $t('Add Header') }}
											</b-button>
										</template>
										<b-row>
											<b-col>
												<div
													v-for="(_, headerIndex) in item.requestHeaders"
													:key="headerIndex"
													class="Additional-headers"
													@paste.stop
												>
													<OfFormInput
														:name="
															`apiItems[${itemIndex}].requestHeaders[${headerIndex}].key`
														"
														class="col-md-6"
														:label="$t('Name')"
														@input="
															v =>
																(apiItems[itemIndex].requestHeaders[
																	headerIndex
																].key = v)
														"
														@paste.stop
													/>
													<OfFormInput
														:name="
															// eslint-disable-next-line max-len
															`apiItems[${itemIndex}].requestHeaders[${headerIndex}].value`
														"
														class="col-md-6"
														:label="$t('Value')"
														@input="
															v =>
																(apiItems[itemIndex].requestHeaders[
																	headerIndex
																].value = v)
														"
													/>
													<b-button
														class="Additional-headers__trash-button"
														size="sm"
														@click="removeHeader('apiItems', itemIndex, headerIndex)"
													>
														<icon name="trash" />
													</b-button>
												</div>
											</b-col>
										</b-row>
									</Section>
								</b-row>
							</template>
						</UploadCard>
					</template>
				</ImportArea>
			</b-tab>
			<b-tab
				:title-item-class="{ 'Import-modal__tab-invisible': !isBookMode }"
				:disabled="!isBookMode"
				no-body
				lazy
				title="Import Book"
			>
				<ImportArea @textInput="textInputHandler('bookItems', $event, 1)">
					<template slot="background">
						<div
							v-show="Array.isArray(bookItems) && bookItems.length === 0"
							class="Background-container"
							contenteditable="false"
						>
							<img src="../../../public/images/upload-icon.svg" alt="upload-icon" />
							<p class="Background-container__paragraph--bold">{{ $t('Paste URL links to import') }}</p>
							<p class="Background-container__paragraph--tiny">
								{{ $t('You can import single link only') }}
							</p>
						</div>
					</template>
					<template slot="content">
						<div class="w-100 Import-modal__scraping-rules">
							<b-form-group
								class="Import-modal__rules-selector"
								label-size="lg"
								label-cols="2"
								label="Scraping rule"
								label-for="scraping-rules-select"
							>
								<OfFormSelect
									id="scraping-rules-select"
									v-model="scrapingRuleId"
									no-label
									:options="bookImportRulesOptions"
									:show-errors="false"
									required
								/>
							</b-form-group>
							<a class="Import-modal__rules-clarification">{{ $t('What’s this?') }}</a>
						</div>
						<UploadCard
							v-for="(item, itemIndex) in bookItems"
							:key="item.id"
							:card-item="item"
							:upload-url="item.data"
							:status="item.status"
							contenteditable
							class="Upload-card--extended"
							@itemChange="changeHandler($event, 'bookItems', itemIndex)"
							@remove="removeItem('bookItems', itemIndex)"
							@retry="rescrapeItem('bookItems', itemIndex)"
						>
							<template #additional>
								<b-row style="padding-top: 20px; padding-left: 30px">
									<b-col>
										<b-form-group
											class="Import-modal__rules-selector"
											label-size="lg"
											label-cols="2"
											label="Scraping rule for recipes"
											label-for="scraping-rules-select"
										>
											<OfFormSelect
												id="scraping-rules-select"
												v-model="recipesScrapingRuleId"
												no-label
												:options="jsonRecipesRulesOptions"
												:show-errors="false"
												required
											/>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="Additional-data__row">
									<Section
										:title="$t('Request headers')"
										:label="_get(bookItems[itemIndex], 'requestHeaders.length')"
										class="w-100"
										collapsible
									>
										<template #actions>
											<b-button
												variant="primary"
												size="sm"
												@click="addHeader('bookItems', itemIndex)"
											>
												<icon name="plus" class="mr-1" />{{ $t('Add Header') }}
											</b-button>
										</template>
										<b-row>
											<b-col>
												<div
													v-for="(_, headerIndex) in item.requestHeaders"
													:key="headerIndex"
													class="Additional-headers"
													@paste.stop
												>
													<OfFormInput
														:name="
															`bookItems[${itemIndex}].requestHeaders[${headerIndex}].key`
														"
														:vuex="false"
														class="col-md-6"
														:label="$t('Name')"
														@input="
															v =>
																(bookItems[itemIndex].requestHeaders[
																	headerIndex
																].key = v)
														"
														@paste.stop
													/>
													<OfFormInput
														:name="
															// eslint-disable-next-line max-len
															`bookItems[${itemIndex}].requestHeaders[${headerIndex}].value`
														"
														:vuex="false"
														class="col-md-6"
														:label="$t('Value')"
														@input="
															v =>
																(bookItems[itemIndex].requestHeaders[
																	headerIndex
																].value = v)
														"
													/>
													<b-button
														class="Additional-headers__trash-button"
														size="sm"
														@click="removeHeader('bookItems', itemIndex, headerIndex)"
													>
														<icon name="trash" />
													</b-button>
												</div>
											</b-col>
										</b-row>
									</Section>
								</b-row>
							</template>
						</UploadCard>
					</template>
				</ImportArea>
			</b-tab>
		</b-tabs>
		<template #modal-footer>
			<div class="row flex justify-content-sm-between w-100">
				<b-button size="ml" class="mr-3" @click="handleCancel">
					{{ $t('Cancel') }}
				</b-button>

				<b-button
					v-show="!importFinished"
					variant="primary"
					size="ml"
					class="mr-3"
					:disabled="isImportButtonDisabled"
					@click="startImport"
				>
					{{ importButtonText }}
				</b-button>

				<b-button v-show="importFinished" variant="primary" size="ml" class="mr-3" @click="handleDoneClick">
					{{ $t('Done') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import ImportArea from '@/components/ImportModal/ImportArea';
import { OfFormInput, OfFormSelect, OfsFormGenerator } from '@oneflow/ofs-vue-layout';
import UploadCard, { statuses } from '@/components/ImportModal/ImportModalUploadCard';
import { mapActions, mapGetters } from 'vuex';
import Section from '@/components/Section';
import { scrapingRuleSubjects, scrapingRuleTypes } from '@/constants';
import notifications from '@/mixins/notifications';
import _ from 'lodash';

const importTypes = {
	html: 'html',
	api: 'api',
	file: 'file',
	json: 'json',
	book: 'book'
};

const jsonEditor = {
	name: 'documents',
	type: 'jsonEditor',
	description: 'JSON array of documents'
};

export default {
	components: {
		ImportArea,
		UploadCard,
		OfsFormGenerator,
		OfFormSelect,
		Section,
		OfFormInput
	},
	mixins: [notifications],
	props: {
		modalId: {
			type: String,
			required: true
		},
		mode: {
			type: String,
			default: scrapingRuleSubjects.Recipe,
			validator: function(value) {
				return _.values(scrapingRuleSubjects).includes(value);
			}
		},
		modalTitle: {
			type: String,
			default: ''
		},
		productContentId: {
			type: String,
			default: ''
		}
	},
	data() {
		const fileUploadStatuses = { uploading: this.$t('Uploading File') };
		return {
			htmlItems: [],
			apiItems: [],
			fileItems: [],
			jsonItems: [],
			bookItems: [],
			tabIndex: 0,
			isRecipesLoading: false,
			importFinished: false,
			jsonEditor,
			inputJSON: {},
			scrapingRuleId: null,
			recipesScrapingRuleId: null,
			fileUploadStatuses
		};
	},
	computed: {
		...mapGetters({
			rules: 'scraping-rule/scraping-rules'
		}),
		isImportButtonDisabled() {
			if (this.isRecipesLoading) {
				return true;
			}
			switch (this.importType) {
				case importTypes.html:
					return !this.htmlItems.length || !this.scrapingRuleId;
				case importTypes.api:
					return !this.apiItems.length || !this.scrapingRuleId;
				case importTypes.book:
					return !this.bookItems.length || !this.scrapingRuleId || !this.recipesScrapingRuleId;
				case importTypes.json:
					const documents = _.get(this.jsonItems[0], 'inputJSON.documents');
					return this.isRecipesLoading || !this.isJSONValid(documents);
				case importTypes.file:
					return !this.fileItems.length;
				default:
					return false;
			}
		},
		currentItems() {
			switch (this.importType) {
				case importTypes.html:
					return 'htmlItems';
				case importTypes.file:
					return 'fileItems';
				case importTypes.json:
					return 'jsonItems';
				case importTypes.api:
					return 'apiItems';
				case importTypes.book:
					return 'bookItems';
				default:
					throw new Error('No item type associated with the selected tab');
			}
		},
		importType() {
			switch (this.tabIndex) {
				case 0:
					return importTypes.html;
				case 1:
					return importTypes.file;
				case 2:
					return importTypes.json;
				case 3:
					return importTypes.api;
				case 4:
					return importTypes.book;
				default:
					throw new Error('No item type associated with the selected tab');
			}
		},
		importHandler() {
			switch (this.importType) {
				case importTypes.html:
					return this.importHTML;
				case importTypes.file:
					return this.importFile;
				case importTypes.json:
					return this.importJSON;
				case importTypes.api:
					return this.importAPI;
				case importTypes.book:
					return this.importBook;
				default:
					throw new Error('No item type associated with the selected tab');
			}
		},
		importButtonText() {
			if (this.importType === importTypes.json || this[this.currentItems].length === 0) {
				return this.$t('Import');
			} else {
				return `${this.$t('Import')} (${this[this.currentItems].length})`;
			}
		},
		htmlImportRulesOptions() {
			const usedRules = _.filter(
				this.rules,
				rule => rule.type === scrapingRuleTypes.HTML && rule.subject === scrapingRuleSubjects.Recipe
			);
			return _.map(usedRules, rule => ({ text: rule.name, value: rule.id }));
		},
		apiImportRulesOptions() {
			const usedRules = _.filter(
				this.rules,
				rule => rule.type === scrapingRuleTypes.JSON && rule.subject === scrapingRuleSubjects.Recipe
			);
			return _.map(usedRules, rule => ({ text: rule.name, value: rule.id }));
		},
		bookImportRulesOptions() {
			const usedRules = _.filter(
				this.rules,
				rule => rule.type === scrapingRuleTypes.JSON && rule.subject === scrapingRuleSubjects.Book
			);
			return _.map(usedRules, rule => ({ text: rule.name, value: rule.id }));
		},
		jsonRecipesRulesOptions() {
			const usedRules = _.filter(
				this.rules,
				rule => rule.type === scrapingRuleTypes.JSON && rule.subject === scrapingRuleSubjects.Recipe
			);
			return _.map(usedRules, rule => ({ text: rule.name, value: rule.id }));
		},
		isBookMode() {
			return this.mode === scrapingRuleSubjects.Book;
		},
		isRecipeMode() {
			return this.mode === scrapingRuleSubjects.Recipe;
		}
	},
	watch: {
		tabIndex() {
			this.cleanup();
		},
		isRecipesLoading(newValue) {
			if (newValue === false && this.importType === importTypes.json) {
				this.importFinished = false;
			}
		}
	},
	mounted() {
		this.findScrapingRules();
	},
	methods: {
		...mapActions({
			importRecipeFromAPI: 'dataImport/importRecipeFromAPI',
			importRecipeFromHTML: 'dataImport/importRecipeFromHTML',
			importRecipeFromJSON: 'dataImport/importRecipeFromJSON',
			importBookFromAPI: 'dataImport/importBookFromAPI',
			findScrapingRules: 'scraping-rule/findAll'
		}),
		isJSONValid(jsonString) {
			try {
				JSON.parse(jsonString);
				return true;
			} catch (e) {
				return false;
			}
		},
		addHeader(itemsType, itemIndex) {
			this[itemsType][itemIndex].requestHeaders.push({ key: '', value: '' });
		},
		removeHeader(itemsType, itemIndex, headerIndex) {
			this[itemsType][itemIndex].requestHeaders.splice(headerIndex, 1);
		},
		checkRecipe(recipe) {
			return (
				!_.isEmpty(recipe) && _.has(recipe, 'name') && _.has(recipe, 'category') && _.has(recipe, 'sourceURL')
			);
		},
		textInputHandler(itemsType, event, limit) {
			const urls = _.filter(
				_.map(event.split('\n'), i => i.trim()),
				i => i.length > 0
			);

			if (limit && urls.length + this[itemsType].length > limit) {
				this.notifyWarning({
					text: `${this.$t('The number of imported links is limited to')} ${limit}`
				});
				return;
			}

			_.forEach(urls, url => {
				this[itemsType].push({
					text: url,
					status: statuses.Initial,
					errors: [],
					id: `${this[itemsType].length}_${Date.now()}`,
					requestHeaders: []
				});
			});
		},
		fileInputHandler(event) {
			for (let i = 0; i < event.length; i++) {
				this.fileItems.push({
					text: event[i].name,
					data: event[i],
					status: statuses.Initial,
					errors: [],
					id: `${this.fileItems.length}_${Date.now()}`
				});
			}
		},
		jsonInputHandler(inputJSON) {
			if (!this.jsonItems.length) {
				this.jsonItems.push({
					data: '',
					id: `${this.jsonItems.length}_${Date.now()}`,
					inputJSON: {}
				});
			}
			this.inputJSON = inputJSON;
			this.jsonItems[0].inputJSON = this.inputJSON;
		},
		changeHandler(event, itemType, index) {
			this.$set(this[itemType], index, event);
		},
		removeItem(itemType, index) {
			this[itemType] = this[itemType].filter((_, idx) => idx !== index);
		},
		async rescrapeItem(itemType, index) {
			this.isRecipesLoading = true;
			await this.importHandler(index);
			this.isRecipesLoading = false;
		},
		cancelButtonHandler() {
			this.$refs.importModal.cancel();
		},
		async importJSON(itemIndex) {
			if (!this.jsonItems[itemIndex]) {
				return;
			}

			const item = this.jsonItems[itemIndex];
			try {
				const recipesToImport = _.filter(_.castArray(JSON.parse(item.inputJSON.documents)), this.checkRecipe);
				if (recipesToImport.length) {
					await this.importRecipeFromJSON(recipesToImport);
					this.handleDoneClick();
				} else {
					throw new Error(this.$t('No recipes found. Please, specify name, category and sourceURL fields.'));
				}
			} catch (e) {
				this.notifyError(e);
			}
		},
		async importHTML(itemIndex) {
			if (!this.htmlItems[itemIndex]) {
				return;
			}

			this.htmlItems[itemIndex].status = statuses.Uploading;
			const item = this.htmlItems[itemIndex];
			try {
				if (!_.isEmpty(item.text.trim().replace(/\n/g, ''))) {
					const result = await this.importRecipeFromHTML({
						url: item.text,
						scrapingRuleId: this.scrapingRuleId
					});
					this.htmlItems[itemIndex].status = statuses.Success;
					return result;
				} else {
					throw new Error(this.$t('URL should not be empty'));
				}
			} catch (e) {
				this.htmlItems[itemIndex].errors.push(e);
				this.htmlItems[itemIndex].status = statuses.Failed;
			}
		},
		async importAPI(itemIndex) {
			if (!this.apiItems[itemIndex]) {
				return;
			}

			this.apiItems[itemIndex].status = statuses.Uploading;
			const item = this.apiItems[itemIndex];
			try {
				if (!_.isEmpty(item.text.trim().replace(/\n/g, ''))) {
					const headers = _.filter(item.requestHeaders, item => !!item.key && !!item.value);
					await this.importRecipeFromAPI({
						url: item.text,
						scrapingRuleId: this.scrapingRuleId,
						headers
					});
					this.apiItems[itemIndex].status = statuses.Success;
				} else {
					throw new Error(this.$t('URL should not be empty'));
				}
			} catch (e) {
				item.errors.push(e);
				this.apiItems[itemIndex].status = statuses.Failed;
			}
		},
		async importBook(itemIndex) {
			if (!this.bookItems[itemIndex]) {
				return;
			}

			this.bookItems[itemIndex].status = statuses.Uploading;
			const item = this.bookItems[itemIndex];
			try {
				if (!_.isEmpty(item.text.trim().replace(/\n/g, ''))) {
					const headers = _.filter(item.requestHeaders, item => !!item.key && !!item.value);
					const result = await this.importBookFromAPI({
						url: item.text,
						scrapingRuleId: this.scrapingRuleId,
						headers,
						recipesScrapingRuleId: this.recipesScrapingRuleId,
						productContentId: this.productContentId
					});
					this.bookItems[itemIndex].status = statuses.Success;
					return result;
				} else {
					throw new Error(this.$t('URL should not be empty'));
				}
			} catch (e) {
				item.errors.push(e);
				this.bookItems[itemIndex].status = statuses.Failed;
			}
		},
		async importFile(itemIndex) {
			if (!this.fileItems[itemIndex]) {
				return;
			}
			return new Promise((resolve, reject) => {
				this.fileItems[itemIndex].status = statuses.Uploading;
				const reader = new FileReader();
				reader.onload = async event => {
					try {
						let parsedRecipes = JSON.parse(event.target.result);
						const formatedRecipes = _.castArray(parsedRecipes);
						const recipesToImport = _.filter(formatedRecipes, this.checkRecipe);
						if (!recipesToImport.length) {
							throw new Error(
								this.$t('No recipes found. Please, specify name, category and sourceURL fields.')
							);
						} else if (recipesToImport.length < formatedRecipes.length) {
							throw new Error(
								this.$t('Only several recipes can be imported', {
									toImport: recipesToImport.length,
									all: formatedRecipes.length
								})
							);
						}
						const result = await this.importRecipeFromJSON(recipesToImport);
						this.numberOfImportedRecipes = result.length;
						this.fileItems[itemIndex].status = statuses.Success;
						resolve(result);
					} catch (e) {
						this.fileItems[itemIndex].errors.push(e);
						this.fileItems[itemIndex].status = statuses.Failed;
						resolve();
					}
				};
				reader.readAsText(this.fileItems[itemIndex].data);
			});
		},
		async startImport() {
			if (this.importFinished) {
				return;
			}
			this.isRecipesLoading = true;

			for (let itemIndex = 0; itemIndex < this[this.currentItems].length; itemIndex++) {
				await this.importHandler(itemIndex);
			}
			this.isRecipesLoading = false;
			this.importFinished = true;
		},
		handleDoneClick() {
			this.notifySuccess({
				title: this.$t('Success'),
				text: this.$t('Recipes successfully imported')
			});
			this.$bvModal.hide(this.modalId);
			this.cleanup();
			this.tabIndex = 0;
			_.delay(() => this.$router.go(), 1000);
		},
		async handleCancel() {
			const confirmationMessage = this.$t('Are you sure you want to cancel? All imports will be deleted.');
			const isConfirmed = await this.confirmAction(confirmationMessage);
			if (!isConfirmed) return;
			this.$bvModal.hide(this.modalId);
			this.cleanup();
			this.tabIndex = 0;
		},
		cleanup() {
			this.htmlItems = [];
			this.jsonItems = [];
			this.fileItems = [];
			this.apiItems = [];
			this.bookItems = [];
			this.inputJSON.documents = '';
			this.importFinished = false;
			this.scrapingRuleId = null;
			this.recipesScrapingRuleId = null;
		},
		_get: _.get
	}
};
</script>
<style lang="scss">
@import '../../style/_variables.scss';
.Import-modal {
	&__content {
		background-color: $color-hp-light;
		padding: 0 !important;
		max-width: 980px !important;
	}

	&__tab-active {
		background-color: $color-hp-highlights !important;
	}

	&__tab-invisible {
		display: none !important;
	}

	&__nav {
		padding: 0 !important;

		li {
			width: 150px;
			height: 39px;
		}
	}

	&__nav-tabs {
		padding: 0 !important;
	}

	&__scraping-rules {
		height: 55px;
		background-color: white;
		width: 100%;
		padding-top: 10px;
		padding-left: 24px;
		padding-right: 24px;
		display: flex;
		align-content: stretch;
		align-items: baseline;
		margin-bottom: 7px;
		box-shadow: 0px -1px 0px $color-hp-grey-3, 0px 1px 0px $color-hp-grey-3;
	}

	&__rules-selector {
		flex-grow: 1;
		padding-right: 30px;
	}

	&__rules-clarification {
		width: 75px;
		white-space: nowrap;
		color: #23b7e5 !important;
	}

	.CodeMirror,
	.vue-codemirror {
		height: 450px !important;
	}

	.Upload-card--extended {
		box-shadow: 0px -1px 0px $color-hp-grey-3, 0px 1px 0px $color-hp-grey-3;
		margin-bottom: 10px;
	}
}
.CodeMirror-lint-tooltip {
	z-index: 1050;
}
.Additional-data__row {
	margin: 0 !important;
	padding: 13px 24px 13px 24px !important;
}
.Additional-headers {
	display: flex;
	vertical-align: center;
	justify-content: space-between;
	align-items: center;

	&__trash-button {
		height: 30px;
		margin-top: 12px;
	}
}

.Background-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	&__paragraph--bold {
		font-family: Source Sans Pro, serif;
		font-size: 15px;
		font-style: normal;
		font-weight: 700;
		line-height: 19px;
		letter-spacing: 0;
		text-align: center;
		margin: 11px 0 0 0;
		color: $color-hp-grey-1;
	}

	&__paragraph--tiny {
		font-family: Source Sans Pro, serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0;
		text-align: center;
		margin: 0;
		color: $color-hp-grey-2;
	}
	&__link {
		color: $color-pimienta;
	}
}

.set-height {
	height: 450px !important;
}
</style>
