const chapter = require('./chapter');
const cover = require('./cover');
const introduction = require('./introduction');
const notes = require('./notes');
const recipeWithImage = require('./recipeWithImage');
const tableOfContent = require('./tableOfContent');
const titlePage = require('./titlePage');
const acknowledgementsPage = require('./acknowledgementsPage');
const pageNumber = require('./pageNumber');

module.exports = {
	chapter,
	cover,
	introduction,
	notes,
	recipeWithImage,
	tableOfContent,
	titlePage,
	acknowledgementsPage,
	pageNumber
};
