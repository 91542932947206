module.exports = {
	name: 'Thank You',
	template: [
		{
			name: 'Thank You page',
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } },
				padding: '11px',
				lineHeight: 1
			},
			children: [
				{
					name: 'Wrapper',
					type: 'container',
					props: {
						display: 'flex',
						width: '100%',
						alignItems: 'center',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						padding: '0 21%'
					},
					children: [
						{
							name: 'Title',
							type: 'text',
							props: {
								value:
									'Aditiores magnatest ut estem liquibusciis as volescilia\n' +
									'ipsum net ad quuntio omnihillore neceped que nonet\n' +
									'reiciam que lam volorro quatisquunda quatas il inis\n' +
									'quo offici cus perovid eliquidel iliqui acessequi optatem\n' +
									'lic tem harcia aut voloribusci inum fugiam cor rae\n' +
									'voluptate venis de voluptat aut parum intio. Et eosam',
								width: '80%',
								fontFamily: 'PoppinsLight',
								textAlign: 'center',
								fontSize: '13px',
								marginTop: '1em',
								marginBottom: '30px'
							}
						},
						{
							name: 'Title',
							type: 'text',
							props: {
								value: 'Brand Logo',
								fontFamily: 'FlowersScript',
								fontSize: '40px',
								marginTop: '20px'
							}
						}
					]
				}
			]
		},
		{ type: 'page' }
	]
};
