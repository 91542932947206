import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'feature',
		pluralName: 'features',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		state: {
			features: { 'cover-inner-separation': true }
		},
		getters: {
			features: state => state.features
		}
	});
}
