<template>
	<b-row class="BookAppearance">
		<BookAppearanceAlert v-if="coverInnerSeparationEnabled" />
		<b-col md="3" class="BookAppearance-OptionsPanel">
			<div class="OptionsPanel-Inner">
				<TemplateSelect
					v-model="activeLayout"
					track-by="_id"
					:label="$t('Template')"
					:placeholder="$t('Select template')"
					:options="filteredLayouts"
					:disabled="coverInnerSeparationEnabled"
				/>

<!--				<Section :title="$t('Cover')" class="mt-4" />-->

<!--				<component-->
<!--					:is="block.componentName"-->
<!--					v-for="block in coverCustomisationBlocks"-->
<!--					:key="block.name + block.key"-->
<!--					:label="block.label"-->
<!--					:right-side-label="block.label"-->
<!--					:options="block.options"-->
<!--					:name="defaultPath + block.key"-->
<!--					:without-label="block.type === blockTypes.CHECKBOX"-->
<!--					:selected-value="getSelectedValue(defaultPath + block.key)"-->
<!--					:form-data="formData"-->
<!--					class="my-1"-->
<!--					@input="handleComponentInput($event, block)"-->
<!--				/>-->

				<Section :title="$t('Content')" class="mt-4" />

				<component
					:is="block.componentName"
					v-for="block in contentCustomisationBlocks"
					:key="block.name + block.key"
					:label="block.label"
					:right-side-label="block.label"
					:options="block.options"
					:name="defaultPath + block.key"
					:without-label="block.type === blockTypes.CHECKBOX"
					:selected-value="getSelectedValue(defaultPath + block.key)"
					:form-data="formData"
					class="my-1"
					@input="handleComponentInput($event, block)"
				/>
			</div>
		</b-col>
		<BookAppearancePreview
			:source="formData ? formData.source : {}"
			:product-content="productContent"
			:preview-options="previewOptions"
		/>
	</b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfFormSelect, withForm, OfFormCheckbox, OfFormRadio } from '@oneflow/ofs-vue-layout';
import Section from '../../../../../components/Section.vue';
import ColorPicker from '../../../../../components/BookAppearance/BookAppearanceColorPicker.vue';
import TextAlign from '../../../../../components/BookAppearance/BookAppearanceTextAlign.vue';
import TemplateSelect from '../../../../../components/BookEdit/TemplateSelect.vue';
import BookAppearancePreview from '../../../../../components/BookAppearance/BookAppearancePreview.vue';
import BookAppearanceAlert from '../../../../../components/BookAppearance/BookAppearanceAlert';
import { featureFlags, templateFormat } from '../../../../../constants';
import { getTemplateOptions, getDefaultAppearance, colorHexValidator } from '../helpers';
import { defaultPath, rules, rulesConstants } from './helpers';
import BookSlider from '../../../../../components/BookSlider';
import BookPreviewPages from '../../../../../components/BookPreviewPages';
import { blockTypes, blockAvailability } from '../constants';
import notifications from '@/mixins/notifications';
export const appearanceFormName = 'bookAppearance';

export default {
	components: {
		OfFormSelect,
		OfFormCheckbox,
		OfFormRadio,
		ColorPicker,
		TextAlign,
		Section,
		TemplateSelect,
		BookSlider,
		BookPreviewPages,
		BookAppearancePreview,
		BookAppearanceAlert
	},
	mixins: [withForm(appearanceFormName, { preserveDataOnMount: true }), notifications],
	props: {
		template: {
			type: Object,
			required: true
		},
		productContent: {
			type: Object,
			required: true
		},
		product: {
			type: Object,
			required: true
		},
		preselectedLayoutId: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			defaultPath,
			blockTypes,
			blockAvailability,
			blocksValidationRules: null,
			previewOptions: {},
			coverCustomisationBlocks: null,
			contentCustomisationBlocks: null
		};
	},
	computed: {
		...mapGetters({
			layouts: 'layout/layouts',
			features: 'feature/features'
		}),
		coverInnerSeparationEnabled() {
			return this.features?.[featureFlags.coverInnerSeparation];
		},
		validationRules() {
			return this.blocksValidationRules;
		},
		activeLayout: {
			get() {
				const parentLayoutId = _.get(this.formData, 'parentLayoutId');
				return _.find(this.filteredLayouts, [
					'id',
					this.preselectedLayoutId === this.formData?.parentLayoutId
						? this.preselectedLayoutId
						: parentLayoutId
				]);
			},
			async set(layout) {
				const isConfirm = await this.confirmAction([
					this.$t('Are you sure to change content template?'),
					this.$t('All items with unsupported type will be removed from the book.')
				]);
				if (!isConfirm) return;

				this.reInitPageByLayout(layout);
				this.$emit('change-content-layout', layout);
			}
		},
		activeVariant() {
			const variantName = _.get(this.formData, 'source.theme.selectedVariant');
			const variants = _.get(this.formData, 'source.theme.variants', []);
			return _.find(variants, ['name', variantName]);
		},
		themeOptions() {
			if (!_.has(this.activeLayout, 'template')) return {};
			return getTemplateOptions(this.activeLayout.template);
		},
		blocks() {
			return _.get(this.formData, 'source.blocks', []);
		},
		filteredLayouts() {
			// temporary condition
			// it will be removed after all book layouts are changed and old ones are removed from db
			return _.filter(this.layouts, layout => !layout.name.includes('separation'));
		}
	},
	watch: {
		template: {
			immediate: true,
			async handler(template, oldTemplate) {
				if (!_.isEqual(template, oldTemplate)) {
					await this.initialize();
				}
			}
		},
		activeLayout(layout, oldLayout) {
			if (!layout || !oldLayout || layout._id === oldLayout._id) return;
			this.resetThemeBaseOptions();
		},
		activeVariant(variant, oldVariant) {
			if (!variant || !oldVariant || variant.name === oldVariant.name) return;
			this.resetThemeBaseOptions();
		}
	},
	methods: {
		...mapActions({
			getPreviewOptions: 'template/getPreviewOptions'
		}),
		async initialize() {
			if (this.preselectedLayoutId) {
				const layout = _.find(this.filteredLayouts, ['id', this.preselectedLayoutId]);
				this.reInitPageByLayout(layout);
			} else {
				let source;
				const templateId = _.get(this.template, '_id');
				const formDataTemplateId = _.get(this.formData, '_id');

				if (!_.isEqual(templateId, formDataTemplateId)) {
					source = _.cloneDeep(this.getThemeBaseOptions());
					const templateCopy = _.cloneDeep(this.template);
					this.setFormData(_.merge(templateCopy, { source }));
				} else {
					source = _.cloneDeep(this.formData.source);
				}

				this.contentCustomisationBlocks = this.normalizeBlocks(
					_.get(this.formData, 'source.theme.customisationOptions.content', [])
				);
				this.coverCustomisationBlocks = this.normalizeBlocks(
					_.get(this.formData, 'source.theme.customisationOptions.cover', [])
				);
				const coverBlocks = _.get(source, 'theme.customisationOptions.cover');
				const contentBlocks = _.get(source, 'theme.customisationOptions.content');
				this.buildValidationsAndRulesForBlocks({ cover: coverBlocks, content: contentBlocks });
			}
			await this.setPreviewOptions();
		},
		reInitPageByLayout(layout) {
			const { id, previewImageUrl, template } = _.cloneDeep(layout);
			this.initFormData({
				...this.formData,
				parentLayoutId: id,
				previewImageUrl: previewImageUrl,
				source: _.cloneDeep(template)
			});

			this.contentCustomisationBlocks = this.normalizeBlocks(
				_.get(this.formData, 'source.theme.customisationOptions.content', [])
			);
			this.coverCustomisationBlocks = this.normalizeBlocks(
				_.get(this.formData, 'source.theme.customisationOptions.cover', [])
			);
			const coverBlocks = _.get(template, 'theme.customisationOptions.cover');
			const contentBlocks = _.get(template, 'theme.customisationOptions.content');
			this.buildValidationsAndRulesForBlocks({ cover: coverBlocks, content: contentBlocks });
		},
		getThemeBaseOptions() {
			const { template } = this;
			const source = _.cloneDeep(template.source);
			const variantName = _.get(template, 'source.theme.selectedVariant');
			const variants = _.get(template, 'source.theme.variants', []);
			const activeVariant = _.find(variants, ['name', variantName]);
			source.theme.base = { ...getDefaultAppearance(activeVariant), ...source.theme.base };
			return source;
		},
		resetThemeBaseOptions() {
			const source = _.cloneDeep(this.formData.source);
			source.theme.base = { ...source.theme.base, ...getDefaultAppearance(this.activeVariant) };
			this.updateField('source', source);
		},
		async setPreviewOptions() {
			const { _id: productId, type: productType } = this.product;

			this.previewOptions = await this.getPreviewOptions({
				productId: productId,
				productContent: this.productContent,
				productType
			});
		},
		getComponentName(name) {
			switch (name) {
				case blockTypes.SELECT:
					return 'OfFormSelect';
				case blockTypes.CHECKBOX:
					return 'OfFormCheckbox';
				case blockTypes.RADIOBUTTON:
					return 'TextAlign';
				case blockTypes.COLORPICKER:
					return 'ColorPicker';
			}
		},
		buildBlockOptions(block) {
			if (_.size(block?.options)) {
				return _.map(block.options, option => {
					switch (block.type) {
						case blockTypes.RADIOBUTTON:
							return { text: option, value: option };
						case blockTypes.COLORPICKER:
							return { text: option.text, value: option.value, key: option.key };
						default:
							return { text: _.isString(option) ? option : option[block.optionsKey], value: option };
					}
				});
			}
			return [];
		},
		normalizeBlocks(blocks) {
			return _.chain(blocks)
				.filter(({ active }) => active !== false)
				.filter(({ availability }) =>
					[blockAvailability.Common, blockAvailability.Studio, undefined].includes(availability)
				)
				.map(block => ({
					...block,
					componentName: this.getComponentName(block.type),
					options: this.buildBlockOptions(block)
				}))
				.value();
		},
		handleComponentInput(data, block) {
			if (data?.name) {
				this.updateField(data.name, data?.value);
			}
			this.handleRules(block, data);
		},
		getSelectedValue(name) {
			return _.get(this.formData, name);
		},
		buildValidationsAndRulesForBlocks(theme) {
			const blocks = _.concat(theme.cover, theme.content);
			const defaultPath = 'formData.source.theme.variants.$each';
			const $t = this.$t.bind(this);
			const colorValidator = colorHexValidator($t);
			const validationRules = _.set({}, defaultPath, {});

			blocks.forEach(block => {
				this.handleRules(block);
				if (block.type === blockTypes.COLORPICKER && !block.options?.length) {
					_.set(validationRules, defaultPath + `.${block.key}`, colorValidator);
				}
			});

			this.blocksValidationRules = validationRules;
		},
		handleRules(block, selectedValue) {
			rules.forEach(rule => {
				switch (rule) {
					case rulesConstants.INCLUDE:
						const blockRule = block?.rules ? block?.rules[rule] : false;
						if (blockRule) {
							const blockValue = selectedValue
								? selectedValue
								: _.get(this.formData, defaultPath + block.key);

							const formDataCover = _.get(this.formData, 'source.theme.customisationOptions.cover', []);
							const formDataContent = _.get(
								this.formData,
								'source.theme.customisationOptions.content',
								[]
							);
							const fieldForDetection = _.concat(formDataCover, formDataContent)?.find(
								block => block.key === blockRule.key
							);
							const generalPathName = fieldForDetection?.key?.includes('cover') ? 'cover' : 'content';

							// calculate new options by rules
							const relatedOptions = fieldForDetection?.options?.filter(option => {
								if (option[blockRule.property]) {
									return option[blockRule.property].includes(blockValue[block.optionsKey]);
								}
								return true;
							});

							// build new options and find correct path
							const newOptions = this.buildBlockOptions({ ...block, options: relatedOptions });
							const property = `${generalPathName}CustomisationBlocks`;
							const index = this[property]?.findIndex(coverBlock => coverBlock.key === blockRule.key);

							// update block structure
							if (this[property] && this[property][index]) {
								this[property][index].options = newOptions;
							}
						}
						break;
				}
			});
		}
	}
};
</script>

<style lang="scss">
@import '../../../../../style/variables';

.BookAppearance {
	margin-top: -30px;

	&-OptionsPanel {
		overflow: scroll;
		max-height: 100vh;
		padding-top: 30px;
		padding-right: 30px;

		@media (min-width: 768px) {
			border-right: 1px solid $color-hp-grey-3;
		}

		.col-form-label {
			font-weight: $of-font-weight-semi-bold;
			color: $color-hp-black;
		}

		.no-label label {
			display: none;
		}

		.form-group {
			input,
			select {
				border-color: $color-hp-grey-3;
			}
		}
	}

	&-PreviewPanel {
		max-height: 100vh;
		overflow: scroll;
		background-color: $color-hp-highlights;
	}

	.custom-checkbox {
		z-index: 0;
	}
}
</style>
