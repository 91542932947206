import store from '../vuex';
import * as filestack from 'filestack-js';

const dispatch = (action, payload) => store.dispatch(`media-uploader/${action}`, payload);

export default class FileUploader {
	/**
	 * @param {string} config.path File directory
	 * @param {Function|undefined} config.onUploadProgress Upload progress handler
	 */
	constructor(config) {
		this.config = config;
		this.controlToken = {};
	}

	async upload(file, accountId) {
		const { policy, signature } = await dispatch('getFilestackUploadSignature');

		const client = filestack.init(window.$config.fileStackApiKey, { security: { policy, signature } });

		const result = await client.upload(
			file,
			{ onProgress: this.config.onUploadProgress },
			{ path: `${this.config.path}/` },
			this.controlToken
		);
		const { _file: uploadedFile } = result;
		return await dispatch('createPimientaFile', {
			accountId,
			path: this.config.path,
			name: uploadedFile?.name,
			contentType: uploadedFile?.type,
			metadata: {
				handle: result.handle,
				fileSize: uploadedFile.size
			},
			accountId
		});
	}

	abortUploading() {
		if (this.controlToken['cancel']) {
			this.controlToken['cancel']();
		}
	}
}
