<template>
	<div class="LoginPage">
		<b-button variant="primary" class="mr-2 ml-2 p-2" @click="handleLoginRequest">
			Sign in
		</b-button>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'Login',
	methods: {
		...mapActions({
			loginWithRedirect: 'auth0/loginWithRedirect'
		}),
		async handleLoginRequest() {
			this.loginWithRedirect();
		}
	}
};
</script>

<style lang="scss">
.LoginPage {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
}
</style>
