const maxTemplateWidth = 816;
const maxTemplateHeight = 816;
const pixelsInMM = 3.7795275591;
const mmInInch = 25.4;
const dpi = 200;

export const minImageWidth = Math.ceil((maxTemplateWidth * dpi) / (pixelsInMM * mmInInch));
export const minImageHeight = Math.ceil((maxTemplateHeight * dpi) / (pixelsInMM * mmInInch));

export const getImageResolutionFromUrl = src =>
	new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = function() {
			const { width, height } = this;
			resolve({ width, height });
		};
		img.onerror = reject;
		img.src = src;
	});

export const getImageResolutionFromFile = file =>
	new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onload = function() {
			const image = new Image();

			image.onload = function() {
				resolve({ width: image.width, height: image.height });
			};

			image.src = fileReader.result;
		};
		fileReader.onerror = reject;
		fileReader.readAsDataURL(file);
	});

export const validateImage = async (image, options = {}) => {
	const handler = image instanceof File ? getImageResolutionFromFile : getImageResolutionFromUrl;
	try {
		const { width, height } = await handler(image);
		if (options.returnSize) {
			return {
				isValid: !(width < minImageWidth || height < minImageHeight),
				width,
				height
			};
		}
		return !(width < minImageWidth || height < minImageHeight);
	} catch (e) {
		return false;
	}
};
