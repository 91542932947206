<template>
	<Layout>
		<b-form class="h-100" novalidate @submit.prevent="onSave">
			<Page class="BookPreview">
				<ContentHeader :title="headerTitle" :breadcrumbs="breadcrumbs" class="mb-2" no-padding />

				<BookPreviewPages :json="json" :fonts="fonts" class="BookPreview__preview-container" />
			</Page>
		</b-form>
	</Layout>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { OfsPanel, ContentHeader } from '@oneflow/ofs-vue-layout';
import { BookTemplateProcessor } from '@custom-content-technology/book-template-processor';
import Layout from '../../../components/Layout.vue';
import Page from '../../../components/Page.vue';
import BookPreviewPages from '../../../components/BookPreviewPages.vue';

export default {
	components: {
		ContentHeader,
		Page,
		Layout,
		BookPreviewPages
	},
	props: {
		bookId: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			book: null,
			template: null
		};
	},
	computed: {
		productContentId() {
			return _.get(this.$route, 'params.id');
		},
		headerTitle() {
			return _.get(this.book, 'headline');
		},
		breadcrumbs() {
			const listRoute = this.$router.resolve({ name: 'books.list' });

			return [
				{ text: this.$t('Books'), href: listRoute.href },
				{ text: this.headerTitle, href: '#' }
			];
		},
		themeVariant() {
			const { base, selectedVariant, variants, customization } = this.template?.theme;
			return _.merge(customization, base, _.find(variants, ['name', selectedVariant])?.theme);
		},
		json() {
			if (!this.template) return [];
			const { layout, renderOptions } = this.template;
			const blocks = [
				..._.get(this.template, 'blocks', []),
				..._.get(this.template, 'contentDependencies', []),
				..._.get(this.template, 'coverDependencies', [])
			];
			const { templateProps, processOptions } = renderOptions;

			const data = {
				$theme: this.themeVariant,
				$book: this.book,
				$props: templateProps
			};

			const bookTemplateProcessor = new BookTemplateProcessor();
			bookTemplateProcessor.registerBlocks(blocks);
			return bookTemplateProcessor.process(layout, data, processOptions);
		},
		fonts() {
			return _.get(this.template, 'assets.fonts');
		}
	},
	async mounted() {
		await this.initialize();
	},
	methods: {
		...mapActions({
			getBookTemplate: 'product-content/getBookTemplate',
			getBookContent: 'product-content/getBookContent'
		}),
		async initialize() {
			this.book = await this.getBookContent({ id: this.productContentId });
			this.template = await this.getBookTemplate({ id: this.productContentId });
		}
	}
};
</script>

<style lang="scss">
.BookPreview {
	&__preview-container {
		display: flex;
		flex-direction: row;
		justify-content: center;

		.responsive-square {
			position: relative;
			background: whitesmoke;
			&:before {
				content: '';
				display: block;
				padding-bottom: 100%;
			}

			& > * {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}
}
</style>
