<template>
	<div class="FullScreenLayout">
		<TopBar slot="top-bar">
			<img slot="logo" src="../../../public/images/pimienta-logo-blue.svg" />
			<template slot="actions">
				<icon name="times" @click="goBack" />
			</template>
		</TopBar>
		<div slot="content">
			<slot></slot>
			<VersionNotification />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TopBar } from '@oneflow/ofs-vue-layout';
import VersionNotification from '../VersionNotification/VersionNotification';

export default {
	components: {
		TopBar,
		VersionNotification
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss">
@import '../../style/variables';

.FullScreenLayout {
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	min-height: 0;
	background: #fff;

	.OneflowTopBar {
		padding: 0 25px;
		border-bottom: 1px solid $color-hp-grey-3;

		&-Menu {
			border-bottom: none;
		}

		&-Logo {
			background: #fff;
		}

		&-Actions {
			display: flex;
			align-items: center;

			.fa-icon {
				cursor: pointer;
				transform: scale(1.5);
			}
		}

		&-Account {
			display: none;
		}

		&-MobileMenuButton {
			display: none !important;
		}
	}
}
</style>
