import XLSX from 'xlsx';
import _ from 'lodash';
import template from '../../templates/recipesPDF';
import Handlebars from 'handlebars';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as Promise from 'bluebird';

Handlebars.registerHelper('inc', function(value, options) {
	return parseInt(value) + 1;
});

export const saveToJSON = (recipes, fileName) => {
	const saveData = _.map(recipes, recipe => recipe.record);
	const a = document.createElement('a');
	const file = new Blob([JSON.stringify(saveData, null, 2)], { type: 'text/plain' });
	a.href = URL.createObjectURL(file);
	a.download = `${fileName}.json`;
	a.click();
};

const getImagesWithResolution = async (recipes, onRecipeImagesProcessed) => {
	const newRecipes = _.map(recipes, async recipe => {
		const newImages = await Promise.map(
			_.get(recipe.record, 'images', []),
			async image => {
				try {
					const resolution = await getImageResolution(image.fileURL || image.url);
					return { ...image, resolution };
				} catch (e) {
					return image;
				}
			},
			{ concurrency: 5 }
		);
		if (onRecipeImagesProcessed) {
			onRecipeImagesProcessed();
		}
		return { ...recipe, record: { ...recipe.record, images: newImages } };
	});

	return Promise.all(newRecipes);
};

const getImageResolution = src => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = function() {
			resolve(`${this.width}px × ${this.height}px`);
		};
		img.onerror = reject;
		img.src = src;
	});
};

const generateHTML = recipe => {
	const hbTemplate = Handlebars.compile(template);
	const htmlTemplate = hbTemplate({ recipe });
	return htmlTemplate;
};

export const saveToPDF = async (recipes, fileName = 'recipes', onRecipeImagesProcessed, onRecipeProcessed) => {
	// eslint-disable-next-line new-cap
	const doc = new jsPDF('p', 'mm', 'a4');
	const imgWidth = 210;
	const pageHeight = 295;
	const recipesWithResolution = await getImagesWithResolution(recipes, onRecipeImagesProcessed);
	await Promise.map(
		recipesWithResolution,
		async recipe => {
			const recipeHTML = generateHTML(recipe);
			let element = document.createElement('div');
			element.innerHTML = recipeHTML;
			document.body.appendChild(element);

			const canvas = await html2canvas(element, { scale: 2, width: 1050 });
			const imgData = canvas.toDataURL('image/png');

			const imgHeight = (canvas.height * imgWidth) / canvas.width;
			let heightLeft = imgHeight;
			let position = 0;

			doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
			doc.addPage();
			heightLeft -= pageHeight;

			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
				doc.addPage();
				heightLeft -= pageHeight;
			}

			document.body.removeChild(element);
			if (onRecipeProcessed) {
				onRecipeProcessed();
			}
		},
		{ concurrency: 1 }
	);

	doc.save(`${fileName}.pdf`);
};
