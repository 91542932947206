import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'template',
		pluralName: 'templates',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		state: {
			selectOptions: null
		},
		actions: {
			createFromLayout: async ({ dispatch, commit }, { name, layoutId }) => {
				const path = 'templates/from-layout';
				const payload = { name, layoutId };

				return dispatch('request', { method: 'POST', path, payload }).then(r => r.data);
			},
			getPreviewOptions: async ({ dispatch }, payload) => {
				return dispatch('request', {
					method: 'POST',
					path: 'templates/preview-options',
					payload
				}).then(r => r.data);
			},
			async getSelectOptions({ dispatch, commit }) {
				const { data } = await dispatch('request', {
					method: 'GET',
					path: 'templates/separate-layouts-options'
				});
				commit('SET_SELECT_OPTIONS', data);
			},
			createFromLayoutsBlocks: async ({ dispatch }, { contentLayoutId, coverLayoutId, name }) => {
				const path = 'templates/from-separate-layouts';

				return dispatch('request', {
					method: 'POST',
					path,
					payload: {
						contentLayoutId,
						coverLayoutId,
						name
					}
				}).then(r => r.data);
			},
			updateTemplateByLayouts: async (
				{ dispatch },
				{ template, layoutId, blockNamesToAdd, blockNamesToRemove, blocksType }
			) => {
				const path = 'templates/set-separate-layouts';

				return dispatch('request', {
					method: 'POST',
					path,
					payload: {
						layoutId,
						template,
						blockNamesToAdd,
						blockNamesToRemove,
						blocksType
					}
				}).then(r => r.data);
			}
		},
		mutations: {
			SET_SELECT_OPTIONS: (state, data) => {
				state.selectOptions = data;
			}
		},
		getters: {
			coverLayouts: state =>
				state.selectOptions?.covers.filter(layout => layout && layout.blocks?.length !== 0) || [],
			contentLayouts: state =>
				state.selectOptions?.contents.filter(layout => layout && layout.blocks?.length !== 0) || []
		}
	});
}
