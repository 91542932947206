module.exports = {
	title: 'Bella’s Kitchen',
	subtitle1: 'HEALTHY AND DELICIOUS',
	subtitle2: 'RECIPES FROM OUR KITCHEN',
	author: 'Andrés Moreno',
	// imageUrl: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/bfac533d-581b-4d07-b849-424c0afea9d5?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1942236413&Signature=mfSkSJHeXNBkZVOh68E76NJvO3c%3D&response-content-disposition=attachment%3B%20filename%3D%22leaf1-back-resize.jpg%22',
	chapters: [
		{
			name: 'breakfast',
			number: '1',
			chapterImgUrl: 'https://content-randomizer.netlify.app/images/templates/explorer/6.jpg',
			recipes: [
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/gluten-free-plantain-waffle-with-mild-hot-sauce/',
					name: 'Plantain Waffle',
					author: '',
					category: 'breakfast',
					description:
						'Plantain Waffle with Mild Hot sauce is my fun way to eat plantain for breakfast. Also because plantain is eating mostly with hot sauce in West Africa, adding my mild green sauce to this plantain is sure a delight. It is a great way to enjoy waffles with kids while staying with my westafrican cuisine […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 medium ripe Plantains'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Eggs'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 Tablespoon of coconut oil to oil the waffle maker'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Pinch of salt (optional)'
								},
								{
									type: 'SECTION',
									text: 'For the Mild Green Sauce',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '4 jalopenos peppers'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1/2 of medium green bell pepper'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1/4 of medium onion'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1/2 teaspoon of Salt'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '4 tablespoons of oil (optional)'
										}
									]
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In a food processor,purree the peeled plantain with 2 eggs and pinch of salt'
								},
								{
									type: 'INSTRUCTION',
									text: 'Start cooking in batch in your waffle maker.'
								},
								{
									type: 'INSTRUCTION',
									text: 'While the waffle is cooking you can make your hot sauce by finely blending: jalopeno pepper, onion, oil, salt, bell pepper.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Heat up the hot sauce and serve over your waffles.'
								}
							]
						}
					],
					tags: ['Breakfast & Brunch', 'Favorite Recipes', 'Gluten Free Recipes'],
					recipeYield: '2',
					images: [
						{
							name: 'Plantain waffles with hot pepper',
							url: 'https://i0.wp.com/www.savourous.com/wp-content/uploads/2021/08/plantainwaffle-new.jpg?fit=1936,1936&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/easy-refreshing-fruits-smoothie/',
					name: 'Fruit Smoothie',
					author: '',
					category: 'breakfast',
					description:
						'Smoothies are the perfect meal whether summer or winter time. It makes is a great refreshing drink after workout. A fabulous energizing drink whether it is breakfast, lunch or dinner. I personally like tart green smoothie or green tasteless smoothies but cannot say the same of the rest of my family. The below recipe is […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups of peeled and halves peach'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 peeled kiwis'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 cup of strawberries (clean with leaves off)'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Put everything in your blender and pure with the smoothie settings.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and serve immediately.'
								}
							]
						}
					],
					tags: [
						'Appetizers & Snacks',
						'Beverages',
						'Breakfast & Brunch',
						'Desserts',
						'Gluten Free Recipes',
						'LowCarb Keto Recipes',
						'Vegetarian Recipes'
					],
					recipeYield: '3',
					images: [
						{
							name: 'Fruit peach, kiwi, strawberry smoothie',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2021/01/blendtec-G6.jpg?fit=2255,2254&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/fonio-porridge/',
					name: 'Fonio porridge',
					author: '',
					category: 'breakfast',
					description:
						'Fonio porridge is my nephew favorite breakfast and snack! Fonio is a whole naturally gluten free grain that is a great source of plant-based amino acids, and may help moderate blood sugar levels! This grains is very versatile. When cooked, it readily takes the flavors of ingredients it’s prepared with. The fonio I am currently […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 cup dry fonio'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups water'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/4 teaspoon salt'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1cup of evaporated milk'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 tablespoons sugar'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/4 teaspoon nutmeg'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/4 teaspoon cinnamon'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½ teaspoon vanilla extract'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Peanuts and raisins for toppings (optional)'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In a saucepan, toast the fonio on medium heat for about 3 minutes.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add water and let it cook until water is evaporated.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the milk, then sugar, and cook it for 7 minutes stirring constantly. You should have a thick porridge. (If you do not like your porridge thick, then add more liquid to your desire thickness.)'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the optional nutmeg, cinnamon, vanilla then stir well and remove from the stove.Serve immediately and garnish with optional peanuts.'
								}
							]
						}
					],
					tags: ['Breakfast & Brunch', 'Gluten Free Recipes'],
					recipeYield: '2',
					images: [
						{
							name: 'Fonio porridge topped with raisins and peanuts',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2021/03/fonio-porridge4.jpg?fit=2016,2016&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL:
						'https://www.savourous.com/recipe-view/turmeric-coconut-milk-chia-seeds-pudding-or-golden-milk-chia-pudding/',
					name: 'Chia Pudding',
					author: '',
					category: 'breakfast',
					description:
						'This turmeric coconut milk chia Seed Pudding can also be called a golden milk chia pudding. This pudding makes a healthy breakfast or snack! I am a fan of tapioca pudding and chia pudding became a healthier alternative. Origin and benefit of Chia seeds Chia seeds became popular in recent years. There is reason why […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '¼ cup of chia seeds'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 cup of coconut milk'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 tablespoons of sweetener of your choice'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 teaspoon of turmeric powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Pinch of cinnamon'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Fruits for topping'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In a bowl, whisk unsweetened coconut milk, sweetener, turmeric, cinnamon, salt together.You can use a blender to make sure everything is well incorporated.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add chia seeds and stir well.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Let it seat in a fridge to allow coconut milk-chia seed mixture to soak until thickened for about 30minutes.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Stir it well then cover it, and put it back in the fridge overnight or about 6hours.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Serve with favorite fruit toppings or as is'
								}
							]
						}
					],
					tags: [
						'Appetizers & Snacks',
						'Breakfast & Brunch',
						'Desserts',
						'Gluten Free Recipes',
						'LowCarb Keto Recipes',
						'Vegetarian Recipes'
					],
					recipeYield: '3',
					images: [
						{
							name: 'golden milk chia pudding',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2020/09/chia-turmeric5.jpg?fit=2077,2077&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/low-carb-lactose-free-almond-flour-crepes/',
					name: 'Low carb, lactose free crepes',
					author: '',
					category: 'breakfast',
					description:
						'You will love this low carb, lactose free almond flour crepes! I can never resist crepe. When I became lactose intolerant, I started experiencing ways to enjoy crepe without dairy. It was easy since there are lactose free milk out there. But trying to lower my carbohydrate consumption made me rethink of my old crepe […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 Large eggs'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3/4 Cups of almond flour'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '¼ cup of lactose free milk'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 ounces of lactose free sour cream.'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Coconut oil for cooking'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tsp of vanilla extract'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Pinch of swerve sweetener sugar (optional)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Pinch of salt (optional)'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Mixed all ingredients but the coconut oil in the blender'
								},
								{
									type: 'INSTRUCTION',
									text: 'Pulse until you obtain an homogeneous mixture'
								},
								{
									type: 'INSTRUCTION',
									text: 'Let the batter rest for about 5 minutes'
								},
								{
									type: 'INSTRUCTION',
									text: 'Grease the skillet and scoop about 2 tablespoons of batter into the skillet (enough batter to coat the bottom, not too much as it will be too thick)'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cook on each sideRemove and serve with your choice of accompaniments'
								}
							]
						}
					],
					tags: [
						'Appetizers & Snacks',
						'Breakfast & Brunch',
						'Desserts',
						'Gluten Free Recipes',
						'LowCarb Keto Recipes'
					],
					recipeYield: '3',
					images: [
						{
							name: 'Low carb, Lactose free, keto friendly, almond flour crepe',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2020/08/almond-crepe-post1-scaled.jpg?fit=2048,2560&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/apple-arugula-kiwi-lemon-smoothie/',
					name: 'Green Tarte Smoothie',
					author: '',
					category: 'breakfast',
					description:
						'The health benefit and the taste of this smoothie made it my number one choice of smoothie. Not too sweet, it has the sour taste of an apple and kiwi. This green smoothie is a great afternoon snack when you just got home or after your afternoon run.',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups of fresh organic arugula'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 whole organic apple'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 kiwi peeled'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups of water'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Blend together all ingredient at high speed for 2mn.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Serve at room temperature or cold.'
								}
							]
						}
					],
					tags: [
						'Beverages',
						'Breakfast & Brunch',
						'Desserts',
						'Favorite Recipes',
						'Gluten Free Recipes',
						'Vegetarian Recipes'
					],
					recipeYield: '2',
					images: [
						{
							name: 'lemon-kiwi-arugula-juice',
							url: 'https://i0.wp.com/www.savourous.com/wp-content/uploads/2017/04/lemon-kiwi-arugula-juice.jpg?fit=5472,3648&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/how-to-make-easy-fluffy-crepe/',
					name: 'Simple Easy Crêpes Recipe',
					author: '',
					category: 'breakfast',
					description:
						'crepes recipes all things crepes - easy fluffy crepe Savourous quick french crepe recipe Savourous',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 large Eggs'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Cups of Flour'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 and 1/4 Cups of skimmed milk'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'A stick of butter for cooking'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tsp of vanilla exctrat'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Pinch of powdered sugar (optional)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Pinch of salt (optional)'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Mixed dry ingredients together (flour, salt and powdered sugar).'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add eggs and mix very well'
								},
								{
									type: 'INSTRUCTION',
									text: 'While mixing well, add milk in small batch, and finally add the vanilla extract'
								},
								{
									type: 'INSTRUCTION',
									text: 'Grease the skillet and scoop a batter into the skillet (enough batter to coat the bottom, not too much as it will be too thick)'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cook on each side'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and serve with your choice of accompaniments'
								}
							]
						}
					],
					tags: ['Appetizers & Snacks', 'Breakfast & Brunch', 'Desserts'],
					recipeYield: '6',
					images: [
						{
							name: '2Y9B2011',
							url: 'https://i0.wp.com/www.savourous.com/wp-content/uploads/2017/03/2Y9B2011.jpg?fit=3840,2560&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/quick-and-easy-concentrated-milk-bread/',
					name: 'Milk bread',
					author: '',
					category: 'breakfast',
					description:
						'My favorite quick and easy concentrated milk bread is always the highlight of the brunch. This bread is so fluffy bread is a crowd pleaser at breakfast. My kids love these like crazy, unfortunatey i do like making these often. It is because I tend to lower my carb intake and this bread is a […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 cup evaporated milk'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '¼ cup sweetened condensed milk'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 egg'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 tbsp sugar'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 and ¼ tsp instant yeast'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 and ¼ cups All-purpose flour'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tsp salt'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '¼ cup softened butter'
								},
								{
									type: 'SECTION',
									text: 'Egg wash:',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1 egg'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '2 tbsp of evaporated milk'
										}
									]
								},
								{
									type: 'SECTION',
									text: 'Glaze:',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '4 tbsps of butter'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '4 tbsp of condensed milk'
										}
									]
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In a bowl, Mix evaporated milk, condensed milk, egg, yeast, sugar'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add flour, salt mix well'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the crush or soft butter than mix until well incorporated'
								},
								{
									type: 'INSTRUCTION',
									text: 'Knead the dough until smooth and elastic for about 10 minutes'
								},
								{
									type: 'INSTRUCTION',
									text: 'Place in oiled bowl and cover and let it rest until double in size'
								},
								{
									type: 'INSTRUCTION',
									text: 'Once risen, punch the air out and divide the dough in two part.'
								},
								{
									type: 'INSTRUCTION',
									text: 'With the rolling pin, flatten the dough'
								},
								{
									type: 'INSTRUCTION',
									text: 'With your hand roll the flatten dough tightly and make sure you seal the edge nicely.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Divide the rolled dough into about 7parts.Place these in an elongated baking pan (Loaf pan) lined with parchment paper'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cover and let it seat in warm place for about 50mins'
								},
								{
									type: 'INSTRUCTION',
									text: 'Brush the dough with the egg wash ( mixture of 1 egg plus 2 tablespoon of milk.)'
								}
							]
						}
					],
					tags: ['Breakfast & Brunch', 'Desserts'],
					recipeYield: '5',
					images: [
						{
							name: 'fluffy-bread4',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2020/11/fluffy-bread4-scaled.jpg?fit=1707,2560&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/quick-and-easy-waffle/',
					name: 'Quick And Easy Waffle',
					author: '',
					category: 'breakfast',
					description:
						'Waffles are my kids number one breakfast item. I came up with a quick recipe that they can easily follow. It is not everyday that I want to wake up early and enjoy breakfast. Some days i just want to stay in bed longer, but kids need their breakfast. Of course you can make waffles […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 large eggs'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1  and 1/2 Cup of all purpose Flour'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1tsp Baking Powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1tbsp Sugar'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 and 1/2 Cup of Milk'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1tsp of salt'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2Tbsp of Irish Butter'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In a large bowl combine all dry ingredients (flour, baking soda, sugar, salt)'
								},
								{
									type: 'INSTRUCTION',
									text: 'In a different bowl beat egg, milk and melted butter'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the wet mixture to the dry mixture and combined well.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cook your waffle in a waffle cooker per instructions or use a waffle mold and bake at 350F.'
								}
							]
						}
					],
					tags: ['Appetizers & Snacks', 'Breakfast & Brunch'],
					recipeYield: '5',
					images: [
						{
							name: 'waffle',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2019/09/waffle12.jpg?fit=3456,3456&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				}
			]
		},
		{
			name: 'lunch',
			number: '2',
			chapterImgUrl: 'https://content-randomizer.netlify.app/images/templates/explorer/6.jpg',
			recipes: [
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/the-protein-loaded-beef-quesadillas/',
					name: 'The Protein Loaded Beef and Beans Quesadillas',
					author: '',
					category: 'main meal',
					description:
						'becareful when adding bouillon or salt as once you add cheese to your quesadilla it can taste a bit salty. I usually do not add extra salt or bouillon after using my taco seasoning.)',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 pound of ground beef'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 TBSP Carotte Purée'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4TBSP of taco seasoning'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Bouillon & Salt  optional (becareful when adding bouillon or salt as once you add cheese to your quesadilla it can taste a bit salty. I usually do not add extra salt or bouillon after using my taco seasoning.)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 medium fresh white onion diced'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 Cloves of garlic chopped'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 can of red Kidney beans'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 can of black beans'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 bunch of cilantro chopped'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups of quesadilla cheese blend (may need more depending on how cheesy you want your quesadilla)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 cup of Mozzarella cheese'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'About 2 Dozen of regular size Lowcarb Flour tortilla'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In a pan at medium heat, add your ground meat , taco seasoning, chopped garlic and diced onion. Stir well until the meat is well incorporated with the seasoning.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cook for about 5 min until the meat is brown then rinse out water from your  cooked beans and add them to the  ground meat.Add the puree carrot and paprika., stir well and cover and cook for another 5 minutes.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the chopped cilantro, stir and remove from the stove.'
								},
								{
									type: 'INSTRUCTION',
									text: 'On a medium heat  coat a non stick pan with a little bit of oil, add your flour tortilla, then your cheese mixture, a scoop of one tablespoon of beef mixture, add  more cheese.Flip the tortilla to  make half moon. Cook  both side until light brown.Repeat until all mixture is gone.'
								}
							]
						}
					],
					tags: ['Appetizers & Snacks', 'Favorite Recipes', 'Lunch & Dinner'],
					recipeYield: '5',
					images: [
						{
							name: 'Quesadilla loaded with beef and beans',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2021/07/Quesadilla-beef3.jpg?fit=2070,2070&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/garlic-herbs-lamb-rib-rack/',
					name: 'Garlic Herbs Lamb Rib Rack',
					author: '',
					category: 'main meal',
					description:
						"Garlic Herbs Lamb Rib Rack is so easy to make. You do not need tons of seasoning because the meat itself is delicious and tender already. When seasoning the garlic herbs mixture, be mindful of the salt since you've already seasoned your lamb with salt for searing. Also when baking the lamb, it is optional but you can cover the rib bone with aluminum foil lined with baking paper to void burned bone. This is just for esthetical purposes. You can serve your lamb as is, with green salad, rice or more...",
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 packs of short lamb racks'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '6 Cloves of garlic'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 Tablespoon of chili pepper flakes'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups packed of parsley leaves'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/4 cup of oil'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Salt and Optional bouillon'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Clean your lamb rib and pat dry.  Then rub it with salt and black pepper then set aside.'
								},
								{
									type: 'INSTRUCTION',
									text: 'In a food processor, finely chop the combine ingredients: parsley, garlic, chili pepper, olive oil. Add a little salt and set aside.'
								},
								{
									type: 'INSTRUCTION',
									text: 'On the stove in a hot cast iron, sear the lamb on both side the best you can until nicely brown. can take about 10 mins'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove from the stove, then safely rub the garlic herbs seasoning on the lamb.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Bake it in a preheated oven for 10 mins at 375F'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and let it seat for about 10mins then cut racks for serving. You can top the cut meat with extra leftover garlic herbs seasoning.'
								}
							]
						}
					],
					tags: ['Gluten Free Recipes', 'LowCarb Keto Recipes', 'Lunch & Dinner'],
					recipeYield: '5',
					images: [
						{
							name: 'Garlic herbs lamb rib rack',
							url: 'https://i0.wp.com/www.savourous.com/wp-content/uploads/2021/03/Lamb-herbs.jpg?fit=2079,2079&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/grilled-stuffed-meat-pita-arayes/',
					name: 'Grilled Stuffed Meat Pita (Arayes)',
					author: '',
					category: 'main meal',
					description:
						'Grilled Stuffed Meat Pita (Arayes) is another flavorful way to cook ground meat. Arayes is middle estern food that  I was introduced to years ago. Believe it of not this was my first time making it myself and turns out really good! Originally you will mix ground beef and ground lamb meat. But do not […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 pound of ground fatty lamb meat'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 bunches of parsley'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 medium onion'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 garlic cloves'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tsp of black pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tsp of salt'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tsp of bouillon powder  (optional)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 Tbsp of cinnamon'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 Tbsp of cumin'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Finely chop the parsley, onion, garlic, then add it to the ground meat'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the cumin, cinnamon, salt, bouillon the mix well.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cut each pita in half and open each pockect then insert the meat in each pocket half way. Use both palm of your hand to flatten the meat inside the pita pocket and distribute it envenl until the end of the pita pocket'
								},
								{
									type: 'INSTRUCTION',
									text: 'Grill the pita on each side for about 2 mins each.When using the oven Bake it in a preaheated oven at 375 for about 4 minutes.Remove and serve'
								}
							]
						}
					],
					tags: ['Favorite Recipes', 'Lunch & Dinner'],
					recipeYield: '5',
					images: [
						{
							name: 'Grilled meat stuffed pita Arayes',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2021/07/arayes4.jpg?fit=1786,1786&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL:
						'https://www.savourous.com/recipe-view/baked-seafood-boil-sheet-pan-seafood-boil-bouilli-de-fruits-de-mer/',
					name: 'Baked Seafood Boil, Sheet Pan Seafood Boil, (Bouilli de fruits de mer)',
					author: '',
					category: 'main meal',
					description:
						'This Baked Seafood Boil, Sheet Pan Seafood Boil is a quick and savory way to enjoy your seafood boil. Check here for the recipe video. Once you tried this recipe, you will never be intimidated by seafood boil. The crab leg and shrimps soaks up the flavor really well. We can eat seafood everyday. Crab […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 pounds of mini potatoes'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '6 crab legs'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1-pound large shrimp, peeled and de-veined'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '12 ounces turkey sausage, sliced into 1/4-inch medallions'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '6 lobster tails'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 ears of corn cut into thirds'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Pack of ranch dip powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '6TBSP Smoked paprika'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4TBSP lemon pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4TBSP onion powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4TBSP garlic powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4TBSP Cajun seasoning'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4TBSP Savourous wild for salmon'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2TBSP of old Bay seasoning'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2TBSP of herb de Provence'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2TBSP of black pepper to taste'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½ cup of minced garlic'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 juice of medium lemon'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 1/2 cup of butter, divided'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 cup of chopped fresh parsley, divided'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Preheat the oven to 350FBoil your mini potatoes in unsalted water'
								},
								{
									type: 'INSTRUCTION',
									text: 'Mix all seasoning and reserve half cup of butter and 1/3 of the parsley and ½ of the minced garlic.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Coat the potatoes and seafood with the seasoning mixture.Arrange the coated corn, potato, seafood in two large baking pan'
								},
								{
									type: 'INSTRUCTION',
									text: 'Mix the 2nd 1/3 of the parsley with the remaining 1/2 minced garlic, and ½ cup of butter left.Drip the garlic parsley mixture all over ingredients in the baking pan.Cover with aluminum foil and bake for 20mins'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and top it with the last 1/3 of the chopped parsley and some lime.Serve and reserve the sauce at the bottom of the baking pan for extra dipping'
								}
							]
						}
					],
					tags: ['Favorite Recipes', 'Lunch & Dinner'],
					recipeYield: '6',
					images: [
						{
							name: 'Baked sheet pan seafood',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2021/04/seafood-boil.jpg?fit=2016,2016&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/stir-fry-kale-and-rice-low-carb-rice/',
					name: 'Stir fry kale and rice (low carb rice)',
					author: '',
					category: 'main meal',
					description:
						'This stir fry kale and rice (low carb rice) is my son’s favorite rice dish. It also my type of  of low carb , I always have frozen kale in my freezer. ice dish. I am a rice lover and being on a low carb diet makes thing kind of hard. I have tried different […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 cup of cooked rice'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups of cooked chopped kale (all excess humidity removed)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Tablespoons of oil'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 Tablespoon on Savourous granulated garlic'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Bouillon, salt, pepper (to taste)'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Cook for about 2 mins, then add the cooked rice and stir well'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add salt, bouillon pepper to taste.Mix well and cook for another 2 mins'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and serve as is or with meat. I garnish my rice with some cherry tomatoes.'
								}
							]
						}
					],
					tags: ['Gluten Free Recipes', 'LowCarb Keto Recipes', 'Lunch & Dinner', 'Vegetarian Recipes'],
					recipeYield: '1',
					images: [
						{
							name: 'Stir fry kale and rice (low carb rice dish)',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2020/12/Kale-and-rice1.jpg?fit=2189,2189&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/goat-meat-light-fufu-soup-cuisine-togolaise/',
					name: 'Goat Meat Light Fufu Soup ( Togolese Pepper soup)',
					author: '',
					category: 'main meal',
					description:
						'Goat meat pepper soup with fufu, - pepper soup - Nigerian Pepper Soup Recipe - Togolese Pepper Soup Recipe - African pepper soup - foufou -',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3-5 pounds of cubed goat meat'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'About 8 cups of water (or boiled yam water)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '5 Tablespoons of ginger paste'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '5 Tablespoons of onion paste'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'About 10 cloves'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'about 6 Akpi seeds or Djassang (optional)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 Big fresh crushed tomato'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 medium onion sliced'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Tablespoons of black pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 Tablespoons of bouillon powder (or to taste)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 habanero peppers (less if hot pepper heat is an issue)'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In the 4quart 360 cookware pot, add the clean cubed goat meat.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Place the pot on the slow cooker base and turn it to High setting.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add ginger paste, onion paste, cloves and the optional Akpi seeds.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cover and let it cook for 30mins to 45 mins (depends on how soft you want the meat)'
								},
								{
									type: 'INSTRUCTION',
									text: 'Open the lid, add your water , remove the pot from the slow cooker base and place on stove top at high heat.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the crushed tomato, sliced onion and hot pepper.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Let it boil for about 3 mins, taste the soup. If you feel it need salt or bouillon, add a little of those to your taste.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Let it boil for about 5 minutes, add black pepper and mix well'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and serve as is or over fufu.'
								}
							]
						}
					],
					tags: ['Breakfast & Brunch', 'Favorite Recipes', 'Gluten Free Recipes', 'Lunch & Dinner'],
					recipeYield: '4',
					images: [
						{
							name: 'Pepper soup, goat meat pepper soup',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2021/02/fufu-soup3.jpg?fit=1080,1080&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/kale-with-sunflower-seeds-and-assorted-meat/',
					name: 'Kale with sunflower seeds stew and assorted meat',
					author: '',
					category: 'main meal',
					description:
						'This Kale with sunflower seeds stew and assorted meat is a very healthy and savory. . The recipe is a cultural fusion of Togolese ‘Gboma dessi” (spinach and pumpkin seeds stew). Check out the recipe video here. The main ingredients about the delicious stew is kale and sunflower seeds. These are healthy ingredients that goes […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 red bell peppers'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 cups of Fresh Kale soften with hot water'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 cup of sun flower seeds (toasted)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 cups of oil (can use less)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 small ginger root peeled (about 2oz)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 large onion'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 Cloves of garlic'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tablespoon of black pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 tablespoons of msg-free bouillon powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 habanero peppers'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Salt to taste'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Sliced onion and pepper for optional garnish'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Assorted cooked meat of choice. (optional)'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Blend the bell peppers, onion, ginger, hot pepper, garlic glove and set aside. I do not like reducing it into a fine puree, I love having some texture to the mixture.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Finely pure the toasted flaxseed with just enough water to help with the processing.'
								},
								{
									type: 'INSTRUCTION',
									text: 'On medium heat add the oil to your cooking pan. Then start frying the bell pepper mixture.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Let it cook for about 5 minutes until liquid is well reduce and oil shows on top.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the puree sunflower seeds cover let it cook for about 5 minutes.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the cooked meat, stir, cover and cook for another 5 minutes.  Add the kale, bouillon, salt.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cover cook for about 3 minutes then add black pepper, optional garnish (sliced onion, hot pepper). Cook for about 3 more minutes.Remove and serve'
								}
							]
						}
					],
					tags: ['Gluten Free Recipes', 'LowCarb Keto Recipes', 'Lunch & Dinner'],
					recipeYield: '5',
					images: [
						{
							name: 'kale-sunflower-seeds with beef',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2021/05/kale-sunflower-seeds.jpg?fit=1080,1920&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/spiced-turmeric-rice/',
					name: 'Spiced Turmeric Rice',
					author: '',
					category: 'main meal',
					description:
						'This spiced Turmeric Rice is a fabulous way to add some color and flavor to boring white rice. Check out the recipe video here. I have a nephew that is visit and and he likes is rice. So I decided to find different ways of cooking rice dishes so my cuisine does not get boring! […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups of Basmati rice'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups of Jasmin Rice'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/4 cup of coconut oil'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '8 cloves'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 star Anise'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Cinnamon stick'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 teaspoons of coriander seeds'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 teaspoon of cumin'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 teaspoon of black peppercorn'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 teaspoon of salt'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 teaspoon of msg free bouillon powder (optional)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 Bay leaves'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 teaspoons of chopped ginger'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 cloves of garlic chopped'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 teaspoon of turmeric powder'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In your pressure cooker, on sautéed setting, heat up the coconut oil.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add bay leaves, cumin, coriander, cloves, black pepper cinnamon sticks, cook it for about 2 mins, then add the turmeric powder, then add the rice'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cook the rice in the hot spiced oil and keep stirring it for about 3mins. add the chopped ginger and garlic, salt and bouillon cook it for 2 minutes then add water per rice cooker instructions.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cook rice and serve hot with grilled meat or as is with side of hot pepper sauce.'
								}
							]
						}
					],
					tags: ['Gluten Free Recipes', 'Lunch & Dinner'],
					recipeYield: '6',
					images: [
						{
							name: 'Spiced turmeric rice',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2021/06/rice_turmeric4.jpg?fit=2008,2009&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/easy-baked-whole-fish/',
					name: 'Easy baked whole fish and chimichurri',
					author: '',
					category: 'main meal',
					description:
						'This is easy baked whole fish is always a delight. Click here for recipe video. I love making whole fish mostly on weekends. Probably because there is more work in cleaning the fish. Luckily there are numerous market like Asian store and African store that sell whole clean fish. I usually buy these whole clean […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Clean whole fish (about 1-2 pounds)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 tablespoons of your favorite fish seasoning (savourous wild for salmon seasoning)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 tablespoon of cooking oil'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Salt and bouillon to taste'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 cup of chimichurri sauce'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Mix the fish seasoning with salt, bouillon and oil, then coat you clean fish'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cook the fish at 400F for 5 mins, then brush both side with about 2 tablespoons of chimichurri sauce'
								},
								{
									type: 'INSTRUCTION',
									text: 'Put back in the oven and cook at 400F for 10 mins, then brush both sides again with a bit of chimichurri sauce'
								},
								{
									type: 'INSTRUCTION',
									text: 'Put back in the oven and bake at 400F for another 5 mins.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and top with chimichurri and serve with your favorite side or as is'
								}
							]
						}
					],
					tags: ['Favorite Recipes', 'LowCarb Keto Recipes', 'Lunch & Dinner'],
					recipeYield: '2',
					images: [
						{
							name: 'fish_chimichuri19',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2020/12/fish_chimichuri19-1.jpg?fit=2218,2218&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/easy-crispy-air-fried-cajun-chicken-wings/',
					name: 'Easy Crispy Air Fried Cajun Chicken Wings',
					author: '',
					category: 'main meal',
					description:
						'This Easy Crispy Air Fried Cajun Chicken Wings recipe is the easiest of all! Air Frying revolutionized healthy eating. Yes; it made healthy cooking faster, effortless without compromising flavor, taste and texture. It is so easy to use by my teenage kids. One of their favorite things to cook in the air fryer is chicken […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '6-8 whole chicken wings'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 tablespoons Savourous Cajun Seasoning'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 teaspoon of baking powder (optional)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tablespoon of Bouillon  powder (to taste) . No need of salt if using bouillon, but it is all about your taste.'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Clean and pat your chicken dry.'
								},
								{
									type: 'INSTRUCTION',
									text: 'In a big bowl place your wings and add the Savourous Cajun seasoning blend, baking powder, bouillon. Toss it well until all wings are well coated.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Transfer your wings into  the air fryer basket  (Do not worry about over packing, I do have a bigger air fryer 5.8 so it was helpful)'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cook at 400 F for 30 minutes, shaking the basket halfway through cooking time.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and serve as is or with your favorite side dish.'
								}
							]
						}
					],
					tags: [
						'Air Fryer Recipes',
						'Appetizers & Snacks',
						'Favorite Recipes',
						'Gluten Free Recipes',
						'LowCarb Keto Recipes',
						'Lunch & Dinner'
					],
					recipeYield: '2',
					images: [
						{
							name: 'Air fried Cajun chicken wings',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2021/02/cajun_chicken7.jpg?fit=2330,2330&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/strawberry-glazed-hot-sauce-with-chicken/',
					name: 'Strawberry glazed hot sauce with chicken',
					author: '',
					category: 'main meal',
					description:
						'Strawberry glazed hot sauce with chicken is always a winner in my house. Click here for the recipe video. I remember the first time I made this recipe, my middle child was all over it! He begged me to show him how to make it the next day. He made that chicken several time since, […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 pound of chicken breast diced'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 eggs'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 tablespoons of tapioca starch'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 tablespoon of sea salt'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tsp bouillon powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tablespoon of black pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Oil for frying'
								},
								{
									type: 'SECTION',
									text: 'Strawberry Glazed sauce',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1/4 cup of apple cider vinegar'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '2 tablespoons of paprika'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1 cup of strawberry preserves'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '2 tablespoons of hot pepper ( depends on your hot pepper tolerance)'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '2 tablespoons of crushed ginger'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '2 tablespoons of garlic'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: 'Salt to taste'
										}
									]
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Coat chicken with egg, tapioca, salt, bouillon, chicken seasoning and set aside.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Place strawberry, vinegar, garlic, ginger, and red chili flakes in a small pot and cook over medium-high heat until well thickened to taste.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Heat up oil on the stove for up to 350 degrees'
								},
								{
									type: 'INSTRUCTION',
									text: 'Start cooking the chicken in batch until light golden brown.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and pour the glazed sauce over it.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Toss until all chicken are well coated.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Serve as is or with rice or noodle'
								}
							]
						}
					],
					tags: ['Appetizers & Snacks', 'Gluten Free Recipes', 'LowCarb Keto Recipes', 'Lunch & Dinner'],
					recipeYield: '4',
					images: [
						{
							name: 'strawberry glazed hot chicken',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2020/09/strawberry-tangy-chicken5.jpg?fit=2339,2339&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/easy-homemade-stove-top-doner-kebab/',
					name: 'Easy Homemade Stove Top Beef Geseh or Doner Kebab',
					author: '',
					category: 'main meal',
					description:
						'Arab shawarma - Greek gyro - gyro - shawarma - pita - ground beef recipe % - ground lamb recipe - ground veal recipe - ground turkey recipe -',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4lbs ground meat'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2/3 cup of plain Greek yogurt'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '5 tablespoons of onion powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 tablespoons of garlic powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tablespoon of thyme'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tablespoon of black pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tablespoon of cumin'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tablespoon of cayenne pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tablespoon of smoked paprika'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tablespoon of coriander'
								},
								{
									type: 'SECTION',
									text: 'Tools',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: 'Shrink wrap'
										}
									]
								},
								{
									type: 'SECTION',
									text: 'For the Salad',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '½ medium onion'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '2 seedless cucumber'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '½ cup of cherry tomatoes'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '¼ cup of chopped cilantro'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: 'Juice of ½ lime'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '2 tablespoons of olive oil'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: 'Salt and pepper to taste'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1 Garlic clove'
										}
									]
								},
								{
									type: 'SECTION',
									text: 'For the dressing',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1cup Greek Yogurt'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: 'Juice of 1 lime'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '5 garlic cloves minced'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '4 tablespoons fresh dill chopped'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1 tablespoon of salt to taste'
										}
									]
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Place the ground meat in a large bowl add all the seasoning and mix wellIn a shrink plastic wrap pack the meat to form a compact log shape (check the recipe video)Freeze the meat for about six hours or overnight.'
								},
								{
									type: 'INSTRUCTION',
									text: 'When ready to use, leave the meat on the counter for about 30mins for easy slicing, then slice the meat thin.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Start cooking the sliced meat in a frying pan at medium heat gently stirring the meat.Remove and serve warm with the salad.'
								},
								{
									type: 'INSTRUCTION',
									text: 'For the salad Mix the diced cucumber, tomatoes, onion. Add the lemon juice, salt pepper, olive oil, and the cilantro.Mix well and serve with your Doner Kebab.'
								},
								{
									type: 'INSTRUCTION',
									text: 'For the Doner Kebab dressing:Mix well the yogurt, lime juice, garlic, dill and salt.Top your Doner Kebab with it.'
								}
							]
						}
					],
					tags: ['Breakfast & Brunch', 'Lunch & Dinner'],
					recipeYield: '5',
					images: [
						{
							name: 'Doner Kebab homemade',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2021/02/shawarma5-jpg.jpg?fit=2464,2464&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/yebessessi-or-togolese-salsa/',
					name: 'Yebessessi or togolese salsa',
					author: '',
					category: 'main meal',
					description:
						'I love making yebessessi or Togolese salsa! In Togo an small country in West part of Africa, yebessessi is very popular. Yebessessi is usually made for sick people as the heat helps keep the food in your stomach.  I personally love yebessessi as it is versatile and can be eating with anything! It is so […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '10 medium roma tomatoes'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 large onion'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 medium jalapeno peppers'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Salt (to taste)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Bouillon (optional)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 air fried Norvegian mackerel (optional) (recipe is on the blog)'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Rinse well all vegetable'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove the tomato seeds if you want but it is optional.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove the pepper seeds if you cannot tolerate the heat.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Blend all vegetable together'
								},
								{
									type: 'INSTRUCTION',
									text: 'on medium heat, in a casserole, pour the blended mixture.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add salt, and bouillon cube to taste'
								},
								{
									type: 'INSTRUCTION',
									text: 'Let it boil for 2 minutes'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and serve with fish and some slice onion, tomato pepper for garnish.'
								}
							]
						}
					],
					tags: ['Breakfast & Brunch', 'Lunch & Dinner'],
					recipeYield: '5',
					images: [
						{
							name: 'Yebessessi, moyo, togolese salsa with air-fried norvegian mackerel',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2020/04/yebessessi_post.jpg?fit=2088,2088&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/suya-meat-skewers/',
					name: 'Suya,  West African Popular Meat Skewers',
					author: '',
					category: 'main meal',
					description:
						'Please note that for this recipe, beef, lamb, goat works wonder! Meat slice should be about1/2 inches. If the slices is too thick, you can physically tenderize your meat. Also midway through baking you can season your skewers with another coat of suya and oil spray.',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 pound of meat'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 cup of suya mild seasoning'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Tablespoons of savourous garlic powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Tablespoons of Savourous onion powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 Tablespoons of Savourous PrimeRib Seasoning'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/4 cup of cooking oil or some spray oil'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Salt to taste'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Bouillon (to taste) Optional'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In a big bowl add the sliced meat, seasoned it with onion, garlic, prime rib seasoning, optional bouillon.3Mix until meat is well coated.4Grill to a medium for about 15 making sure both side of meat has the grill marks.5Remove the grilled meat and set in a big bowl6Coat the meat with half of the suya seasoning7Spread the meat on a baking plate8Bake at 375F for about 10 minutes9Remove from the oven, flip the meat10Spray or coat with oil, the meat with remaining suya seasoning11Bake at 375F for about another 15 minutes12Remove, slice garnish and serve warm'
								},
								{
									type: 'INSTRUCTION',
									text: 'In a big bowl add the sliced meat, seasoned it with onion, garlic, prime rib seasoning, optional bouillon.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Mix until meat is well coated.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Grill to a medium for about 15 making sure both side of meat has the grill marks.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove the grilled meat and set in a big bowl'
								},
								{
									type: 'INSTRUCTION',
									text: 'Coat the meat with half of the suya seasoning'
								},
								{
									type: 'INSTRUCTION',
									text: 'Spread the meat on a baking plate'
								},
								{
									type: 'INSTRUCTION',
									text: 'Bake at 375F for about 10 minutes'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove from the oven, flip the meat'
								},
								{
									type: 'INSTRUCTION',
									text: 'Spray or coat with oil, the meat with remaining suya seasoning'
								},
								{
									type: 'INSTRUCTION',
									text: 'Bake at 375F for about another 15 minutes'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove, slice garnish and serve warm'
								},
								{
									type: 'INSTRUCTION',
									text: 'Skew the meat on the skewer and place it on a grill.Grill to a medium for about 10mins flipping it midway'
								},
								{
									type: 'INSTRUCTION',
									text: 'Skew the meat on the skewer and place it on a grill.'
								},
								{
									type: 'INSTRUCTION',
									text: ''
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove the grilled meat and set on a baking sheetCoat the meat with part of your suya seasoning  and spray a little oil over the coated skewers'
								},
								{
									type: 'INSTRUCTION',
									text: ''
								},
								{
									type: 'INSTRUCTION',
									text: 'Bake at 375F for about 15 minutes'
								},
								{
									type: 'INSTRUCTION',
									text: 'Bake at 375F for about 15 minutes'
								},
								{
									type: 'INSTRUCTION',
									text: ''
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and serve with slice tomato and onion and a bit of suya on a side.'
								}
							]
						}
					],
					tags: ['Favorite Recipes', 'Gluten Free Recipes', 'LowCarb Keto Recipes', 'Lunch & Dinner'],
					recipeYield: '5',
					images: [
						{
							name: 'suya',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2021/04/suya1.jpg?fit=2464,2464&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/air-fryer-goat-meat-suya/',
					name: 'Air Fryer Goat Meat Suya',
					author: '',
					category: 'main meal',
					description:
						'This recipe is fabulous when used goat meat with skin on. You can also add some liquid smoke for the smoky grill flavor flavor.  You can cook the meat on the stove to your desire tenderness if not using a pressure cooker.',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 pounds of cubed goat meat'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Half medium onion slices'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1Tablespoon of Bouillon'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1tablespoon of salt'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '5 cloves'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tablespoon of onion'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 tablespoons of ginger'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½ teaspoon of coriander seeds'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½ teaspoon of black pepper corn'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 tablespoons of suya seasoning'
								},
								{
									type: 'SECTION',
									text: 'Topping',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: 'Slices of ½ medium red onion,'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: 'Slices of1 medium fresh deseeded tomato.'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1 tablespoon of suya seasoning'
										}
									]
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In an instant pot, place the cleaned goat meat cubed.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add all ingredients but the suya seasoning and toppings.You do not need to add water as the meat is wet enough from the rinsing and the wetness is good to help build pressure in the instant pot.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cook it at meat stew setting for about 35minsOnce done cooking remove the meat from instant a put it in a large bowl without'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the suya seasoning mix well until all meat is coated.Cook it in the air fryer for 15-20mins at 370F, stir at halfway of cooking.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and top it with optional Suya seasoning, slice of red onion and tomato.Serve as is or with your favorite side dish.'
								}
							]
						}
					],
					tags: ['Gluten Free Recipes', 'LowCarb Keto Recipes', 'Lunch & Dinner'],
					recipeYield: '3',
					images: [
						{
							name: 'goat meat suya',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2021/03/suya-goat9.jpg?fit=2012,2012&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/fonio-couscous-with-veggies-and-beans/',
					name: 'Fonio Couscous With Veggies, Beans and Salmon',
					author: '',
					category: 'main meal',
					description:
						'You can have this dish with your favorite fried or grilled meat. You can cook your fonio with meat or veggies stock when doing so do not use extra salt or bouillon in your dish to avoid over seasoning your dish.',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½ cup of Fonio'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3/4 cup of water'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 tablespoons of cooking oil'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 clove of garlic'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½ cup of diced mix colored pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½ cup of black-eyed peas. Correction: (1/2 cup of cooked black-eyed peas)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½ teaspoon of chili pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½ teaspoon of bouillon powder'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Add 1 tablespoon of cooking oil to a pan and toast the 1/2 cup of fonio for about 2minutes at low heat.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add 3/4 cup of water and a pinch of salt to the fonio and turn the heat to high. Let it boil for about 2 mins, then turn the heat to low. Cook it until all the liquid is absorbed.Fluff the fonio with a fork and remove from the stove then set aside'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add 2 tablespoons of cooking oil to a pan, stir fry diced green, red, yellow bell pepper and optional cooked beans with minced garlic, chili pepper and a pinch of bouillonAdd the stir-fried veggies to the cooked fonio and mix well then serve.'
								}
							]
						}
					],
					tags: ['Gluten Free Recipes', 'Lunch & Dinner', 'Vegetarian Recipes'],
					recipeYield: '2',
					images: [
						{
							name: 'fonio couscous with salmon',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2021/03/fonio-couscous3.jpg?fit=2016,1134&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/easy-steak-kebab/',
					name: 'Easy Steak Kebab',
					author: '',
					category: 'main meal',
					description:
						'Easy steak kebab are the number one appetizer in my house. We do eat it as is like snack or with salad or other side dish. Skewing the meat with veggies makes a pretty and also helps kids eat those veggies. For this recipe, you do not need to let the meat seat overnight before […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2-pound cubed Sirloin Steak (tender part of beef)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½ cup of Oil'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 tablespoons Garlic powder'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 tablespoons Ginger'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 tablespoons Mustard'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Bouillon cube'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Salt'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 tablespoons Lemon juice'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 tablespoons Black pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Fresh veggies (bell pepper, cherry tomato red onion)'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Skewers (about 10)'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In a mixing bowl mix together Oil, Garlic powder, Ginger, Mustard, Bouillon cube, Salt, Lemon juice, Black pepper'
								},
								{
									type: 'INSTRUCTION',
									text: 'Pour the oil mixture over steak, mix until well coated.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cover and let it rest in the fridge overnight or for 2 to 6 hours'
								},
								{
									type: 'INSTRUCTION',
									text: 'Cut your veggies to your desired size,'
								},
								{
									type: 'INSTRUCTION',
									text: 'Season the veggies with oil, and bouillon cube'
								},
								{
									type: 'INSTRUCTION',
									text: 'Skew the meat and veggies onto kebabs in desired style.'
								},
								{
									type: 'INSTRUCTION',
									text: 'At medium heat grill kebab until cook..(You can bake it at 350 also if you do not want to grill.)'
								},
								{
									type: 'INSTRUCTION',
									text: 'Turn kebab so both side are done Do not overcook or it will be to hard to eat'
								}
							]
						}
					],
					tags: ['Appetizers & Snacks', 'Gluten Free Recipes', 'LowCarb Keto Recipes', 'Lunch & Dinner'],
					recipeYield: '5',
					images: [
						{
							name: 'kebab',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2020/05/kebab-post10-scaled.jpg?fit=1829,2560&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/easy-lowcarb-shrimp-taco/',
					name: 'Easy Lowcarb Shrimp Taco',
					author: '',
					category: 'main meal',
					description:
						'This easy lowcarb shrimp taco was for a quick last minute family gathering. Like they say, “Happiness is having a large, loving, caring, close-knit family in another city.” Georges Burns I use purple cabbage because of its beauty and also because i have in the fridge and needed to use it up! Also the tortilla […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 pound of peeled and clean shrimps'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '24 mini lowcarb flour tortillas'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups of shredded purple cabbage'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3tbsp of chopped garlic'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 /2 cup of chopped cilantro'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Juice of half lime'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1tsp of black pepper'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4tbsps of olive oil'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1tsp of bouillon'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '½tsp of salt'
								},
								{
									type: 'SECTION',
									text: 'For the taco dressing',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '2TBSP of chopped garlic'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1 Tbsp of chopped cilantro'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: 'Half lime Juice'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1 cup of dairy free sour cream'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1 cup of dairy free mayo'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '½ cup of Sriracha sauce'
										}
									]
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Pat your clean shrimp dry, add bouillon, black pepper, chopped garlic; mix well.'
								},
								{
									type: 'INSTRUCTION',
									text: 'On medium heat, Sautee your shrimp with the olive oil in the Tramontina Triply Clad SS sautéed pan. Finish the cooking with a drizzle of lime juice then set the cooked shrimp aside.'
								},
								{
									type: 'INSTRUCTION',
									text: 'In the Tramontina mixing bowl, mix the following ingredient for the dressing: sour cream, mayonnaise, sriracha sauce, chopped garlic, lime juice, chopped cilantro. Mix well and set aside.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Warm up the mini tortilla in the Tramontina Proline Nonstick Fry Pan on medium heat.Start assembling your tortillas, by first placing your warm flour tortilla in the bowl, add shredded purple cabbage, spray about half teaspoon of optional lime juice on the cabbage. Add about 4 pieces of cooked shrimps, then the taco sauce and top the whole thing with cilantro.'
								}
							]
						}
					],
					tags: ['Appetizers & Snacks', 'Favorite Recipes', 'LowCarb Keto Recipes', 'Lunch & Dinner'],
					recipeYield: '5',
					images: [
						{
							name: 'Shrimp taco',
							url: 'https://i2.wp.com/www.savourous.com/wp-content/uploads/2021/08/DI5A3850_sq-1.jpg?fit=2000,2000&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.instagram.com/reel/CNndiOGhIux/?utm_source=ig_web_copy_link',
					name: '',
					author: '',
					category: '',
					description:
						'Welcome back to Instagram. Sign in to check out what your friends, family & interests have been capturing & sharing around the world.',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: []
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: []
						}
					],
					tags: [],
					recipeYield: '',
					images: [],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				},
				{
					id: '',
					sourceURL: 'https://www.savourous.com/recipe-view/stove-top-salmon-dish-and-spaghetti-squash/',
					name: 'Stove top Salmon Dish and Spaghetti Squash',
					author: '',
					category: 'main meal',
					description:
						'Stove top Salmon Dish and Spaghetti Squash is the best lowcarb meal of the week. I enjoy squash season and love how cheap spaghetti squash are. Check my recipe for the spaghetti squash here along with the video recipe. This dish is flavorful and so appetizing you will end up making it more. For the […]',
					ingredients: [
						{
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 cuts of salmon'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1/2 cup of cherry tomatoes'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cloves of garlic'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '3 Tbsps of savourous salmon seasoning'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Tbsps of oil'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 tsps of salt'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 tsp of msg free bouillon seasoning'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Scotch bonnet pepper or habanero peppers.'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Herbs: rosemary & thyme (optional)'
								}
							]
						}
					],
					instructions: [
						{
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Season your salmon with savourous salmon seasoning, salt and optional bouillon'
								},
								{
									type: 'INSTRUCTION',
									text: 'Place your non cooking cookware on the stove at medium heat. Add oil, minced garlic, rosemary and thyme.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Place your salmon pieces skin side down in the skillet. Cook for about 3 minutes and flip the salmon to allow the other side to cook'
								},
								{
									type: 'INSTRUCTION',
									text: 'Add the cherry tomato to the salmon, cover and cook for about 5 mins.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and serve over spaghetti squash'
								}
							]
						}
					],
					tags: ['Gluten Free Recipes', 'LowCarb Keto Recipes', 'Lunch & Dinner'],
					recipeYield: '2',
					images: [
						{
							name: 'salmon spaghetti squash',
							url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2021/03/salmon-squash4.jpg?fit=2464,2464&ssl=1',
							thumbnailURL: '',
							fileURL: ''
						}
					],
					nutrition: {
						calories: 0,
						fat: 0,
						protein: 0,
						carbs: 0
					},
					cookingTime: {
						prepTime: '',
						cookTime: '',
						totalTime: ''
					}
				}
			]
		}
	]
};
