export default function(store) {
	const mobilePanelStore = {
		namespaced: true,
		state: {
			isOpened: false,
			component: null
		},
		actions: {
			toggle({ commit, state }, component) {
				if (!state.isOpened) {
					commit('SHOW_MOBILE_PANEL', component);
				} else {
					commit('HIDE_MOBILE_PANEL');
				}
			},
			hide({ commit }) {
				commit('HIDE_MOBILE_PANEL');
			}
		},
		mutations: {
			SHOW_MOBILE_PANEL: (state, component) => {
				state.isOpened = true;
				state.component = component;
			},
			HIDE_MOBILE_PANEL: state => {
				state.isOpened = false;
				state.component = null;
			}
		},
		getters: {
			isOpened: state => state.isOpened,
			component: state => state.component
		}
	};

	store.registerModule('mobile-panel', mobilePanelStore);
}
