module.exports = [
	{
		'?': {
			if: [
				{ var: '$props.coverWithSpine' },
				{
					type: 'Cover with spine',
					props: {
						title: { '?': { var: '$book.title' } },
						subtitle1: { '?': { var: '$book.subtitle1' } },
						subtitle2: { '?': { var: '$book.subtitle2' } },
						backText:
							'Os ius dolore quodi sandant mi, corro maxim ex et imus am volo eria nistem expliatem ut eum doloremque veritio rrovide mporum in preprat aut ea nus ande essum, suntiandunt velest, quis verrum sundignati cus et hit dem hitiurem nem remosto idunt ulpario quianda vellacc uptatumqui odi cus quassin rere nihilit as et, ut qui cusandit, nonem verum es magnatusaest pror a alit qui quaeser ferepernat od esed unt, ipsae excearum as exceriaestem et debisti cuptae perupta quisqui duciis.',
						imageUrl: { '?': { var: '$book.imageUrl' } }
					},
					meta: {
						productComponent: 'cover'
					}
				},
				[{
					type:  'Cover Front',
					props: {
						title: { '?': { var: '$book.title' } },
						subtitle1: { '?': { var: '$book.subtitle1' } },
						subtitle2: { '?': { var: '$book.subtitle2' } },
						backText:
							'Os ius dolore quodi sandant mi, corro maxim ex et imus am volo eria nistem expliatem ut eum doloremque veritio rrovide mporum in preprat aut ea nus ande essum, suntiandunt velest, quis verrum sundignati cus et hit dem hitiurem nem remosto idunt ulpario quianda vellacc uptatumqui odi cus quassin rere nihilit as et, ut qui cusandit, nonem verum es magnatusaest pror a alit qui quaeser ferepernat od esed unt, ipsae excearum as exceriaestem et debisti cuptae perupta quisqui duciis.',
						imageUrl: { '?': { var: '$book.imageUrl' } }
					},
					meta: {
						productComponent: 'cover-front'
					}
				},
				{
					type:  'Cover Back',
					props: {
						title: { '?': { var: '$book.title' } },
						subtitle1: { '?': { var: '$book.subtitle1' } },
						subtitle2: { '?': { var: '$book.subtitle2' } },
						backText:
							'Os ius dolore quodi sandant mi, corro maxim ex et imus am volo eria nistem expliatem ut eum doloremque veritio rrovide mporum in preprat aut ea nus ande essum, suntiandunt velest, quis verrum sundignati cus et hit dem hitiurem nem remosto idunt ulpario quianda vellacc uptatumqui odi cus quassin rere nihilit as et, ut qui cusandit, nonem verum es magnatusaest pror a alit qui quaeser ferepernat od esed unt, ipsae excearum as exceriaestem et debisti cuptae perupta quisqui duciis.',
						imageUrl: { '?': { var: '$book.imageUrl' } }
					},
					meta: {
						productComponent: 'cover-back'
					}
				}]
			]
		}
	},
	{
		type: 'Title page',
		props: {
			title: { '?': { var: '$book.title' } },
			subtitle1: { '?': { var: '$book.subtitle1' } },
			subtitle2: { '?': { var: '$book.subtitle2' } }
		},
		meta: {
			productComponent: 'text'
		}
	},
	{
		type: 'Introduction',
		props: {
			imageUrl:
				'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/7c93462a-67e7-4665-815c-f3c2eb3c958b?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941563136&Signature=51uAf4AZWGGHs2nPbJn7E4jxozA%3D&response-content-disposition=attachment%3B%20filename%3D%22Leaf_intro%20%281%29.jpg%22',
			text: `Perehent otatemo lenitaquis dolenist est unt.
Occulpa nonserspides excepe re perfere aut oditati dolor
sae num rersper ibusdanditi berchil et qui reseneste
vollabo. Arum velit lacitias sam aut quae liquaspis
dellate pos idel is inctio. Omnisquatias et lab il iuribusam
volenima vollent ratemporis everferferio expelest accus,
ulla velecaerum fuga. Pelibus eumque eicimus, tet elis
arumquam fuga. Rum as aut re nobitatume si omnitaquia
con eat parcid quatem conse es dolori aut aut a plibus,
quat ut ut libus eossum alissequam net reperferchit
ipsam voloribea dolorem venist, vernam sequia sectur.`
		},
		meta: {
			productComponent: 'text'
		}
	},
	{
		type: 'Table of contents',
		props: {
			pagesCount: 1,
			chapters: { '?': { var: '$book.chapters' } },
			imageUrl:
				'https://oneflow-file-core-api-dev-new-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/788cd099-e6d5-4b3e-978e-847bd4be13c9?AWSAccessKeyId=AKIAJXKUBB3FVPIZFSRA&Expires=1940157482&Signature=t0i%2FeTF3oCztqe0%2BOzXS1I6bKJo%3D&response-content-disposition=attachment%3B%20filename%3D%22contents-v1.png%22'
		},
		meta: {
			productComponent: 'text'
		}
	},
	{
		type: 'Chapter',
		props: {
			chapters: { '?': { var: '$book.chapters' } }
		},
		meta: {
			productComponent: 'text'
		}
	},
	{
		type: 'Notes',
		props: {
			pagesCount: 2,
			linesCount: 17
		},
		meta: {
			productComponent: 'text'
		}
	},
	{
		type: 'Thank You',
		meta: {
			productComponent: 'text'
		}
	}
	/*,
	{
		type: 'Acknowledgements page',
		props: {
			text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`
		}
	}*/
];
