module.exports = {
	name: 'Cover',
	exampleProps: {
		title: 'Among Friends',
		subtitle: 'Food from the Heart',
		author: 'Andrés Moreno',
		imageUrl: 'https://content-randomizer.netlify.app/images/templates/explorer/3.jpg'
	},
	template: [
		{ type: 'page' },
		{
			name: 'Cover',
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } },
				position: 'relative',
				classNames: ['page-cover']
			},
			children: [
				{
					name: 'Background image',
					type: 'image',
					props: {
						position: 'absolute',
						width: '100%',
						height: '99.9%',
						objectFit: 'cover',
						zIndex: '10',
						src: { '?': { var: '$props.imageUrl' } }
					}
				},
				{
					name: 'Title Container',
					type: 'row',
					props: {
						position: 'absolute',
						width: '100%',
						marginTop: '40px',
						flexGrow: '0',
						zIndex: '100',
						paddingVertical: '1%',
						paddingHorizontal: '5%',
						backgroundColor: 'rgba(0, 158, 179, .9)',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						color: 'white'
					},
					children: [
						{
							name: 'Title top text',
							type: 'text',
							props: {
								value: { '?': { toUpper: { var: '$props.subtitle' } } },
								margin: '20px 0',
								fontSize: '21pt',
								fontFamily: 'AshemoreNormBold',
								lineHeight: '120%',
								letterSpacing: '0.03em',
								textTransform: 'uppercase'
							}
						},
						{
							name: 'Title',
							type: 'text',
							props: {
								value: { '?': { var: '$props.title' } },
								lineHeight: '1.2',
								fontSize: '64pt',
								fontFamily: 'StrenuousCast',
								fontWeight: 'bold'
							}
						},
						{
							name: 'Title bottom text',
							type: 'text',
							props: {
								value: { '?': { toUpper: { var: '$props.author' } } },
								margin: '20px 0',
								fontSize: '21pt',
								fontFamily: 'AshemoreNormBold',
								lineHeight: '120%',
								letterSpacing: '0.03em',
								textTransform: 'uppercase'
							}
						}
					]
				}
			]
		}
	]
};
