import BlockRendererShowcase from './BlockRendererTemplates';

const routes = [
	{
		name: 'blockRendererTemplates',
		path: '/block-renderer-templates/',
		component: BlockRendererShowcase
	}
];

export default routes;
