<template>
	<b-tab v-bind="$attrs">
		<template #title>
			{{ $t('Appearance') }}
			<Badge v-if="templateFormSummary.invalid" variant="warning" class="ml-1"> ! </Badge>
		</template>
		<BookAppearanceV3
			v-if="isAppearancePageV3"
			ref="bookAppearance"
			:template="template"
			:product-content="productContentPreview"
			:product="formData.product"
			@change-content-layout="handleChangeContentLayout"
		/>
		<BookAppearanceV2
			v-else-if="isAppearancePageV2 || preselectedLayoutId"
			ref="bookAppearance"
			:template="template"
			:product-content="productContentPreview"
			:product="formData.product"
			:preselected-layout-id="preselectedLayoutId"
			@change-content-layout="handleChangeContentLayout"
		/>
		<BookAppearance
			v-else
			ref="bookAppearance"
			:template="template"
			:product-content="productContentPreview"
			:product="formData.product"
			@layoutChange="handleLayoutChange"
		/>
	</b-tab>
</template>

<script>
import { withForm } from '@oneflow/ofs-vue-layout';
import Badge from '../../../../components/Badge';
import BookAppearanceV2 from './AppearancePage/BookAppearanceV2.vue';
import BookAppearanceV3 from './AppearancePage/BookAppearanceV3.vue';
import BookAppearance from './AppearancePage/BookAppearance.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { productContentFormName } from '@/containers/Products/Books/BookEdit/constants';
import { predefinedSections } from './constants';

export default {
	components: {
		Badge,
		BookAppearance,
		BookAppearanceV2,
		BookAppearanceV3
	},
	mixins: [withForm(productContentFormName, { preserveDataOnMount: true })],
	props: {
		bookDocuments: {
			type: Object,
			default: () => ({})
		},
		templateFormSummary: {
			type: Object,
			default: () => ({})
		},
		contributors: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			newVersionVisible: true,
			preselectedLayoutId: null
		};
	},
	computed: {
		...mapGetters({
			template: 'template/template',
			getFormSummary: 'form/getFormSummary'
		}),
		isAppearancePageV2() {
			return !!_.get(this.template, 'source.theme.customisationOptions');
		},
		isAppearancePageV3() {
			return _.get(this.template, 'contentLayoutId') && _.get(this.template, 'coverLayoutId');
		},
		productContentPreview() {
			const chapters = _.map(this.formData.content.chapters, chapter => ({
				...chapter,
				recipes: _.map(chapter.documentIds, id => ({
					...this.bookDocuments[id]?.record,
					creator: this.bookDocuments[id]?.creator
				}))
			}));
			return _.merge({}, this.formData.content, { chapters }, { contributors: this.contributors });
		}
	},
	methods: {
		handleLayoutChange(preselectedLayoutId) {
			this.preselectedLayoutId = preselectedLayoutId;
		},
		async handleChangeContentLayout(layout) {
			const supportedTypes = _.get(layout, 'supportedTypes', []);

			const chapters = _.get(this.formData, `content.${predefinedSections.Chapters}`, []);

			const newChaptersWithRecipes = _.map(chapters, chapter => {
				const newChapterRecipes = _.filter(chapter.documentIds, documentId =>
					_.includes(supportedTypes, this.bookDocuments[documentId].type)
				);

				return { ...chapter, documentIds: newChapterRecipes };
			});
			this.updateField(`content.${predefinedSections.Chapters}`, newChaptersWithRecipes);
		}
	}
};
</script>
