<template>
	<b-tab :title="$t('Details')">
		<b-row>
			<b-col lg="6">
				<OfFormInput name="content.headline" :label="$t('Book Title')" required :show-errors="true" />
			</b-col>
			<b-col lg="6">
				<OfFormInput name="content.author" :label="$t('Author')" :show-errors="true" required />
			</b-col>
			<b-col lg="6">
				<OfFormInput name="content.subtitle1" :label="$t('Subtitle')" :show-errors="true" />
			</b-col>
			<b-col lg="6">
				<OfFormInput
					name="content.subtitle2"
					:label="`${$t('Second Subtitle')} (${$t('optional')})`"
					:show-errors="true"
				/>
			</b-col>
		</b-row>
	</b-tab>
</template>

<script>
import { OfFormInput, withForm } from '@oneflow/ofs-vue-layout';
import { productContentFormName } from '@/containers/Products/Books/BookEdit/constants';

export default {
	components: {
		OfFormInput
	},
	mixins: [withForm(productContentFormName, { preserveDataOnMount: true })],
	props: {
		$v: { type: Object, default: () => ({}) }
	},
	beforeCreate() {
		delete this.$options.computed.$v;
	}
};
</script>
