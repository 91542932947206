<template>
	<b-form-group class="BookAppearanceTextAlign mb-2">
		<template v-for="(option, index) in options">
			<b-button
				:key="index"
				:class="{ 'BookAppearanceTextAlign__button-selected': option.value === selectedValue }"
				class="BookAppearanceTextAlign__button mr-1"
				@click="handleButtonClick(option)"
			>
				<icon
					:class="{ 'BookAppearanceTextAlign__icon-selected': option.value === selectedValue }"
					class="BookAppearanceTextAlign__icon"
					:name="getIconName(option.value)"
				/>
			</b-button>
		</template>
	</b-form-group>
</template>

<script>
import Icon from 'vue-awesome/components/Icon';

export default {
	components: {
		Icon
	},
	props: {
		name: {
			type: String,
			default: ''
		},
		selectedValue: {
			type: String,
			default: ''
		},
		options: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		handleButtonClick(option) {
			this.$emit('input', { name: this.name, value: option.value });
		},
		getIconName(type) {
			const staticPrefix = 'align-';
			return staticPrefix + type;
		}
	}
};
</script>

<style lang="scss">
@import '../../style/variables';

.BookAppearanceTextAlign {
	&__button {
		&-selected {
			border-color: $of-color-aqua;

			&:focus,
			&:hover:not(:disabled):not(.disabled) {
				border-color: $of-color-aqua;
				background: $of-color-white;
			}
		}
	}

	&__icon {
		&-selected {
			color: $of-color-aqua;
		}
	}
}
</style>
