<template>
	<div class="OneflowTopBar">
		<button class="OneflowTopBar-MobileMenuButton" @click="onOpenMobileMenu">
			<icon :name="isMobileMenuOpened ? 'times' : 'bars'" scale="1.2" />
		</button>
		<div class="OneflowTopBar-Logo">
			<div class="OneflowTopBar-Logo-inner">
				<slot name="logo">
					<img v-if="logoImg" :src="logoImg" class="OneflowTopBar-Image" />
				</slot>
				<div v-if="title" class="OneflowTopBar-Title">{{ title }}</div>
			</div>
		</div>
		<div class="OneflowTopBar-Account">
			<slot name="account"></slot>
		</div>
		<slot name="nav">
			<menu class="OneflowTopBar-Menu OneflowTopBarMenu">
				<li
					v-for="item in topMenuItems"
					:key="item.path"
					class="OneflowTopBarMenu-MenuItem"
					:class="getItemClass(item)"
				>
					<a v-if="isAbsolutePath(item)" :href="item.path">
						<span>{{ item.title }}</span>
					</a>
					<router-link
						v-if="!isAbsolutePath(item) && getTopItemRoute(item)"
						tag="a"
						:to="getTopItemRoute(item)"
					>
						<span>{{ item.title }}</span>
					</router-link>
				</li>
			</menu>
		</slot>
		<div class="OneflowTopBar-Actions">
			<slot name="actions"></slot>
		</div>
		<div class="OneflowTopBar-User">
			<slot name="user"></slot>
		</div>
		<button class="OneflowTopBar-MobileMenuButton pr-3" @click="onOpenMobilePanel('UserMobilePanel')">
			<icon :name="isMobilePanelOpened ? 'times' : 'user'" />
		</button>
	</div>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import { menu as mobileMenuIcon, user as mobileUserIcon } from 'glyphicons';

export default {
	props: {
		logoImg: {
			type: String,
			default: null
		},
		title: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			mobileMenuIcon,
			mobileUserIcon
		};
	},
	computed: {
		...mapGetters({
			topMenuItems: 'menu/topMenuItems',
			activeTopItem: 'menu/activeTopItem',
			topItemRoute: 'menu/topItemRoute',
			getTopItemRoute: 'menu/getTopItemRoute',
			isMobilePanelOpened: 'mobile-panel/isOpened',
			isMobileMenuOpened: 'menu/mobileMenuOpened'
		})
	},
	methods: {
		...mapActions({
			toggleMobileMenu: 'menu/toggleMobileMenu',
			toggleMobilePanel: 'mobile-panel/toggle',
			hideMobilePanel: 'mobile-panel/hide'
		}),
		getItemClass(item) {
			const itemRoute = this.getTopItemRoute(item) || {};
			const activeTopItemRoute = this.getTopItemRoute(this.activeTopItem) || {};
			const haveSameRouteName = itemRoute.name && itemRoute.name === activeTopItemRoute.name;
			const haveSameRoutePath = itemRoute.path && itemRoute.path === activeTopItemRoute.path;

			return {
				'is-active': haveSameRouteName || haveSameRoutePath
			};
		},
		isAbsolutePath(item) {
			return item && item.path && item.path.indexOf('http') === 0;
		},
		onOpenMobileMenu() {
			this.hideMobilePanel();
			this.toggleMobileMenu();
		},
		onOpenMobilePanel(component) {
			this.toggleMobileMenu(false);
			this.toggleMobilePanel(component);
		}
	}
};
</script>
