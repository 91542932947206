<template>
	<div class="ColorPicker">
		<sketch
			v-show="isPicking"
			ref="ColorPicker"
			v-click-outside="clickOutside"
			class="ColorPicker-Picker"
			:value="value || '#fff'"
			:preset-colors="options"
			@input="onInput"
		/>
		<div
			:id="pickerToggleId"
			class="ColorPicker-Preview"
			:style="{ backgroundColor: value }"
			@click="togglePicker"
		/>
	</div>
</template>

<script>
import _ from 'lodash';
import Popper from 'popper.js';
import { Sketch } from 'vue-color';
import { withBaseInputConfig } from '@oneflow/ofs-vue-layout';

export default {
	components: {
		Sketch
	},
	mixins: [withBaseInputConfig()],
	props: {
		options: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			popper: null,
			isPicking: false,
			pickerToggleId: `picker-toggle-${this['_uid']}`
		};
	},
	mounted() {
		this.popper = new Popper(this.$el, this.$refs.ColorPicker.$el, { placement: 'bottom-start' });
	},
	destroyed() {
		this.popper.destroy();
	},
	methods: {
		togglePicker() {
			this.isPicking = !this.isPicking;
			if (this.isPicking) {
				this.popper.update(); // re-calculate popup position
			}
		},
		onInput: _.debounce(function(value) {
			// hex8 format includes color opacity
			const hexColor = _.isPlainObject(value) ? value.hex8 : value;
			this.onInputChange(hexColor);
		}, 200),
		clickOutside(event) {
			if (!this.isPicking) return;
			if (_.get(event, 'target.id') !== this.pickerToggleId) {
				this.togglePicker();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../style/variables';

.ColorPicker {
	position: relative;

	&-Picker {
		z-index: 10;
	}

	&-Preview {
		width: 40px;
		min-width: 40px;
		height: 37px;
		min-height: 37px;
		border: 1px solid $color-hp-grey-3;
		border-radius: 6px;
		cursor: pointer;
	}
}
</style>
