<template>
	<div class="BookSlider">
		<template v-if="showCombinedSlider">
			<b-col cols="12" class="overflow-hidden p-0 mt-0">
				<div :class="{ 'BookSlider__Fullscreen-wrapper': isFullScreen }" @keydown.esc="turnOffFullscreen">
					<div :class="{ 'BookSlider__Fullscreen-container': isFullScreen }">
						<b-btn-close
							v-if="isFullScreen"
							class="BookSlider__Close-fullscreen"
							@click="turnOffFullscreen"
						>
						</b-btn-close>
						<div id="bookPagesBlock" class="BookSlider__Preview-container shadow"></div>
						<b-input-group :prepend="`${displayedPageNumber}/${bookPages.length}`" class="mt-3 mb-3">
							<b-form-input
								ref="pagesSlider"
								type="range"
								:value="currentPage"
								:min="0"
								:max="maxCurrentPage"
								:step="2"
								@input="turnToPage"
							></b-form-input>
							<template #append>
								<b-input-group-text @click="toggleFullScreen">
									<icon name="fullscreen" class="BookSlider__FullScreen-icon" />
								</b-input-group-text>
							</template>
						</b-input-group>
					</div>
				</div>
			</b-col>
			<div class="BookSlider__Render-container">
				<div v-for="(bookPage, index) in bookPages" :key="index" class="BookSlider__Page-item">
					<PagePreview :json="bookPage" :fonts="fonts" no-debounce />
				</div>
			</div>
		</template>
		<template v-else>
			<b-col cols="12" class="overflow-hidden p-0 mt-0">
				<b-tabs card class="p-0" content-class="pl-4 pr-4 pt-1" @activate-tab="onActivateTab">
					<b-tab no-body :title="$t('Cover')">
						<div class="shadow">
							<PagePreview
								v-for="(coverPage, index) in coverPages"
								:key="index"
								:json="coverPage"
								:fonts="fonts"
								no-debounce
								is-cover
							/>
						</div>
					</b-tab>
					<b-tab no-body :title="$t('Content')">
						<div
							:class="{ 'BookSlider__Fullscreen-wrapper': isFullScreen }"
							@keydown.esc="turnOffFullscreen"
						>
							<div :class="{ 'BookSlider__Fullscreen-container': isFullScreen }">
								<b-btn-close
									v-if="isFullScreen"
									class="BookSlider__Close-fullscreen"
									@click="turnOffFullscreen"
								>
								</b-btn-close>
								<div id="bookPagesBlock" class="BookSlider__Preview-container shadow"></div>
								<b-input-group
									:prepend="`${displayedPageNumber}/${contentPages.length}`"
									class="mt-3 mb-3"
								>
									<b-form-input
										ref="pagesSlider"
										type="range"
										:value="currentPage"
										:min="0"
										:max="maxCurrentPage"
										:step="2"
										@input="turnToPage"
									></b-form-input>
									<template #append>
										<b-input-group-text @click="toggleFullScreen">
											<icon name="fullscreen" class="BookSlider__FullScreen-icon" />
										</b-input-group-text>
									</template>
								</b-input-group>
							</div>
						</div>
					</b-tab>
				</b-tabs>
			</b-col>
			<div class="BookSlider__Render-container">
				<div v-for="pageIndex in indexes" :key="pageIndex" class="BookSlider__Page-item">
					<PagePreview
						v-if="Math.abs(pageIndex - currentPage) <= 3"
						:json="contentPages[pageIndex]"
						:fonts="fonts"
						no-debounce
					/>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PagePreview from './PagePreview';
import { PageFlip } from 'page-flip';
import Icon from 'vue-awesome/components/Icon';
import { productComponents } from '../constants';
import { coverComponentTypes } from '../constants';

Icon.register({
	fullscreen: {
		width: '19',
		height: '20',
		raw:
			// eslint-disable-next-line max-len
			'<path d="M0.75 6.89004V1.81564C0.75 1.27137 1.16797 0.833496 1.6875 0.833496H6.53125C6.78906 0.833496 7 1.05448 7 1.32457V2.96147C7 3.23156 6.78906 3.45254 6.53125 3.45254H3.25V6.89004C3.25 7.16013 3.03906 7.38111 2.78125 7.38111H1.21875C0.960938 7.38111 0.75 7.16013 0.75 6.89004ZM12 1.32457V2.96147C12 3.23156 12.2109 3.45254 12.4688 3.45254H15.75V6.89004C15.75 7.16013 15.9609 7.38111 16.2188 7.38111H17.7812C18.0391 7.38111 18.25 7.16013 18.25 6.89004V1.81564C18.25 1.27137 17.832 0.833496 17.3125 0.833496H12.4688C12.2109 0.833496 12 1.05448 12 1.32457ZM17.7812 12.6192H16.2188C15.9609 12.6192 15.75 12.8402 15.75 13.1103V16.5478H12.4688C12.2109 16.5478 12 16.7688 12 17.0389V18.6758C12 18.9458 12.2109 19.1668 12.4688 19.1668H17.3125C17.832 19.1668 18.25 18.729 18.25 18.1847V13.1103C18.25 12.8402 18.0391 12.6192 17.7812 12.6192ZM7 18.6758V17.0389C7 16.7688 6.78906 16.5478 6.53125 16.5478H3.25V13.1103C3.25 12.8402 3.03906 12.6192 2.78125 12.6192H1.21875C0.960938 12.6192 0.75 12.8402 0.75 13.1103V18.1847C0.75 18.729 1.16797 19.1668 1.6875 19.1668H6.53125C6.78906 19.1668 7 18.9458 7 18.6758Z" fill="#1E1F23"/>\n'
	}
});

export default {
	components: {
		PagePreview
	},
	props: {
		jsonPages: {
			type: Array,
			default: () => []
		},
		fonts: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			pageFlip: null,
			loaded: false,
			currentPage: 1,
			isFullScreen: false
		};
	},
	computed: {
		...mapGetters({
			template: 'template/template'
		}),
		indexes() {
			return _.range(0, this.contentPages.length);
		},
		showCombinedSlider() {
			return _.get(this.template, 'source.props.templateVersion') >= 1;
		},
		contentPages() {
			return _.filter(this.jsonPages, i => !coverComponentTypes.includes(_.get(i, 'meta.productComponent')));
		},
		coverPages() {
			const pages = _.filter(this.jsonPages, i =>
				coverComponentTypes.includes(_.get(i, 'meta.productComponent'))
			);
			return _.size(pages) === 2 ? _.reverse(pages) : pages;
		},
		bookPages() {
			const [frontCover, backCover] = this.getCoverPreviewComponents();
			return [frontCover, ...this.contentPages, backCover];
		},
		displayedPageNumber() {
			return parseInt(this.currentPage) + 1;
		},
		pages() {
			return document.querySelectorAll('.BookSlider__Render-container .BookSlider__Page-item');
		},
		maxCurrentPage() {
			return this.showCombinedSlider ? this.bookPages.length : this.contentPages.length - 1;
		}
	},
	mounted() {
		if (!this.showCombinedSlider) return;

		this.initialize();
	},
	methods: {
		onActivateTab(newValue) {
			if (newValue === 1) {
				this.initialize();
			}
		},
		getPageNumber(value) {
			if (value >= this.maxCurrentPage) {
				return this.maxCurrentPage - 1;
			} else {
				return value % 2 ? value - 1 : value;
			}
		},
		turnToPage(newValue) {
			const parsedValue = parseInt(newValue);
			const pageNumber = this.getPageNumber(parsedValue);
			this.currentPage = pageNumber;
			this.pageFlip.turnToPage(pageNumber);
		},
		initialize() {
			if (!this.pageFlip) {
				this.initializePageFlip();
			}
			this.$nextTick(() => this.$refs.pagesSlider.focus());
		},
		initializePageFlip() {
			if (this.pageFlip) {
				this.pageFlip.destroy();
			}
			this.pageFlip = new PageFlip(document.getElementById('bookPagesBlock'), {
				width: 555,
				height: 555,
				size: 'stretch',
				minWidth: 50,
				maxWidth: 5000,
				minHeight: 50,
				maxHeight: 2500,
				maxShadowOpacity: 0.5,
				autoSize: true,
				showCover: this.showCombinedSlider
			});
			this.pageFlip.on('flip', e => {
				this.currentPage = e.data;
			});
			this.pageFlip.loadFromHTML(this.pages);
		},
		toggleFullScreen() {
			const page = this.currentPage;
			this.isFullScreen = !this.isFullScreen;
			document.getElementById('bookPagesBlock').innerHTML = '';
			this.pageFlip.loadFromHTML(this.pages);
			this.currentPage = page;
			this.turnToPage(this.currentPage);
			this.$nextTick(() => this.$refs.pagesSlider.focus());
		},
		turnOffFullscreen() {
			if (this.isFullScreen) {
				this.toggleFullScreen();
			}
		},
		getTwoPagesCoverPreviewComponents() {
			const { coverFront, coverBack } = productComponents;
			const frontCover = _.find(this.coverPages, ['meta.productComponent', coverFront]);
			const backCover = _.find(this.coverPages, ['meta.productComponent', coverBack]);
			return [frontCover, backCover];
		},
		getOnePageCoverPreviewComponents() {
			const cover = _.find(this.coverPages, ['meta.productComponent', productComponents.cover]);
			// front and back cover
			return [_.get(cover, 'children.2'), _.get(cover, 'children.0')];
		},
		getCoverPreviewComponents() {
			const isTwoPagesCover = _.size(this.coverPages) === 2;
			return isTwoPagesCover ? this.getTwoPagesCoverPreviewComponents() : this.getOnePageCoverPreviewComponents();
		}
	}
};
</script>

<style lang="scss">
.BookSlider {
	height: 100%;
	width: 100%;

	&__Page-item {
		width: 555px;
		height: 555px;
		overflow: hidden;
	}

	&__Preview-container {
		overflow: hidden;
		width: 100%;
		margin: 0 auto;
	}

	&__Render-container {
		display: none;
	}

	&__FullScreen-icon {
		width: 25px;
	}

	&__Fullscreen-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(30, 30, 30, 0.9);
		z-index: 1000;
		display: flex;
		align-items: center;
		padding: 50px;
	}

	&__Fullscreen-wrapper &__Preview-container {
		max-height: 85vh;
	}

	&__Fullscreen-container {
		margin: 0 auto;
		width: 90vw;
		height: auto;
	}

	&__Close-fullscreen,
	&__Close-fullscreen:hover {
		color: white;
		width: 40px;
		height: 40px;
		font-size: 30px;
	}
}
</style>
