<template>
	<div class="ItemStatus">
		<icon v-if="isItemValid(item)" name="check" class="fa-success-status" />
		<template v-else>
			<icon :id="tooltipTriggerId" name="exclamation-triangle" class="fa-error-status" />
			<b-tooltip :target="tooltipTriggerId" placement="right" custom-class="ItemStatus-Tooltip">
				<ul>
					<li v-for="({ message }, i) in item.validationErrors" :key="`${item.id}-${i}`">
						{{ message }}
					</li>
				</ul>
			</b-tooltip>
		</template>
	</div>
</template>

<script>
import _ from 'lodash';

export default {
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	computed: {
		tooltipTriggerId() {
			return `${this['_uid']}-status`;
		}
	},
	methods: {
		isItemValid(item) {
			return _.isEmpty(item.validationErrors);
		}
	}
};
</script>

<style lang="scss">
@import '../style/variables.scss';

.ItemStatus {
	.fa-success-status {
		fill: $color-hp-green !important;
	}

	.fa-error-status {
		fill: $of-color-orange !important;
	}
}

.ItemStatus-Tooltip {
	z-index: 1;

	.arrow::before {
		border-left-color: $of-color-white !important;
	}

	.tooltip-inner {
		color: $color-hp-dark;
		background-color: $of-color-white;
		border-radius: 5px;
		padding: 13px 25px 0 25px;
		text-align: left;
		border: 1px solid $color-hp-grey-3;
		border-left: 0;
		box-shadow: -1px 0px 5px rgba(0, 0, 0, 0.15);

		ul {
			padding-left: 10px;
		}
	}
}
</style>
