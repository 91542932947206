var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"visible":_vm.visible,"title":_vm.$t('Add items to book'),"ok-title":((_vm.$t('Select Items')) + " (" + (_vm.addedIds.length) + ")"),"ok-disabled":_vm.isSubmitDisabled,"cancel-title":_vm.$t('Cancel'),"cancel-variant":null,"size":"xl","dialog-class":"SelectItemsModal"},on:{"ok":function($event){return _vm.$emit('input', _vm.selectedIdsHash, _vm.addedIds)},"hidden":_vm.onHidden}},[_c('b-row',{staticClass:"TopFilterBar mx-0"},[_c('b-col',{staticClass:"pl-0"},[_c('SearchInput',{attrs:{"placeholder":_vm.$t('Search items by name...')},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-select',{attrs:{"options":_vm.accountOptions},on:{"change":_vm.reloadDocuments},model:{value:(_vm.selectedAccount),callback:function ($$v) {_vm.selectedAccount=$$v},expression:"selectedAccount"}})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('OfInlineFilter',{attrs:{"filters":_vm.filters,"values":_vm.filterValues},on:{"change":_vm.filtersChanged}})],1)],1),_c('Datatable',{ref:"documentsTable",attrs:{"items":_vm.items,"fields":_vm.fields,"is-busy":_vm.isDocumentsFetching,"hover":""},on:{"row-clicked":_vm.onRowClicked,"table-change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"head(checked)",fn:function(){return [_c('OfFormCheckbox',{attrs:{"no-label":""},model:{value:(_vm.isAllSelected),callback:function ($$v) {_vm.isAllSelected=$$v},expression:"isAllSelected"}})]},proxy:true},{key:"cell(checked)",fn:function(ref){
var item = ref.item;
return [_c('OfFormCheckbox',{attrs:{"value":item.checked,"no-label":""},on:{"input":function($event){return _vm.toggleSelect(item._id)}}})]}},{key:"cell(itemSize)",fn:function(ref){
var item = ref.item;
return [_c('ItemSize',{attrs:{"size":item.record ? item.record.size : ''}})]}},{key:"cell(itemSpread)",fn:function(ref){
var item = ref.item;
return [_c('ItemSpread',{attrs:{"id":item._id,"size":item.record ? item.record.size : '',"is-show-link-icon":false}})]}},{key:"cell(validationErrors)",fn:function(ref){
var item = ref.item;
return [_c('ItemStatus',{attrs:{"item":item}})]}},{key:"cell(thumbnail)",fn:function(ref){
var item = ref.item;
return [_c('Thumbnail',{attrs:{"src":item.thumbnail,"size":45}})]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fromNow(item.updatedAt))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }