var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('b-form',{staticClass:"h-100",attrs:{"novalidate":""}},[_c('Page',{staticClass:"ProductContentEdit",attrs:{"content-class":"ProductContentEdit-Content","actions-class":"ProductContentEdit-Actions"},scopedSlots:_vm._u([(!_vm.activeItemId)?{key:"actions",fn:function(){return [_c('b-button',{directives:[{name:"t",rawName:"v-t",value:(_vm.$t('Cancel')),expression:"$t('Cancel')"}],staticClass:"mr-2",on:{"click":_vm.onCancel}}),_c('b-button',{directives:[{name:"t",rawName:"v-t",value:(_vm.$t('Save')),expression:"$t('Save')"}],attrs:{"variant":"primary","disabled":!_vm.canSubmit},on:{"click":_vm.onSave}})]},proxy:true}:null],null,true)},[_c('ContentHeader',{staticClass:"mb-2",attrs:{"title":_vm.headerTitle,"breadcrumbs":_vm.breadcrumbs,"no-padding":""}},[_c('b-dropdown',{staticClass:"mx-1",attrs:{"right":"","text":"Actions"}},[_c('b-dropdown-item',{attrs:{"disabled":!_vm.isPreviewAvailable},on:{"click":_vm.redirectToPreview}},[_c('icon',{staticClass:"mr-2",attrs:{"name":"eye"}}),_vm._v(" "+_vm._s(_vm.$t('Preview'))+" ")],1),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":_vm.handleImportItem}},[_c('icon',{staticClass:"mr-2",attrs:{"name":"file-upload"}}),_vm._v(" "+_vm._s(_vm.$t('Import'))+" ")],1),_c('b-dropdown-item',{attrs:{"disabled":!_vm.productContentId},on:{"click":_vm.handleRemove}},[_c('icon',{staticClass:"mr-2",attrs:{"name":"trash"}}),_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")],1)],1)],1),_c('b-alert',{staticClass:"mb-0 mt-2",attrs:{"show":_vm.unpairedSpreadItems,"variant":"warning","dismissible":""}},[_vm._v(" "+_vm._s(_vm.$t('ALERT: You can not print this book because there are unpaired items.'))+" ")]),_c('b-tabs',{staticClass:"ProductContentEdit-Tabs mt-3",attrs:{"lazy":""},model:{value:(_vm.activeTabIndex),callback:function ($$v) {_vm.activeTabIndex=$$v},expression:"activeTabIndex"}},[_c('BookEditDetailsTab',_vm._b({},'BookEditDetailsTab',{
						$v: _vm.$v
					},false)),_c('BookEditContentTab',_vm._b({attrs:{"lazy":""},on:{"update-documents":_vm.handleUpdateDocuments}},'BookEditContentTab',{
						isValidationAlertShown: _vm.isValidationAlertShown,
						globalValidationErrors: _vm.globalValidationErrors,
						productContentId: _vm.productContentId,
						contributorIds: _vm.contributorIds,
						bookDocuments: _vm.bookDocuments,
						activeSectionsConfig: _vm.activeSectionsConfig,
						sections: _vm.sections,
						supportedTypes: _vm.supportedTypes,
						rootSections: _vm.rootSections,
						rootValidationErrors: _vm.validationErrors,
						sectionsConfig: _vm.sectionsConfig,
						$v: _vm.$v
					},false)),(_vm.isAppearanceVisible)?_c('BookEditAppearanceTab',_vm._b({ref:"bookAppearanceTab",attrs:{"disabled":!_vm.productTemplateId,"lazy":""}},'BookEditAppearanceTab',{ bookDocuments: _vm.bookDocuments, templateFormSummary: _vm.templateFormSummary, contributors: _vm.contributors },false)):_vm._e(),_c('BookEditContributorsTab',_vm._b({attrs:{"lazy":""},on:{"update-contributors":_vm.handleUpdateContributors}},'BookEditContributorsTab',{
						contributorIds: _vm.contributorIds
					},false))],1)],1),_c('ImportModal',{attrs:{"modal-id":"importBookModal","product-content-id":_vm.productContentId,"modal-title":_vm.$t('Import Book'),"mode":_vm.scrapingRuleSubjects.Book,"visible":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }