<template>
	<codemirror ref="codemirror" :value="value" :options="defaultCodeMirrorOptions" />
</template>

<script>
import { codemirror } from 'vue-codemirror';

export default {
	components: {
		codemirror
	},
	props: {
		data: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			defaultCodeMirrorOptions: {
				readOnly: true,
				tabSize: 4,
				lineNumbers: true,
				line: true,
				theme: 'rubyblue',
				gutters: ['CodeMirror-lint-markers'],
				lint: true,
				autoRefresh: true,
				styleActiveLine: true,
				mode: 'application/json'
			}
		};
	},
	computed: {
		value() {
			return JSON.stringify(this.data, null, 2);
		}
	},
	mounted() {
		const codemirror = this.$refs.codemirror;
		setTimeout(function() {
			codemirror.refresh();
		}, 1);
	}
};
</script>

<style lang="scss">
.vue-codemirror {
	height: 800px;

	.CodeMirror {
		height: 800px !important;
	}
}
</style>
