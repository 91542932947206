<template>
	<b-col v-if="json" md="9" class="BookAppearance-PreviewPanel">
		<b-row class="pl-4">
			<div class="OptionsPanel-Inner">
				<OfFormRadio v-model="previewType" :options="previewTypeOptions" />
			</div>
		</b-row>
		<BookPreviewPages
			v-if="previewType === previewTypes.Spreads"
			:json="json"
			:fonts="fonts"
			md="12"
			class="book-preview-container"
		/>
		<b-row v-else md="12" class="book-preview-container">
			<BookSlider :key="`slider_key_${json.length}`" :fonts="fonts" :json-pages="json" />
		</b-row>
	</b-col>
</template>

<script>
import { OfFormRadio } from '@oneflow/ofs-vue-layout';
import { BookTemplateProcessor } from '@custom-content-technology/book-template-processor';
import BookSlider from '../BookSlider';
import BookPreviewPages from '../BookPreviewPages';

const PreviewTypes = {
	Slider: 'slider',
	Spreads: 'spreads'
};

export default {
	components: {
		BookSlider,
		BookPreviewPages,
		OfFormRadio
	},
	props: {
		source: {
			type: Object,
			required: true
		},
		productContent: {
			type: Object,
			required: true
		},
		previewOptions: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			previewTypeOptions: [
				{ text: this.$t('Slider'), value: PreviewTypes.Slider },
				{ text: this.$t('Spreads'), value: PreviewTypes.Spreads }
			],
			previewType: PreviewTypes.Spreads,
			bookTemplateProcessor: null
		};
	},
	computed: {
		blocks() {
			return [
				..._.get(this.source, 'blocks', []),
				..._.get(this.source, 'contentDependencies', []),
				..._.get(this.source, 'coverDependencies', [])
			];
		},
		fonts() {
			return _.get(this.source, 'assets.fonts', []);
		},
		isPreviewAvailable() {
			return this.bookTemplateProcessor && this.activeVariant && !_.isEmpty(this.previewOptions);
		},
		activeVariant() {
			const variants = _.get(this.source, 'theme.variants', []);
			const variantName = this.source?.theme?.selectedVariant;
			return _.find(variants, ['name', variantName]);
		},
		json() {
			if (!this.isPreviewAvailable) return [];

			const { theme, layout, props } = _.cloneDeep(this.source);
			const { sizes, renderProps } = this.previewOptions;
			const data = _.cloneDeep({
				$book: this.productContent,
				$theme: { ...this.activeVariant.theme, ...theme.base, ...sizes, ...theme.customization },
				$props: renderProps?.templateProps
			});

			return this.bookTemplateProcessor.process(layout, data, renderProps?.processOptions);
		},
		previewTypes() {
			return PreviewTypes;
		}
	},
	watch: {
		blocks() {
			this.initTemplateProcessor();
		}
	},
	async mounted() {
		await this.initialize();
	},
	methods: {
		async initialize() {
			this.initTemplateProcessor();
		},
		initTemplateProcessor() {
			this.bookTemplateProcessor = new BookTemplateProcessor();
			this.bookTemplateProcessor.registerBlocks(this.blocks);
		}
	}
};
</script>

<style lang="scss">
@import '../../style/variables';

.BookAppearance {
	&-PreviewPanel {
		max-height: 100vh;
		overflow: scroll;
		background-color: $color-hp-highlights;
	}
}
</style>
