module.exports = {
	name: 'Acknowledgements page',
	exampleProps: {
		text:
			'Cras mattis augue erat, eu commodo sem aliquam et. In fringilla faucibus justo in malesuada. Nam in turpis ut nulla vehicula semper. Cras maximus quam purus, ut pharetra arcu cursus vitae. Duis rutrum magna vel purus volutpat rutrum. Sed molestie libero vitae libero interdum, eu rutrum enim porttitor.'
	},
	template: [
		{
			name: 'Acknowledgements page',
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } },
				classNames: ['acknowledgements-page'],
				display: 'flex',
				justifyContent: 'center'
			},
			children: [
				{
					name: 'Page title',
					type: 'container',
					props: {
						width: '40%',
						marginTop: '160px'
					},
					children: [
						{
							name: 'Title',
							type: 'text',
							props: {
								fontFamily: { '?': { var: '$theme.primaryFont' } },
								fontSize: '25px',
								fontWeight: '700',
								textAlign: 'center',
								textTransform: 'uppercase',
								marginBottom: '30px',
								value: 'acknowledgments'
							}
						},
						{
							name: 'Content',
							type: 'text',
							props: {
								fontFamily: { '?': { var: '$theme.primaryFont' } },
								fontSize: '15px',
								lineHeight: '1.5',
								textAlign: 'center',
								value: { '?': { var: '$props.text' } }
							}
						}
					]
				}
			]
		}
	]
};
