<template>
	<b-form-group :label="label">
		<OfFormSelect
			v-if="dropdownVisible"
			class="mb-2"
			:options="options"
			:value="selectedOption ? selectedOption.value : selectedOption"
			without-label
			@input="handleSelectInput"
		/>
		<div v-if="colorPickerVisible" class="d-flex align-items-start">
			<ColorPicker class="mr-1" :name="colorPickerName" />
			<OfFormInput class="no-label w-100" :name="colorPickerName" show-errors />
		</div>
	</b-form-group>
</template>

<script>
import { OfFormInput, OfFormSelect } from '@oneflow/ofs-vue-layout';
import ColorPicker from '../ColorPicker.vue';
import { defaultPath } from '../../containers/Products/Books/BookEdit/AppearancePage/helpers';

const solidColor = 'solidColor';

export default {
	components: {
		ColorPicker,
		OfFormInput,
		OfFormSelect
	},
	props: {
		name: {
			type: String,
			default: null
		},
		label: {
			type: String,
			default: null
		},
		options: {
			type: Array,
			default: () => []
		},
		selectedValue: {
			type: [Boolean, String],
			default: ''
		},
		formData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			selectedOption: null
		};
	},
	computed: {
		dropdownVisible() {
			return Array.isArray(this.options) && this.options.length;
		},
		colorPickerVisible() {
			return (this.dropdownVisible && this.selectedOption?.value === solidColor) || !this.dropdownVisible;
		},
		colorPickerName() {
			return this.dropdownVisible ? defaultPath + this.selectedOption.key : this.name;
		}
	},
	mounted() {
		const useSolidColor = this.selectedValue;
		if (useSolidColor) {
			this.selectedOption = this.options?.find(option => option.value === solidColor);
		} else {
			const optionKey = this.options?.find(option => option.value !== solidColor)?.key;
			const selectedValue = _.get(this.formData, defaultPath + optionKey);
			this.selectedOption = this.options?.find(option => option.value === selectedValue);
		}
	},
	methods: {
		handleSelectInput(value) {
			this.selectedOption = this.options?.find(option => option.value === value);
			this.$emit('input', {
				name: this.name,
				value: this.selectedOption.value === solidColor
			});
			if (this.selectedOption.value !== solidColor) {
				this.$emit('input', {
					name: defaultPath + this.selectedOption?.key,
					value: this.selectedOption?.value
				});
			}
		}
	}
};
</script>
