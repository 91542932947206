import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'document',
		pluralName: 'documents',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		actions: {
			getSummary: async ({ dispatch }, query) => {
				return dispatch('request', {
					method: 'GET',
					path: 'documents/summary',
					query
				}).then(r => r.data);
			},
			bulkDelete: async ({ dispatch }, documentIds) => {
				const payload = { documentIds };

				return dispatch('request', {
					path: `documents/bulk-delete`,
					method: 'DELETE',
					payload
				}).then(r => r.data);
			}
		}
	});
}
