<template>
	<div class="UserMobilePanel">
		<label>{{ $t('Signed in as') }}</label>
		<UserCard v-if="user" :user="user" :is-mobile="true" @logout="logOut" />
		<div class="divider"></div>
		<label class="mt-3">{{ $t('Account') }}</label>
		<AccountSwitcher
			class="p-0"
			:current-account="currentAccount"
			:accounts="accounts"
			:on-account-selection="onAccountChange"
		>
			<template slot="current-account-title" slot-scope="{}">
				{{ $t('Account') }}
			</template>
		</AccountSwitcher>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AccountSwitcher } from '@oneflow/ofs-vue-layout';
import UserCard from '../UserCard.vue';

export default {
	components: {
		AccountSwitcher,
		UserCard
	},
	computed: {
		...mapGetters('account', ['currentAccount', 'accounts']),
		...mapGetters('auth0', ['user'])
	},
	methods: {
		...mapActions('account', ['setCurrentAccount']),
		...mapActions('auth0', ['logOut']),
		onAccountChange(account) {
			this.setCurrentAccount(account);
			this.$nextTick(() => window.location.reload());
		}
	}
};
</script>

<style lang="scss">
@import '../../style/variables.scss';

.UserMobilePanel {
	padding: 20px 15px;

	.AccountSwitcher {
		&-current,
		&-input {
			width: 100% !important;
		}

		&-input .TypeAhead-input input {
			height: 57px;
		}

		&-current {
			position: relative;

			&-title {
				display: none;
			}

			&-body {
				min-width: 100%;
			}

			&-arrow-down {
				position: relative;
				right: 30px;
			}
		}

		&-input .TypeAhead-input input,
		&-current-text {
			padding: 10px 20px;
			border: 1px solid $color-hp-grey-3;
			border-radius: $of-border-radius;
			font-size: 18px !important;
			line-height: 35px !important;
			color: #000 !important;
		}

		&-input .TypeAhead .TypeAhead-list.is-visible > .TypeAhead-list-inner {
			width: 100%;

			.dropdown-menu li {
				padding: 10px;
				font-size: 16px;
			}
		}
	}

	label {
		margin-bottom: 10px;
		color: $color-hp-grey-2;
		font-size: 16px;
		line-height: 1;
	}

	.divider {
		width: 100%;
		border-top: 1px solid $color-hp-grey-3;
	}
}
</style>
