<template>
	<Layout>
		<Page class="ScrappingRulesList">
			<ListTable
				bordered
				hover
				:table-title="$t('Scraping rules')"
				:config="config"
				:items="scrapingRules.data"
				:fields="fields"
				:sort="sort"
				:total-items="scrapingRules.total"
				:current-page="currentPage"
				:per-page="perPage"
				:fetch-data="fetchData"
				:is-busy="isScrapingRulesFetching"
				@row-clicked="handleRowClick"
				@table-change="handleTableChange"
			>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<SearchInput
						v-model="searchString"
						:placeholder="$t('Search scraping rules by name...')"
						class="mr-2"
					/>
					<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
				</template>

				<template slot="TableButtons-Slot-right" slot-scope="{}">
					<div class="TableButtons-Right">
						<b-button variant="primary" @click="handleAdd">{{ $t('New scraping rule') }}</b-button>
					</div>
				</template>

				<template slot="TableHeader" slot-scope="{}">
					<OfFilterBar :filters="filters" :values="filterValues" @change="filtersChanged" />
				</template>

				<template #cell(actions)="{ item }">
					<b-dropdown size="sm" toggle-class="p-1" right no-caret>
						<template #button-content>
							<icon name="ellipsis-h" />
						</template>
						<b-dropdown-item @click.stop="handleDelete(item._id)">
							<icon name="trash" class="mr-2" /> {{ $t('Delete') }}
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</ListTable>
		</Page>
	</Layout>
</template>

<script type="text/javascript">
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { Datatable, ListTable, OfInlineFilter, OfFilterBar } from '@oneflow/ofs-vue-layout';
import Layout from '../../../components/Layout';
import Page from '../../../components/Page';
import SearchInput from '../../../components/SearchInput';
import notifications from '../../../mixins/notifications';
import { scrapingRuleTypes } from '../../../constants';

const config = {
	refresh: { visible: true }
};

export default {
	components: {
		Layout,
		Page,
		ListTable,
		OfInlineFilter,
		OfFilterBar,
		SearchInput
	},
	mixins: [notifications],
	data() {
		const fields = [
			{ label: this.$t('Name'), key: 'name', sortable: true },
			{ label: this.$t('Type'), key: 'type', sortable: true },
			{ label: this.$t('Subject'), key: 'subject', sortable: true },
			{ label: '', key: 'actions', tdClass: 'text-right' }
		];

		const filters = [
			{
				header: this.$t('Type'),
				key: 'type',
				type: 'radio',
				items: [
					{ title: scrapingRuleTypes.HTML, value: scrapingRuleTypes.HTML },
					{ title: scrapingRuleTypes.JSON, value: scrapingRuleTypes.JSON }
				]
			}
		];

		return {
			currentPage: 1,
			perPage: 10,
			fields,
			filters,
			filterValues: {},
			sort: {},
			config,
			searchString: ''
		};
	},
	computed: {
		...mapGetters({
			scrapingRules: 'scraping-rule/scraping-rules',
			isScrapingRulesFetching: 'scraping-rule/isFetching'
		})
	},
	watch: {
		searchString: _.debounce(async function(searchString, oldSearchString) {
			if (searchString !== oldSearchString) {
				this.currentPage = 1;
				await this.fetchData();
			}
		}, 800)
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		...mapActions({
			findScrapingRules: 'scraping-rule/find',
			deleteScrapingRuleById: 'scraping-rule/deleteById'
		}),
		async fetchData() {
			try {
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$where: {}
				};
				if (!_.isEmpty(this.filterValues)) {
					query.$where = _.reduce(
						this.filterValues,
						($where, value, key) => ({
							...$where,
							[key]: Array.isArray(value) ? { $in: value } : value
						}),
						{}
					);
				}
				if (!_.isEmpty(this.searchString)) {
					query.$where.name = { $regex: this.searchString, $options: 'i' };
				}
				query.$sort = !_.isEmpty(this.sort) ? this.sort : { name: 1 };
				query.$sort._id = 1;
				await this.findScrapingRules({ query: { query } });
			} catch (err) {
				this.notifyError(err, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching scraping rules')
				});
			}
		},
		async filtersChanged(filters) {
			this.currentPage = 1;
			this.filterValues = filters;

			await this.fetchData();
		},
		handleTableChange({ currentPage, perPage, filter, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (filter !== undefined) {
				this.filter = filter;
			}
			if (sort) {
				this.sort = sort;
			}
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') return;
			this.$router.push({ name: 'scraping-rules.edit', params: { id: item._id } });
		},
		handleAdd() {
			this.$router.push({ name: 'scraping-rules.edit', params: { id: 'new' } });
		},
		async handleDelete(id) {
			const isConfirmed = await this.confirmAction(this.$t('Are you sure you want to delete?'));
			if (!isConfirmed) return;

			try {
				await this.deleteScrapingRuleById({ id });
				await this.fetchData();
			} catch (error) {
				this.notifyError(error);
			}
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/_variables.scss';

.ScrappingRulesList {
	.TableButtons-Right {
		margin-left: 20px;
		padding-left: 20px;
		border-left: 1px solid $color-hp-grey-3;
	}
}
</style>
