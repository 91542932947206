module.exports = {
	name: 'Recipe',
	exampleProps: {
		recipe: {
			id: '',
			sourceURL: 'https://www.savourous.com/recipe-view/gluten-free-plantain-waffle-with-mild-hot-sauce/',
			name: 'Plantain Waffle',
			author: '',
			category: 'breakfast',
			description:
				'Plantain Waffle with Mild Hot sauce is my fun way to eat plantain for breakfast. Also because plantain is eating mostly with hot sauce in West Africa, adding my mild green sauce to this plantain is sure a delight. It is a great way to enjoy waffles with kids while staying with my westafrican cuisine […]',
			ingredients: {
				type: 'SECTION',
				text: 'Ingredients',
				elements: [
					{
						type: 'INGREDIENT',
						quantity: 0,
						units: '',
						text: '2 medium ripe Plantains'
					},
					{
						type: 'INGREDIENT',
						quantity: 0,
						units: '',
						text: '2 Eggs'
					},
					{
						type: 'INGREDIENT',
						quantity: 0,
						units: '',
						text: '1 Tablespoon of coconut oil to oil the waffle maker'
					},
					{
						type: 'INGREDIENT',
						quantity: 0,
						units: '',
						text: 'Pinch of salt (optional)'
					},
					{
						type: 'SECTION',
						text: 'For the Mild Green Sauce',
						elements: [
							{
								type: 'INGREDIENT',
								quantity: 0,
								units: '',
								text: '4 jalopenos peppers'
							},
							{
								type: 'INGREDIENT',
								quantity: 0,
								units: '',
								text: '1/2 of medium green bell pepper'
							},
							{
								type: 'INGREDIENT',
								quantity: 0,
								units: '',
								text: '1/4 of medium onion'
							},
							{
								type: 'INGREDIENT',
								quantity: 0,
								units: '',
								text: '1/2 teaspoon of Salt'
							},
							{
								type: 'INGREDIENT',
								quantity: 0,
								units: '',
								text: '4 tablespoons of oil (optional)'
							}
						]
					}
				]
			},
			instructions: {
				type: 'SECTION',
				text: 'Instructions',
				elements: [
					{
						type: 'INSTRUCTION',
						text: 'In a food processor,purree the peeled plantain with 2 eggs and pinch of salt'
					},
					{
						type: 'INSTRUCTION',
						text: 'Start cooking in batch in your waffle maker.'
					},
					{
						type: 'INSTRUCTION',
						text: 'While the waffle is cooking you can make your hot sauce by finely blending: jalopeno pepper, onion, oil, salt, bell pepper.'
					},
					{
						type: 'INSTRUCTION',
						text: 'Heat up the hot sauce and serve over your waffles.'
					}
				]
			},
			tags: ['Breakfast & Brunch', 'Favorite Recipes', 'Gluten Free Recipes'],
			recipeYield: '2',
			images: [
				{
					name: 'Plantain waffles with hot pepper',
					url: 'https://i0.wp.com/www.savourous.com/wp-content/uploads/2021/08/plantainwaffle-new.jpg?fit=1936,1936&ssl=1',
					thumbnailURL: '',
					fileURL: ''
				}
			],
			nutrition: {
				calories: 0,
				fat: 0,
				protein: 0,
				carbs: 0
			},
			cookingTime: {
				prepTime: '',
				cookTime: '',
				totalTime: ''
			}
		}
	},
	template: [
		{
			name: 'Recipe - Left Page',
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } }
			},
			meta: {
				tableOfContents: {
					title: { '?': { var: '$props.recipe.name' } },
					parentTitle: { '?': { var: '$props.chapter.name' } }
				}
			},
			children: [
				{
					name: 'Recipe container',
					type: 'container',
					props: {
						paddingLeft: '62px',
						paddingRight: '70px',
						display: 'flex',
						paddingTop: '40px',
						paddingBottom: '40px',
						flexDirection: 'column',
						fontSize: '8pt',
						fontFamily: 'PoppinsLight'
					},
					children: [
						{
							name: 'Recipe title',
							type: 'text',
							props: {
								marginTop: '10px',
								fontSize: '32pt',
								fontFamily: 'CrimsonPro-Bold',
								value: { '?': { var: '$props.recipe.name' } }
							}
						},
						{
							name: 'Recipe servings container',
							type: 'container',
							props: {
								display: 'flex',
								justifyContent: 'space-between',
								borderTop: '2px solid black',
								borderBottom: '2px solid black',
								padding: '2px 0',
								marginTop: '18px',
								textTransform: 'uppercase'
							},
							children: [
								{
									name: 'Recipe servings',
									type: 'text',
									props: {
										value: { '?': { cat: ['serves ', { var: '$props.recipe.recipeYield' }] } },
										fontFamily: 'Poppins-Bold',
										margin: '0',
										fontSize: '8pt',
										color: 'black',
										fontWeight: 'normal',
										letterSpacing: '0'
									}
								},
								{
									name: 'Recipe Tags',
									type: 'text',
									props: {
										value: {
											'?': { var: '$props.recipe.tags.0' }
										},
										fontFamily: 'Poppins-Bold',
										margin: '0',
										fontSize: '8pt',
										color: 'black',
										fontWeight: 'normal',
										letterSpacing: '0'
									}
								}
							]
						},
						{
							name: 'Recipe info container',
							type: 'container',
							props: {
								display: 'flex',
								marginTop: '13px'
							},
							children: [
								{
									type: 'container',
									name: 'Recipe ingredients container',
									props: {
										flexBasis: '35%'
									},
									children: [
										{
											type: 'text',
											props: {
												value: 'Ingredients',
												textTransform: 'uppercase',
												fontFamily: "'Poppins-Bold'",
												marginBottom: '15px',
												margin: '0',
												fontSize: '8pt',
												color: 'black',
												fontWeight: 'normal',
												letterSpacing: '0'
											}
										},
										{
											type: 'repeater',
											props: {
												source: { '?': { var: '$props.recipe.ingredients' } },
												itemAs: 'ingredientSection',
												item: [
													{
														type: 'container',
														name: 'Recipe ingredients box',
														children: [
															{
																name: 'Recipe ingredients',
																type: 'list',
																props: {
																	itemProps: {
																		li: 'not',
																		position: 'relative',

																		lineHeight: '12.4pt'
																	},
																	itemPath: 'text',
																	source: {
																		'?': { var: 'ingredientSection.elements' }
																	},
																	marginTop: '8px',
																	paddingLeft: '16px',
																	marginBottom: '0'
																}
															}
														]
													}
												]
											}
										}
									]
								},
								{
									name: 'Recipe directions container',
									type: 'container',
									props: {
										marginLeft: 'auto',
										marginRight: 'auto',
										flexBasis: '60%'
									},
									children: [
										{
											type: 'text',
											props: {
												value: 'Directions',
												textTransform: 'uppercase',
												fontFamily: "'Poppins-Bold'",
												marginBottom: '15px',
												margin: '0',
												fontSize: '8pt',
												color: 'black',
												fontWeight: 'normal',
												letterSpacing: '0'
											}
										},
										{
											type: 'repeater',
											props: {
												source: { '?': { var: '$props.recipe.instructions' } },
												itemAs: 'instructionSection',
												item: [
													{
														type: 'container',
														name: 'Recipe instructions box',
														children: [
															{
																name: 'Recipe instructions',
																type: 'list',
																props: {
																	tag: 'ol',
																	itemProps: {
																		li: 'not',
																		position: 'relative',

																		lineHeight: '12.4pt'
																	},
																	itemPath: 'text',
																	source: {
																		'?': { var: 'instructionSection.elements' }
																	},
																	marginTop: '8px',
																	paddingLeft: '16px',
																	marginBottom: '0'
																}
															}
														]
													}
												]
											}
										}
									]
								}
							]
						},
						{
							name: 'Recipe note',
							type: 'container',
							props: {
								position: 'relative',
								marginTop: '15px',
								backgroundColor: '#f8ecec',
								padding: '15px 10px 15px 30px'
							},
							children: [
								{
									type: 'text',
									props: {
										display: 'block',
										value: 'Notes',
										marginTop: '0',
										marginBottom: '10px',
										fontSize: '15px',
										fontFamily: 'CrimsonPro-Bold'
									}
								},
								{
									type: 'text',
									props: {
										value: { '?': { var: '$props.recipe.description' } }
									}
								},
								{
									type: 'container',
									props: {
										position: 'absolute',
										borderLeft: '15px dotted white',
										height: '100%',
										left: '-7.5px',
										top: '15px'
									}
								}
							]
						}
					]
				},
				{
					type: 'Page Number'
				}
			]
		},
		{
			name: 'Recipe - Right Page',
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } },
				classNames: ['page-recipe'],
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			},
			children: [
				{
					type: 'image',
					props: {
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						zIndex: '10',
						margin: '5%',
						src: { '?': { var: '$props.recipe.images.0.url' } }
					}
				}
			]
		}
	]
};
