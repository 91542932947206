<template>
	<b-overlay
		:show="isVisible"
		no-center
		no-wrap
		opacity="0.2"
		bg-color="grey"
		@click="e => !isLoading && handleClick(e)"
	>
		<template #overlay>
			<div class="d-flex justify-content-end">
				<Loader v-if="isLoading" overlay />

				<div class="overlay-container" :class="{ 'overlay-container-splitted': isContentTab }">
					<div class="overlay-content">
						<RecipePreview :recipe="recipe" />
					</div>
					<div class="overlay-actions" :class="{ 'overlay-actions-splitted': isContentTab }">
						<div class="overlay-actions--edit" @click.stop="handleEdit">
							<icon name="pencil-alt" />
							<span class="ml-2"> {{ $t('Edit Item') }} </span>
						</div>
						<div class="overlay-actions--export" @click.stop="handleExport">
							<icon name="file-download" />
							<span class="ml-2"> {{ $t('Export JSON') }} </span>
						</div>
					</div>
				</div>
			</div>
		</template>
	</b-overlay>
</template>
<script type="text/javascript">
import RecipePreview from './RecipePreview';
import Loader from '../Loader.vue';
import { saveToJSON } from '../../lib/RecipeDownloader';
import notifications from '../../mixins/notifications';

export default {
	components: {
		RecipePreview,
		Loader
	},
	mixins: [notifications],
	props: {
		isVisible: {
			type: Boolean,
			default: false
		},
		recipe: {
			type: Object,
			default: () => {}
		},
		isContentTab: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isLoading: false
		};
	},
	methods: {
		handleClick(event) {
			if (event.target.matches('.position-absolute')) {
				this.$emit('hide');
			}
		},
		handleEdit() {
			this.$router.push({ name: 'recipes.edit', params: { id: this.recipe.id } });
		},
		handleExport() {
			try {
				this.isLoading = true;
				saveToJSON([this.recipe], _.get(this.recipe, 'record.name', 'recipe'));
			} catch (error) {
				this.notifyError(error, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while downloading items')
				});
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>

<style lang="scss">
@import '../../style/_variables.scss';
.overlay-container {
	box-shadow: -1px 0px 5px rgba(0, 0, 0, 0.15), -4px 0px 10px rgba(0, 0, 0, 0.2);
	background: $of-color-white;
	position: absolute;
	width: 40%;
	height: 100%;
	overflow-y: scroll;

	&-splitted {
		height: calc(100% - 57px);
	}
}

.overlay-actions {
	display: flex;
	position: fixed;
	bottom: 0;
	right: 0;
	width: inherit;
	height: 55px;
	font-size: 15px;
	border: 1px solid $color-hp-grey-3;
	cursor: pointer;

	&-splitted {
		bottom: 57px;
	}

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
	}

	&--edit {
		background: $color-pimienta;
		color: $of-color-white;
	}

	&--export {
		background: $of-color-white;
		color: $color-hp-dark;
	}
}
</style>
