export default {
	sentryKey: process.env.VUE_APP_SENTRY_KEY,
	apiBase: process.env.VUE_APP_API_BASE,
	hpStoreUrl: process.env.VUE_APP_HP_STORE_URL,
	auth0: {
		domain: process.env.VUE_APP_AUTH_DOMAIN,
		clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
		redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URL,
		audience: process.env.VUE_APP_AUTH_AUDIENCE
	},
	fileStackApiKey: process.env.VUE_APP_FILESTACK_API_KEY
};
