<template>
	<Layout>
		<b-form class="h-100" novalidate>
			<Page
				class="ProductContentEdit"
				content-class="ProductContentEdit-Content"
				actions-class="ProductContentEdit-Actions"
			>
				<ContentHeader :title="headerTitle" :breadcrumbs="breadcrumbs" class="mb-2" no-padding>
					<b-dropdown class="mx-1" right text="Actions">
						<b-dropdown-item :disabled="!isPreviewAvailable" @click="redirectToPreview">
							<icon name="eye" class="mr-2" />
							{{ $t('Preview') }}
						</b-dropdown-item>
						<b-dropdown-item href="#" @click="handleImportItem">
							<icon name="file-upload" class="mr-2" /> {{ $t('Import') }}
						</b-dropdown-item>
						<b-dropdown-item :disabled="!productContentId" @click="handleRemove">
							<icon name="trash" class="mr-2" />
							{{ $t('Delete') }}
						</b-dropdown-item>
					</b-dropdown>
				</ContentHeader>

				<b-alert :show="unpairedSpreadItems" variant="warning" dismissible class="mb-0 mt-2">
					{{ $t('ALERT: You can not print this book because there are unpaired items.') }}
				</b-alert>

				<b-tabs v-model="activeTabIndex" class="ProductContentEdit-Tabs mt-3" lazy>
					<BookEditDetailsTab
						v-bind="{
							$v
						}"
					/>

					<BookEditContentTab
						v-bind="{
							isValidationAlertShown,
							globalValidationErrors,
							productContentId,
							contributorIds,
							bookDocuments,
							activeSectionsConfig,
							sections,
							supportedTypes,
							rootSections,
							rootValidationErrors: validationErrors,
							sectionsConfig,
							$v
						}"
						lazy
						@update-documents="handleUpdateDocuments"
					/>

					<BookEditAppearanceTab
						v-if="isAppearanceVisible"
						v-bind="{ bookDocuments, templateFormSummary, contributors }"
						ref="bookAppearanceTab"
						:disabled="!productTemplateId"
						lazy
					/>

					<BookEditContributorsTab
						v-bind="{
							contributorIds
						}"
						lazy
						@update-contributors="handleUpdateContributors"
					/>
				</b-tabs>

				<template v-if="!activeItemId" #actions>
					<b-button v-t="$t('Cancel')" class="mr-2" @click="onCancel" />
					<b-button v-t="$t('Save')" variant="primary" :disabled="!canSubmit" @click="onSave" />
				</template>
			</Page>
			<ImportModal
				modal-id="importBookModal"
				:product-content-id="productContentId"
				:modal-title="$t('Import Book')"
				:mode="scrapingRuleSubjects.Book"
				:visible="false"
			/>
		</b-form>
	</Layout>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { maxLength, required } from 'vuelidate/lib/validators';
import { ContentHeader, withForm, validateWithMessage } from '@oneflow/ofs-vue-layout';
import Layout from '../../../../components/Layout.vue';
import Page from '../../../../components/Page.vue';
import notifications from '../../../../mixins/notifications';
import { buildValidationRules, getSectionValidationErrors } from '@/components/BookEdit';
import { getActiveSectionFields, buildSectionFieldPath, checkUnpairedItems } from './helpers';
import ImportModal from '../../../../components/ImportModal';
import { appearanceFormName } from './AppearancePage/BookAppearance.vue';
import { scrapingRuleSubjects } from '@/constants';
import { validationRules } from './constants';
import BookEditDetailsTab from './BookEditDetailsTab';
import BookEditContentTab from './BookEditContentTab';
import BookEditAppearanceTab from './BookEditAppearanceTab';
import BookEditContributorsTab from './BookEditContributorsTab';
import { initialFormData, productContentFormName, predefinedSections } from './constants';

export default {
	components: {
		Layout,
		Page,
		ContentHeader,
		ImportModal,
		BookEditDetailsTab,
		BookEditContentTab,
		BookEditAppearanceTab,
		BookEditContributorsTab
	},
	mixins: [withForm(productContentFormName), notifications],
	async beforeRouteLeave(to, from, next) {
		if (this.skipRedirectConfirmation || !this.isFormModified()) {
			return next();
		}

		const isConfirm = await this.confirmAction([
			this.$t('Are you sure you want to leave?'),
			this.$t('Unsaved changes will be lost.')
		]);
		if (isConfirm) next();
	},
	data() {
		return {
			activeItemId: null,
			activeSectionIndex: 0,
			isSelectItemsModalVisible: false,
			bookDocuments: {},
			skipRedirectConfirmation: false,
			activeTabIndex: 0,
			scrapingRuleSubjects,
			contributors: []
		};
	},
	computed: {
		...mapGetters({
			productContent: 'product-content/product-content',
			template: 'template/template',
			getFormSummary: 'form/getFormSummary',
			getFormData: 'form/getFormData',
			getFormInitialData: 'form/getFormInitialData',
			sectionsConfig: 'root-book-section/root-book-sections',
			layouts: 'layout/layouts'
		}),
		activeSectionsConfig() {
			const activeRootSections = _.filter(this.sectionsConfig, section =>
				section.dynamic ? _.get(this.formData, `content.${section.key}`) : true
			);

			return _.map(activeRootSections, section => ({
				...section,
				sections: _.map(section.sections, subsection => ({
					...subsection,
					fields: getActiveSectionFields(subsection.fields, this.formData)
				}))
			}));
		},
		withTemplate() {
			return this.productTemplateId && !_.isEmpty(this.template);
		},
		validationRules() {
			const sectionValidationRules = {
				formData: {
					content: {
						...buildValidationRules(this.activeSectionsConfig)
					}
				}
			};
			const additionalRules = {
				formData: {
					content: {
						subtitle2: {
							maxLength: validateWithMessage('Must have less than 30 characters', maxLength(30))
						}
					}
				}
			};
			return _.merge({}, sectionValidationRules, additionalRules);
		},
		basicValidationFields() {
			const { MinImageSize, ImageAspectRatio } = validationRules;
			return this.validationErrors.filter(el => ![MinImageSize, ImageAspectRatio].includes(el.name));
		},
		canSubmit() {
			return true; // TODO: disable save button during file upload
		},
		isContentBasicallyValid() {
			return this.basicValidationFields.length === 0;
		},
		isValidationAlertShown() {
			return this.basicValidationFields.some(el => el.$v.$dirty);
		},
		headerTitle() {
			return this.productContentId ? _.get(this.formData, 'content.headline') : this.$t('New book');
		},
		breadcrumbs() {
			const listRoute = this.$router.resolve({ name: 'books.list' });

			return [
				{ text: this.$t('Books'), href: listRoute.href },
				{ text: this.headerTitle, href: '#' }
			];
		},
		productContentId() {
			const id = _.get(this.$route, 'params.id', 'new');
			return id !== 'new' ? id : null;
		},
		productTemplateId() {
			return _.get(this.productContent, 'product.templateId', null);
		},
		contributorIds() {
			return _.get(this.formData, 'contributorIds', []);
		},
		specificationCode() {
			return _.get(this.$route, 'params.code');
		},
		isPreviewAvailable() {
			return !!this.productTemplateId;
		},
		rootSections() {
			let globalIndex = 0;
			return _.map(this.activeSectionsConfig, section => {
				if (!section.flatten) return { ...section, index: globalIndex++ };
				const { key, name } = section;
				const items = _.get(this.formData, `content.${key}`, []);
				return _.times(items.length, index => ({
					...section,
					name: _.get(this.formData, `content.${key}.${index}.name`, name),
					rootKey: key,
					index: globalIndex++,
					key: `${key}[${index}]`,
					localIndex: index
				}));
			});
		},
		sections() {
			return _.flatten(this.rootSections);
		},
		templateFormData() {
			return this.getFormData({ formName: appearanceFormName });
		},
		templateFormInitialData() {
			return this.getFormInitialData({ formName: appearanceFormName });
		},
		templateFormSummary() {
			return this.getFormSummary({ formName: appearanceFormName });
		},
		isAppearanceVisible() {
			return this.withTemplate && !_.isEmpty(this.formData.content) && !this.isOldLeafTemplate;
		},
		isOldLeafTemplate() {
			if (!this.withTemplate) return;
			const oldThemeNames = ['Square', 'Circle', 'Basic'];
			const theme = _.get(this.template, 'source.theme');
			const validationField = _.get(theme, 'variants[0].theme.cover.textBackgroundImage', false);
			return (
				_.includes(oldThemeNames, _.get(theme, 'selectedVariant')) &&
				theme.variants.length === 3 &&
				validationField
			);
		},
		contentValidationErrors() {
			return _.flatMap(this.sections, section => getSectionValidationErrors(section, this.validationErrors));
		},
		rootSectionKeysMap() {
			return _.keyBy(_.map(this.sectionsConfig, section => `content.${section.key}`));
		},
		globalValidationErrors() {
			return _.filter(this.validationErrors, error => !!this.rootSectionKeysMap[error.path]);
		},
		unpairedSpreadItems() {
			const chapters = _.get(this.formData, 'content.chapters');
			const chaptersWithDocuments = _.map(chapters, chapter => {
				return _.map(chapter.documentIds, documentId => this.bookDocuments[documentId]);
			});
			const chaptersWithUnpairedItems = _.map(chaptersWithDocuments, documents => checkUnpairedItems(documents));
			return _.flatten(chaptersWithUnpairedItems).includes(false);
		},
		supportedTypes() {
			let layoutId;

			if (!this.templateFormData) {
				layoutId = this.getLayoutIdFromTemplate(this.template);
			} else {
				layoutId = this.getLayoutIdFromTemplate(this.templateFormData);
			}

			const layout = _.find(this.layouts, ['id', layoutId]);
			return _.get(layout, 'supportedTypes', []);
		}
	},
	watch: {
		async productContentId(productContentId, oldProductContentId) {
			if (productContentId !== oldProductContentId) {
				await this.initialize();
			}
		}
	},
	async mounted() {
		await this.initialize();
	},
	beforeDestroy() {
		this.resetForm({ formName: appearanceFormName });
	},
	methods: {
		getSectionValidationErrors: getSectionValidationErrors,
		getActiveSectionFields: getActiveSectionFields,
		buildSectionFieldPath: buildSectionFieldPath,
		...mapActions({
			findProductContentById: 'product-content/findById',
			updateProductContent: 'product-content/update',
			createProductContent: 'product-content/create',
			deleteProductContentById: 'product-content/deleteById',
			findAllDocuments: 'document/findAll',
			findTemplateById: 'template/findById',
			updateTemplate: 'template/update',
			updateProduct: 'product/update',
			fetchAllSections: 'root-book-section/findAll',
			getCreators: 'creator/find',
			findAllLayouts: 'layout/findAll',
			resetForm: 'form/resetFormData'
		}),
		async initialize() {
			await this.fetchAllSections({
				query: {
					query: {
						$sort: { displayOrder: 1 }
					}
				}
			});

			if (!this.productContentId) {
				return this.initFormData(initialFormData);
			}

			const productContent = await this.fetchProductContent();
			await this.fetchTemplate();

			this.initFormData(_.merge({}, initialFormData, productContent));
			await this.fetchCreators();

			await this.fetchLayouts();
		},
		async fetchProductContent() {
			const query = { $populate: { path: 'product', $select: { templateId: 1, type: 1 } } };
			return this.findProductContentById({ id: this.productContentId, query: { query } });
		},
		async fetchTemplate() {
			if (!this.productTemplateId) return;
			return this.findTemplateById({ id: this.productTemplateId });
		},
		async fetchLayouts() {
			const query = { $where: { format: 'json', active: true } };
			await this.findAllLayouts({ query: { query } });
		},
		async fetchDocuments(documentIds) {
			const query = {
				$where: { _id: { $in: documentIds }, contributorIds: this.formData.contributorIds },
				$populate: { path: 'creator' }
			};
			const documents = await this.findAllDocuments({ query: { query } });

			this.bookDocuments = _.keyBy(documents, 'id');
		},
		async fetchCreators() {
			if (!_.get(this.formData, 'contributorIds.length')) {
				return (this.contributors = []);
			}
			try {
				const query = {
					$where: {
						accountId: { $in: _.uniq(this.formData.contributorIds) }
					}
				};
				const { data } = await this.getCreators({ query: { query } });
				this.contributors = _.orderBy(data, 'username', 'asc');
			} catch (error) {
				this.notifyError(error);
			}
		},
		async handleRemove() {
			const isConfirmed = await this.confirmAction(this.$t('Are you sure you want to delete?'));
			if (!isConfirmed) return;

			try {
				await this.deleteProductContentById({ id: this.productContentId });

				this.skipRedirectConfirmation = true;
				this.$router.push({ name: 'books.list' });
			} catch (err) {
				this.notifyError(error);
			}
		},
		cleanupFormData() {
			// remove empty array items
			const content = _.cloneDeepWith(this.formData.content, value => {
				if (Array.isArray(value)) return _.compact(value);
			});

			this.updateField('content', content);
		},
		async handleUpdateProductType() {
			const prevProductType = _.get(this.productContent, 'product.type');
			const currentProductType = _.get(this.formData, 'product.type');
			if (prevProductType === currentProductType) return;
			await this.updateProduct({
				id: _.get(this.formData, 'product._id'),
				data: { type: currentProductType }
			});
		},
		async onSave() {
			if (!this.isContentBasicallyValid || this.templateFormSummary.invalid) {
				this.$v.formData.content.$touch();
				if (this.isAppearanceVisible) {
					this.$refs?.bookAppearanceTab?.$refs.bookAppearance?.$v?.formData?.source?.$touch();
				}

				return this.notifyWarning({ text: this.$t('To save please resolve all alerts.') });
			}

			this.cleanupFormData();

			this.$refs?.bookAppearanceTab?.$refs?.bookAppearance?.setFormData(this.templateFormData);

			try {
				if (this.formData._id) {
					await this.dispatchSubmit(this.submitUpdate());
					await this.handleUpdateProductType();

					this.notifySuccess({
						title: this.$t('Success'),
						text: this.$t('Book has been updated')
					});
				} else {
					await this.submitCreate();
					this.notifySuccess({
						title: this.$t('Success'),
						text: this.$t('Book has been created successfully')
					});
				}
				await this.initialize();
				this.$refs?.bookAppearanceTab?.$refs?.bookAppearance?.initialize();
			} catch (error) {
				this.notifyError(error);
			}
		},
		async submitCreate() {
			const payload = {
				...this.formData,
				name: _.get(this.formData, 'content.headline'),
				specificationCode: this.specificationCode
			};

			const productContent = await this.createProductContent(payload);

			this.skipRedirectConfirmation = true;
			this.$router.push({ name: 'books.edit', params: { id: productContent._id } });
		},
		async submitUpdate() {
			if (this.templateFormData) {
				await this.updateTemplate({ id: this.templateFormData._id, data: this.templateFormData });
			}

			await this.updateProductContent({ id: this.formData._id, data: this.formData });
		},
		onCancel() {
			this.$router.push({ name: 'books.list' });
		},
		redirectToPreview() {
			const id = this.productContentId;
			this.$router.push({ name: 'books.preview', params: { id } });
		},
		handleImportItem() {
			this.$bvModal.show('importBookModal');
		},
		async handleUpdateDocuments(newDocumentIds) {
			const currentDocumentIds = _.keys(this.bookDocuments);
			const missingDocumentIds = _.difference(newDocumentIds, currentDocumentIds);
			if (!missingDocumentIds.length && _.size(newDocumentIds) === _.size(currentDocumentIds)) return;
			await this.fetchDocuments(newDocumentIds);
		},
		async handleUpdateContributors(newContributors) {
			const missingContributorIds = _.difference(this.contributorIds, newContributors);
			this.updateField('contributorIds', newContributors);
			await this.fetchCreators();
			if (!missingContributorIds.length) return;

			const chapters = _.get(this.formData, `content.${predefinedSections.Chapters}`, []);
			const newChaptersWithRecipes = _.map(chapters, chapter => {
				const newChapterRecipes = _.filter(
					chapter.documentIds,
					documentId => !_.includes(missingContributorIds, this.bookDocuments[documentId].accountId)
				);

				return { ...chapter, documentIds: newChapterRecipes };
			});
			this.updateField(`content.${predefinedSections.Chapters}`, newChaptersWithRecipes);
		},
		isFormModified() {
			const { templateFormData, templateFormInitialData } = this;

			const isProductContentNotChanged = _.isEqual(this.productContent, this.formData);
			const isHTML = this.template.format === 'html';
			const hasTemplateForm =
				![templateFormData, templateFormInitialData].includes(undefined) &&
				!_.isEmpty(templateFormData) &&
				!_.isEmpty(templateFormInitialData);

			return !(
				isProductContentNotChanged &&
				((isHTML ? !this.withTemplate : false) ||
					(hasTemplateForm ? _.isEqual(templateFormInitialData, templateFormData) : true))
			);
		},
		getLayoutIdFromTemplate(template) {
			return _.get(template, 'contentLayoutId') || _.get(template, 'parentLayoutId');
		}
	}
};
</script>

<style lang="scss">
@import '../../../../style/_variables.scss';

.ProductContentEdit {
	margin-bottom: 60px;

	&-Content {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	@media (min-width: 768px) {
		&-Actions {
			position: fixed;
			bottom: -1px;
			min-width: calc(100vw - 200px);
			background: #fff;
			z-index: 99;
		}
	}

	&-Tabs {
		height: 100%;

		.tab-content {
			height: calc(100% - 45px);
		}
	}

	.tabs:not(.row) > .tab-content {
		margin-top: 30px;
	}

	.is-invalid {
		border-color: $color-hp-orange !important;
	}

	.is-invalid:focus {
		box-shadow: 0 0 0 0.2rem rgba($color-hp-orange, 0.25);
	}

	.invalid-feedback {
		color: $color-hp-orange !important;
	}

	.SectionTabs {
		&.row {
			.col-auto {
				padding-top: 0;
				padding-right: 30px;
				border-right: 1px solid $color-hp-grey-3;

				.nav-tabs {
					min-width: 200px;
					border: none;
				}
			}

			.tab-content {
				padding-top: 0;
				padding-left: 35px;

				.custom-checkbox--opening-page {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}
		}

		.AddTabBtn {
			.fa-icon {
				margin-right: 7px;
			}
		}
	}
}
</style>
