<template>
	<component :is="layout" v-bind="templateProps">
		<router-view v-if="!auth0Loading" :layout.sync="layout" @update:templateProps="handleUpdateProps" />
		<Notification />
	</component>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Notification } from '@oneflow/ofs-vue-layout';

export default {
	components: {
		Notification
	},
	data: () => ({ layout: 'div', templateProps: null }),
	computed: {
		...mapGetters({
			isAuthenticated: 'auth0/isAuthenticated',
			auth0Loading: 'auth0/isLoading',
			lang: 'lang/lang',
			currentAccount: 'account/currentAccount'
		})
	},
	watch: {
		isAuthenticated: {
			immediate: true,
			async handler() {
				this.redirectIfNotAuth();
			}
		}
	},
	async created() {
		this.setLanguage({ lang: this.lang });
		this.$router.afterEach(this.redirectIfNotAuth);

		try {
			await this.initAuth0({
				domain: window.$config.auth0.domain,
				clientId: window.$config.auth0.clientId,
				authorizationParams: {
					redirect_uri: window.$config.auth0.redirectUri,
					audience: window.$config.auth0.audience
				}
			});
			await this.fetchBaseAccountData();
		} catch (err) {
			this.$notify({
				type: 'error',
				title: 'Authentication error',
				text: err.message
			});
		}
	},
	methods: {
		...mapActions('account', ['fetchBaseAccountData']),
		...mapActions('lang', ['setLanguage']),
		...mapActions('auth0', ['initAuth0']),
		handleUpdateProps(props) {
			this.templateProps = props;
		},
		redirectIfNotAuth() {
			if (this.isAuthenticated === undefined) {
				return;
			}

			if (!this.isAuthenticated && this.$route.name !== 'login') {
				this.$router.push('/login');
			}

			if (this.$route.name === 'login' && this.isAuthenticated === true) {
				this.$router.push('/');
			}
		}
	}
};
</script>
