<template>
	<div class="Page">
		<div class="Page-content" :class="contentClass">
			<slot></slot>
		</div>
		<footer v-if="$slots.actions" class="Page-actions" :class="actionsClass">
			<slot name="actions"></slot>
		</footer>
	</div>
</template>

<script>
export default {
	name: 'Page',
	props: {
		contentClass: {
			type: String,
			default: null
		},
		actionsClass: {
			type: String,
			default: null
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../style/_variables.scss';

.Page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	&-content {
		padding: 20px 30px;
	}

	&-actions {
		display: flex;
		justify-content: flex-end;
		border-top: 1px solid rgba($of-color-grey-3, 0.5);
		box-shadow: 0px -2px 4px rgba(58, 63, 81, 0.2);
		padding: 10px 20px;
	}
}
</style>
