<template>
	<b-modal :title="$t('Download')" size="md" :visible="isVisible" @hidden="onClose">
		<b-col v-if="downloadStatus === downloadStatuses.DOWNLOADING">
			<span>{{ downloaded }} / {{ total }}</span>
			<b-progress :max="total" :value="downloaded" animated></b-progress>
		</b-col>
		<b-col v-if="downloadStatus === downloadStatuses.PROCESSING_IMAGES">
			<div>{{ $t('Calculating the resolution of images') }}</div>
			<span>{{ recipesWithProcessedImages }} / {{ validItems.length }}</span>
			<b-progress :max="validItems.length" :value="recipesWithProcessedImages" animated></b-progress>
		</b-col>
		<b-col v-if="downloadStatus === downloadStatuses.WRITING_TO_PDF">
			<div>{{ $t('Writing recipes to pdf') }}</div>
			<span>{{ recipesWrote }} / {{ validItems.length }}</span>
			<b-progress :max="validItems.length" :value="recipesWrote" animated></b-progress>
		</b-col>
		<b-alert :show="canSubmit" variant="success">
			<span>{{ $t('Downloaded') }} {{ validItems.length }} / {{ total }}</span>
		</b-alert>
		<b-alert :show="Boolean(canSubmit && invalidItems.length)" variant="danger" class="mt-2">
			<span>{{ $t('Failed to download') }}</span>
			<ul>
				<li v-for="invalidItem in invalidItems" :key="invalidItem._id">
					<router-link :to="`/recipes/${invalidItem._id}`">{{ invalidItem._id }}</router-link>
				</li>
			</ul>
		</b-alert>
		<div slot="modal-footer">
			<b-button variant="primary" :disabled="!canSubmit" @click="onClose">
				{{ canSubmit ? $t('Close') : $t('In progress') }}
			</b-button>
		</div>
	</b-modal>
</template>

<script>
import { downloadStatuses } from '../../../constants';
export default {
	props: {
		isVisible: {
			type: Boolean,
			required: true
		},
		total: {
			type: Number,
			required: true
		},
		downloaded: {
			type: Number,
			required: true
		},
		downloadStatus: {
			type: String,
			required: true
		},
		validItems: {
			type: Array,
			required: true
		},
		invalidItems: {
			type: Array,
			required: true
		},
		recipesWithProcessedImages: {
			type: Number,
			required: true
		},
		recipesWrote: {
			type: Number,
			required: true
		}
	},
	computed: {
		canSubmit() {
			return !this.downloadStatus;
		},
		downloadStatuses() {
			return downloadStatuses;
		}
	},
	methods: {
		onClose() {
			this.$emit('hidden');
		}
	}
};
</script>

<style lang="scss"></style>
