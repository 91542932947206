<template>
	<b-form-group class="TemplateSelect" :label="label">
		<multiselect
			:value="value"
			:options="options"
			:placeholder="placeholder"
			:allow-empty="false"
			:track-by="trackBy"
			:disabled="disabled"
			@input="$emit('input', $event)"
		>
			<template slot="singleLabel" slot-scope="props">
				<Thumbnail :src="props.option.previewImageUrl" size="50" />
				<span class="ml-2">{{ props.option.name }}</span>
			</template>
			<template slot="option" slot-scope="props">
				<Thumbnail :src="props.option.previewImageUrl" size="50" />
				<span class="ml-2">{{ props.option.name }}</span>
			</template>
		</multiselect>
	</b-form-group>
</template>

<script>
import Thumbnail from '../Thumbnail.vue';

export default {
	components: {
		Thumbnail
	},
	props: {
		options: {
			type: Array,
			default: () => []
		},
		trackBy: {
			type: String,
			required: true
		},
		value: {
			type: Object,
			default: null
		},
		label: {
			type: String,
			default: null
		},
		placeholder: {
			type: String,
			default: undefined
		},
		disabled: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss">
@import '../../style/variables.scss';

.TemplateSelect {
	.multiselect {
		cursor: pointer;

		&__select {
			height: 100%;

			&::before {
				border-color: $color-hp-black transparent transparent;
			}
		}

		&__tags {
			border: 1px solid $color-hp-grey-3;
		}

		&__option--highlight::after {
			display: none;
		}

		.thumbnail {
			border-radius: 5px;
		}
	}
}
</style>
