import Vue from 'vue';
import OfsCrud from '@ofs-vuex-crud';
import httpProviderInstance from './httpProviderInstance';
import httpDaVinciTransport from './httpDaVinciTransport';
import registerMobilePanelModule from './mobilePanel';
import registerLangStoreModule from './lang';
import registerMediaUploaderModule from './media-uploader';
import registerScrapingRuleModule from './scrapingRule';
import registerDocumentModule from './document';
import registerProductContentModule from './productContent';
import registerVersionNotificationStoreModule from './version-notification';
import registerRecipeImportModule from './dataImport';
import registerLayoutModule from './layout';
import registerTemplateModule from './template';
import registerProductModule from './product';
import registerRootBookSectionsModule from './rootBookSections';
import registerContributorModule from './contributor';
import registerBooksModule from './book';
import registerFeatureFlagsModule from './feature-flags';
import registerCreatorModule from './creator';
import registerAuth0Module from './auth0';
import registerAccountModule from './account';

const setupModules = (store, i18n) => {
	Vue.use(OfsCrud, { store });

	Vue.$ofsCrud.registerTransport({ name: 'http', fn: () => httpProviderInstance });
	Vue.$ofsCrud.registerTransport({ name: 'davinci', fn: () => httpDaVinciTransport });

	// Register Modules
	registerMobilePanelModule(store);
	registerLangStoreModule(store, i18n);
	registerMediaUploaderModule(store);
	registerScrapingRuleModule(store);
	registerDocumentModule(store);
	registerProductContentModule(store);
	registerVersionNotificationStoreModule(store);
	registerRecipeImportModule(store);
	registerLayoutModule(store);
	registerTemplateModule(store);
	registerProductModule(store);
	registerRootBookSectionsModule(store);
	registerContributorModule(store);
	registerBooksModule(store);
	registerFeatureFlagsModule(store);
	registerCreatorModule(store);
	registerAuth0Module(store);
	registerAccountModule(store);
};

export default setupModules;
