<template>
	<Layout>
		<Page class="RecipesScraper">
			<ContentHeader :title="headerTitle" :breadcrumbs="breadcrumbs" class="mb-2" no-padding />

			<b-tabs>
				<b-tab :title="$t('Single')">
					<b-row class="d-flex justify-content-center align-items-center m-0">
						<div class="d-flex align-items-center">
							<h2 class="mb-0 w-auto mr-3">{{ $t('Import from URL') }}</h2>
						</div>
						<div class="col-md-4 mr-3 mt-4 mb-4">
							<OfFormInput v-model="url" name="sourceURL" without-label />
						</div>
						<div class="d-flex align-items-center">
							<h2 class="mb-0 w-auto mr-3">{{ $t('Scraping rules') }}</h2>
						</div>
						<div class="mr-3">
							<OfFormSelect v-model="scrapingRuleId" :options="rulesOptions" :show-errors="true" />
						</div>
						<div class="d-flex align-items-center mr-3">
							<b-button
								variant="primary"
								size="ml"
								:disabled="!url || isRecipeLoading || !scrapingRuleId"
								@click="importRecipe"
							>
								{{ $t('Import') }}
							</b-button>
						</div>
						<div class="d-flex align-items-center">
							<b-button variant="primary" size="ml" @click="openEditor">
								{{ $t('Add manually') }}
							</b-button>
						</div>
					</b-row>
					<b-row v-if="!scrapingRuleId">
						<b-col class="d-flex justify-content-center">
							{{ $t('No scraping rules specified for this book') }}
						</b-col>
					</b-row>
					<b-row>
						<template v-if="result">
							<RecipePreview :recipe="{ ...result, errors }" />
						</template>

						<template v-if="isRecipeLoading">
							<Loader />
						</template>
					</b-row>
				</b-tab>

				<b-tab :title="$t('Bulk')">
					<b-row class="mt-0 p-3">
						<h2 class="mb-0">{{ $t('Add the list of URLs') }}</h2>
					</b-row>
					<b-row class="d-flex justify-content-center align-items-center pr-3 pl-3">
						<OfFormTextarea v-model="bulkURLs" name="bulkURLs" without-label :rows="6" />
					</b-row>
					<b-row class="pr-3 pl-3">
						<div class="d-flex align-items-center">
							<h2 class="mb-0 w-auto mr-3">{{ $t('Scraping rules') }}</h2>
						</div>
						<div class="mr-3">
							<OfFormSelect v-model="scrapingRuleId" :options="rulesOptions" :show-errors="true" />
						</div>
					</b-row>
					<b-row class="p-3">
						<b-button
							variant="primary"
							size="ml"
							:disabled="!bulkURLs || isRecipeLoading || !scrapingRuleId"
							@click="bulkImportRecipe"
						>
							{{ $t('Import') }}
						</b-button>
					</b-row>
					<b-row v-if="!scrapingRuleId">
						<b-col>{{ $t('No scraping rules specified for this book') }}</b-col>
					</b-row>
					<b-row>
						<template v-if="isRecipeLoading">
							<Loader />
						</template>
					</b-row>
					<b-row v-if="resultArray.length" class="m-3 recipe-progress">
						<template v-for="(item, index) in resultArray">
							<div :key="index" class="w-100 d-flex">
								<b-col
									:class="[
										item.warnings.length
											? item.error
												? 'recipe-error'
												: 'recipe-warning'
											: 'recipe-error'
									]"
									md="6"
								>
									{{ item.url }}
								</b-col>
								<b-col md="3">
									<template v-for="warning in item.warnings">
										{{ parseError(warning) }}
									</template>
								</b-col>
								<b-col>
									{{ item.error }}
								</b-col>
							</div>
						</template>
					</b-row>
				</b-tab>

				<b-tab :title="$t('From JSON')">
					<b-row class="mt-0 p-3">
						{{ $t('Recipe should be not empty and have name and category properties') }}
					</b-row>
					<b-row class="pl-3 pr-3">
						<OfsFormGenerator
							class="w-100"
							:fields="[jsonEditor]"
							:model="inputJSON"
							:show-errors="true"
							@change="formChanged"
						/>
					</b-row>
					<b-row class="p-3">
						<b-button variant="primary" size="ml" :disabled="!canSubmit" @click="importJSON">
							{{ $t('Import') }}
						</b-button>
					</b-row>
				</b-tab>

				<b-tab :title="$t('From file')">
					<b-row class="mt-0 p-3">
						<b-form-file
							v-model="file"
							:state="Boolean(file)"
							placeholder="Choose a file or drop it here..."
							drop-placeholder="Drop file here..."
							accept="application/json"
						></b-form-file>
					</b-row>
					<b-row v-if="numberOfImportedRecipes" class="mt-0 pl-3">
						<p>
							{{
								numberOfImportedRecipes === 1
									? numberOfImportedRecipes + $t(' recipe was imported')
									: numberOfImportedRecipes + $t(' recipes were imported')
							}}
						</p>
					</b-row>
					<b-row class="p-3">
						<b-button variant="primary" size="ml" :disabled="!file" @click="importFromFile">
							{{ $t('Import') }}
						</b-button>
					</b-row>
				</b-tab>

				<b-tab :title="$t('From API')">
					<b-row class="d-flex justify-content-md-start align-items-center m-0">
						<div class="d-flex align-items-center">
							<h2 class="mb-0 w-auto mr-3">{{ $t('API url') }}</h2>
						</div>
						<div class="col-md-5 mr-3 mt-4 mb-4">
							<OfFormInput v-model="url" name="sourceURL" without-label />
						</div>
						<div class="d-flex align-items-center">
							<h2 class="mb-0 w-auto mr-3">{{ $t('Scraping rules') }}</h2>
						</div>
						<div class="mr-3">
							<OfFormSelect v-model="scrapingRuleId" :options="rulesOptions" :show-errors="true" />
						</div>
						<div class="d-flex align-items-center mr-3">
							<b-button
								variant="primary"
								size="ml"
								:disabled="!url || isRecipeLoading"
								@click="importRecipeFromAPI"
							>
								{{ $t('Import') }}
							</b-button>
						</div>
					</b-row>
					<b-row class="d-flex justify-content-md-start align-items-center m-0">
						<div class="d-flex align-items-center">
							<h2 class="mb-0 w-auto mr-3">{{ $t('Request headers') }}</h2>
						</div>
						<div class="w-50 mr-3 mt-4 mb-4">
							<b-button variant="primary" size="ml" @click="addHeader">
								{{ $t('Add Header') }}
							</b-button>
						</div>
					</b-row>
					<b-row class="d-flex justify-content-md-start align-items-center m-0">
						<b-col v-for="(item, index) in requestHeaders" :key="index" lg="8" md="12">
							<b-card class="mb-2">
								<b-row>
									<b-col lg="6">
										<OfFormInput
											:label="$t('Header name')"
											:value="item.key"
											@input="handleChange(index, 'key', $event)"
										/>
									</b-col>
									<b-col lg="6">
										<OfFormInput
											:label="$t('Value')"
											:value="item.value"
											@input="handleChange(index, 'value', $event)"
										/>
									</b-col>
									<icon
										name="times-circle"
										class="request-header-remove"
										@click="removeHeader(index)"
									/>
								</b-row>
							</b-card>
						</b-col>
					</b-row>
					<b-row v-if="!scrapingRuleId">
						<b-col class="d-flex justify-content-center">
							{{ $t('No scraping rules specified for this book') }}
						</b-col>
					</b-row>
					<b-row>
						<template v-if="isRecipeLoading">
							<Loader />
						</template>
					</b-row>
					<div v-if="apiResult && apiResult.length">
						<b-row v-for="(item, index) in apiResult" :key="index" class="mb-15">
							<RecipePreview :recipe="{ ...item, errors: apiErrors[index] }" />
							<hr class="recipe-delimiter" />
						</b-row>
					</div>
				</b-tab>
			</b-tabs>
		</Page>
	</Layout>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

import {
	OfsPanel,
	OfFormInput,
	OfFormTextarea,
	OfsFormGenerator,
	ContentHeader,
	OfFormSelect
} from '@oneflow/ofs-vue-layout';
import RecipePreview from '../../../components/ItemPreview/RecipePreview';
import Layout from '../../../components/Layout';
import Page from '../../../components/Page';
import Loader from '../../../components/Loader';
import router from '../../../router/index';

const jsonEditor = {
	name: 'documents',
	type: 'jsonEditor',
	description: 'JSON array of documents'
};

export default {
	components: {
		OfFormInput,
		Layout,
		Page,
		RecipePreview,
		Loader,
		OfFormTextarea,
		OfsFormGenerator,
		OfFormSelect,
		ContentHeader
	},
	data() {
		return {
			url: '',
			bulkURLs: '',
			result: null,
			resultArray: [],
			errors: null,
			isRecipeLoading: false,
			jsonEditor,
			inputJSON: {},
			file: null,
			numberOfImportedRecipes: 0,
			requestHeaders: [],
			apiResult: [],
			apiErrors: [],
			scrapingRuleId: ''
		};
	},
	computed: {
		...mapGetters({
			rules: 'scraping-rule/scraping-rules',
			books: 'book/books',
			recipes: 'recipes/recipes'
		}),
		arrayOfURL() {
			return _.split(this.bulkURLs, ',');
		},
		documents() {
			let documents;
			try {
				documents = JSON.parse(this.inputJSON.documents);
			} catch (_err) {
				// ignore exception
			}
			return documents;
		},
		canSubmit() {
			return _.isObject(this.documents);
		},
		rulesOptions() {
			return _.map(this.rules, rule => ({ text: rule.name, value: rule.id }));
		},
		headerTitle() {
			return this.$t('New Recipe');
		},
		breadcrumbs() {
			const listRoute = this.$router.resolve({ name: 'recipes.list' });
			return [
				{ text: this.$t('Recipes'), href: listRoute.href },
				{ text: this.headerTitle, href: '#' }
			];
		}
	},
	mounted() {
		this.findScrapingRules();
	},
	methods: {
		...mapActions({
			importFromAPI: 'recipeImport/importFromAPI',
			importFromHTML: 'recipeImport/importFromHTML',
			importFromJSON: 'recipeImport/importFromJSON',
			findScrapingRules: 'scraping-rule/findAll'
		}),
		removeHeader(index) {
			this.requestHeaders.splice(index, 1);
		},
		addHeader() {
			this.requestHeaders.push({ key: '', value: '' });
		},
		handleChange: function(index, prop, value) {
			this.requestHeaders[index][prop] = value;
		},
		checkRecipe(recipe) {
			return (
				!_.isEmpty(recipe) && _.has(recipe, 'name') && _.has(recipe, 'category') && _.has(recipe, 'sourceURL')
			);
		},
		formChanged(inputJSON) {
			this.inputJSON = inputJSON;
		},
		async importRecipeFromAPI() {
			this.result = null;
			this.isRecipeLoading = true;
			const headers = _.filter(this.requestHeaders, item => !!item.key && !!item.value);
			try {
				const result = await this.importFromAPI({
					url: this.url,
					scrapingRuleId: this.scrapingRuleId,
					headers
				});

				this.apiResult = _.map(result, item => ({
					id: item._id,
					record: { ...item.record, _id: item._id }
				}));
				this.apiErrors = _.map(result, 'validationErrors');
			} catch (e) {
				this.getNotification('error', 'Error', _.get(e, 'response.data.message', e.message));
			} finally {
				this.isRecipeLoading = false;
			}
		},
		async importRecipe() {
			this.result = null;
			this.isRecipeLoading = true;

			try {
				const result = await this.importFromHTML({
					url: this.url,
					scrapingRuleId: this.scrapingRuleId
				});

				this.result = {
					id: result._id,
					record: { ...result.record, _id: result._id }
				};
				this.errors = result.validationErrors;
				this.getNotification('success', 'Success', 'Recipe save successfully');
			} catch (e) {
				this.getNotification('error', 'Error', _.get(e, 'response.data.message', e.message));
			}

			this.isRecipeLoading = false;
		},
		openEditor() {
			router.push({ name: 'recipes.edit', params: { id: 'new' } });
		},
		parseError(error) {
			if (_.last(error.instancePath.split('/')) === 'elements') return error.instancePath.split('/')[1] + ' ???';

			return _.last(error.instancePath.split('/')) + ' ???';
		},
		bulkImportRecipe: async function() {
			this.resultArray = [];
			this.isRecipeLoading = true;
			for (const url of this.arrayOfURL) {
				try {
					if (!_.isEmpty(url.trim().replace(/\n/g, ''))) {
						const result = await this.importFromHTML({
							url,
							scrapingRuleId: this.scrapingRuleId
						});

						this.resultArray.push({
							url,
							warnings: result.validationErrors || [],
							error: null
						});
					}
				} catch (e) {
					this.resultArray.push({
						url,
						warnings: [],
						error: e.message
					});
				}
			}

			this.getNotification('success', 'Success', 'Recipes save successfully');

			this.isRecipeLoading = false;
		},
		async importJSON() {
			try {
				const recipesToImport = _.filter(_.castArray(this.documents), this.checkRecipe);
				if (recipesToImport.length) {
					const result = await this.importFromJSON(recipesToImport);
					this.getNotification('success', 'Success', 'Recipes save successfully');
				}
			} catch (e) {
				this.getNotification('error', 'Error', _.get(e, 'response.data.message', e.message));
			}
		},
		getNotification(type, title, text) {
			this.$notify({
				group: 'recipeScraper',
				type,
				title: this.$t(title),
				text: this.$t(text)
			});
		},
		async importFromFile() {
			this.numberOfImportedRecipes = 0;
			const reader = new FileReader();

			reader.onload = async event => {
				try {
					let result = JSON.parse(event.target.result);
					const recipesToImport = _.filter(_.castArray(result), this.checkRecipe);
					if (recipesToImport.length) {
						const result = await this.importFromJSON(recipesToImport);
						this.numberOfImportedRecipes = result.length;
					}
					if (this.numberOfImportedRecipes) {
						this.getNotification('success', 'Success', 'Recipes save successfully');
					} else {
						this.getNotification('error', 'Error', 'No recipes were detected');
					}
				} catch (e) {
					this.getNotification('error', 'Error', e.message);
				}
			};

			reader.readAsText(this.file);
		}
	}
};
</script>

<style lang="scss">
.recipe-warning {
	color: #ebbd34;
}

.recipe-error {
	color: red;
}

.recipe-succes {
	color: green;
}

.recipe-progress {
	overflow: auto;
	max-height: 300px;
	border: 1px solid #d9d9d9;
}

.request-header-remove {
	position: absolute;
	right: 10px;
}

.recipe-delimiter {
	width: 100%;
	border-top: 1px solid gray;
}
</style>
