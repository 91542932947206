<template>
	<div class="FileGridItem" @click="fileClicked">
		<div class="FileGridItem_Thumbnail">
			<img v-if="file.thumbnailUrlData" :src="file.thumbnailUrlData.url" />
			<icon v-else :name="icon" class="FileGridItem_Thumbnail_Placeholder" />
		</div>
		<div class="FileGridItem_Info">
			<p class="FileGridItem_Info_Name">{{ file.name | shorten(25) }}</p>
			<div class="FileGridItem_Info_Metadata">
				<div class="FileGridItem_Info_Metadata_Container">
					<icon :name="icon" size="sm" class="FileGridItem_Info_Metadata_Icon" />
					<div class="FileGridItem_Info_Metadata_Size">{{ size | fileSize }}</div>
				</div>
				<div class="FileGridItem_Info_Metadata_Date">{{ file.createdAt | date }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import filters from './filters';

export default {
	filters: {
		fileSize: filters.fileSize,
		shorten: filters.shorten,
		date: filters.dateTimeFormat
	},
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		file() {
			return this.content;
		},
		size() {
			return _.get(this.file, 'metadata.fileSize', 0);
		},
		icon() {
			switch (this.file.contentType) {
				case 'image/jpeg':
				case 'image/png':
				case 'image/tiff':
					return 'file-image';
				case 'application/pdf':
					return 'file-pdf';
				default:
					return 'file';
			}
		}
	},
	methods: {
		fileClicked() {
			this.$emit('content-clicked', this.content);
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/src/styles/variables.scss';
@import '~@oneflow/ofs-vue-layout/src/styles/mixins.scss';

.FileGridItem {
	border: 1px solid $of-color-grey-3;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	height: 182px;
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 0 3px rgba(red($of-color-aqua), green($of-color-aqua), blue($of-color-aqua), 0.5);
	}

	&:focus {
		box-shadow: 0 0 0 3px rgba(red($of-color-aqua), green($of-color-aqua), blue($of-color-aqua), 0.5);
		outline: none;
	}

	&_Thumbnail {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 120px;
		background-color: $of-color-base;
		text-align: center;

		img {
			object-fit: contain;
			height: 100%;
			max-width: 90%;
		}

		&_Placeholder {
			width: 50%;
			height: 50%;

			path {
				fill: $of-color-grey-2;
			}
		}
	}

	&_Info {
		height: 60px;
		display: flex;
		flex-direction: column;
		padding: 11px 14px 11px 14px;

		&_Name {
			margin: 0;
		}

		&_Metadata {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&_Size,
			&_Date,
			&_Icon {
				@include ofTextStyleFormLabels();
			}

			&_Icon {
				margin-right: 5px;
			}

			&_Container {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}
	}
}
</style>
