var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('Page',[_c('ListTable',{attrs:{"bordered":"","hover":"","table-title":_vm.$t('Books'),"config":_vm.config,"items":_vm.productContents.data,"fields":_vm.fields,"sort":_vm.sort,"total-items":_vm.productContents.total,"current-page":_vm.currentPage,"per-page":_vm.perPage,"fetch-data":_vm.fetchData,"is-busy":_vm.isProductContentsFetching},on:{"row-clicked":_vm.handleRowClick,"table-change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"TableButtons-Slot-left",fn:function(ref){return [_c('SearchInput',{staticClass:"mr-2",attrs:{"placeholder":_vm.$t('Search books by name...')},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('OfInlineFilter',{staticClass:"mr-2",attrs:{"filters":_vm.filters,"values":_vm.filterValues},on:{"change":_vm.filtersChanged}})]}},{key:"TableButtons-Slot-right",fn:function(ref){return [_c('div',{staticClass:"TableButtons-Right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.handleAdd}},[_vm._v(_vm._s(_vm.$t('New book')))])],1)]}},{key:"TableHeader",fn:function(ref){return [_c('OfFilterBar',{attrs:{"filters":_vm.filters,"values":_vm.filterValues},on:{"change":_vm.filtersChanged}})]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product ? _vm.getBookType(item.product.formats) : '')+" ")]}},{key:"cell(documents)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDocumentsCount(item))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('OfsBadge',{attrs:{"text":item.status,"status":item.status}})]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fromNow(item.updatedAt))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","disabled":!_vm.enableBookPreview(item)},on:{"click":function($event){$event.stopPropagation();return _vm.redirectToBookPreviewPage(item._id)}}},[_vm._v(" "+_vm._s(_vm.$t('Preview'))+" ")]),_c('b-dropdown',{attrs:{"size":"sm","toggle-class":"p-1","right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('icon',{attrs:{"name":"ellipsis-h"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(item._id)}}},[_c('icon',{staticClass:"mr-2",attrs:{"name":"trash"}}),_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }