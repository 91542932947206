module.exports = {
	name: 'Title page',
	exampleProps: {
		bookSettings: {
			title: 'FLAVOURS OF MEXICO CITY'
		}
	},
	template: [
		{ type: 'page' },
		{
			name: 'Title page',
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } },
				display: 'flex',
				justifyContent: 'center'
			},
			children: [
				{
					type: 'container',
					props: {
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						width: '60%'
					},
					children: [
						{
							name: 'Title top text',
							type: 'text',
							props: {
								value: { '?': { toUpper: { var: '$props.subtitle1' } } },
								fontFamily: 'GothamBook',
								fontSize: '30px',
								letterSpacing: '3px'
							}
						},
						{
							type: 'divider',
							props: {
								width: '70%',
								height: '1px',
								backgroundColor: 'black',
								margin: '15px auto'
							}
						},
						{
							name: 'Title',
							type: 'text',
							props: {
								value: { '?': { var: '$props.title' } },
								fontFamily: 'FlowersScript',
								fontSize: '120px',
								textAlign: 'center',
								lineHeight: '0.9',
								fontWeight: '500'
							}
						},
						{
							type: 'divider',
							props: {
								width: '70%',
								height: '1px',
								backgroundColor: 'black',
								margin: '15px auto'
							}
						},
						{
							name: 'Title bottom text',
							type: 'text',
							props: {
								value: { '?': { toUpper: { var: '$props.subtitle2' } } },
								fontFamily: 'GothamBook',
								fontSize: '30px',
								letterSpacing: '3px'
							}
						}
					]
				}
			]
		}
	]
};
