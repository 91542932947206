<template>
	<div class="ItemSize">
		{{ shortSize }}
	</div>
</template>

<script>
import { documentSizes } from '../constants';

export default {
	props: {
		size: {
			type: String,
			default: documentSizes.STANDARD
		}
	},
	computed: {
		shortSize() {
			switch (this.size) {
				case documentSizes.SHORT:
				case documentSizes.STANDARD:
					return 'S';
				case documentSizes.STANDARD_WITH_IMAGE:
					return 'M';
				case documentSizes.LONG:
					return 'L';
				default:
					return 'M';
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../style/variables.scss';

.ItemSize {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	font-weight: 700;
	width: 23px;
	height: 23px;
	border-radius: 6px;
	font-size: 13px;
	background-color: $grey-bg-color;
}
</style>
