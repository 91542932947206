import { totalSectionSize } from '@/lib/validators';

export const initialFormData = {
	content: {
		cover: {},
		tableOfContents: {
			title: 'Contents'
		},
		title: {},
		introduction: {
			headline: 'Introduction',
			paragraphs: ['']
		},
		acknowledgements: {
			headline: 'Acknowledgements',
			paragraphs: ['']
		},
		includeOpeningPage: false,
		chapters: []
	}
};

export const predefinedSections = {
	Covers: 'covers',
	Title: 'title',
	Introduction: 'introduction',
	TableOfContents: 'tableOfContents',
	NarrativeSection: 'narrativeSection',
	Chapters: 'chapters',
	Notes: 'notes',
	Acknowledgements: 'acknowledgements'
};

export const productContentFormName = 'productContentEdit';

export const fieldType = {
	Text: 'text',
	Textarea: 'textarea',
	Image: 'image',
	Checkbox: 'checkbox',
	ButtonGroup: 'button-group',
	MultiSelect: 'multi-select'
};

/* JSON Schema validators format */
export const validationRules = {
	MinLength: 'minLength',
	MaxLength: 'maxLength',
	MaxDocuments: 'maxDocuments',
	Required: 'required',
	MinImageSize: 'minImageSize',
	ImageAspectRatio: 'imageAspectRatio',
	TotalSectionSize: 'totalSectionSize'
};

export const blockTypes = {
	SELECT: 'SELECT',
	CHECKBOX: 'CHECKBOX',
	RADIOBUTTON: 'RADIOBUTTON',
	COLORPICKER: 'COLORPICKER'
};

export const blockAvailability = {
	Common: 'common',
	Studio: 'studio',
	Mobile: 'mobile'
};

export const templateTypes = {
	content: 'content',
	cover: 'cover'
};
