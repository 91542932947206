import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'scraping-rule',
		pluralName: 'scraping-rules',
		transport: 'davinci',
		options: {
			namespaced: true
		}
	});
}
