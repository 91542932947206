<template>
	<Layout>
		<OfsPanel>
			<b-form-group label="Template">
				<b-select :value="selectedTemplateName" :options="templateOptions" @change="handleTemplateChange" />
			</b-form-group>

			<b-row>
				<b-col cols="3">
					<b-form-group v-if="templateVariants.length" label="Variants">
						<div v-for="variantName in templateVariants" :key="variantName" class="d-inline-block mr-2">
							<b-button
								class="font-weight-bold"
								:variant="variantName === selectedTemplateVariantName ? 'primary' : 'outline-primary'"
								size="lg"
								@click="() => handleTemplateVariantChange(variantName)"
							>
								{{ variantName }}
							</b-button>
						</div>
					</b-form-group>
				</b-col>
				<b-col cols="3">
					<b-form-group label="Covers">
						<div v-for="{ label, value } in coverVariants" :key="value" class="d-inline-block mr-2">
							<b-button
								class="font-weight-bold"
								:variant="value === selectedCoverType ? 'primary' : 'outline-primary'"
								size="lg"
								@click="() => handleCoverTypeChange(value)"
							>
								{{ label }}
							</b-button>
						</div>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<template v-for="(_, pageIndex) in json.length">
					<b-col
						v-if="json[pageIndex].meta.productComponent === 'cover'"
						:key="pageIndex"
						cols="12"
						class="overflow-hidden p-4"
					>
						<div class="shadow">
							<PagePreview :json="json[pageIndex]" :fonts="selectedTemplate.fonts" is-cover />
						</div>
					</b-col>
					<b-col v-else :key="pageIndex" cols="6" class="overflow-hidden p-4">
						<Intersect v-slot="{ shouldRender }">
							<div class="responsive-square shadow">
								<PagePreview
									v-if="shouldRender"
									:json="json[pageIndex]"
									:fonts="selectedTemplate.fonts"
								/>
							</div>
						</Intersect>
					</b-col>
				</template>
			</b-row>
		</OfsPanel>
	</Layout>
</template>

<script>
import { OfsPanel } from '@oneflow/ofs-vue-layout';
import { BookTemplateProcessor } from '@custom-content-technology/book-template-processor';
import Layout from '../../components/Layout';
import templates from './templates';
import PagePreview from '../../components/PagePreview';
import Intersect from '../../components/Intersect';

export default {
	components: {
		OfsPanel,
		Layout,
		PagePreview,
		Intersect
	},
	data() {
		return {
			selectedTemplateName: 'leaf',
			selectedTemplateVariantName: null,
			selectedCoverType: 'coverTwoPages'
		};
	},
	computed: {
		templates: () => templates,
		templateOptions: () => templates.map(t => ({ text: t.name, value: t.name })),
		selectedTemplate() {
			return templates.find(t => t.name === this.selectedTemplateName);
		},
		templateVariants() {
			return Object.keys(this.selectedTemplate?.theme).filter(theme => theme !== 'base');
		},
		coverVariants() {
			return [
				{ label: 'Cover with spine', value: 'coverWithSpine' },
				{ label: 'Cover without spine', value: 'coverTwoPages' }
			];
		},
		json() {
			if (!this.selectedTemplate) return {};

			const theme = _.merge(
				{},
				this.selectedTemplate.theme.base,
				this.selectedTemplate.theme[this.selectedTemplateVariantName]
			);
			const data = {
				$theme: theme,
				$book: this.selectedTemplate.data,
				$props: {
					withNotes: true,
					coverWithSpine: this.selectedCoverType === 'coverWithSpine'
				}
			};

			const bookTemplateProcessor = new BookTemplateProcessor();
			bookTemplateProcessor.registerBlocks(this.selectedTemplate.blocks);
			return bookTemplateProcessor.process(this.selectedTemplate.layout, data);
		}
	},
	watch: {
		selectedTemplate: {
			immediate: true,
			handler() {
				this.selectedTemplateVariantName = this.templateVariants?.[0];
			}
		}
	},
	methods: {
		handleTemplateChange(templateName) {
			this.selectedTemplateName = templateName;
		},
		handleTemplateVariantChange(variantName) {
			this.selectedTemplateVariantName = variantName;
		},
		handleCoverTypeChange(coverType) {
			this.selectedCoverType = coverType;
		}
	}
};
</script>

<style lang="scss">
.responsive-square {
	position: relative;
	background: whitesmoke;
	&:before {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	& > * {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
</style>
