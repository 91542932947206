module.exports = {
    "name": "Cover Front",
    "template": [{
        "name": "Cover Front Page",
        "type": "container",
        "props": {
            "width": {
                "?": {
                    "var": "$theme.coverPageWidth"
                }
            },
            "height": {
                "?": {
                    "var": "$theme.coverPageHeight"
                }
            }
        },
        "children": [{
            "name": "Background image",
            "type": "image",
            "props": {
                "position": "absolute",
                "width": "100%",
                "height": "99.9%",
                "objectFit": "cover",
                "zIndex": "10",
                "src": {
                    "?": {
                        "firstValid": [{
                            "var": "$props.imageUrl"
                        }, {
                            "var": "$theme.cover.front.defaultBackgroundImage"
                        }]
                    }
                }
            }
        }, {
            "name": "Outer",
            "type": "container",
            "props": {
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
                "padding": "11px",
                "height": {
                    "?": {
                        "var": "$theme.coverPageHeight"
                    }
                }
            },
            "children": [{
                "name": "Inner",
                "type": "container",
                "children": [{
                    "name": "Title Container",
                    "type": "container",
                    "props": {
                        "position": "relative",
                        "width": {
                            "?": {
                                "var": "$theme.cover.titleContainerSize"
                            }
                        },
                        "height": {
                            "?": {
                                "var": "$theme.cover.titleContainerSize"
                            }
                        },
                        "outline": {
                            "?": {
                                "cat": ["2px solid ", {
                                    "firstValid": [{
                                        "var": "$theme.cover.front.outlineColor"
                                    }, "transparent"]
                                }]
                            }
                        },
                        "outlineOffset": "20px",
                        "display": "flex",
                        "justifyContent": "center",
                        "alignItems": "center",
                        "borderRadius": {
                            "?": {
                                "if": [{
                                    "var": "$theme.cover.roundContainer"
                                }, "50%", 0]
                            }
                        },
                        "zIndex": 10,
                        "backgroundColor": {
                            "?": {
                                "cond": [
                                    [{
                                        "!": {
                                            "firstValid": [{
                                                "var": "$theme.cover.front.textBackgroundImage"
                                            }, {
                                                "var": "$theme.cover.textBackgroundImage"
                                            }]
                                        }
                                    }, {
                                        "firstValid": [{
                                            "var": "$theme.cover.front.textBackgroundColor"
                                        }, {
                                            "var": "$theme.cover.textBackgroundColor"
                                        }]
                                    }]
                                ]
                            }
                        }
                    },
                    "children": [{
                        "name": "Background image",
                        "type": "image",
                        "props": {
                            "position": "absolute",
                            "width": "100%",
                            "height": "99.9%",
                            "objectFit": "cover",
                            "zIndex": "10",
                            "src": {
                                "?": {
                                    "firstValid": [{
                                        "var": "$theme.cover.front.textBackgroundImage"
                                    }, {
                                        "var": "$theme.cover.textBackgroundImage"
                                    }]
                                }
                            },
                            "visible": {
                                "?": {
                                    "firstValid": [{
                                        "var": "$theme.cover.front.textBackgroundImage"
                                    }, {
                                        "var": "$theme.cover.textBackgroundImage"
                                    }]
                                }
                            }
                        }
                    }, {
                        "type": "container",
                        "props": {
                            "zIndex": "20",
                            "display": "flex",
                            "flexDirection": "column",
                            "justifyContent": "space",
                            "alignItems": "center"
                        },
                        "children": [{
                            "name": "Title top text",
                            "type": "text",
                            "props": {
                                "value": {
                                    "?": {
                                        "toUpper": {
                                            "var": "$props.subtitle1"
                                        }
                                    }
                                },
                                "fontFamily": {
                                    "?": {
                                        "var": "$theme.cover.front.subtitleFont"
                                    }
                                },
                                "color": {
                                    "?": {
                                        "var": "$theme.cover.front.textColor"
                                    }
                                },
                                "fontSize": "23.5pt",
                                "lineHeight": "120%",
                                "letterSpacing": "0.075em",
                                "textTransform": "uppercase",
                                "marginBottom": "20px"
                            }
                        }, {
                            "type": "divider",
                            "props": {
                                "border": "none",
                                "borderBottom": {
                                    "?": {
                                        "cat": ["1.5px solid ", {
                                            "var": "$theme.cover.front.dividersColor"
                                        }]
                                    }
                                },
                                "width": "350px",
                                "margin": "0",
                                "visible": {
                                    "?": {
                                        "var": "$theme.cover.front.hasDividers"
                                    }
                                }
                            }
                        }, {
                            "name": "Title",
                            "type": "text",
                            "props": {
                                "?": {
                                    "mergeObjects": [{
                                        "value": {
                                            "?": {
                                                "var": "$props.title"
                                            }
                                        },
                                        "fontFamily": {
                                            "?": {
                                                "var": "$theme.cover.titleFont"
                                            }
                                        },
                                        "color": {
                                            "?": {
                                                "var": "$theme.cover.front.textColor"
                                            }
                                        },
                                        "textAlign": "center"
                                    }, {
                                        "var": "$theme.cover.front.title"
                                    }]
                                }
                            }
                        }, {
                            "type": "divider",
                            "props": {
                                "border": "none",
                                "borderBottom": {
                                    "?": {
                                        "cat": ["1.5px solid ", {
                                            "var": "$theme.cover.front.dividersColor"
                                        }]
                                    }
                                },
                                "width": "350px",
                                "margin": "0",
                                "visible": {
                                    "?": {
                                        "var": "$theme.cover.front.hasDividers"
                                    }
                                }
                            }
                        }, {
                            "name": "Title bottom text",
                            "type": "text",
                            "props": {
                                "visible": {
                                    "?": {
                                        "var": "$theme.cover.front.hasSubtitle2"
                                    }
                                },
                                "value": {
                                    "?": {
                                        "toUpper": {
                                            "var": "$props.subtitle2"
                                        }
                                    }
                                },
                                "fontFamily": {
                                    "?": {
                                        "var": "$theme.cover.front.subtitleFont"
                                    }
                                },
                                "color": {
                                    "?": {
                                        "var": "$theme.cover.front.textColor"
                                    }
                                },
                                "fontSize": "23.5pt",
                                "lineHeight": "120%",
                                "letterSpacing": "0.075em",
                                "textTransform": "uppercase",
                                "marginTop": "20px"
                            }
                        }, {
                            "name": "Customer Logo",
                            "type": "image",
                            "props": {
                                "width": "80px",
                                "height": "80px",
                                "src": {
                                    "?": {
                                        "var": "$theme.cover.front.customerLogo"
                                    }
                                },
                                "visible": {
                                    "?": {
                                        "var": "$theme.cover.front.customerLogo"
                                    }
                                }
                            }
                        }]
                    }]
                }]
            }]
        }]
    }]
}