import ScrapingRuleEdit from './ScrapingRules/ScrapingRuleEdit';
import ScrapingRulesList from './ScrapingRules/ScrapingRulesList';

const routes = [
	{
		name: 'scraping-rules.edit',
		path: '/scraping-rules/:id',
		component: ScrapingRuleEdit
	},
	{
		name: 'scraping-rules.list',
		path: '/scraping-rules',
		component: ScrapingRulesList
	}
];

export default routes;
