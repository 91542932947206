import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'dataImport',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		state: {
			apiResponse: null,
			htmlResponse: null,
			jsonResponse: null,
			bookResponse: null
		},
		getters: {
			apiResponse: state => state.recipeApiResponse,
			htmlResponse: state => state.recipeHtmlResponse,
			jsonResponse: state => state.recipeJsonResponse,
			bookResponse: state => state.bookApiResponse
		},
		actions: {
			importRecipeFromAPI: async ({ dispatch, commit }, { url, scrapingRuleId, headers }) => {
				const payload = {
					url,
					scrapingRuleId,
					headers
				};
				const response = await dispatch('request', { method: 'POST', path: 'dataImport/recipe-api', payload });
				commit('SET_API_RESPONSE', response.data);
				return response.data;
			},
			importRecipeFromHTML: async ({ dispatch, commit }, { url, scrapingRuleId }) => {
				const payload = {
					url,
					scrapingRuleId
				};
				const response = await dispatch('request', { method: 'POST', path: 'dataImport/recipe-html', payload });
				commit('SET_HTML_RESPONSE', response.data);
				return response.data;
			},
			importRecipeFromJSON: async ({ dispatch, commit }, recipes) => {
				const payload = { recipes };
				const response = await dispatch('request', { method: 'POST', path: 'dataImport/recipe-json', payload });
				commit('SET_JSON_RESPONSE', response.data);
				return response.data;
			},
			importBookFromAPI: async (
				{ dispatch, commit },
				{ url, scrapingRuleId, headers, recipesScrapingRuleId, productContentId }
			) => {
				const payload = {
					url,
					scrapingRuleId,
					headers,
					recipesScrapingRuleId,
					productContentId
				};
				const response = await dispatch('request', { method: 'POST', path: 'dataImport/book-api', payload });
				commit('SET_BOOK_RESPONSE', response.data);
				return response.data;
			}
		},
		mutations: {
			SET_API_RESPONSE: (state, response) => {
				state.recipeApiResponse = response;
			},
			SET_HTML_RESPONSE: (state, response) => {
				state.recipeHtmlResponse = response;
			},
			SET_JSON_RESPONSE: (state, response) => {
				state.recipeJsonResponse = response;
			},
			SET_BOOK_RESPONSE: (state, response) => {
				state.bookApiResponse = response;
			}
		}
	});
}
