module.exports = {
	name: 'Page Number',
	template: [
		{
			type: 'text',
			props: {
				value: { '?': { var: 'pageNumber' } },
				position: 'absolute',
				bottom: '30px',
				right: '38px',
				fontSize: '10px',
				fontFamily: 'Open Sans',
				fontWeight: 'bold'
			}
		}
	]
};
