module.exports = {
	base: {
		pageWidth: "794px",
		pageHeight: "794px",
		pagePadding: "11px",
		coverFullWidth: "1761px",
		coverPageWidth: "833px",
		coverPageHeight: "794px",
		coverSpineWidth: "94px"
	},
	variant1: {
		cover: {
			textBackgroundImage:
				'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/f23b0217-d0ba-415f-8d21-8a9d81c70150?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1942225639&Signature=g1QQS4PDgJABmXh%2F0VueV%2BRieL4%3D&response-content-disposition=attachment%3B%20filename%3D%22circle-bg%20%281%29.png%22',
			titleFont: 'MachoMedium',
			titleContainerSize: '555px',
			front: {
				title: {
					fontSize: '75.5pt',
					textAlign: 'center',
					lineHeight: '115%'
				},
				hasSubtitle2: true,
				subtitleFont: 'GothamCondensedBook',
				roundContainer: true,
				hasDividers: true,
				dividersColor: 'rgb(96, 123, 172)',
				textColor: 'rgb(58, 63, 81)',
				defaultBackgroundImage:
					'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/bfac533d-581b-4d07-b849-424c0afea9d5?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1942236413&Signature=mfSkSJHeXNBkZVOh68E76NJvO3c%3D&response-content-disposition=attachment%3B%20filename%3D%22leaf1-back-resize.jpg%22'
			},
			back: {
				hasTitle: false,
				textFont: 'PoppinsLight',
				backgroundColor: '#607bac'
			},
			spine: {
				backgroundColor: '#607bac',
				textColor: 'white'
			}
		}
	},
	variant2: {
		cover: {
			titleFont: 'AshemoreNormLight',
			titleContainerSize: '530px',
			front: {
				title: {
					fontSize: '60.5pt',
					lineHeight: '105%',
					textTransform: 'uppercase',
					margin: '30px 0'
				},
				hasSubtitle2: true,
				subtitleFont: 'GothamCondensedBook',
				roundContainer: true,
				hasDividers: true,
				dividersColor: '#df1683',
				textBackgroundColor: 'rgba(139, 30, 65, .8)',
				textColor: 'white',
				defaultBackgroundImage:
					'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/9f236f58-920e-438c-bdf1-864306b5f886?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941201946&Signature=yX1t2IM6NLJrq%2F%2B88LRCSG6HTEU%3D&response-content-disposition=attachment%3B%20filename%3D%22IMG_5679.JPG%22'
			},
			back: {
				hasTitle: true,
				textFont: 'PoppinsLight',
				titleColor: '#df1683',
				textBackgroundColor: '#fae5f1',
				backgroundColor: '#8b1e41'
			},
			spine: {
				backgroundColor: '#8b1e41',
				textColor: 'white'
			}
		}
	},
	variant3: {
		cover: {
			titleFont: 'AzoSansThin',
			titleContainerSize: '430px',
			front: {
				title: {
					fontSize: '70pt',
					textAlign: 'center',
					lineHeight: '90%',
					margin: '30px 0',
					textTransform: 'uppercase'
				},
				subtitleFont: 'GothamCondensedMedium',
				hasDividers: false,
				outlineColor: 'white',
				dividersColor: '#df1683',
				textBackgroundColor: 'white',
				textColor: 'black',
				defaultBackgroundImage:
					'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/3f14698d-f3bc-44ab-9cc0-ca19262c2af6?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941202463&Signature=n3O4qE5Uop0xANSqv8gSpgx8tEQ%3D&response-content-disposition=attachment%3B%20filename%3D%22shutterstock_737570047.jpg%22',
				customerLogo:
					'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/c85c9a2b-2ffa-4cfc-bad1-ab225431c60b?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941202475&Signature=le5UUsca1M9jrVVjcwbQHkSx35g%3D&response-content-disposition=attachment%3B%20filename%3D%22hands-icon-green.svg%22'
			},
			back: {
				hasTitle: false,
				textFont: 'PoppinsMedium',
				backgroundColor: '#60dbc7',
				textColor: 'white',
				customerLogo:
					'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/3b61b0f8-55bc-4ca8-aae0-755908756615?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941202386&Signature=e2KJRTDAzBHXmSCyhMTX3JLWNyo%3D&response-content-disposition=attachment%3B%20filename%3D%22hands-icon-white.svg%22'
			},
			spine: {
				backgroundColor: '#60dbc7',
				textColor: 'white'
			}
		}
	}
};
