module.exports = [
	{
		fontFamily: 'AshemoreNormMedium',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/50d86f92-5a60-43fb-a291-96572d9c0bbd?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941293732&Signature=cMd2IO7P3noeJh5KCrXPEjZ6RcI%3D&response-content-disposition=attachment%3B%20filename%3D%22Ashemore-NormMedium.ttf%22'
	},
	{
		fontFamily: 'AshemoreNormBold',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/f2874b65-c50d-44a0-9144-397a76b28d20?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941293721&Signature=%2FKm2fh2gw4pBhOYy%2F67WA%2BdCL2E%3D&response-content-disposition=attachment%3B%20filename%3D%22Ashemore-NormBold.ttf%22'
	},
	{
		fontFamily: 'StrenuousCast',
		url: 'https://oneflow-file-core-api-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/5dae7f97-8793-43c3-8401-a144da2a060a?AWSAccessKeyId=AKIAJFYOLW5OEPEGHEYA&Expires=1941293742&Signature=Qcn1KFFP172ibHXgxOkNNxCzQfk%3D&response-content-disposition=attachment%3B%20filename%3D%22strenuous-cast.otf%22'
	},
	{
		fontFamily: 'OpenSans-Light',
		url: 'https://oneflow-file-core-api-dev-new-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/8a6cc575-77af-4023-bf0a-a37cc6c51ac8?AWSAccessKeyId=AKIAJXKUBB3FVPIZFSRA&Expires=1939907784&Signature=cSfKkecNTwj%2BuknLUp8M%2Bak0KvY%3D&response-content-disposition=attachment%3B%20filename%3D%22OpenSans-Light.ttf%22'
	},
	{
		fontFamily: 'Poppins',
		url: 'https://oneflow-file-core-api-dev-new-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/52c323b6-366c-44e4-81d9-31e7df1bd6ca?AWSAccessKeyId=AKIAJXKUBB3FVPIZFSRA&Expires=1940162980&Signature=kRogiD%2BQaJ%2FNmNzHocmy%2FXr7hy8%3D&response-content-disposition=attachment%3B%20filename%3D%22Poppins-ExtraLight.ttf%22'
	},
	{
		fontFamily: 'Poppins-bold',
		url: 'https://oneflow-file-core-api-dev-new-eu-west-1.s3.eu-west-1.amazonaws.com/53342e7b1011064844000168/8ccb5f1e-b579-48fa-bd1e-69e034e8ac09?AWSAccessKeyId=AKIAJXKUBB3FVPIZFSRA&Expires=1940163330&Signature=cNhcBnNf6SpK23uX8lefN5RdFm0%3D&response-content-disposition=attachment%3B%20filename%3D%22Poppins-Bold.ttf%22'
	}
];
