<!-- eslint-disable max-len -->
<template>
	<b-col md="12" mb="0">
		<b-alert show dismissible variant="warning">
			{{
				$t(
					'You can not change the layout of the book, because the new version with separate book parts is available'
				)
			}}
		</b-alert>
	</b-col>
</template>

<script>
export default {
	name: 'BookAppearanceAlert'
};
</script>
