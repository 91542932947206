<template>
	<b-tab>
		<template #title>
			{{ $t('Content') }}
			<Badge v-if="isValidationAlertShown" variant="warning" class="ml-1">!</Badge>
		</template>
		<WarningAlert v-if="isValidationAlertShown">
			<div class="d-flex align-items-center">{{ $t('To save please resolve all alerts.') }}</div>
		</WarningAlert>
		<WarningAlert v-for="error in globalValidationErrors" :key="`${error.key}-${error.name}`">
			{{ error.errorMessage }}
		</WarningAlert>
		<WarningAlert v-for="error in narrativeSectionErrors" :key="`${error.key}-${error.path}`">
			{{ error.errorMessage }}
		</WarningAlert>
		<SelectItemsModal
			:visible="isSelectItemsModalVisible"
			:selected-ids="bookDocumentsIds"
			:contributor-ids="contributorIds"
			:supported-types="supportedTypes"
			@input="onAddDocuments"
			@hidden="closeSelectItemsModal"
		/>

		<ItemPreviewOverlay
			:is-visible="!!activeRecipeId"
			:recipe="bookDocuments[activeRecipeId]"
			:is-content-tab="true"
			@hide="activeRecipeId = null"
		/>
		<b-tabs :value="activeSectionIndex" class="SectionTabs" vertical lazy>
			<template #tabs-start>
				<Section :title="$t('Sections')" class="mb-3">
					<template #actions>
						<b-dropdown size="sm" toggle-class="AddTabBtn" right no-caret>
							<template #button-content> <icon name="plus" scale="0.8" /> {{ $t('Add') }} </template>
							<b-dropdown-item @click.stop="addSection('chapters')">
								{{ $t('Chapter of items') }}
							</b-dropdown-item>
							<b-dropdown-item v-if="!notesAdded" @click.stop="addSection('notes')">
								{{ $t('Notes') }}
							</b-dropdown-item>
							<b-dropdown-item
								v-if="!isExistNarrativeSection"
								@click.stop="addSection('narrativeSection')"
							>
								{{ $t('Narrative Section') }}
							</b-dropdown-item>
							<b-dropdown-item
								v-for="additionalSection in additionalSections"
								:key="additionalSection.key"
								@click.stop="addSection(additionalSection.key)"
							>
								{{ additionalSection.name }}
							</b-dropdown-item>
						</b-dropdown>
					</template>
				</Section>

				<div v-for="section in rootSections" :key="section.key">
					<TabsNavItem
						v-if="!_isArray(section)"
						:title="section.name"
						:active="activeSectionIndex === section.index"
						:invalid-fields-number="_size(getSectionValidationErrors(section, rootValidationErrors))"
						:disabled="_isEmpty(section.sections)"
						:required="!section.dynamic"
						@click="activeSectionIndex = section.index"
						@remove="removeRootSection(section.index)"
					/>
					<draggable
						v-else
						:value="section"
						handler=".drag-handle"
						@input="moveSection"
						@change="onSectionsChanged"
					>
						<TabsNavItem
							v-for="subsection in section"
							:key="subsection.key"
							:title="subsection.name"
							:disabled="_isEmpty(subsection.sections)"
							:active="activeSectionIndex === subsection.index"
							:invalid-fields-number="_size(getSectionValidationErrors(subsection, rootValidationErrors))"
							@click="activeSectionIndex = subsection.index"
							@remove="removeChildSection(subsection.index)"
						/>
					</draggable>
				</div>
			</template>

			<b-tab v-for="section in sections" :key="section.index" :title="section.name" title-item-class="d-none">
				<div v-for="(subsection, index) in section.sections" :key="index" class="mb-3">
					<Section
						:title="subsection.name || section.name"
						class="mb-3"
						:name="getSectionNameKey(section, subsection)"
					/>
					<template v-if="section.key === 'introduction' && index === 0">
						<OfFormCheckbox
							v-model="includePersonalisedMessage"
							right-side-label="Include personalised message"
							without-label
						/>
					</template>
					<b-row v-if="!_isEmpty(getActiveSectionFields(subsection.fields, formData))">
						<template v-for="(field, fIndex) in getActiveSectionFields(subsection.fields, formData)">
							<SectionField
								v-if="!(field.key === 'name' && subsection.editable)"
								:key="field.key"
								:field="field"
								:path="buildSectionFieldPath(field, section, subsection)"
								:upload-path="uploadPath"
							/>
							<NarrativeSectionParagraphs
								v-if="field.key === 'sections'"
								:key="field.key + fIndex"
								:sections="
									_get(formData, `${buildSectionFieldPath(field, section, subsection)}.sections`)
								"
								:path="buildSectionFieldPath(field, section, subsection)"
								@sections="
									e => handleUpdateSection(buildSectionFieldPath(field, section, subsection), e)
								"
								@paragraphs="
									e =>
										handleUpdateSectionParagraphs(
											buildSectionFieldPath(field, section, subsection),
											e
										)
								"
							/>
						</template>
					</b-row>
				</div>
				<template v-if="isRecipesSection(section)">
					<Section
						:title="$t('Items')"
						:label="_get(formData.content, `${section.key}.documentIds.length`)"
						class="mb-2 mt-4"
					>
						<template #actions>
							<b-button
								variant="primary"
								size="sm"
								class="d-flex align-items-center"
								@click="openSelectItemsModal"
							>
								<icon name="plus" class="mr-1" />{{ $t('Add Item') }}
							</b-button>
						</template>
					</Section>
					<ChapterItems
						:document-ids="_get(formData.content, `${section.key}.documentIds`)"
						:documents="bookDocuments"
						:active-section-index="activeSectionIndex"
						:section-index="section.index"
						@add="openSelectItemsModal"
						@view="activeRecipeId = $event"
						@input="onUpdateSectionDocuments(section.localIndex, $event)"
					/>
				</template>
			</b-tab>
		</b-tabs>
	</b-tab>
</template>

<script>
import { OfFormCheckbox, withForm } from '@oneflow/ofs-vue-layout';
import Badge from '../../../../components/Badge';
import WarningAlert from '../../../../components/WarningAlert.vue';
import { buildSectionFieldPath, getActiveSectionFields } from './helpers';
import _ from 'lodash';
import { getSectionValidationErrors, SectionField, TabsNavItem } from '../../../../components/BookEdit';
import Draggable from 'vuedraggable';
import { initialFormData, predefinedSections, productContentFormName } from './constants';
import { productType } from '@/constants';
import SelectItemsModal from '../SelectItemsModal.vue';
import ItemPreviewOverlay from '../../../../components/ItemPreview/ItemPreviewOverlay.vue';
import ChapterItems from '../ChapterItems.vue';
import NarrativeSectionParagraphs from '../NarrativeSections/NarrativeSectionParagraphs.vue';
import Section from '../../../../components/Section.vue';

const predefinedSectionsKeys = _.values(predefinedSections);

export default {
	components: {
		Badge,
		WarningAlert,
		SectionField,
		TabsNavItem,
		Draggable,
		SelectItemsModal,
		ItemPreviewOverlay,
		OfFormCheckbox,
		ChapterItems,
		NarrativeSectionParagraphs,
		Section
	},
	mixins: [withForm(productContentFormName, { preserveDataOnMount: true })],
	props: {
		isValidationAlertShown: { type: Boolean, default: false },
		globalValidationErrors: { type: Array, default: () => [] },
		rootValidationErrors: { type: Array, default: () => [] },
		activeSectionsConfig: { type: Array, default: () => [] },
		sections: { type: Array, default: () => [] },
		rootSections: { type: Array, default: () => [] },
		productContentId: { type: [String, null], default: null },
		contributorIds: { type: Array, default: () => [] },
		bookDocuments: { type: Object, default: () => ({}) },
		sectionsConfig: { type: Array, default: () => [] },
		supportedTypes: { type: Array, default: () => [] },
		$v: { type: Object, default: () => ({}) }
	},
	data: () => ({
		activeSectionIndex: 0,
		isSelectItemsModalVisible: false,
		activeRecipeId: null
	}),
	computed: {
		notesAdded() {
			return _.get(this.formData, 'content.notes');
		},
		isExistNarrativeSection() {
			return _.get(this.formData, 'content.narrativeSection');
		},
		includePersonalisedMessage: {
			get() {
				return _.get(this.formData, 'product.type') === productType.Personalised;
			},
			set(include) {
				this.updateField('product.type', include ? productType.Personalised : productType.Standard);
			}
		},
		uploadPath() {
			return this.productContentId ? `pimienta/books/${this.productContentId}` : null;
		},
		bookDocumentsIds() {
			return _.keys(this.bookDocuments);
		},
		narrativeSectionErrors() {
			return this.rootValidationErrors.filter(
				item => item?.path?.includes('content.narrativeSection.sections') && item?.$v?.$model
			);
		},
		additionalSections() {
			return _.filter(this.sectionsConfig, section => !predefinedSectionsKeys.includes(section.key));
		},
		recipesSectionsRootKeys() {
			const sectionsWithRecipes = _.filter(this.sections, this.isRecipesSection);
			return _.uniq(_.map(sectionsWithRecipes, 'rootKey'));
		},
		allDocumentIds() {
			return _.flatMap(this.recipesSectionsRootKeys, rootSectionKey => {
				const sections = _.get(this.formData, `content.${rootSectionKey}`, []);
				return _.flatMap(sections, 'documentIds');
			});
		}
	},
	watch: {
		sections() {
			this.$emit('update-documents', this.allDocumentIds);
		}
	},
	beforeCreate() {
		delete this.$options.computed.$v;
	},
	methods: {
		_get: _.get,
		_isArray: _.isArray,
		_isEmpty: _.isEmpty,
		_size: _.size,
		getSectionValidationErrors,
		getActiveSectionFields,
		buildSectionFieldPath,
		addSection(sectionKey) {
			const section = _.find(this.sectionsConfig, ['key', sectionKey]);
			const path = `content.${section.key}`;

			if (section.flatten) {
				this.addChildSection(path, section);
			} else if (section.dynamic) {
				this.addRootSection(path, sectionKey);
			}
		},
		addChildSection(path, section) {
			const childSections = _.get(this.formData, path, []);
			const childSection = this.getSectionDefaultFields(section);
			this.updateField(path, [...childSections, childSection]);
			const insertedKey = `${section.key}[${childSections.length}]`;
			this.switchToSection(insertedKey);
		},
		addRootSection(path, sectionKey) {
			const newRootSection = _.get(this.formData, path, {});
			this.updateField(path, newRootSection);
			this.switchToSection(sectionKey);
		},
		getSectionDefaultFields(section) {
			if (_.size(section.sections) <= 1) return this.getDefaultFields(_.get(section, 'sections.0.fields', []));

			return _.reduce(
				section.sections,
				(acc, subsection) => _.set(acc, subsection.key, this.getDefaultFields(subsection.fields)),
				{}
			);
		},
		switchToSection(key) {
			const section = _.find(this.sections, ['key', key]);

			if (_.isEmpty(section?.sections)) return;

			this.activeSectionIndex = section.index;
		},
		getDefaultFields(fields) {
			return _.reduce(
				fields,
				(acc, value) => {
					const defaultValue = _.get(value, 'definition.default');
					if (defaultValue === undefined) return acc;

					return _.set(acc, value.key, defaultValue);
				},
				{}
			);
		},
		removeRootSection(globalIndex) {
			const section = this.sections[globalIndex];
			if (!section.dynamic) return;

			const path = `content.${section.key}`;
			this.updateField(path, _.get(initialFormData, path, undefined));
		},
		moveSection(sections) {
			const { rootKey } = _.first(sections);
			const path = `content.${rootKey}`;

			const updatedOrder = _.map(sections, 'localIndex');
			const subsections = _.get(this.formData, path);

			const reorderedSections = _.map(_.times(subsections.length), index => subsections[updatedOrder[index]]);
			this.updateField(`content.${rootKey}`, reorderedSections);
		},
		onSectionsChanged({ moved }) {
			if (moved.element.index === this.activeSectionIndex) {
				this.activeSectionIndex += moved.newIndex - moved.oldIndex;
			}
		},
		removeChildSection(sectionIndex) {
			const section = this.sections[sectionIndex];
			if (!section.flatten) return;

			const path = `content.${section.rootKey}`;
			const current = _.get(this.formData, path);
			const subsections = _.filter(current, (_, index) => index !== section.localIndex);

			this.updateField(path, subsections);

			if (this.activeSectionIndex >= sectionIndex) {
				this.activeSectionIndex = this.activeSectionIndex - 1;
			}
		},
		getSectionNameKey(section = {}, subsection = {}) {
			if (!subsection.editable) return;
			const nameField = _.find(subsection.fields, field => field.key === 'name');
			return `${buildSectionFieldPath(nameField, section, subsection)}.name`;
		},
		openSelectItemsModal() {
			this.isSelectItemsModalVisible = true;
		},
		closeSelectItemsModal() {
			this.isSelectItemsModalVisible = false;
		},
		onAddDocuments(selectedIdsHash, addedDocumentIds) {
			const containerIndex = this.sections[this.activeSectionIndex].localIndex;
			const containerRootKey = this.sections[this.activeSectionIndex].rootKey;
			const containersWithDocuments = _.get(this.formData, `content.${containerRootKey}`, []);

			const chaptersWithUpdatedDocumentIds = _.reduce(
				containersWithDocuments,
				(acc, chapter, index) => {
					const { documentIds: chapterDocumentIds } = chapter;

					const activeChapterDocumentIds = _.filter(
						chapterDocumentIds,
						chapterDocumentId => selectedIdsHash[chapterDocumentId]
					);

					const updatedChapterDocumentIds =
						index === containerIndex
							? _.concat(activeChapterDocumentIds, addedDocumentIds)
							: activeChapterDocumentIds;

					acc.push({
						...chapter,
						documentIds: updatedChapterDocumentIds
					});
					return acc;
				},
				[]
			);
			this.updateField(`content.${containerRootKey}`, chaptersWithUpdatedDocumentIds);
			this.$emit('update-documents', this.allDocumentIds);
		},
		onUpdateSectionDocuments(sectionIndex, documentIds) {
			this.updateField(`content.chapters[${sectionIndex}].documentIds`, documentIds);
		},
		handleUpdateSection(path, sections) {
			this.updateField(`${path}.sections`, sections);
		},
		handleUpdateSectionParagraphs(path, { paragraphs, sectionIndex }) {
			this.updateField(`${path}.sections[${sectionIndex}].paragraphs`, paragraphs);
		},
		isRecipesSection(section) {
			if (section.rootKey === predefinedSections.Chapters) {
				return true;
			}

			const sectionFields = _.map(_.get(section, 'sections'), 'fields');

			const containsRecipeSection = _.some(sectionFields, fields => {
				if (_.find(fields, ['key', 'documentIds'])) {
					return true;
				}
			});

			return containsRecipeSection;
		}
	}
};
</script>
