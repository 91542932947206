module.exports = {
	name: 'Notes',
	exampleProps: {
		pagesCount: 2,
		linesCount: 15
	},
	template: {
		type: 'repeater',
		props: {
			source: { '?': { var: '$props.pagesCount' } },
			item: [
				{
					name: 'Page',
					type: 'page',
					props: {
						width: { '?': { var: '$theme.pageWidth' } },
						height: { '?': { var: '$theme.pageHeight' } },
						fontFamily: { '?': { var: '$theme.primaryFont' } },
						fontWeight: 'bold',
						classNames: ['page-notes', 'page-with-number']
					},
					children: [
						{
							name: 'Text',
							type: 'text',
							props: {
								margin: '40px 0 0 63px',
								fontWeight: '700',
								letterSpacing: '1px',
								fontSize: '34pt',
								value: 'NOTES'
							}
						},
						{
							type: 'repeater',
							props: {
								source: { '?': { var: '$props.linesCount' } },
								item: [
									{
										name: 'Text',
										type: 'row',
										props: {
											height: { '?': { var: '$theme.notes.lineHeight' } },
											margin: '0 63px',
											borderBottomWidth: '2px',
											borderBottomStyle: 'solid',
											borderBottomColor: { '?': { var: '$theme.notes.lineColor' } }
										}
									}
								]
							}
						},
						{
							type: 'Page Number'
						}
					]
				}
			]
		}
	}
};
