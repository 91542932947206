module.exports = {
	name: 'Table of contents',
	exampleProps: {
		pagesCount: 2,
		chapters: [
			{
				name: 'breakfast',
				number: '1',
				recipes: [
					{
						id: '',
						sourceURL:
							'https://www.savourous.com/recipe-view/gluten-free-plantain-waffle-with-mild-hot-sauce/',
						name: 'Plantain Waffle',
						author: '',
						category: 'breakfast',
						description:
							'Plantain Waffle with Mild Hot sauce is my fun way to eat plantain for breakfast. Also because plantain is eating mostly with hot sauce in West Africa, adding my mild green sauce to this plantain is sure a delight. It is a great way to enjoy waffles with kids while staying with my westafrican cuisine […]',
						ingredients: {
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 medium ripe Plantains'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Eggs'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 Tablespoon of coconut oil to oil the waffle maker'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Pinch of salt (optional)'
								},
								{
									type: 'SECTION',
									text: 'For the Mild Green Sauce',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '4 jalopenos peppers'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1/2 of medium green bell pepper'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1/4 of medium onion'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1/2 teaspoon of Salt'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '4 tablespoons of oil (optional)'
										}
									]
								}
							]
						},
						instructions: {
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In a food processor,purree the peeled plantain with 2 eggs and pinch of salt'
								},
								{
									type: 'INSTRUCTION',
									text: 'Start cooking in batch in your waffle maker.'
								},
								{
									type: 'INSTRUCTION',
									text: 'While the waffle is cooking you can make your hot sauce by finely blending: jalopeno pepper, onion, oil, salt, bell pepper.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Heat up the hot sauce and serve over your waffles.'
								}
							]
						},
						tags: ['Breakfast & Brunch', 'Favorite Recipes', 'Gluten Free Recipes'],
						recipeYield: '2',
						images: [
							{
								name: 'Plantain waffles with hot pepper',
								url: 'https://i0.wp.com/www.savourous.com/wp-content/uploads/2021/08/plantainwaffle-new.jpg?fit=1936,1936&ssl=1',
								thumbnailURL: '',
								fileURL: ''
							}
						],
						nutrition: {
							calories: 0,
							fat: 0,
							protein: 0,
							carbs: 0
						},
						cookingTime: {
							prepTime: '',
							cookTime: '',
							totalTime: ''
						}
					},
					{
						id: '',
						sourceURL: 'https://www.savourous.com/recipe-view/easy-refreshing-fruits-smoothie/',
						name: 'Fruit Smoothie',
						author: '',
						category: 'breakfast',
						description:
							'Smoothies are the perfect meal whether summer or winter time. It makes is a great refreshing drink after workout. A fabulous energizing drink whether it is breakfast, lunch or dinner. I personally like tart green smoothie or green tasteless smoothies but cannot say the same of the rest of my family. The below recipe is […]',
						ingredients: {
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups of peeled and halves peach'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 peeled kiwis'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 cup of strawberries (clean with leaves off)'
								}
							]
						},
						instructions: {
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Put everything in your blender and pure with the smoothie settings.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and serve immediately.'
								}
							]
						},
						tags: [
							'Appetizers & Snacks',
							'Beverages',
							'Breakfast & Brunch',
							'Desserts',
							'Gluten Free Recipes',
							'LowCarb Keto Recipes',
							'Vegetarian Recipes'
						],
						recipeYield: '3',
						images: [
							{
								name: 'Fruit peach, kiwi, strawberry smoothie',
								url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2021/01/blendtec-G6.jpg?fit=2255,2254&ssl=1',
								thumbnailURL: '',
								fileURL: ''
							}
						],
						nutrition: {
							calories: 0,
							fat: 0,
							protein: 0,
							carbs: 0
						},
						cookingTime: {
							prepTime: '',
							cookTime: '',
							totalTime: ''
						}
					}
				]
			},
			{
				name: 'lunch',
				number: '2',
				recipes: [
					{
						id: '',
						sourceURL:
							'https://www.savourous.com/recipe-view/gluten-free-plantain-waffle-with-mild-hot-sauce/',
						name: 'Plantain Waffle',
						author: '',
						category: 'breakfast',
						description:
							'Plantain Waffle with Mild Hot sauce is my fun way to eat plantain for breakfast. Also because plantain is eating mostly with hot sauce in West Africa, adding my mild green sauce to this plantain is sure a delight. It is a great way to enjoy waffles with kids while staying with my westafrican cuisine […]',
						ingredients: {
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 medium ripe Plantains'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 Eggs'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 Tablespoon of coconut oil to oil the waffle maker'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: 'Pinch of salt (optional)'
								},
								{
									type: 'SECTION',
									text: 'For the Mild Green Sauce',
									elements: [
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '4 jalopenos peppers'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1/2 of medium green bell pepper'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1/4 of medium onion'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '1/2 teaspoon of Salt'
										},
										{
											type: 'INGREDIENT',
											quantity: 0,
											units: '',
											text: '4 tablespoons of oil (optional)'
										}
									]
								}
							]
						},
						instructions: {
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'In a food processor,purree the peeled plantain with 2 eggs and pinch of salt'
								},
								{
									type: 'INSTRUCTION',
									text: 'Start cooking in batch in your waffle maker.'
								},
								{
									type: 'INSTRUCTION',
									text: 'While the waffle is cooking you can make your hot sauce by finely blending: jalopeno pepper, onion, oil, salt, bell pepper.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Heat up the hot sauce and serve over your waffles.'
								}
							]
						},
						tags: ['Breakfast & Brunch', 'Favorite Recipes', 'Gluten Free Recipes'],
						recipeYield: '2',
						images: [
							{
								name: 'Plantain waffles with hot pepper',
								url: 'https://i0.wp.com/www.savourous.com/wp-content/uploads/2021/08/plantainwaffle-new.jpg?fit=1936,1936&ssl=1',
								thumbnailURL: '',
								fileURL: ''
							}
						],
						nutrition: {
							calories: 0,
							fat: 0,
							protein: 0,
							carbs: 0
						},
						cookingTime: {
							prepTime: '',
							cookTime: '',
							totalTime: ''
						}
					},
					{
						id: '',
						sourceURL: 'https://www.savourous.com/recipe-view/easy-refreshing-fruits-smoothie/',
						name: 'Fruit Smoothie',
						author: '',
						category: 'breakfast',
						description:
							'Smoothies are the perfect meal whether summer or winter time. It makes is a great refreshing drink after workout. A fabulous energizing drink whether it is breakfast, lunch or dinner. I personally like tart green smoothie or green tasteless smoothies but cannot say the same of the rest of my family. The below recipe is […]',
						ingredients: {
							type: 'SECTION',
							text: 'Ingredients',
							elements: [
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '2 cups of peeled and halves peach'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '4 peeled kiwis'
								},
								{
									type: 'INGREDIENT',
									quantity: 0,
									units: '',
									text: '1 cup of strawberries (clean with leaves off)'
								}
							]
						},
						instructions: {
							type: 'SECTION',
							text: 'Instructions',
							elements: [
								{
									type: 'INSTRUCTION',
									text: 'Put everything in your blender and pure with the smoothie settings.'
								},
								{
									type: 'INSTRUCTION',
									text: 'Remove and serve immediately.'
								}
							]
						},
						tags: [
							'Appetizers & Snacks',
							'Beverages',
							'Breakfast & Brunch',
							'Desserts',
							'Gluten Free Recipes',
							'LowCarb Keto Recipes',
							'Vegetarian Recipes'
						],
						recipeYield: '3',
						images: [
							{
								name: 'Fruit peach, kiwi, strawberry smoothie',
								url: 'https://i1.wp.com/www.savourous.com/wp-content/uploads/2021/01/blendtec-G6.jpg?fit=2255,2254&ssl=1',
								thumbnailURL: '',
								fileURL: ''
							}
						],
						nutrition: {
							calories: 0,
							fat: 0,
							protein: 0,
							carbs: 0
						},
						cookingTime: {
							prepTime: '',
							cookTime: '',
							totalTime: ''
						}
					}
				]
			}
		]
	},
	template: [
		{
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } },
				position: 'relative'
			},
			children: [
				{
					name: 'Background image',
					type: 'image',
					props: {
						width: '100%',
						height: '100%',
						position: 'absolute',
						objectFit: 'cover',
						src: { '?': { var: '$props.imageUrl' } }
					}
				}
			]
		},
		{
			name: 'Table of Contents',
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } },
				padding: { '?': { var: '$theme.pagePadding' } }
			},
			children: [
				{
					name: 'Table of Contents Container',
					type: 'container',
					props: {
						width: '100%',
						height: '100%',
						padding: '80px 60px 50px 60px',
						overflow: 'hidden'
					},
					children: [
						{
							name: 'Header',
							type: 'text',
							props: {
								value: 'Contents',
								fontFamily: 'ACaslonPro-bold',
								letterSpacing: '-2px',
								fontSize: '50px',
								margin: '0 auto',
								lineHeight: '1'
							}
						},
						{
							type: 'container',
							name: 'Content container',
							props: {
								marginTop: '40px',
								display: 'flex',
								width: '100%',
								maxHeight: 'calc(100% - 80px)',
								flexDirection: 'column',
								flexWrap: 'wrap'
							},
							children: [
								{
									type: 'repeater',
									props: {
										source: { '?': { var: '$tableOfContents' } },
										itemAs: 'chapter',
										item: [
											{
												name: 'Chapter container',
												type: 'container',
												props: {
													fontFamily: 'Poppins-bold',
													fontSize: '16px',
													width: '48%',
													marginRight: '4%',
													flex: '1 1 100%',
													display: 'flex',
													justifyContent: 'space-between',
													marginTop: '8px'
												},
												children: [
													{
														name: 'Chapter title',
														type: 'text',
														props: {
															width: '85%',
															value: {
																'?': { var: 'chapter.title' }
															}
														}
													},
													{
														name: 'Chapter page number',
														type: 'text',
														props: {
															display: 'flex',
															alignItems: 'flex-end',
															value: {
																'?': { var: 'chapter.pageNumber' }
															}
														}
													}
												]
											},
											{
												type: 'repeater',
												props: {
													source: { '?': { var: 'chapter.children' } },
													itemAs: 'section',
													item: [
														{
															name: 'Recipe container',
															type: 'container',
															props: {
																fontFamily: 'Poppins',
																fontSize: '12px',
																width: '48%',
																marginRight: '4%',
																flex: '1 1 100%',
																display: 'flex',
																justifyContent: 'space-between'
															},
															children: [
																{
																	name: 'Recipe name',
																	type: 'text',
																	props: {
																		value: { '?': { var: 'section.title' } },
																		width: '85%'
																	}
																},
																{
																	name: 'Recipe page number',
																	type: 'text',
																	props: {
																		value: {
																			'?': { var: 'section.pageNumber' }
																		},
																		display: 'flex',
																		alignItems: 'flex'
																	}
																}
															]
														}
													]
												}
											}
										]
									}
								}
							]
						}
					]
				}
			]
		}
	]
};
