<!-- eslint-disable max-len -->
<template>
	<div class="ItemSpread">
		<svg
			v-if="isSingleSpread && isShowLinkIcon"
			class="ItemSpread__link-icon"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.2979 7.70137C14.2428 9.64828 14.2161 12.7697 12.3096 14.6867C12.3061 14.6906 12.3018 14.6948 12.2979 14.6988L10.1104 16.8863C8.18106 18.8156 5.0421 18.8153 3.11303 16.8863C1.18368 14.9572 1.18368 11.8179 3.11303 9.88887L4.32091 8.68099C4.64122 8.36068 5.19285 8.57357 5.20939 9.02624C5.23048 9.60313 5.33393 10.1827 5.52481 10.7424C5.58946 10.9319 5.54327 11.1416 5.40167 11.2832L4.97566 11.7092C4.06336 12.6215 4.03475 14.107 4.93806 15.0282C5.8503 15.9585 7.34971 15.964 8.26895 15.0448L10.4564 12.8576C11.3741 11.9399 11.3703 10.4567 10.4564 9.54284C10.336 9.42259 10.2146 9.32917 10.1198 9.2639C10.0528 9.21785 9.9974 9.15677 9.95813 9.08552C9.91887 9.01428 9.8968 8.93484 9.89369 8.85355C9.8808 8.50957 10.0027 8.15511 10.2745 7.88331L10.9598 7.19792C11.1396 7.0182 11.4215 6.99613 11.6299 7.14158C11.8685 7.30823 12.0921 7.49555 12.2979 7.70137ZM16.8856 3.11343C14.9566 1.18433 11.8176 1.18407 9.88825 3.11343L7.70075 5.30092C7.69685 5.30483 7.69262 5.30906 7.68904 5.31296C5.78259 7.23002 5.75587 10.3514 7.70075 12.2983C7.90657 12.5041 8.1301 12.6914 8.36875 12.8581C8.57715 13.0035 8.85912 12.9814 9.03881 12.8017L9.72416 12.1163C9.99597 11.8445 10.1178 11.4901 10.1049 11.1461C10.1018 11.0648 10.0798 10.9854 10.0405 10.9141C10.0012 10.8429 9.94587 10.7818 9.87881 10.7357C9.78402 10.6705 9.66266 10.5771 9.54219 10.4568C8.62836 9.54297 8.62452 8.0597 9.54219 7.14203L11.7297 4.95486C12.6489 4.03562 14.1483 4.04116 15.0606 4.97146C15.9639 5.89268 15.9353 7.37816 15.023 8.29047L14.597 8.71647C14.4554 8.85808 14.4092 9.06771 14.4738 9.25723C14.6647 9.81693 14.7682 10.3965 14.7893 10.9734C14.8058 11.4261 15.3574 11.639 15.6777 11.3187L16.8856 10.1108C18.815 8.18177 18.815 5.04246 16.8856 3.11343Z"
				:fill="isLinked ? '#4F9865' : '#C70000'"
			/>
		</svg>
		<b-tooltip
			:target="id"
			placement="top"
			custom-class="ItemSpread-Tooltip"
			triggers="hover"
			:boundary-padding="10"
		>
			{{ tooltipLabel }}
		</b-tooltip>
		<div :id="id" class="ItemSpread__container">
			<div class="d-flex">
				<div class="ItemSpread__left-page" />
				<div :class="{ 'ItemSpread__empty-page': isSingleSpread }" class="ItemSpread__right-page" />
			</div>
		</div>
	</div>
</template>

<script>
import { documentSizes } from '../constants';

export default {
	props: {
		id: {
			type: String,
			required: true
		},
		size: {
			type: String,
			default: documentSizes.STANDARD
		},
		isLinked: {
			type: Boolean,
			default: false
		},
		isShowLinkIcon: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		isSingleSpread() {
			return this.size === documentSizes.SHORT || this.size === documentSizes.STANDARD;
		},
		tooltipLabel() {
			return this.isSingleSpread ? this.$t('Single spread') : this.$t('Double Spread');
		}
	}
};
</script>

<style lang="scss">
@import '../style/variables.scss';

.ItemSpread {
	position: relative;
	min-width: 100px;
	max-width: 100px;
	margin: 0 auto;

	&__container {
		margin: 0 auto;
		min-width: 40px;
		max-width: 40px;
		height: 23px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		font-size: 13px;
		background-color: $grey-bg-color;
	}

	&__left-page,
	&__right-page {
		width: 9px;
		height: 12px;
		background-color: $color-hp-grey-1;
	}

	&__left-page {
		border-radius: 2px 0 0 2px;
	}

	&__right-page {
		margin-left: 1px;
		border-radius: 0 2px 2px 0;
	}

	&__empty-page {
		border: 1px solid $color-hp-grey-1;
		background-color: transparent;
	}

	&__link-icon {
		position: absolute;
		left: 0;
	}
}

.ItemSpread-Tooltip {
	z-index: 1;

	.arrow::before {
		border-top-color: $of-color-white !important;
	}

	.tooltip-inner {
		color: $color-hp-black;
		background-color: $of-color-white;
		border-radius: 5px;
		font-size: 15px;
		padding: 12px 15px;
		text-align: center;
		box-shadow: -1px 0px 5px rgba(0, 0, 0, 0.15);
	}
}
</style>
