<template>
	<div :class="`treeview-node ${isSection ? 'treeview-node__section' : ''}`">
		<slot v-if="isSection" name="prepend" v-bind="{ item: value }">
			<div class="treeview-node__drag-handle pr-2 pl-2">
				<img src="../../assets/grip-vertical-lg.svg" class="drag-handle" />
			</div>
		</slot>

		<div :class="[isSection ? 'treeview-node__container' : '']">
			<div class="treeview-node__root">
				<template v-if="isSection">
					<b-row class="treeview-node__label m-0 pt-1 pb-1">
						<slot name="label" v-bind="{ item: value }">
							<b-col md="4">
								<OfFormInput
									class="sectionInput"
									no-label
									:value="value.name"
									:placeholder="$t('Section name')"
									@input="val => onValueChange(value, 'name', val)"
								>
									<template #append>
										<icon name="edit" />
									</template>
								</OfFormInput>
							</b-col>
							<b-col class="d-flex justify-content-end">
								<b-button size="sm" variant="light" class="DeleteBtn mr-2" @click="removeElement">
									<icon name="trash" />
								</b-button>
							</b-col>
						</slot>
					</b-row>
				</template>

				<template v-if="_has(value, 'unit') && _has(value, 'quantity')">
					<b-row class="treeview-node__label pt-1 pb-1 mr-0">
						<slot v-if="!isSection" name="prepend" v-bind="{ item: value }">
							<div class="d-flex ml-2 mr-2">
								<img src="../../assets/grip-vertical.svg" class="drag-handle" />
							</div>
						</slot>
						<slot name="label" v-bind="{ item: value }">
							<b-row class="flex-grow-1">
								<b-col md="2">
									<OfFormInput
										no-label
										:value="value.quantity"
										:placeholder="'0'"
										@input="val => onValueChange(value, 'quantity', val)"
									/>
								</b-col>
								<b-col md="2">
									<OfFormInput
										no-label
										:value="value.unit"
										:placeholder="'-'"
										@input="val => onValueChange(value, 'unit', val)"
									/>
								</b-col>
								<b-col>
									<OfFormInput
										no-label
										:name="`${getFieldPath(sectionIndex, itemIndex)}.name`"
										:value="value.name"
										:placeholder="$t('e.g. The name of the ingredient')"
										@input="val => onValueChange(value, 'name', val)"
									/>
								</b-col>
							</b-row>
						</slot>
						<slot name="append" v-bind="{ item: value }">
							<b-button class="DeleteBtn ml-2" size="sm" variant="light" @click="removeElement">
								<icon name="trash" />
							</b-button>
						</slot>
					</b-row>
				</template>

				<template v-if="_has(value, 'text')">
					<b-row class="treeview-node__label m-0 mb-2">
						<slot v-if="!isSection" name="prepend" v-bind="{ item: value }">
							<div class="d-flex mr-2">
								<img src="../../assets/grip-vertical.svg" class="drag-handle" />
							</div>
						</slot>
						<slot name="label" v-bind="{ item: value }">
							<b-row class="flex-grow-1">
								<b-col>
									<OfFormTextarea
										no-label
										:value="value.text"
										@input="val => onValueChange(value, 'text', val)"
									/>
								</b-col>
							</b-row>
						</slot>
						<slot name="append" v-bind="{ item: value }">
							<b-button size="sm" variant="light" class="DeleteBtn ml-2" @click="removeElement">
								<icon name="trash" />
							</b-button>
						</slot>
					</b-row>
				</template>
			</div>

			<template v-if="isSection && hasChildren">
				<b-row class="treeview-node__label mt-2 ml-4 mr-4">
					<template v-if="tab === 'ingredients'">
						<b-col md="2" class="text-center pb-0">
							<label>{{ $t('Quantity') }}</label>
						</b-col>
						<b-col md="2" class="text-center pb-0">
							<label>{{ $t('Unit') }}</label>
						</b-col>
						<b-col class="text-center pb-0">
							<label>{{ $t('Name') }}</label>
						</b-col>
					</template>
					<template v-else-if="tab === 'methods'">
						<b-col class="text-center pb-0">
							<label>{{ $t('Description') }}</label>
						</b-col>
					</template>
				</b-row>
			</template>

			<div class="treeview-node__children">
				<draggable :value="value.elements" handle=".drag-handle" @input="updateValue">
					<treeview-node
						v-for="(child, index) in value.elements"
						:key="index"
						:section-index="sectionIndex"
						:item-index="index"
						:value="child"
						:level="level + 1"
						@input="value => updateChildValue(value, index)"
						@remove="removeChildValue(index)"
					>
						<template #prepend="{ item }">
							<slot name="prepend" v-bind="{ item }" />
						</template>
						<template #label="{ item }">
							<slot name="label" v-bind="{ item }"></slot>
						</template>
						<template #append="{ item }">
							<slot name="append" v-bind="{ item }" />
						</template>
					</treeview-node>
				</draggable>

				<b-button
					v-if="isSection"
					variant="outline-primary"
					class="AddBtn mt-1 btn-block"
					@click="
						event =>
							addComponentItem(
								event,
								tab === 'ingredients'
									? componentTypes.ingredientComponentType
									: componentTypes.instructionComponentType
							)
					"
				>
					<icon name="plus" />
					{{ tab === 'ingredients' ? $t('Add Ingredient') : $t('Add Step') }}
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { OfFormInput, OfFormTextarea } from '@oneflow/ofs-vue-layout';
import Vue, { PropType } from 'vue';
import Draggable from 'vuedraggable';
import {
	sectionComponentType,
	instructionComponentType,
	ingredientComponentType,
	componentSchemas
} from '../../constants';

export default Vue.extend({
	name: 'TreeviewNode',
	components: {
		Draggable,
		OfFormInput,
		OfFormTextarea
	},
	props: {
		level: {
			type: Number,
			default: 0
		},
		value: {
			type: Object,
			default: () => {}
		},
		tab: {
			type: String,
			default: () => 'ingredients'
		},
		sectionIndex: {
			type: Number,
			default: 0
		},
		itemIndex: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			localValue: { ...this.value },
			componentTypes: {
				sectionComponentType,
				instructionComponentType,
				ingredientComponentType
			}
		};
	},
	computed: {
		hasChildren() {
			return !_.isEmpty(this.value.elements);
		},
		appendLevel() {
			return this.level + (this.hasChildren ? 0 : 1);
		},
		isSection() {
			return _.has(this.value, 'elements');
		}
	},
	watch: {
		value(value) {
			this.localValue = { ...value };
		}
	},
	methods: {
		_has: _.has,
		updateValue(value) {
			this.localValue.elements = [...value];
			this.$emit('input', this.localValue);
		},
		updateChildValue(value, index) {
			this.$set(this.localValue.elements, index, value);
			this.$emit('input', this.localValue);
		},
		addComponentItem(event, componentType) {
			event.stopPropagation();
			const newItem = _.cloneDeep(componentSchemas[componentType]);
			this.$set(this.localValue.elements, _.size(this.localValue.elements), newItem);
			this.$emit('input', this.localValue);
		},
		onValueChange(item, key, value) {
			item[key] = value;
			this.$emit('input', _.cloneDeep(item));
		},
		removeElement(event) {
			event.stopPropagation();
			this.$emit('remove');
		},
		removeChildValue(index) {
			if (_.isUndefined(index)) {
				this.localValue = null;
			} else {
				this.localValue.elements.splice(index, 1);
			}
			this.$emit('input', this.localValue);
		},
		getFieldPath(sectionIndex, itemIndex) {
			return `ingredients.elements.${sectionIndex}${_.isNil(itemIndex) ? '' : `.elements.${itemIndex}`}`;
		}
	}
});
</script>

<style lang="scss">
@import '../../style/_variables.scss';

.treeview {
	.treeview-node {
		margin-bottom: 20px;

		.drag-handle {
			cursor: pointer;
			width: 100%;
		}
	}

	.treeview-node.treeview-node__section {
		border: 1px solid $color-hp-grey-3;
		border-radius: 3px;
		display: flex;

		.treeview-node {
			margin-bottom: 0;
		}

		.treeview-node__drag-handle {
			background-color: $color-hp-highlights;
			display: flex;
		}

		.treeview-node__container {
			flex: 1;
			border-left: 1px solid $color-hp-grey-3;
			padding-bottom: 20px;

			& > .treeview-node__root {
				background-color: $color-hp-highlights;
				border-bottom: 1px solid $color-hp-grey-3;
			}

			& > .treeview-node__children {
				margin: 0 20px;
				padding-top: 10px;

				& .treeview-node__root {
					padding: 0;
				}
			}
		}
	}

	.treeview-node__label label {
		color: $color-hp-grey-1;
		font-size: 13px;
		font-weight: 600;
		margin-bottom: 0;
	}

	.AddBtn {
		.fa-icon {
			margin-right: 7px;
			vertical-align: -0.25em;
		}
	}

	.DeleteBtn {
		height: 30px;
		align-self: center;
	}

	.sectionInput {
		& input {
			border: 0 !important;
			box-shadow: none !important;
			background-color: transparent !important;
			font-weight: 700;
		}

		.input-group-append {
			display: none !important;
		}

		&:hover .input-group-append {
			display: flex !important;
			margin-top: 10px;
		}
	}
}
</style>
