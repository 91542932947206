<template>
	<b-modal
		:visible="visible"
		:title="$t('Add items to book')"
		:ok-title="`${$t('Select Items')} (${addedIds.length})`"
		:ok-disabled="isSubmitDisabled"
		:cancel-title="$t('Cancel')"
		:cancel-variant="null"
		size="xl"
		dialog-class="SelectItemsModal"
		@ok="$emit('input', selectedIdsHash, addedIds)"
		@hidden="onHidden"
	>
		<b-row class="TopFilterBar mx-0">
			<b-col class="pl-0">
				<SearchInput v-model="searchString" :placeholder="$t('Search items by name...')" />
			</b-col>
			<b-col cols="3">
				<b-form-select v-model="selectedAccount" :options="accountOptions" @change="reloadDocuments" />
			</b-col>
			<b-col cols="1">
				<OfInlineFilter :filters="filters" :values="filterValues" @change="filtersChanged" />
			</b-col>
		</b-row>
		<Datatable
			ref="documentsTable"
			:items="items"
			:fields="fields"
			:is-busy="isDocumentsFetching"
			hover
			@row-clicked="onRowClicked"
			@table-change="handleTableChange"
		>
			<template #head(checked)>
				<OfFormCheckbox v-model="isAllSelected" no-label />
			</template>
			<template #cell(checked)="{ item }">
				<OfFormCheckbox :value="item.checked" no-label @input="toggleSelect(item._id)" />
			</template>
			<template #cell(itemSize)="{ item }">
				<ItemSize :size="item.record ? item.record.size : ''" />
			</template>
			<template #cell(itemSpread)="{ item }">
				<ItemSpread :id="item._id" :size="item.record ? item.record.size : ''" :is-show-link-icon="false" />
			</template>
			<template #cell(validationErrors)="{ item }">
				<ItemStatus :item="item" />
			</template>
			<template #cell(thumbnail)="{ item }">
				<Thumbnail :src="item.thumbnail" :size="45" />
			</template>
			<template #cell(updatedAt)="{ item }">
				{{ fromNow(item.updatedAt) }}
			</template>
		</Datatable>
	</b-modal>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { OfInlineFilter, Datatable, OfFormCheckbox } from '@oneflow/ofs-vue-layout';
import SearchInput from '../../../components/SearchInput.vue';
import Thumbnail from '../../../components/Thumbnail.vue';
import ItemStatus from '../../../components/ItemStatus.vue';
import ItemSize from '../../../components/ItemSize.vue';
import ItemSpread from '../../../components/ItemSpread.vue';
import { fromNow } from '../../../lib/dateFormatters';

const initialState = () => ({
	searchString: '',
	selectedAccount: null,
	sort: {
		updatedAt: -1
	},
	filterValues: {},
	currentPage: 1,
	selectedIdsHash: {},
	loadedRecipesById: {},
	loadedRecipeIds: [],
	documentsSummary: {},
	contributors: [],
	loadedItemsById: {},
	loadedItemIds: []
});

export default {
	components: {
		OfFormCheckbox,
		Datatable,
		SearchInput,
		Thumbnail,
		OfInlineFilter,
		ItemStatus,
		ItemSpread,
		ItemSize
	},
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		selectedIds: {
			type: Array,
			default: () => []
		},
		contributorIds: {
			type: Array,
			default: () => []
		},
		supportedTypes: {
			type: Array,
			default: () => []
		}
	},
	data() {
		const fields = [
			{ label: '', key: 'checked' },
			{ label: '', key: 'thumbnail' },
			{ label: this.$t('Name'), key: 'record.name', sortable: true, tdClass: 'col-4' },
			{ label: this.$t('Category'), key: 'record.category', sortable: true },
			{ label: this.$t('Author'), key: 'record.author', sortable: true },
			{ label: this.$t('Item Size'), key: 'itemSize' },
			{ label: this.$t('Spread'), key: 'itemSpread' },
			{ label: this.$t('Status'), key: 'validationErrors' },
			{ label: this.$t('Last modified'), key: 'updatedAt', sortable: true }
		];

		return {
			...initialState(),
			fields,
			perPage: 20
		};
	},
	computed: {
		...mapGetters({
			documents: 'document/documents',
			isDocumentsFetching: 'document/isFetching',
			currentAccount: 'account/currentAccount'
		}),
		isAllFetched() {
			return this.documents.total === this.loadedItemIds.length;
		},
		activeIds() {
			return _.keys(this.selectedIdsHash);
		},
		addedIds() {
			return _.difference(this.activeIds, this.selectedIds);
		},
		isSubmitDisabled() {
			return _.xor(this.activeIds, this.selectedIds).length === 0;
		},
		isAllSelected: {
			get() {
				if (this.loadedItemIds.length === 0) return false;
				return _.difference(this.loadedItemIds, this.activeIds).length === 0;
			},
			set(isAllSelected) {
				if (isAllSelected) {
					this.selectedIdsHash = _.assign({}, this.selectedIdsHash, _.keyBy(this.loadedItemIds));
				} else {
					this.selectedIdsHash = _.omit(this.selectedIdsHash, this.loadedItemIds);
				}
			}
		},
		items() {
			return _.map(this.loadedItemIds, itemId => {
				const item = this.loadedItemsById[itemId];
				const isActive = !!this.selectedIdsHash[itemId];
				const isSelectetd = _.includes(this.selectedIds, itemId);

				let rowVariant;
				if (isSelectetd) {
					rowVariant = 'selected';
				} else if (isActive) {
					rowVariant = 'active';
				}

				return {
					...item,
					checked: isActive,
					thumbnail: this.getThumbnailUrl(item),
					updatedAt: item.updatedAt,
					_rowVariant: rowVariant
				};
			});
		},
		filters() {
			const getFilterItems = path => {
				return (
					_.get(this.documentsSummary, path, [])
						.filter(item => item && typeof item === 'string')
						// Added sort because results from 'document/getSummary always returned in a different order
						.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
						.map(item => ({ title: item, value: item }))
				);
			};

			const typeFilterItems = getFilterItems('type').filter(item =>
				_.find(this.supportedTypes, type => type === item.value)
			);
			const categoryFilterItems = getFilterItems('category');
			const authorFilterItems = getFilterItems('author');
			return [
				{
					header: this.$t('Item Type'),
					key: 'type',
					type: 'checkbox',
					items: typeFilterItems
				},
				{
					header: this.$t('Category'),
					key: 'record.category',
					type: 'checkbox',
					items: categoryFilterItems
				},
				{
					header: this.$t('Author'),
					key: 'record.author',
					type: 'checkbox',
					items: authorFilterItems
				}
			];
		},
		accountOptions() {
			const contributorOptions = _.map(this.contributors, account => {
				return { value: account.authAccountId, text: account.name };
			});
			const currentAccountOption = {
				value: null,
				text: this.currentAccount.name
			};

			return [currentAccountOption, ...contributorOptions];
		}
	},
	watch: {
		visible: {
			immediate: true,
			async handler(visible, prevVisible) {
				if (visible === prevVisible || !visible) return;

				this.fetchContributorAccounts();
				this.fetchDocuments();
				this.fetchDocumentsSummary();
				this.selectedIdsHash = _.keyBy(this.selectedIds);

				setTimeout(this.initializeInfinityScroll, 0);
			}
		},
		searchString: _.debounce(async function(searchString, oldSearchString) {
			if (searchString !== oldSearchString) {
				await this.reloadDocuments();
			}
		}, 800)
	},
	methods: {
		fromNow,
		...mapActions({
			findDocuments: 'document/find',
			getDocumentsSummary: 'document/getSummary',
			getBookContributors: 'contributor/getBookContributors'
		}),
		async fetchDocuments() {
			const query = {
				$select: {
					'record.name': 1,
					'record.category': 1,
					'record.author': 1,
					'record.images': 1,
					'record.size': 1,
					validationErrors: 1,
					updatedAt: 1
				},
				$where: {
					$or: [{ collectionId: { $exists: false } }, { collectionId: null }],
					contributorIds: this.selectedAccount
				},
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1)
			};
			if (!_.isEmpty(this.sort)) {
				query.$sort = { ...this.sort, _id: 1 };
			}
			if (!_.isEmpty(this.searchString)) {
				query.$where['record.name'] = { $regex: this.searchString, $options: 'i' };
			}

			if (!_.isEmpty(this.filterValues)) {
				const queryFilterValues = _.reduce(
					this.filterValues,
					($where, value, key) => {
						return {
							...$where,
							[key]: Array.isArray(value) ? { $in: value } : value
						};
					},
					{}
				);
				_.merge(query.$where, queryFilterValues);
			}

			const typeFieldKey = '$where.type';

			if (!_.get(query, typeFieldKey)) {
				_.set(query, typeFieldKey, { $in: this.supportedTypes });
			}

			const { data: filteredItems } = await this.findDocuments({ query: { query } });
			const itemsById = _.keyBy(filteredItems, '_id');
			this.loadedItemsById = _.assign({}, this.loadedItemsById, itemsById);
			this.loadedItemIds.push(..._.map(filteredItems, '_id'));
		},
		async fetchDocumentsSummary() {
			this.documentsSummary = await this.getDocumentsSummary({
				query: {
					fields: ['category', 'author'],
					$where: { $or: [{ collectionId: { $exists: false } }, { collectionId: null }] }
				}
			});
		},
		async filtersChanged(filters) {
			this.filterValues = filters;
			await this.reloadDocuments();
		},
		async handleTableChange({ sort }) {
			this.sort = sort;
			await this.reloadDocuments();
		},
		onRowClicked(item) {
			this.toggleSelect(item._id);
		},
		async reloadDocuments() {
			this.currentPage = 1;
			this.loadedItemsById = {};
			this.loadedItemIds = [];

			await this.fetchDocuments();
		},
		async fetchContributorAccounts() {
			const query = {
				$where: { authAccountId: { $in: this.contributorIds } },
				$limit: this.contributorIds.length
			};
			const { data } = await this.getBookContributors({ query: { query }, options: { skipMutations: true } });
			this.contributors = data;
		},
		toggleSelect(itemId) {
			if (!this.selectedIdsHash[itemId]) {
				this.$set(this.selectedIdsHash, itemId, true);
			} else {
				this.$delete(this.selectedIdsHash, itemId);
			}
		},
		getThumbnailUrl(document) {
			const image = _.get(document, 'record.images[0]', {});
			return image.thumbnailURL || image.fileURL || image.url;
		},
		initializeInfinityScroll() {
			const { documentsTable } = this.$refs;
			documentsTable.$refs.table.stickyHeader = '540px';

			this.$nextTick(() => {
				const tableInner = _.head(documentsTable.$el.getElementsByClassName('b-table-sticky-header'));
				tableInner.addEventListener('scroll', this.onScrollTable);
			});
		},
		onScrollTable: _.debounce(function() {
			if (this.isDocumentsFetching || this.isAllFetched) return;

			const tableWrapper = _.get(this.$refs, 'documentsTable.$refs.table.$el');
			const tableInner = _.get(tableWrapper, 'children[0]');

			if (!tableWrapper || !tableInner) return;

			const [{ bottom: wrapperBottom }] = tableWrapper.getClientRects();
			const [{ bottom: innerBottom }] = tableInner.getClientRects();

			if (Math.abs(innerBottom - wrapperBottom) < 180) {
				this.currentPage++;
				this.fetchDocuments();
			}
		}, 200),
		onHidden() {
			Object.assign(this.$data, initialState());
			this.$emit('hidden');
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/variables';

.SelectItemsModal {
	.Datatable {
		thead {
			th {
				color: $color-hp-grey-1 !important;
				background-color: $color-hp-highlights !important;

				&:first-child {
					border-top-left-radius: $of-border-radius;
				}

				&:last-child {
					border-top-right-radius: $of-border-radius;
				}
			}
		}

		tbody {
			.table {
				&-active {
					background-color: $color-hp-highlights;

					td {
						background-color: $color-hp-highlights;
					}
				}

				&-selected {
					color: $color-pimienta-alpha-70;

					.custom-control-input:checked ~ .custom-control-label::before {
						background-color: $color-pimienta-alpha-70;
						border-color: $color-pimienta-alpha-70;
					}
				}
			}

			td {
				padding: 7px 12px;
			}
		}
	}

	.TopFilterBar {
		display: flex;
		margin-bottom: 12px;

		.SearchInput {
			max-width: unset;
		}

		.InlineFilter {
			border: 1px solid $color-hp-grey-3;
			border-radius: 3px;
		}
	}

	// head of Datatable is overflowing OfInlineFilter without this
	.InlineFilterPanel {
		z-index: 3;
	}

	/*
		Fix of vuetify global styles.
		Should be removed after migration
	*/
	.form-group {
		margin-bottom: 0;

		.form-row > .col {
			padding: 0;
		}
	}
}
</style>
