module.exports = {
	"name": "Cover with spine",
	"template": [{
		"name": "Cover page",
		"type": "page",
		"props": {
			"width": {
				"?": {
					"var": "$theme.coverFullWidth"
				}
			},
			"height": {
				"?": {
					"var": "$theme.coverPageHeight"
				}
			},
			"display": "flex",
			"flexDirection": "row"
		},
		"children": [
			{
				"type": "Cover Back",
				"props": {
					"backText": { "?": { "var": "$props.backText" } }
				}
			},
			{
				"type": "Cover Spine",
				"props": {
					"title": { "?": { "var": "$props.title" } }
				}
			},
			{
				"type": "Cover Front",
				"props": {
					"title": { "?": { "var": "$props.title" } },
					"subtitle1": { "?": { "var": "$props.subtitle1" } },
					"subtitle2": { "?": { "var": "$props.subtitle2" } },
					"imageUrl": { "?": { "var": "$props.imageUrl" } }
				}
			}
		]
	}]
};
