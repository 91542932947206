<template>
	<div class="DropArea" @drop.prevent.stop="onInput" @dragover.prevent="onDragOver" @dragleave.prevent="onDragLeave">
		<label
			for="DropAreaInput"
			:class="[
				'DropArea_Label',
				disabled ? 'DropArea_Label--disabled' : '',
				dragging ? 'DropArea_Label--dragging' : ''
			]"
		>
			<p class="DropArea_Label_Title">{{ $t('Drag files anywhere to upload') }}</p>
			<p>{{ $t('or') }}</p>
			<b-button class="DropArea_Label_Button" variant="primary" @click="openPicker">
				{{ $t('Select File') }}
			</b-button>
			<p>{{ $t('Accepted file types include jpeg, png, pdf, tiff and fonts') }}</p>
		</label>
		<input
			id="DropAreaInput"
			ref="fileInput"
			type="file"
			:accept="acceptedFileTypes"
			:disabled="disabled"
			@input="onInput"
			@drop.prevent.stop="onInput"
		/>
	</div>
</template>

<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		acceptedFileTypes: {
			type: String,
			default: 'image/jpeg, image/png, image/tiff, application/pdf, .ttf, .otf, .woff, .woff2'
		}
	},
	data() {
		return {
			dragging: false
		};
	},
	methods: {
		onInput(event) {
			this.dragging = false;
			const file = _.get(event, 'target.files.0', _.get(event, 'dataTransfer.files.0'));
			this.$emit('input', file);
		},
		onDragOver() {
			this.dragging = true;
		},
		onDragLeave() {
			this.dragging = false;
		},
		openPicker() {
			this.$refs.fileInput.click();
		}
	}
};
</script>

<style lang="scss" scoped>
@import '~@oneflow/ofs-vue-layout/src/styles/variables.scss';
@import '~@oneflow/ofs-vue-layout/src/styles/mixins.scss';

.DropArea {
	#DropAreaInput {
		display: none;
	}

	&_Label {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		height: 170px;
		border: 1px dashed $of-color-aqua;
		border-radius: 3px;
		padding: 28px 0;

		&_Button {
			width: 137px;
		}

		p {
			margin: 0;
			@include ofTextStyleFormLabels();
		}

		.DropArea_Label_Title {
			@include ofTextStyleBody();
		}

		&--disabled {
			background-color: $of-color-grey-3;
		}

		&--dragging {
			background-color: $of-color-grey-4;
		}
	}
}
</style>
