<template>
	<b-modal
		:visible="isModalVisible"
		:title="$t('Deleting Recipes')"
		body-class="recipe-modal"
		size="lg"
		ok-only
		@change="$emit('hideModal')"
	>
		<div v-if="deletedDocuments.length">
			<b-alert show variant="success">{{ deletedListTextLabel }}</b-alert>
			<div class="documents-info">
				<b-card
					v-for="(docInfo, ind) in deletedDocuments"
					:key="`deleted-doc-${ind}`"
					class="documents-info__item"
				>
					<b-card-text>{{ docInfo.recordName }}</b-card-text>
				</b-card>
			</div>
		</div>

		<div v-if="warningDocuments.length" class="mt-2">
			<b-alert show variant="warning">{{ blockedListTextLabel }}</b-alert>
			<div class="accordion documents-info" role="tablist">
				<b-card v-for="(docInfo, ind) in warningDocuments" :key="docInfo.documentId">
					<b-row>
						<b-col md="9" cols="12" class="d-flex flex-column justify-content-center">
							<b-link target="_blank" :to="{ name: 'recipes.edit', params: { id: docInfo.documentId } }">
								{{ docInfo.recordName }}
							</b-link>
						</b-col>
						<b-col md="3" cols="6" class="d-flex flex-row-reverse">
							<b-button v-b-toggle="`rel-key-${ind}`">{{ $t('Show related books') }}</b-button>
						</b-col>
					</b-row>
					<b-collapse :id="`rel-key-${ind}`" class="mt-2" accordion="my-accordion" role="tabpanel">
						<b-card>
							<b-row v-for="bookInfo in getDocumentLinkedBooks(docInfo)" :key="`rel-${bookInfo.id}`">
								<b-col>
									<span class="recipe-modal__book-link" @click="redirectToBook(bookInfo.id)">
										{{ bookInfo.name }}
									</span>
								</b-col>
							</b-row>
						</b-card>
					</b-collapse>
				</b-card>
			</div>
		</div>
	</b-modal>
</template>

<script>
import _ from 'lodash';

export default {
	name: 'RecipeDeleteModal',
	props: {
		isModalVisible: {
			type: Boolean
		},
		deleteResults: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		deletedDocuments() {
			return _.get(this.deleteResults, 'deletedDocuments', []);
		},
		warningDocuments() {
			return _.get(this.deleteResults, 'warningDocuments', []);
		},
		deletedListTextLabel() {
			const delDocsCount = _.size(this.deletedDocuments);
			return delDocsCount === 1
				? this.$t('Deleted recipe')
				: this.$t('Deleted recipes', { number: delDocsCount });
		},
		blockedListTextLabel() {
			const nonDelDocsCount = _.size(this.warningDocuments);
			return nonDelDocsCount === 1
				? this.$t(`Can't delete recipe because it is included to 1 or more product content`)
				: this.$t(`Can't delete these recipes because they are included to 1 or more product content`, {
					number: nonDelDocsCount // eslint-disable-line
				  });
		}
	},
	methods: {
		getDocumentLinkedBooks(documentInfo) {
			return _.get(documentInfo, 'linkedBooks');
		},
		redirectToRecipe(id) {
			this.$router.push({ name: `recipes.edit`, params: { id: id } });
		},
		async redirectToBook(id) {
			this.$emit('hideModal');
			this.$router.push({ name: `books.edit`, params: { id: id } });
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../style/_variables.scss';

.recipe-modal {
	max-height: 70vh;
	overflow-x: auto;

	.documents-info {
		max-height: 300px;
		overflow-y: auto;

		.card-body {
			padding: 0.75rem;
		}
	}

	&__book-link {
		color: $color-pimienta;
		text-decoration: underline;
		cursor: pointer;

		&:hover {
			transition: 0.3s;
			opacity: 0.8;
		}
	}
}
</style>
