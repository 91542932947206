module.exports = {
	name: 'Notes',
	exampleProps: {
		pagesCount: 2,
		linesCount: 15
	},
	template: {
		type: 'repeater',
		props: {
			source: { '?': { var: '$props.pagesCount' } },
			itemAs: 'pageIndex',
			item: [
				{
					name: 'Page',
					type: 'page',
					props: {
						width: { '?': { var: '$theme.pageWidth' } },
						height: { '?': { var: '$theme.pageHeight' } },
						padding: '11px',
						lineHeight: 1
					},
					children: [
						{
							type: 'container',
							props: {
								width: '100%',
								height: '100%',
								flexDirection: 'column',
								alignItems: 'center',
								display: 'flex',
								paddingTop: '40px',
								paddingBottom: '55px',
								position: 'relative',
								fontSize: '8pt',
								fontFamily: 'Poppins-Light'
							},
							children: [
								{
									name: 'Text',
									type: 'text',
									props: {
										marginRight: 'auto',
										marginLeft: '63px',
										fontSize: '34pt',
										fontFamily: 'ACaslonPro-Bold',
										minHeight: '54px',
										value: {
											'?': {
												if: [
													{
														'===': [{ var: 'pageIndex' }, 0]
													},
													'Notes',
													''
												]
											}
										}
									}
								},
								{
									name: 'Lines Container',
									type: 'container',
									props: {
										display: 'flex',
										flexDirection: 'column',
										flexGrow: '1',
										width: '100%',
										justifyContent: 'space-evenly',
										alignItems: 'center',
										padding: '0 63px'
									},
									children: [
										{
											type: 'repeater',
											props: {
												source: { '?': { var: '$props.linesCount' } },
												item: [
													{
														name: 'Line',
														type: 'container',
														props: {
															width: '100%',
															borderBottom: '1.75px solid black'
														}
													}
												]
											}
										}
									]
								},
								{
									type: 'Page Number'
								}
							]
						}
					]
				}
			]
		}
	}
};
