<template>
	<table class="FormTable">
		<thead>
			<tr>
				<th v-for="field in fields" :key="field.key">{{ field.label }}</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(_, index) in items" :key="index">
				<td v-for="field in fields" :key="field.key">
					<slot :name="`cell(${field.key})`" :item="field" :index="index" />
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import _ from 'lodash';

export default {
	props: {
		fields: {
			type: Array,
			required: true,
			validate: fields => _.every(fields, ({ key }) => !!key)
		},
		items: {
			type: Array,
			default: () => []
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../style/_variables.scss';

.FormTable {
	width: 100%;
	margin-bottom: 30px;

	td,
	th {
		padding-right: 30px;
	}

	thead {
		text-align: center;
		border-bottom: 1px solid $color-hp-grey-3;

		th {
			padding-bottom: 10px;

			font-size: $of-font-size-smaller;
			line-height: 1.2;
			font-weight: $of-font-weight-semi-bold;
			color: $color-hp-grey-1;
		}
	}

	tbody {
		tr:first-child td {
			padding-top: 10px;
		}

		td {
			padding-bottom: 5px;
		}
	}
}
</style>
