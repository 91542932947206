<template>
	<div class="Upload-card">
		<div class="Upload-card__active-background" :style="{ width: `${percents}%` }"></div>
		<div class="Upload-card__container">
			<p
				class="Upload-card__link-line"
				:contenteditable="contenteditable"
				@input="inputHandler($event)"
				@paste.stop
			>
				{{ displayedText }}
			</p>
			<p
				v-show="status !== statuses.Initial"
				class="Upload-card__status-line"
				:class="statusLineClass"
				spellcheck="false"
				contenteditable="false"
			>
				{{ statusString }}
			</p>
		</div>
		<div class="Button-bar">
			<a
				v-show="status === statuses.Failed || status === statuses.Success"
				class="Button-bar__icon Button-bar__retry-button"
				@click="retryHandler"
			>
				<icon name="redo"></icon>&nbsp;Retry
			</a>
			<icon
				v-show="status === statuses.Failed"
				class="Button-bar__icon Button-bar__icon--error"
				name="exclamation-triangle"
			></icon>
			<icon
				v-show="status === statuses.Success"
				class="Button-bar__icon Button-bar__icon--success"
				name="check-circle"
			></icon>
			<icon
				v-if="status === statuses.Initial"
				class="Button-bar__icon Button-bar--hidden show-on-hover"
				name="times-circle"
				@click="removeHandler($event)"
			></icon>
		</div>
		<div class="Upload-card__additional-slot-container">
			<slot name="additional" :item="item"> </slot>
		</div>
	</div>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export const statuses = {
	Initial: 'initial',
	Failed: 'failed',
	Uploading: 'uploading',
	Success: 'success'
};

export default {
	props: {
		cardItem: {
			type: Object,
			default: null
		},
		contenteditable: {
			type: Boolean,
			default: false
		},
		showScrapingRuleSelector: {
			type: Boolean,
			default: false
		},
		customStatuses: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		const statusesTextDefaults = {
			failed: `${this.$t('Error')} -- `,
			uploading: `${this.$t('Retrieving data from URL')} - `,
			success: this.$t('Done')
		};
		const statusesText = _.merge({}, statusesTextDefaults, this.customStatuses);
		return {
			displayedText: this.cardItem.text,
			item: this.cardItem,
			percents: 0,
			statusInterval: null,
			scrapingRuleId: null,
			statusesText,
			statuses
		};
	},
	computed: {
		...mapGetters({
			rules: 'scraping-rule/scraping-rules'
		}),
		status() {
			return this.item.status;
		},
		statusString() {
			switch (this.status) {
				case statuses.Uploading:
					return `${this.statusesText.uploading} ${this.percents.toFixed(0)}%`;
				case statuses.Success:
					return `${this.statusesText.success}`;
				case statuses.Failed:
					return `${this.statusesText.failed} ${this.item.errors[0].message}`;
				default:
					return '';
			}
		},
		statusLineClass() {
			switch (this.status) {
				case statuses.Success:
					return 'Upload-card__status-line--success';
				case statuses.Failed:
					return 'Upload-card__status-line--error';
				default:
					return '';
			}
		},
		rulesOptions() {
			return _.map(this.rules, rule => ({ text: rule.name, value: rule.id }));
		}
	},
	watch: {
		status(newValue, oldValue) {
			switch (newValue) {
				case statuses.Uploading:
					this.uploadingStarted();
					break;
				case statuses.Success:
				case statuses.Failed:
					this.uploadingFinished();
					break;
				default:
					break;
			}
		}
	},
	methods: {
		uploadingStarted() {
			this.statusInterval = setInterval(() => {
				if (this.percents < 60) {
					this.percents += 10;
				} else if (this.percents < 90) {
					this.percents += 5;
				} else {
					this.percents += (99 - this.percents) / 5;
				}
			}, 500);
		},
		uploadingFinished() {
			clearInterval(this.statusInterval);
			this.percents = 100;
		},
		inputHandler($event) {
			this.item.text = $event.target.innerText;
			this.$emit('itemChange', this.item);
		},
		handleScrapingRulesChange() {
			this.item.scrapingRuleId = this.scrapingRuleId;
			this.$emit('itemChange', this.item);
		},
		removeHandler($event) {
			this.$emit('remove', $event);
		},
		retryHandler() {
			this.percents = 0;
			this.$emit('retry');
		}
	}
};
</script>
<style lang="scss">
@import '../../style/_variables.scss';

.Upload-card {
	background: white;
	position: relative;

	&__active-background {
		background-color: rgba($color-pimienta, 0.1);
		position: absolute;
		top: 0;
		left: 0;
		height: 55px;
		width: 0;
		z-index: 0;
	}

	&__container {
		z-index: 1;
		position: absolute;
		height: 55px;
		box-shadow: 0px -1px 0px $color-hp-grey-3, 0px 1px 0px $color-hp-grey-3;
		width: 100%;
		padding: 17px 24px 16px 24px;
	}

	&__additional-slot-container {
		padding-top: 55px;
	}

	&__status-line {
		font-family: Source Sans Pro, serif;
		font-size: 13px;
		font-style: italic;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0;
		text-align: left;
		color: $color-hp-grey-2;
		margin: 0;
		position: relative;
		top: -2px;
	}

	&__status-line--success {
		color: $color-hp-green;
	}

	&__status-line--error {
		color: $color-hp-red;
	}

	&__link-line {
		margin: 0;
		outline: none;
		overflow: hidden;
		white-space: nowrap !important;
		max-width: 100%;
		max-height: 1.5em;
		margin-right: 200px;
	}
}

.Upload-card:hover .show-on-hover {
	display: block !important;
}

.Button-bar {
	position: absolute;
	right: 25px;
	top: 19px;
	z-index: 3;

	&__icon {
		position: absolute;
		cursor: pointer;

		&--error {
			color: $color-hp-red;
			right: 60px;
		}

		&--success {
			color: $color-hp-green;
			right: 60px;
		}
	}

	&--hidden {
		display: none !important;
	}

	&__retry-button {
		color: $color-pimienta;
		right: 125px;
		width: 60px;
	}
}
</style>
