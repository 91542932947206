module.exports = {
	base: {
		pageWidth: '794px',
		pageHeight: '794px',
		primaryFont: "'Open Sans Condensed', sans-serif",
		secondaryFont: 'Space Grotesk',
		bodyFont: 'Open Sans',
		primaryFontSize: '0.7cm',
		secondaryFontSize: '1cm',
		titleBackgroundLuminance: 25,
		primaryColor: 'rgba(114, 190, 206, 0.85)',
		notes: {
			lineColor: 'rgba(114, 190, 206, 0.85)',
			lineHeight: '1cm'
		}
	}
};
