<template>
	<BookCreateV2 v-if="coverInnerSeparationEnabled" />
	<BookCreate v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import { featureFlags } from '../../../../constants';
import BookCreate from './BookCreate.vue';
import BookCreateV2 from './BookCreateV2.vue';
export default {
	name: 'BookCreatePage',

	components: {
		BookCreate,
		BookCreateV2
	},
	computed: {
		...mapGetters({
			features: 'feature/features'
		}),
		coverInnerSeparationEnabled() {
			return this.features?.[featureFlags.coverInnerSeparation];
		}
	}
};
</script>
