import _ from 'lodash';
import jsonLogic from 'json-logic-js';
import { required } from 'vuelidate/lib/validators';
import { validateWithMessage } from '@oneflow/ofs-vue-layout';
import { hexColor } from '../../../../lib/validators';
import { documentSizes } from '../../../../constants';

/* Map configurable appearance settings with the base theme options */
export const optionsMap = {
	coverHeadersFontFamily: 'theme.cover.front.title.fontFamily',
	coverHeadersColor: 'theme.cover.front.title.color',
	coverOverlayBackgroundColor: 'theme.cover.container.color',
	coverBackgroundColor: 'theme.cover.back.background.color',
	contentHeadersFontFamily: 'theme.content.titleFont',
	contentHeadersColor: 'theme.content.titleColor'
};

/* The list of appearance settings with select options */
export const settingsWithOptions = [
	'coverHeadersFontFamily',
	'contentHeadersFontFamily',
	'coverBackgroundColor',
	'coverOverlayBackgroundColor'
];

export const optionValidationRules = $t => ({
	coverHeadersColor: {
		colorHex: validateWithMessage($t('Must have valid HEX colour format'), hexColor),
		colorRequired: validateWithMessage($t('Colour option is required'), required)
	},
	coverOverlayBackgroundColor: {
		colorHex: validateWithMessage($t('Must have valid HEX colour format'), hexColor)
	},
	coverBackgroundColor: {
		colorHex: validateWithMessage($t('Must have valid HEX colour format'), hexColor),
		colorRequired: validateWithMessage($t('Colour option is required'), required)
	},
	contentHeadersColor: {
		colorHex: validateWithMessage($t('Must have valid HEX colour format'), hexColor),
		colorRequired: validateWithMessage($t('Colour option is required'), required)
	}
});

export const colorHexValidator = $t => ({
	colorHex: validateWithMessage($t('Must have valid HEX colour format'), hexColor)
});

export const getTemplateOptions = template => {
	const { variants } = template.theme;
	const options = _.reduce(
		settingsWithOptions,
		(options, field) => ({ ...options, [field]: _.map(variants, optionsMap[field], []) }),
		{}
	);
	return _.mapValues(options, _.uniq);
};

export const getDefaultAppearance = theme => _.mapValues(optionsMap, path => _.get(theme, path));

export const getActiveSectionFields = (fields, formData) => {
	return _.filter(fields, ({ condition }) => (condition ? jsonLogic.apply(condition, formData) : true));
};

export const buildSectionFieldPath = (field, section = {}, subsection = {}) => {
	if (_.get(field, 'definition.root')) return 'content';

	return `content${section.key ? `.${section.key}` : ''}${subsection.key ? `.${subsection.key}` : ''}`;
};

const isSingleSpreadItem = size => {
	return size === documentSizes.SHORT || size === documentSizes.STANDARD;
};

export const checkUnpairedItems = documents => {
	let isLinkedRecipes = _.times(documents.length, _.constant(null));
	let counter = 0;
	documents
		.map(document => isSingleSpreadItem(document?.record?.size))
		.concat([false]) // for last element be processed properly
		.forEach((el, index) => {
			if (el) {
				counter++;
				isLinkedRecipes[index] = true;
				return;
			}

			if (counter % 2 === 1) {
				isLinkedRecipes[index - 1] = false;
			}

			counter = 0;
		});
	return isLinkedRecipes;
};
