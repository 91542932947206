import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'contributor',
		pluralName: 'accounts',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		state: {
			contributors: {
				total: 0,
				data: [],
				limit: 10,
				skip: 0
			}
		},
		getters: {
			contributors: state => state.contributors
		},
		actions: {
			getBookContributors: async ({ dispatch, commit }, { query = {} }) => {
				const path = `accounts`;
				const contributors = await dispatch('request', { method: 'GET', path, params: query }).then(
					r => r.data
				);

				commit('SET_BOOK_CONTRIBUTORS', contributors);

				return contributors;
			},
			getContributorOptions: async ({ dispatch }, { query = {} }) => {
				const path = `accounts/contibutor-options`;
				const contributors = await dispatch('request', { method: 'GET', path, params: query }).then(
					r => r.data
				);

				return contributors;
			}
		},
		mutations: {
			SET_BOOK_CONTRIBUTORS(state, contributors) {
				state.contributors = contributors;
			}
		}
	});
}
