import RecipeEdit from './Recipes/RecipeEdit';
import RecipesScraper from './Recipes/RecipesScraper';
import RecipesList from './Recipes/RecipesList';

export const routes = [
	{
		path: '/recipes',
		name: 'recipes.list',
		component: RecipesList
	},
	{
		path: '/recipes/:id',
		name: 'recipes.edit',
		component: RecipeEdit
	},
	{
		path: '/recipes/scraper',
		name: 'recipes.scraper',
		component: RecipesScraper
	}
];

export default routes;
