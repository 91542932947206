module.exports = {
	name: 'Recipe',
	exampleProps: {
		recipe: {
			id: '',
			sourceURL: 'https://www.savourous.com/recipe-view/gluten-free-plantain-waffle-with-mild-hot-sauce/',
			name: 'Plantain Waffle',
			author: '',
			category: 'breakfast',
			description:
				'Plantain Waffle with Mild Hot sauce is my fun way to eat plantain for breakfast. Also because plantain is eating mostly with hot sauce in West Africa, adding my mild green sauce to this plantain is sure a delight. It is a great way to enjoy waffles with kids while staying with my westafrican cuisine […]',
			ingredients: {
				type: 'SECTION',
				text: 'Ingredients',
				elements: [
					{
						type: 'INGREDIENT',
						quantity: 0,
						units: '',
						text: '2 medium ripe Plantains'
					},
					{
						type: 'INGREDIENT',
						quantity: 0,
						units: '',
						text: '2 Eggs'
					},
					{
						type: 'INGREDIENT',
						quantity: 0,
						units: '',
						text: '1 Tablespoon of coconut oil to oil the waffle maker'
					},
					{
						type: 'INGREDIENT',
						quantity: 0,
						units: '',
						text: 'Pinch of salt (optional)'
					},
					{
						type: 'SECTION',
						text: 'For the Mild Green Sauce',
						elements: [
							{
								type: 'INGREDIENT',
								quantity: 0,
								units: '',
								text: '4 jalopenos peppers'
							},
							{
								type: 'INGREDIENT',
								quantity: 0,
								units: '',
								text: '1/2 of medium green bell pepper'
							},
							{
								type: 'INGREDIENT',
								quantity: 0,
								units: '',
								text: '1/4 of medium onion'
							},
							{
								type: 'INGREDIENT',
								quantity: 0,
								units: '',
								text: '1/2 teaspoon of Salt'
							},
							{
								type: 'INGREDIENT',
								quantity: 0,
								units: '',
								text: '4 tablespoons of oil (optional)'
							}
						]
					}
				]
			},
			instructions: {
				type: 'SECTION',
				text: 'Instructions',
				elements: [
					{
						type: 'INSTRUCTION',
						text: 'In a food processor,purree the peeled plantain with 2 eggs and pinch of salt'
					},
					{
						type: 'INSTRUCTION',
						text: 'Start cooking in batch in your waffle maker.'
					},
					{
						type: 'INSTRUCTION',
						text:
							'While the waffle is cooking you can make your hot sauce by finely blending: jalopeno pepper, onion, oil, salt, bell pepper.'
					},
					{
						type: 'INSTRUCTION',
						text: 'Heat up the hot sauce and serve over your waffles.'
					}
				]
			},
			tags: ['Breakfast & Brunch', 'Favorite Recipes', 'Gluten Free Recipes'],
			recipeYield: '2',
			images: [
				{
					name: 'Plantain waffles with hot pepper',
					url:
						'https://i0.wp.com/www.savourous.com/wp-content/uploads/2021/08/plantainwaffle-new.jpg?fit=1936,1936&ssl=1',
					thumbnailURL: '',
					fileURL: ''
				}
			],
			nutrition: {
				calories: 0,
				fat: 0,
				protein: 0,
				carbs: 0
			},
			cookingTime: {
				prepTime: '',
				cookTime: '',
				totalTime: ''
			}
		}
	},
	template: [
		{
			name: 'Left Page',
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } },
				classNames: ['page-recipe'],
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			},
			children: [
				{
					type: 'image',
					props: {
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						zIndex: '10',
						margin: '5%',
						src: { '?': { var: '$props.recipe.images.0.url' } }
					}
				}
			]
		},
		{
			name: 'Right Page',
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } },
				classNames: ['page-recipe', 'page-with-number']
			},
			children: [
				{
					name: 'Recipe container',
					type: 'container',
					props: {
						padding: '30px 83px 19px 91px'
					},
					children: [
						{
							name: 'Recipe servings container',
							type: 'container',
							props: {
								display: 'flex',
								justifyContent: 'start',
								alignItems: 'center',
								fontFamily: { '?': { var: '$theme.primaryFont' } },
								fontSize: '10pt'
							},
							children: [
								{
									name: 'Recipe servings',
									type: 'text',
									props: {
										marginRight: '5px',
										textTransform: 'uppercase',
										value: 'serves'
									}
								},
								{
									name: 'Recipe number',
									type: 'text',
									props: {
										value: { '?': { var: '$props.recipe.recipeYield' } }
									}
								}
							]
						},
						{
							name: 'Recipe name',
							type: 'text',
							props: {
								width: '100%',
								fontSize: '17pt',
								textTransform: 'uppercase',
								fontWeight: 'bold',
								paddingTop: '5px',
								letterSpacing: '3px',
								fontFamily: { '?': { var: '$theme.primaryFont' } },
								borderTop: '1px solid #29a5b2',
								value: { '?': { var: '$props.recipe.name' } }
							}
						},
						{
							type: 'container',
							props: {
								classNames: ['recipe-content-wrapper'],
								width: '100%',
								fontSize: '11px',
								flexWrap: 'wrap'
							},
							children: [
								{
									name: 'Recipe description',
									type: 'paragraph',
									props: {
										margin: '14px 0 20px 0',
										value: { '?': { var: '$props.recipe.description' } },
										fontFamily: { '?': { var: '$theme.primaryFont' } },
										fontWeight: '300',
										fontSize: '10pt',
										lineHeight: '14pt'
									}
								},
								{
									type: 'container',
									name: 'Recipe ingredents box',
									props: {
										classNames: ['recipe-content'],
										fontSize: '1em',
										display: 'flex',
										justifyContent: 'space-between'
									},
									children: [
										{
											type: 'container',
											name: 'Recipe ingredents box',
											props: {
												classNames: ['recipe-content-ingridients'],
												width: '30%'
											},
											children: [
												{
													name: 'Recipe ingredients',
													type: 'list',
													props: {
														fontSize: '8pt',
														fontFamily: { '?': { var: '$theme.bodyFont' } },
														listStyle: 'none',
														paddingLeft: '0',
														margin: '0',
														childTitle: {
															color: '#29a5b2',
															fontWeight: 'bold',
															fontFamily: { '?': { var: '$theme.primaryFont' } },
															marginBottom: '5px',
															textTransform: 'uppercase'
														},
														childText: {
															marginBottom: '5px',
															fontFamily: { '?': { var: '$theme.bodyFont' } }
														},
														value: { '?': { var: '$props.recipe.ingredients.elements' } }
													}
												}
											]
										},
										{
											type: 'container',
											name: 'Recipe instructions box',
											props: {
												classNames: ['recipe-content-instructions'],
												width: '65%'
											},
											children: [
												{
													name: 'Recipe instructions',
													type: 'list',
													props: {
														fontSize: '9pt',
														fontFamily: { '?': { var: '$theme.bodyFont' } },
														listStyle: 'none',
														margin: '0',
														paddingLeft: '0',
														fontWeight: '300',
														childTitle: {
															color: '#29a5b2',
															fontWeight: 'bold',
															fontFamily: { '?': { var: '$theme.primaryFont' } },
															marginBottom: '5px',
															textTransform: 'uppercase'
														},
														childText: {
															marginBottom: '10px'
														},
														value: { '?': { var: '$props.recipe.instructions.elements' } }
													}
												}
											]
										}
									]
								}
							]
						}
					]
				},
				{
					type: 'Page Number'
				}
			]
		}
	]
};
