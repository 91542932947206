<!-- eslint-disable max-len -->
<template>
	<svg
		class="grip-vertical"
		:class="{ disabled: disabled }"
		width="10"
		height="26"
		viewBox="0 0 10 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.34783 0H0V4.34783H4.34783V0ZM4.34783 5.21739H0V9.56522H4.34783V5.21739ZM0 10.4348H4.34783V14.7826H0V10.4348ZM4.34783 15.6522H0V20H4.34783V15.6522ZM0 20.8696H4.34783V25.2174H0V20.8696ZM10 0H5.65217V4.34783H10V0ZM5.65217 5.21739H10V9.56522H5.65217V5.21739ZM10 10.4348H5.65217V14.7826H10V10.4348ZM5.65217 15.6522H10V20H5.65217V15.6522ZM10 20.8696H5.65217V25.2174H10V20.8696Z"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../../style/variables';

.grip-vertical {
	min-width: 10px;

	path {
		fill: $color-hp-grey-3;
	}

	&.disabled path {
		fill: $color-hp-highlights;
	}
}
</style>
