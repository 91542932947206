var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('Page',{staticClass:"RecipesList"},[_c('ListTable',{attrs:{"bordered":"","hover":"","table-title":_vm.$t('Recipes'),"config":_vm.config,"items":_vm.documents.data,"fields":_vm.fields,"sort":_vm.sort,"total-items":_vm.documents.total,"current-page":_vm.currentPage,"per-page":_vm.perPage,"fetch-data":_vm.fetchData,"is-busy":_vm.isDocumentsFetching},on:{"row-clicked":_vm.handleRowClick,"table-change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"head(checked)",fn:function(ref){return [_c('div',{staticClass:"select-all-checkbox-container"},[_c('of-form-checkbox',{attrs:{"value":_vm.allActiveItemsSelected},on:{"input":function($event){return _vm.toggleSelectAllActiveItems()}}})],1)]}},{key:"cell(checked)",fn:function(ref){
var item = ref.item;
return [_c('of-form-checkbox',{attrs:{"value":_vm.checkIsItemSelected(item._id)},on:{"input":function($event){return _vm.toggleSelectedItem(item)}}})]}},{key:"TableButtons-Slot-left",fn:function(ref){return [_c('SearchInput',{staticClass:"col-lg-6 col-md-4 col-sm-5",attrs:{"placeholder":_vm.$t('Search recipes by name...')},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})]}},{key:"cell(validationErrors)",fn:function(ref){
var item = ref.item;
return [_c('ItemStatus',{attrs:{"item":item}})]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fromNow(item.updatedAt))+" ")]}},{key:"TableButtons-Slot-right",fn:function(ref){return [_c('div',{staticClass:"TableButtons-Right col-auto ml-2"},[_c('b-dropdown',{staticClass:"Dropdown-buttons",attrs:{"text":_vm.$t('Download'),"variant":"light","right":""}},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.handleSave({ exportType: _vm.exportTypes.JSON })}}},[_c('icon',{staticClass:"mr-2",attrs:{"name":"file-code"}}),_vm._v(" "+_vm._s(_vm.$t('Download JSON'))+" ")],1),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.handleSave({ exportType: _vm.exportTypes.PDF })}}},[_c('icon',{staticClass:"mr-2",attrs:{"name":"file-pdf"}}),_vm._v(" "+_vm._s(_vm.$t('Download PDF'))+" ")],1)],1),_c('b-dropdown',{staticClass:"Dropdown-buttons",attrs:{"text":_vm.$t('New Recipe'),"right":"","variant":"primary"}},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":_vm.handleImportRecipe}},[_c('icon',{staticClass:"mr-2",attrs:{"name":"file-upload"}}),_vm._v(" "+_vm._s(_vm.$t('Import'))+" ")],1),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":_vm.handleManualEntry}},[_c('icon',{staticClass:"mr-1",attrs:{"name":"plus-circle"}}),_vm._v(" "+_vm._s(_vm.$t('Manual entry'))+" ")],1)],1)],1)]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"size":"sm","toggle-class":"p-1","right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('icon',{attrs:{"name":"ellipsis-h"}})]},proxy:true}],null,true)},_vm._l((_vm.rowActions),function(ref){
var icon = ref.icon;
var label = ref.label;
var handler = ref.handler;
return _c('b-dropdown-item',{key:label,on:{"click":function($event){return handler({ item: item, exportType: _vm.exportTypes.PDF })}}},[_c('icon',{staticClass:"mr-2",attrs:{"name":icon}}),_vm._v(" "+_vm._s(label)+" ")],1)}),1)]}},{key:"cell(button)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{on:{"click":function($event){return _vm.handleEditRecipe({ item: item })}}},[_vm._v(_vm._s(_vm.$t('Edit')))])]}}])}),_c('ItemPreviewOverlay',{attrs:{"is-visible":!!_vm.selectedDocumentId,"recipe":_vm.selectedDocument},on:{"hide":function () { return (_vm.selectedDocumentId = null); }}})],1),_c('ImportModal',{attrs:{"modal-id":"importRecipesModal","visible":false}}),_c('DownloadModal',{attrs:{"is-visible":_vm.downloadModalData.showDownloadModal,"total":_vm.downloadModalData.documentsTotal,"downloaded":_vm.downloadModalData.downloadedDocuments.length,"download-status":_vm.downloadModalData.downloadStatus,"valid-items":_vm.downloadModalData.validItems,"invalid-items":_vm.downloadModalData.invalidItems,"recipes-with-processed-images":_vm.downloadModalData.recipesWithProcessedImages,"recipes-wrote":_vm.downloadModalData.recipesWrote},on:{"hidden":_vm.resetDownloadModalData}}),_c('ImportModal',{attrs:{"modal-id":"importRecipesModal","visible":false}}),_c('RecipeDeleteModal',{attrs:{"is-modal-visible":_vm.isRecipeDeleteModalVisible,"delete-results":_vm.deleteResults},on:{"hideModal":_vm.hideRecipeDeleteModal}}),_c('SelectedRecipesNotification',{attrs:{"is-visible":_vm.showSelectedRecipesNotification,"selected-items":_vm.selectedItems},on:{"removeRecipes":_vm.handleRemoveManyRecipes,"deselectAllRecipes":_vm.handleDeselectAllRecipes}}),(_vm.areRecipesRemoving)?_c('Loader',{attrs:{"overlay":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }