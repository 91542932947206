<template>
	<intersect @enter="handleEnter" @leave="handleLeave">
		<slot v-bind="{ shouldRender }"></slot>
	</intersect>
</template>

<script>
import Intersect from 'vue-intersect';

export default {
	components: { Intersect },
	data() {
		return {
			isVisible: false,
			hasBeenVisibleBefore: false
		};
	},
	computed: {
		shouldRender() {
			return this.isVisible;
		}
	},
	methods: {
		handleEnter() {
			this.isVisible = true;
			this.hasBeenVisibleBefore = true;
		},
		handleLeave() {
			this.isVisible = false;
		}
	}
};
</script>
