<template>
	<b-row>
		<b-col
			v-for="(page, pageIndex) in coverPages"
			:key="`cover-${pageIndex}`"
			:cols="page.meta.productComponent === 'cover' ? 12 : 6"
			class="overflow-hidden p-4"
		>
			<div class="shadow">
				<PagePreview :json="page" :fonts="fonts" is-cover />
			</div>
		</b-col>
		<b-col
			v-for="(page, pageIndex) in contentPages"
			:key="`content-${pageIndex}`"
			cols="6"
			class="overflow-hidden p-4"
		>
			<Intersect v-slot="{ shouldRender }">
				<div class="responsive-square shadow">
					<PagePreview v-if="shouldRender" :json="page" :fonts="fonts" />
				</div>
			</Intersect>
		</b-col>
	</b-row>
</template>

<script>
import _ from 'lodash';
import PagePreview from './PagePreview';
import Intersect from './Intersect';
import { coverComponentTypes } from '../constants';

export default {
	components: {
		PagePreview,
		Intersect
	},
	props: {
		json: {
			type: Array,
			default: null
		},
		fonts: {
			type: Array,
			default: null
		}
	},
	computed: {
		contentPages() {
			return _.filter(this.json, i => !coverComponentTypes.includes(_.get(i, 'meta.productComponent')));
		},
		coverPages() {
			const pages = _.filter(this.json, i => coverComponentTypes.includes(_.get(i, 'meta.productComponent')));
			return _.size(pages) === 2 ? _.reverse(pages) : pages;
		}
	}
};
</script>
