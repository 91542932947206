<template>
	<b-row class="UploaderProgress">
		<div class="UploaderProgress_Icon">
			<icon size="3x" :name="icon" />
		</div>
		<div class="UploaderProgress_Progress">
			<div class="UploaderProgress_Progress_Info">
				<p>{{ filename }}</p>
				<p>{{ progressLabel }}</p>
			</div>
			<b-progress height="7px" class="UploaderProgress_Progress_Bar" :value="progress" :max="100" />
		</div>
		<icon class="UploaderProgress_RemoveIcon" name="times-circle" @click="onRemoveClick" />
	</b-row>
</template>

<script>
export default {
	props: {
		progress: {
			type: Number,
			default: 0
		},
		filename: {
			type: String,
			required: true
		},
		fileType: {
			type: String,
			required: true
		}
	},
	computed: {
		progressLabel() {
			if (this.progress < 100) {
				return `${this.progress.toFixed(0)}%`;
			}
			return this.$t('Complete');
		},
		icon() {
			switch (this.fileType) {
				case 'image/jpeg':
				case 'image/png':
				case 'image/tiff':
					return 'file-image';
				case 'application/pdf':
					return 'file-pdf';
				default:
					return 'file-image';
			}
		}
	},
	methods: {
		onRemoveClick() {
			this.$emit('remove');
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/src/styles/variables.scss';

.UploaderProgress {
	display: flex;
	flex-direction: row;
	height: 70px;
	padding: 10px 20px 15px 12px;
	border-bottom: 1px solid $of-color-grey-3;
	border-top: 1px solid $of-color-grey-3;
	align-items: center;

	&_Icon {
		color: $of-color-grey-2;
		margin-right: 12px;
	}

	&_Progress {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;

		&_Info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}

	&_RemoveIcon {
		color: $of-color-grey-2;
		cursor: pointer;
		margin-left: 50px;

		&:hover {
			color: $of-color-dark;
		}
	}
}
</style>
