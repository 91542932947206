<template>
	<div class="ChapterItems">
		<b-alert v-if="unpairedSpread" show dismissible variant="warning">
			<img src="@/assets/alert-orange.svg" class="alert-icon" alt="alert icon" />
			{{ $t('You have unpaired singles spreads. Single spreads need to be paired before you can save.') }}
		</b-alert>

		<table v-if="documentIds.length" class="ChaperTable table b-table table-hover">
			<thead>
				<tr>
					<th></th>
					<th>{{ $t('Order') }}</th>
					<th></th>
					<th>{{ $t('Item name') }}</th>
					<th class="text-center">{{ $t('Item Size') }}</th>
					<th class="text-center">{{ $t('Spread') }}</th>
					<th class="text-center">{{ $t('Status') }}</th>
					<th></th>
				</tr>
			</thead>
			<draggable :value="items" tag="tbody" handle=".drag-handle" @end="handleDragleave" @input="handleDragInput">
				<tr
					v-for="(document, index) in items"
					:key="document.id"
					@click.stop="event => handleRowClick(document, index, event)"
				>
					<td class="w-0"><img src="../../../assets/grip-vertical.svg" class="drag-handle" /></td>
					<td class="w-0 text-center">{{ index + 1 }}</td>
					<td><Thumbnail :src="getDocumentImageUrl(document)" /></td>
					<td>{{ _get(document, 'record.name') }}</td>
					<td class="text-center">
						<ItemSize :size="_get(document, 'record.size')" />
					</td>
					<td class="text-center">
						<ItemSpread
							:id="_get(document, 'id')"
							:size="_get(document, 'record.size')"
							:is-linked="isLinkedItems[index]"
						/>
					</td>
					<td class="text-center">
						<ItemStatus :item="document" />
					</td>
					<td class="ChaperTable-Actions text-right">
						<b-button size="sm" class="mr-1" @click="onEditDocument(document.id)">
							<icon name="pen" />
						</b-button>
						<b-button size="sm" @click="onRemoveDocument(document.id)">
							<icon name="trash" />
						</b-button>
					</td>
				</tr>
			</draggable>
		</table>
		<div v-else class="TablePlaceholder">
			<div class="TablePlaceholder__Content">
				<img src="../../../assets/empty-folder.svg" alt="Empty Folder" />
				<div class="mt-3">
					<b>{{ $t('No items have been added yet') }}</b>
				</div>
				<div class="TablePlaceholder__Content-Label">
					{{ $t('Select the items you wish to add to this chapter') }}
				</div>
				<b-button variant="primary" size="sm" class="mt-2" @click="$emit('add')">
					{{ $t('Select Items') }}
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import Draggable from 'vuedraggable';
import Thumbnail from '../../../components/Thumbnail.vue';
import ItemStatus from '../../../components/ItemStatus.vue';
import ItemSize from '../../../components/ItemSize.vue';
import ItemSpread from '../../../components/ItemSpread.vue';
import { checkUnpairedItems } from './BookEdit/helpers';

export default {
	components: {
		Draggable,
		Thumbnail,
		ItemStatus,
		ItemSpread,
		ItemSize
	},
	props: {
		documentIds: {
			type: Array,
			default: () => []
		},
		documents: {
			type: Object,
			default: () => ({})
		},
		sectionIndex: {
			type: Number,
			default: 0
		},
		activeSectionIndex: {
			type: Number,
			default: 0
		}
	},
	data: () => ({
		isLinkedItems: []
	}),
	computed: {
		items() {
			const items = _.map(this.documentIds, id => this.documents[id]);
			return _.compact(items);
		},
		unpairedSpread() {
			return this.isLinkedItems.includes(false);
		}
	},
	watch: {
		documentIds: {
			handler(value, oldValue) {
				if (_.isEqual(value, oldValue)) return;

				this.processDocumentsLinks();
			}
		},
		// also need to be handled because of async recipes fetch on adding
		documents: {
			handler(value, oldValue) {
				if (_.isEqual(value, oldValue)) return;

				this.processDocumentsLinks();
			}
		}
	},
	mounted() {
		this.processDocumentsLinks();
	},
	methods: {
		_get: _.get,
		getDocumentImageUrl(document) {
			const image = _.get(document, 'record.images[0]', {});
			return image.thumbnailURL || image.fileURL || image.url;
		},
		handleRowClick(item, index, event) {
			const isButtonTarget = _.some(event.path, el => el.type === 'button');
			if (isButtonTarget) return;

			this.$emit('view', item.id);
		},
		onEditDocument(documentId) {
			this.$router.push({ name: 'recipes.edit', params: { id: documentId } });
		},
		onRemoveDocument(documentId) {
			const documentIds = _.filter(this.documentIds, id => id !== documentId);
			this.$emit('input', documentIds);
		},
		processDocumentsLinks() {
			const documents = _.cloneDeep(_.map(this.documentIds, id => this.documents[id]));
			this.isLinkedItems = checkUnpairedItems(documents);
		},
		handleDragleave() {
			this.processDocumentsLinks();
		},
		handleDragInput(items) {
			const documentIds = items.map(item => item.id);
			this.$emit('input', documentIds);
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/variables.scss';

.ChapterItems {
	.ChaperTable {
		&-Actions {
			.btn {
				width: 30px;
				height: 30px;
				padding: 6px;
				text-align: center;
				border-color: $color-hp-grey-3;
			}
		}
	}

	.TablePlaceholder {
		display: flex;
		justify-content: center;

		&__Content {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 100px;

			&-Label {
				font-size: 14px;
				color: $color-hp-grey-2;
			}

			img {
				max-width: 55px;
			}
		}
	}
}

.w-0 {
	width: 0 !important;
}
</style>
