<template>
	<li
		role="presentation"
		class="TabsNavItem nav-item ChapterTabs__Tab"
		:class="{ 'TabsNavItem--disabled': disabled, 'TabsNavItem--required': required }"
		@click="!disabled && $emit('click')"
	>
		<a role="tab" target="_self" class="nav-link" :class="{ active }">
			<div class="d-flex align-items-center">
				<GripVertical class="drag-handle mr-2" :disabled="required" />
				{{ title }}
			</div>
			<icon name="minus-circle" @click.stop="$emit('remove')" />
			<Badge v-if="invalidFieldsNumber" variant="warning">
				{{ invalidFieldsNumber }}
			</Badge>
		</a>
	</li>
</template>

<script>
import Badge from '../../components/Badge.vue';
import GripVertical from '../icons/GripVertical.vue';

export default {
	components: {
		Badge,
		GripVertical
	},
	props: {
		title: {
			type: String,
			required: true
		},
		active: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		invalidFieldsNumber: {
			type: Number,
			default: 0
		}
	}
};
</script>

<style lang="scss">
@import '../../style/variables';

.SectionTabs .TabsNavItem {
	cursor: pointer;
	margin-bottom: 5px;

	a.nav-link {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 200px;
		color: #000;
		border: 1px solid $color-hp-grey-3;
		border-radius: $of-border-radius;
		padding: 8px 30px 8px 10px !important;
		word-break: break-word;

		&.active {
			background: rgba(82, 137, 220, 0.1);
			border-color: $color-pimienta;

			&:before {
				display: none;
			}
		}

		.fa-icon {
			position: absolute;
			right: 10px;
			width: 16px;
			height: 16px;
			color: $color-hp-grey-2;
			visibility: hidden;
		}

		.Badge {
			position: absolute;
			right: 10px;
		}

		&:hover {
			.fa-icon {
				visibility: visible;
			}
		}
	}

	&--disabled a {
		color: $color-hp-grey-2 !important;

		&:hover {
			background: #fff !important;
		}
	}

	&--required a:hover {
		.fa-icon {
			display: none;
		}
	}

	&:not(&--required) a:hover {
		.Badge {
			display: none;
		}
	}
}
</style>
