<template>
	<FullscreenLayout>
		<b-form novalidate @submit.prevent="onSave">
			<b-container class="BookCreate">
				<h1 class="BookCreate-header">{{ $t('Create a new recipe book') }}</h1>

				<section class="BookCreate-section">
					<label>{{ $t('1. Your books title') }}</label>
					<OfFormInput name="name" :placeholder="$t('The name of your book')" no-label required />
				</section>

				<section class="BookCreate-section BoundSection">
					<label>{{ $t('2. How would you like your book bound?') }}</label>
					<div class="BookOptionsGrid" :class="{ 'BookOptionsGrid--selected': !!formData.specificationCode }">
						<div
							v-for="specification in bookSpecifications"
							:key="specification.code"
							class="OptionItem"
							:class="{ 'OptionItem--active': specification.code === formData.specificationCode }"
							@click="selectSpecification(specification.code)"
						>
							<div class="OptionItem-image">
								<div class="OptionImage-mask"></div>
								<img :src="specification.image" :alt="specification.name" />
							</div>
							<div class="OptionItem-title text-center mt-3">{{ specification.name }}</div>
						</div>
					</div>
				</section>

<!--				<section v-if="contentLayouts.length" class="BookCreate-section">-->
<!--					<label>{{ $t('3. Select a cover template for your book') }}</label>-->
<!--					<div-->
<!--						class="BookOptionsGrid"-->
<!--						:class="{ 'BookOptionsGrid&#45;&#45;selected': !!formData.activeCoverLayoutId }"-->
<!--					>-->
<!--						<div v-for="layout in coverLayouts" :key="`${layout._id}-cover`">-->
<!--							<div-->
<!--								class="OptionItem"-->
<!--								:class="{ 'OptionItem&#45;&#45;active': layout._id === formData.activeCoverLayoutId }"-->
<!--								@click="selectCoverLayout(layout._id)"-->
<!--							>-->
<!--								<img :src="getCoverLayoutThumbnail(layout)" />-->
<!--								<div class="OptionItem-title text-center mt-3">-->
<!--									{{ $t('Book cover', { name: layout.name }) }}-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</section>-->

				<section class="BookCreate-section">
					<label>{{ $t('4. Select a content template for your book') }}</label>
					<div
						class="BookOptionsGrid"
						:class="{ 'BookOptionsGrid--selected': !!formData.activeContentLayoutId }"
					>
						<div v-for="layout in contentLayouts" :key="`${layout._id}-content`">
							<div
								class="OptionItem"
								:class="{ 'OptionItem--active': layout._id === formData.activeContentLayoutId }"
								@click="selectContentLayout(layout._id)"
							>
								<img :src="getContentLayoutThumbnail(layout)" />
								<div class="OptionItem-title text-center mt-3">
									{{ $t('Book content', { name: layout.name }) }}
								</div>
							</div>
						</div>
					</div>
				</section>

				<OfSubmitButton
					:disabled="!canSubmit || !isBookCreating"
					variant="primary"
					class="CreateBtn"
					:class="{ 'CreateBtn--hidden': isInvalid }"
				>
					{{ $t('Create Book') }}
				</OfSubmitButton>

				<Loader v-if="isBookCreating" overlay class="BookCreate__loader-container" />
			</b-container>
		</b-form>
	</FullscreenLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { OfFormInput, OfSubmitButton, withForm } from '@oneflow/ofs-vue-layout';
import FullscreenLayout from '../../../../components/layouts/FullscreenLayout.vue';
import notifications from '../../../../mixins/notifications';
import Loader from '../../../../components/Loader';
import { specificationImage } from '../../../../constants';
import placeholder from '../../../../assets/placeholder.svg';

export default {
	components: {
		FullscreenLayout,
		OfFormInput,
		OfSubmitButton,
		Loader
	},
	mixins: [notifications, withForm('bookCreate')],
	data() {
		return {
			isBookCreating: false,
			bookSpecifications: []
		};
	},
	computed: {
		...mapGetters({
			coverLayouts: 'template/coverLayouts',
			contentLayouts: 'template/contentLayouts'
		}),
		validationRules() {
			return {
				formData: {
					activeContentLayoutId: { required },
					specificationCode: { required }
				}
			};
		}
	},
	async mounted() {
		await Promise.all([this.getCoversAndContents(), this.fetchSpecifications()]);
	},
	methods: {
		...mapActions({
			createTemplateFromBlocks: 'template/createFromLayoutsBlocks',
			createProductContent: 'product-content/create',
			findProductSpecifications: 'product-content/findSpecifications',
			getSelectOptions: 'template/getSelectOptions'
		}),
		async fetchSpecifications() {
			let specifications = [];
			try {
				specifications = await this.findProductSpecifications();
			} catch (error) {
				this.notifyError(error, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching available specifications')
				});
			}

			this.bookSpecifications = _.reduce(
				specifications,
				(result, spec, code) => [...result, { code, ...spec, image: specificationImage[code] }],
				[]
			);
		},
		async getCoversAndContents() {
			try {
				await this.getSelectOptions();
			} catch (error) {
				this.notifyError(error, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching covers and contents layouts')
				});
			}
		},
		selectContentLayout(layoutId) {
			const value = this.formData?.activeContentLayoutId === layoutId ? null : layoutId;
			this.updateField('activeContentLayoutId', value);
		},
		selectCoverLayout(layoutId) {
			const value = this.formData?.activeCoverLayoutId === layoutId ? null : layoutId;
			this.updateField('activeCoverLayoutId', value);
		},
		selectSpecification(code) {
			const value = this.formData.specificationCode === code ? null : code;
			this.updateField('specificationCode', value);
		},
		getContentLayoutThumbnail(layout) {
			return _.get(layout, 'contentPreviewImageUrl', placeholder);
		},
		getCoverLayoutThumbnail(layout) {
			return _.get(layout, 'coverPreviewImageUrl', placeholder);
		},
		getTemplateName() {
			const { name, activeContentLayoutId, activeCoverLayoutId } = this.formData;
			const contentLayoutName = _.get(_.find(this.contentLayouts, ['_id', activeContentLayoutId]), 'name');
			const coverLayoutName = _.get(_.find(this.coverLayouts, ['_id', activeCoverLayoutId]), 'name');
			const layoutNames =
				contentLayoutName === coverLayoutName ? contentLayoutName : `${coverLayoutName} / ${contentLayoutName}`;

			return `${name} / ${layoutNames} / ${new Date().toISOString()}`;
		},
		async onSave() {
			try {
				this.isBookCreating = true;
				const productContent = await this.dispatchSubmit(this.createBookWithTemplate());
				this.isBookCreating = false;
				this.$router.push({ name: 'books.edit', params: { id: productContent._id } });

				this.notifySuccess({
					title: this.$t('Success'),
					text: this.$t('Book has been created successfully')
				});
			} catch (error) {
				this.notifyError(error);
			}
		},
		async createBookWithTemplate() {
			const { name, activeContentLayoutId, specificationCode } = this.formData;
			const template = await this.createTemplateFromBlocks({
				name: this.getTemplateName(),
				contentLayoutId: activeContentLayoutId,
				coverLayoutId: activeContentLayoutId
			});
			return this.createProductContent({
				name,
				specificationCode,
				templateId: template.id,
				content: { headline: name }
			});
		}
	}
};
</script>

<style lang="scss">
@import '../../../../style/variables';

.BookCreate {
	position: relative;
	margin-top: 45px;

	&__loader-container {
		min-height: calc(100vh - 95px);
		position: fixed !important;
	}

	&.container {
		height: calc(100vh - 95px);
		overflow-y: scroll;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: $color-hp-grey-2;
			border-radius: 6px;
			height: 300px;
			max-height: 300px;
		}

		&::-webkit-scrollbar-track-piece {
			margin-bottom: 60px;
		}
	}

	@media (min-width: 1200px) {
		&.container {
			max-width: 940px;
			padding: 0 30px;
		}
	}

	&-header {
		margin-bottom: 80px;
		font-size: 36px;
		font-weight: 300;
		text-align: center;
	}

	&-section {
		margin-top: 60px;

		&:last-of-type {
			margin-bottom: 60px;
		}

		label {
			font-weight: bold;
			font-size: 20px;
			line-height: 22px;
			margin-bottom: 18px;
		}

		input[type='text'] {
			height: 50px;
			font-size: 22px;
			text-align: center;

			&:focus {
				background-color: $color-hp-light;
			}
		}
	}

	.BookOptionsGrid {
		display: grid;
		grid-gap: 30px;
		grid-template-columns: repeat(auto-fill, 250px);
		justify-content: space-between;
		margin-top: 20px;

		&--selected img {
			opacity: 0.75;
			filter: grayscale(100%);
		}

		.OptionItem {
			display: flex;
			justify-content: center;
			flex-direction: column;
			cursor: pointer;

			img {
				width: 100%;
				background: $color-hp-grey-3;
				border-radius: 5px;
				border: 2px solid transparent;
			}

			&-title {
				font-weight: 600;
				font-size: 18px;
				line-height: 22px;
			}

			&--active img {
				border-color: #fff;
				outline: 2px solid $color-pimienta;
				opacity: 1;
				filter: none;
			}
		}
	}

	section.BoundSection {
		.BookOptionsGrid {
			grid-template-columns: repeat(auto-fill, 170px);
			grid-template-rows: repeat(auto-fill, 170px);
			grid-gap: 20px;

			.OptionItem {
				&-image {
					position: relative;
					height: 100%;

					img {
						height: 100%;
						border-radius: 50%;
						object-position: 20px 15px;
					}

					&:hover {
						.OptionImage-mask {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							position: absolute;
							background: rgba(0, 0, 0, 0.2);
						}
					}
				}
			}
		}
	}

	.CreateBtn {
		position: fixed;
		bottom: 25px;
		right: 30px;
		width: 200px;

		&--hidden {
			visibility: hidden;
		}

		@media (max-width: 1400px) {
			position: initial;
			width: 100%;
			margin-bottom: 20px;
		}
	}
}
</style>
