<template>
	<b-col :class="containerClass">
		<template v-if="type === fieldType.Text">
			<OfFormInput
				:name="name"
				class="mb-2"
				:label="field.label"
				:without-label="!field.label"
				:placeholder="field.definition.placeholder"
				:required="field.definition.required"
				:disabled="field.definition.disabled"
				:show-errors="true"
			>
				<template v-if="field.definition.description" slot="description">
					<i>{{ field.definition.description }}</i>
				</template>
			</OfFormInput>
		</template>
		<template v-else-if="type === fieldType.Textarea">
			<OfFormTextarea
				:name="name"
				:label="field.label"
				:without-label="!field.label"
				:placeholder="field.definition.placeholder"
				:required="field.definition.required"
				:disabled="field.definition.disabled"
				:show-errors="true"
			/>
		</template>
		<template v-else-if="type === fieldType.Image">
			<label v-if="field.label" class="col-form-label">
				{{ field.label }}{{ field.definition.required && '*' }}
			</label>
			<ImageUploader
				:name="name"
				:path="uploadPath"
				:disabled="!uploadPath"
				:required="field.definition.required"
				:show-errors="true"
			/>
		</template>
		<template v-else-if="type === fieldType.Checkbox">
			<OfFormCheckbox
				:name="name"
				:right-side-label="field.label"
				:disabled="field.definition.disabled"
				without-label
			/>
		</template>
		<template v-else-if="type === fieldType.ButtonGroup">
			<OfFormRadio
				id="image-position"
				class="section-field__radio-buttons"
				:name="name"
				:label="field.label"
				:options="field.definition.options"
			/>
		</template>
		<template v-else-if="type === fieldType.MultiSelect">
			<OfMultiSelect
				class="section-field__multi-select"
				:label="field.label"
				:name="name"
				:options="field.definition.options"
				:multiple="true"
			>
				<template v-if="field.definition.description" slot="description">
					<i>{{ field.definition.description }}</i>
				</template>
			</OfMultiSelect>
		</template>
	</b-col>
</template>

<script>
import { OfFormInput, OfFormTextarea, OfFormCheckbox, OfFormRadio, OfMultiSelect } from '@oneflow/ofs-vue-layout';
import ImageUploader from '../ImageUploader.vue';
import { fieldType } from '@/containers/Products/Books/BookEdit/constants';
export default {
	components: {
		OfFormInput,
		OfFormTextarea,
		ImageUploader,
		OfFormCheckbox,
		OfFormRadio,
		OfMultiSelect
	},
	props: {
		field: {
			type: Object,
			required: true
		},
		path: {
			type: String,
			default: ''
		},
		uploadPath: {
			type: String,
			default: ''
		}
	},
	data() {
		return { fieldType };
	},
	computed: {
		type() {
			return _.get(this.field, 'definition.type');
		},
		containerClass() {
			const fieldClass = _.get(this.field, 'definition.containerClass');
			return fieldClass || (this.type === fieldType.Image ? 'col-lg-6' : 'col-lg-12');
		},
		name() {
			return `${this.path ? `${this.path}.` : ''}${this.field.key}`;
		},
		fileId() {
			return _.get(this.formData, `${this.path ? `${this.path}.` : ''}${this.field.key}.fileId`);
		}
	}
};
</script>

<style lang="scss">
@import '../../style/variables';

.section-field {
	&__checkbox-group {
		.section-field__radio-buttons {
			margin-top: 15px;
			display: flex;

			label.col-form-label {
				color: #3a3f51;
				max-width: 150px;
				font-size: 15px;
			}

			div {
				max-width: 160px;
			}
		}
	}

	&__multi-select {
		.selected-option {
			background-color: $color-pimienta !important;
		}
	}
}
</style>
