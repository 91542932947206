<template>
	<b-alert show variant="warning" dismissible class="d-flex mt-2">
		<div class="d-flex flex-grow-0 align-items-center">
			<b-img :src="AlertOrange" class="alert-icon" alt="info icon"></b-img>
		</div>
		<slot></slot>
	</b-alert>
</template>

<script>
import AlertOrange from '../assets/alert-orange.svg';

export default {
	data() {
		return {
			AlertOrange
		};
	}
};
</script>
