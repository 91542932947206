import _ from 'lodash';
import jp from 'jsonpath';

export const hexColorPattern = '^#(?:[0-9a-fA-F]{3}){1,2}([0-9a-fA-F]{2})?$';

export const hexColor = value => !value || new RegExp(hexColorPattern).test(value);

/**
 * Validates Image shortest edge (width and height)
 *
 * @param {number} size Minimal allowed size
 * @returns {boolean} Validation result flag
 */
export const minImageSize = size => {
	return file => {
		if (!file) return true;

		const { width, height } = file;
		if (!width || !height) return false;

		return width >= size && height >= size;
	};
};

/**
 * Validates Image aspect ratio based on a horizontal and vertical ratio.
 * Applies optional aspect ratio threshold
 *
 * @param {number} horizontal Horizontal aspect
 * @param {number} vertical Vertical aspect
 * @param {number} [threshold=0.1] Aspect ratio difference threshold
 * @returns {boolean} Validation result flag
 */
export const imageAspectRatio = (horizontal, vertical, threshold = 0.1) => {
	return file => {
		if (!file) return true;

		const { width, height } = file;
		if (!width || !height) return false;

		const aspectDelta = Math.abs(width / height - horizontal / vertical);
		return aspectDelta <= threshold;
	};
};

/**
 * Validates total documents count around all the chapters.
 *
 * @param {number} maxCount Maximum allowed documents count
 * @returns {boolean} Validation result flag
 */
export const maxDocuments = maxCount => {
	return chapters => {
		const documentsCount = _.sumBy(chapters, chapter => _.get(chapter, 'documentIds.length', 0));
		return documentsCount <= maxCount;
	};
};

/**
 * Validates total section size.
 *
 * @param {number} maxCount Maximum allowed size
 * @param {string[]} paths Paths which should be validated (in jsonpath format)
 * @returns {boolean} Validation result flag
 */

export const totalSectionSize = (maxLength, paths) => {
	return function(data) {
		let totalLength = 0;
		_.forEach(paths, path => {
			const nodePathes = jp.paths(data, path);
			_.forEach(nodePathes, nodePath => {
				totalLength += _.get(jp.value(data, nodePath), 'length', 0);
			});
		});

		return totalLength < maxLength;
	};
};
