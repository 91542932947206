const $t = str => str;

const menuStructure = [
	{
		matches: [{ name: '^' }],
		items: [
			{
				items: [
					{
						title: $t('Block Renderer Templates'),
						name: 'blockRendererTemplates'
					}
				]
			},
			{
				title: $t('Products'),
				items: [
					{
						title: $t('Books'),
						name: 'books.list',
						matches: [{ name: 'books.*' }]
					}
				]
			},
			{
				title: $t('Content'),
				items: [
					{
						title: $t('Recipes'),
						name: 'recipes.list',
						matches: [{ name: 'recipes.*' }]
					}
				]
			},
			{
				title: $t('Settings'),
				items: [
					{
						title: $t('Scraping Rules'),
						name: 'scraping-rules.list',
						matches: [{ name: 'scraping-rules.*' }]
					}
				]
			}
		]
	}
];

export default menuStructure;
