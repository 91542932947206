var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-col',[_c('draggable',{staticClass:"treeview",attrs:{"value":_vm.localValue,"ghost-class":"ghost","handle":".drag-handle"},on:{"input":_vm.updateValue}},_vm._l((_vm.value),function(nodeItem,index){return _c('draggable-tree-view-node',{key:index,attrs:{"section-index":index,"value":nodeItem,"tab":_vm.tab},on:{"input":function (value) { return _vm.updateItem(value, index); },"remove":function($event){return _vm.removeElement(index)}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_vm._t("prepend",null,null,{ item: item, open: open })]}},{key:"label",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_vm._t("label",null,null,{ item: item, open: open })]}},{key:"append",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_vm._t("append",null,null,{ item: item, open: open })]}}],null,true)})}),1)],1),_c('b-col',[_c('b-button',{staticClass:"AddSectionBtn mt-1 btn-block",attrs:{"variant":"outline-primary"},on:{"click":_vm.onAddSection}},[_c('icon',{attrs:{"name":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('Add Section'))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }