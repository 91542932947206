import _ from 'lodash';
import * as vuelidateValidators from 'vuelidate/lib/validators';
import { validateWithMessage } from '@oneflow/ofs-vue-layout';
import { minImageSize, imageAspectRatio, maxDocuments, totalSectionSize } from '../../lib/validators';

// extend vuelidate validators with custom ones
const validators = { ...vuelidateValidators, minImageSize, imageAspectRatio, maxDocuments, totalSectionSize };

export const getSectionValidationErrors = (section, validationErrors) => {
	const sectionPath = `content.${section.key}`;
	const rootKeys = _.reduce(
		section.sections,
		(acc, section) => {
			const rootFields = _.filter(section.fields, 'definition.root');
			acc.push(..._.map(rootFields, rootField => `content.${rootField.key}`));
			return acc;
		},
		[]
	);
	// key[0] -> key.0
	const validationKeys = [sectionPath, ...rootKeys].map(key => key.replace(/\[(\d+)\]/g, '.$1'));
	const validationRegex = new RegExp(`^${validationKeys.join('|^')}`, 'i');
	return _.filter(validationErrors, error => error.$v.$error && validationRegex.test(error.path));
};

const getVuelidateFieldPath = (field = {}, section = {}, subsection = {}) => {
	if (_.get(field, 'definition.root')) return field.key;
	const subsectionKey = _.get(subsection, 'key', '');
	const subsectionPath = subsectionKey ? `.${subsectionKey}` : '';

	const fieldKey = _.get(field, 'key', '');
	const fieldPath = fieldKey ? `.${fieldKey}` : '';

	let sectionPath = section.key;
	if (section.flatten && (subsectionPath || fieldPath)) {
		sectionPath = `${sectionPath}.$each`;
	}
	return `${sectionPath}${subsectionPath}${fieldPath}`;
};

const getVuelidatePath = (field, section, subsection, relativePath) => {
	const fieldPath = getVuelidateFieldPath(field, section, subsection);
	let validationPath = fieldPath.replace(/\[\d+\]./g, '.$each.'); // [0]. -> .$each.
	validationPath = relativePath ? `${validationPath}${relativePath}` : validationPath;

	// The case when we are validating specific array item
	const arrayIndexMatch = validationPath.match(/\[(\d+)\]$/);
	if (arrayIndexMatch) {
		validationPath = validationPath.replace(/\[\d+\]$/, '');
	}

	return {
		path: validationPath,
		itemIndex: arrayIndexMatch && +arrayIndexMatch[1],
		fullPath: arrayIndexMatch ? `${validationPath}[${+arrayIndexMatch[1]}]` : validationPath
	};
};

function buildValidator(section, subsection, field, validator, rules) {
	const { message, rule, arguments: args, relativePath } = validator;
	const { path, itemIndex, fullPath } = getVuelidatePath(field, section, subsection, relativePath);

	// Define validation functions
	const validations = _.get(rules, fullPath, {});
	const validatorFn = _.get(args, 'length') > 0 ? validators[rule].apply(null, args) : validators[rule];
	const validation = { ...validations, [rule]: validateWithMessage(message, validatorFn) };

	// Apply validation to general rules.
	// For array item validators we need to use [itemIndex] because it has to be numeric
	const validate = _.isNumber(itemIndex) ? { [itemIndex]: validation } : validation;
	_.set(rules, path, validate);
}

/**
 * Builds Vuelidate validation rules object based on section validators
 *
 * @param {array} sections
 * @returns {object} validationRules
 */
export const buildValidationRules = sections => {
	const rules = {};
	_.each(sections, section => {
		// section level validators
		if (_.get(section.validators, 'length') > 0) {
			_.each(section.validators, validator => {
				buildValidator(section, null, null, validator, rules);
			});
		}

		_.each(section.sections, subsection => {
			_.each(subsection.fields, field => {
				_.each(field.validators, validator => {
					buildValidator(section, subsection, field, validator, rules);
				});
			});
		});
	});

	return rules;
};
