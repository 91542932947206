import BooksList from './Books/BooksList';
import BookCreatePage from './Books/BookCreate';
import BookEdit from './Books/BookEdit';
import BookPreview from './Books/BookPreview';

export const routes = [
	{
		path: '/',
		redirect: '/books'
	},
	{
		path: '/books',
		name: 'books.list',
		component: BooksList
	},
	{
		path: '/books/create',
		name: 'books.create',
		component: BookCreatePage
	},
	{
		path: '/books/:id',
		name: 'books.edit',
		component: BookEdit
	},
	{
		path: '/books/new/:code',
		name: 'books.new',
		component: BookEdit
	},
	{
		path: '/books/:id/preview',
		name: 'books.preview',
		component: BookPreview
	}
];

export default routes;
