module.exports = {
	name: 'Title page',
	exampleProps: {
		bookSettings: {
			title: 'FLAVOURS OF MEXICO CITY'
		}
	},
	template: [
		{
			name: 'Title page',
			type: 'page',
			props: {
				width: { '?': { var: '$theme.pageWidth' } },
				height: { '?': { var: '$theme.pageHeight' } },
				classNames: ['page-introduction'],
				display: 'flex',
				justifyContent: 'center'
			},
			children: [
				{
					name: 'Page title',
					type: 'text',
					props: {
						fontFamily: 'OpenSans-Light',
						marginTop: '150px',
						fontSize: '70px',
						textAlign: 'center',
						width: '600px',
						textTransform: 'uppercase',
						fontWeight: '100',
						letterSpacing: '3px',
						value: { '?': { var: '$props.title' } }
					}
				}
			]
		}
	]
};
