<template>
	<div class="FileGrid">
		<FileGridItem v-for="file in content" :key="file._id" :content="file" @content-clicked="contentClicked" />
	</div>
</template>

<script>
import FileGridItem from './FileGridItem';

export default {
	components: {
		FileGridItem
	},
	props: {
		content: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		contentClicked(content) {
			this.$emit('content-clicked', content);
		}
	}
};
</script>

<style lang="scss">
.FileGrid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
	grid-gap: 13px;
}
</style>
