<template>
	<section>
		<template v-if="collapsible">
			<div
				class="SectionHeader"
				:aria-expanded="visible || 'false'"
				:aria-controls="`collapse-${_uid}`"
				@click="onHeaderClick"
			>
				<div class="SectionHeader-title">
					<icon :name="visible ? 'caret-down' : 'caret-right'" />
					<h3>{{ title }}</h3>
					<span v-if="label" class="SectionHeader-label">{{ label }}</span>
				</div>
				<div class="SectionHeader-actions">
					<slot name="actions"></slot>
				</div>
			</div>
			<b-collapse :id="`collapse-${_uid}`" v-model="visible" class="mt-2">
				<slot></slot>
			</b-collapse>
		</template>

		<template v-else>
			<div class="SectionHeader">
				<div class="SectionHeader-title">
					<template v-if="name">
						<OfFormInput :name="name" :show-errors="true" class="mt-0 mb-0 EditableSectionInput">
							<template #append>
								<icon name="edit" />
							</template>
						</OfFormInput>
					</template>
					<template v-else>
						<h3>{{ title }}</h3>
						<span v-if="label" class="SectionHeader-label">{{ label }}</span>
					</template>
				</div>
				<div class="SectionHeader-actions">
					<slot name="actions"></slot>
				</div>
			</div>
			<slot></slot>
		</template>
	</section>
</template>

<script>
import { OfFormInput } from '@oneflow/ofs-vue-layout';

export default {
	components: {
		OfFormInput
	},
	props: {
		title: {
			type: String,
			required: true
		},
		label: {
			type: [String, Number],
			default: null
		},
		collapsible: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			visible: true
		};
	},
	methods: {
		onHeaderClick(event) {
			if (event.target.type === 'button') return;
			this.visible = !this.visible;
		}
	}
};
</script>

<style lang="scss">
@import '../style/_variables.scss';

.SectionHeader {
	display: flex;
	justify-content: space-between;
	min-height: 36px;
	padding: 5px 8px 5px 10px;
	background-color: $color-hp-highlights;
	border-radius: $of-border-radius;

	&-title {
		display: flex;
		align-items: center;

		h3 {
			margin: 0;
		}

		.fa-icon {
			width: 10px;
			margin: 0 15px 0 5px;
		}

		.EditableSectionInput {
			.fa-icon {
				width: auto;
				margin: 0;
			}

			label {
				display: none !important;
			}

			input {
				height: 30px;
				background-color: transparent !important;
				font-weight: 700;
				padding-top: 0;
				padding-bottom: 0;
				border: 0 !important;
				box-shadow: none !important;
			}

			.input-group-append {
				display: flex !important;
				justify-content: center;
				align-items: center;
			}

			.invalid-feedback {
				margin-left: 12px;
			}
		}
	}

	&-label {
		min-width: 28px;
		margin-left: 8px;
		padding: 4px 8px;
		background: #fff;
		border-radius: 33px;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		color: $color-hp-grey-1;
		border: 1px solid $color-hp-grey-3;
	}
}
</style>
