import filesize from 'filesize';

/**
 * Humanise a file size
 * @param {any} bytes - Size in bytes
 * @param {number} [precision=0] - Rounding precision
 * @returns {string} - Humanised file size
 */
const fileSize = (bytes, precision = 0) => filesize(bytes, { base: precision });

/**
 * Cut string to some length with dots
 * @param {string} str - Target string
 * @param {number} length - Output string length
 * @returns {string} - Cut string
 */
const shorten = (str, length = 40) => (str.length > length ? `${(str || '').substring(0, length - 1)}...` : str);

/**
 * Format date
 * @param {Date} date - Date
 * @returns {string} - Formatted date string
 */
const dateTimeFormat = date =>
	new Intl.DateTimeFormat('default', {
		dateStyle: 'medium',
		timeStyle: 'medium'
	}).format(new Date(date));

export default {
	fileSize,
	shorten,
	dateTimeFormat
};
