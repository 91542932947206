<template>
	<div ref="container" class="Wrapper d-flex" :style="containerStyles">
		<!-- eslint-disable-next-line vue/no-v-html -->
		<span v-html="previewHtml" />
	</div>
</template>

<script>
import _ from 'lodash';
import { HtmlRenderer } from '@custom-content-technology/block-renderer-html-renderer';

export default {
	props: {
		json: {
			type: Object,
			default: () => null
		},
		fonts: {
			type: Array,
			default: () => null
		},
		noDebounce: {
			type: Boolean,
			default: false
		},
		isCover: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			htmlRenderer: null,
			containerWidth: 0,
			maxHeightContainer: 816,
			previewHtml: '',
			resizeObserver: null
		};
	},
	computed: {
		containerStyles() {
			if (this.isCover) {
				return {
					maxHeight: `${this.maxHeightContainer}px`,
					minHeight: `${this.maxHeightContainer}px`
				};
			}
			return null;
		}
	},
	watch: {
		json(json, oldJson) {
			if (_.isEqual(json, oldJson)) return;
			this.renderJson();
		},
		fonts(fonts, oldFonts) {
			if (_.isEqual(fonts, oldFonts)) return;

			this.initRenderer();
			this.renderJson();
		}
	},
	created() {
		this.debouncedResizeHandler = _.debounce(this.resizeHandler, 200);
	},
	async mounted() {
		this.initRenderer();

		await this.$nextTick();
		this.resizeHandler();

		if (this.noDebounce) {
			this.resizeObserver = new ResizeObserver(this.resizeHandler);
		} else {
			this.resizeObserver = new ResizeObserver(this.debouncedResizeHandler);
		}

		this.resizeObserver.observe(this.$refs.container);
	},
	beforeDestroy() {
		this.resizeObserver.unobserve(this.$refs.container);
	},
	methods: {
		initRenderer() {
			this.htmlRenderer = new HtmlRenderer();
			_.each(this.fonts, font => {
				this.htmlRenderer.addFont({ name: font.fontFamily, url: font.url });
			});
		},
		resizeHandler(entries) {
			const width = _.get(entries, '[0].contentRect.width');
			this.containerWidth = width ?? this.$refs.container?.offsetWidth ?? this.containerWidth;
			this.renderJson();
		},
		updateCoverContainerHeight() {
			const actualElement = _.head(
				document.querySelectorAll('.Wrapper > span > section > section')
			)?.getBoundingClientRect();
			if (actualElement) {
				this.maxHeightContainer = actualElement?.height;
			}
		},
		async renderJson() {
			const options = { width: this.containerWidth };
			this.previewHtml = await this.htmlRenderer.render(this.json, options);
			if (this.isCover) {
				this.$nextTick(() => this.updateCoverContainerHeight());
			}
		}
	}
};
</script>

<style lang="scss">
.Wrapper {
	background: white;
}
</style>
