<!-- eslint-disable max-len -->
<template>
	<Layout>
		<b-form class="h-100" novalidate>
			<Page class="RecipeEdit">
				<ContentHeader :title="headerTitle" :breadcrumbs="breadcrumbs" class="mb-2" no-padding>
					<b-dropdown class="mx-1" right text="Actions">
						<b-dropdown-item :disabled="!documentId" @click="onRemove">
							<icon name="trash" />
							{{ $t('Delete') }}
						</b-dropdown-item>
					</b-dropdown>
				</ContentHeader>

				<b-tabs>
					<b-tab :title="$t('Details')">
						<b-alert
							v-for="detailsValidationMessage in detailsValidationMessages"
							:key="detailsValidationMessage.id"
							show
							variant="danger"
							dismissible
						>
							<img src="@/assets/alert.svg" class="alert-icon" alt="alert icon" />
							{{ detailsValidationMessage.text }}
						</b-alert>
						<b-row class="mt-3">
							<b-col md="7">
								<OfFormInput name="name" :label="$t('Recipe name')" />
								<b-row>
									<b-col md="6">
										<OfFormInput name="author" :label="$t('Author')" />
									</b-col>
									<b-col md="6">
										<OfFormInput name="category" :label="$t('Category')" />
									</b-col>
								</b-row>

								<b-row>
									<b-col md="6">
										<OfFormInput name="cuisine" :label="$t('Cuisine')" />
									</b-col>
									<b-col md="6">
										<OfFormInput :name="getYieldPath" :label="$t('Number of servings')">
											<template #label>
												{{ $t('Number of servings') }}
												<b-form-radio-group
													v-if="!isStringYeild"
													v-model="yieldTitle"
													:options="yieldOptions"
													class="serves-radio ml-2"
												/>
											</template>
										</OfFormInput>
									</b-col>
								</b-row>

								<OfFormTextarea name="description" :label="$t('Description')" :rows="6" />

								<OfMultiSelect
									name="tags"
									:label="$t('Tags')"
									:placeholder="$t('Add custom tags')"
									:taggable="true"
									data-test-id="tags"
								/>

								<OfFormTextarea name="notes" :label="$t('Notes')" :rows="5" />

								<OfFormInput name="qrCode" :label="$t('QR code link')" />
							</b-col>
							<b-col md="5">
								<Section :title="$t('Nutrition')">
									<b-row>
										<b-col>
											<OfFormInput name="nutrition.calories" :label="$t('Calories')">
												<template #append>
													<b-input-group-text>{{ $t('kJ') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>

										<b-col>
											<OfFormInput name="nutrition.fatContent" :label="$t('Fat')">
												<template #append>
													<b-input-group-text>{{ $t('g') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<OfFormInput name="nutrition.proteinContent" :label="$t('Protein')">
												<template #append>
													<b-input-group-text>{{ $t('g') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>

										<b-col>
											<OfFormInput
												name="nutrition.carbohydrateContent"
												:label="$t('Carbohydrates')"
											>
												<template #append>
													<b-input-group-text>{{ $t('g') }}</b-input-group-text>
												</template>
											</OfFormInput>
										</b-col>
									</b-row>
								</Section>

								<Section :title="$t('Cooking Times')">
									<b-row>
										<b-col>
											<OfFormInput name="prepTime" :label="$t('Preparation Time')" />
										</b-col>

										<b-col>
											<OfFormInput name="cookTime" :label="$t('Cooking Time')" />
										</b-col>

										<b-col>
											<OfFormInput name="totalTime" :label="$t('Total Time')" />
										</b-col>
									</b-row>
								</Section>

								<Section :title="$t('Size')">
									<b-col class="mt-2">
										<b-row>
											<OfFormCheckbox
												name="calculateSize"
												:right-side-label="$t('Automatically calculate recipe size')"
												class="mb-1"
												no-label
											/>
										</b-row>
										<b-row>
											<OfMultiSelect
												name="size"
												:label="$t('Manually change recipe size to:')"
												:placeholder="$t('Choose recipe size')"
												:options="documentSizesOptions"
												:disabled="formData.calculateSize"
												class="size-dropdown w-50"
											/>
										</b-row>
									</b-col>
								</Section>

								<OfFormCheckbox
									v-if="isRecipeStandardWithImage"
									name="isImageWithParagraph"
									:right-side-label="$t('Use image with paragraph (only for ZEN)')"
									class="mb-1"
									no-label
								/>
								<OfFormTextarea
									v-if="isRecipeStandardWithImage"
									name="imageParagraph"
									:label="$t('Image paragraph')"
									:rows="6"
									:disabled="!formData.isImageWithParagraph"
								/>

								<OfMultiSelect
									v-if="isRecipeShort"
									name="imageOrientation"
									class="size-dropdown w-50"
									:label="$t('Change image orientation to:')"
									:placeholder="$t('Image orientation')"
									:options="imageOrientationOptions"
									:allow-clear="false"
								/>
							</b-col>
						</b-row>
					</b-tab>
					<b-tab :title="$t('Ingredients')">
						<b-alert show variant="primary" dismissible>
							<b-img :src="InfoCircle" class="alert-icon" alt="info icon"></b-img>
							{{
								$t(
									'Add the ingredients and quantities required for this recipe. Seperate ingredients into sections if required (e.g. Sauce and Main Meal).'
								)
							}}
						</b-alert>
						<b-alert
							v-for="ingredientValidationMessage in ingredientsValidationMessages"
							:key="ingredientValidationMessage.id"
							show
							variant="danger"
							dismissible
						>
							<img src="@/assets/alert.svg" class="alert-icon" alt="alert icon" />
							{{ ingredientValidationMessage.text }}
						</b-alert>
						<RecipeIngredients :data="formData.ingredients" @input="updateData" />
					</b-tab>
					<b-tab :title="$t('Method')">
						<b-alert show variant="primary" dismissible>
							<b-img :src="InfoCircle" class="alert-icon" alt="info icon"></b-img>
							{{ $t('Add the step by step method to making the recipe.') }}
						</b-alert>
						<b-alert
							v-for="instructionValidationMessage in instructionsValidationMessages"
							:key="instructionValidationMessage.id"
							show
							variant="danger"
							dismissible
						>
							<img src="@/assets/alert.svg" class="alert-icon" alt="alert icon" />
							{{ instructionValidationMessage.text }}
						</b-alert>
						<RecipeInstructions :data="formData.instructions" @input="updateData" />
					</b-tab>
					<b-tab :title="$t('Images')">
						<WarningAlert v-if="imagesValidationErrorCount">
							<div>
								{{ $tc('N of your images is low quality.', imagesValidationErrorCount) }}
								{{ $t('We recommend re-uploading a higher quality image with minimum resolution') }}
								{{ minImageWidth }}x{{ minImageHeight }}px
							</div>
						</WarningAlert>
						<b-row>
							<div v-for="(image, index) in images" :key="index" class="ThumbnailItem">
								<Thumbnail
									:src="image.thumbnailURL || image.fileURL || image.url"
									size="150"
									class="mb-2 mt-1 cursor-pointer"
									style="margin-right: 20px"
									:warning="imagesValidationStatuses[index] === false"
								/>
								<icon
									name="times-circle"
									scale="1.2"
									class="ThumbnailItem-RemoveBtn"
									@click="removeImage(index)"
								/>
							</div>
							<span id="open-media-library-wrapper" class="d-inline-block">
								<b-button
									variant="outline-primary"
									class="AddFieldBtn add-media-btn mt-1"
									@click="isMediaUploaderShown = true"
								>
									<icon name="plus" />
									{{ $t('Add Image') }}
								</b-button>
							</span>
							<MediaUploader
								:title="$t('Upload')"
								:show="isMediaUploaderShown"
								:on-close="() => (isMediaUploaderShown = false)"
								:path="mediaUploaderPath"
								:active-tab="2"
								validate-images
								accepted-file-types="image/jpeg, image/png, image/tiff"
								:account-id="accountId"
								@file-picked="onImageFilePicked"
								@url-submitted="onImageUrlSubmitted"
							/>
						</b-row>
					</b-tab>
					<b-tab :title="$t('Preview')" lazy>
						<Codemirror :data="formData" />
					</b-tab>
					<b-tab :title="$t('Additional fields')">
						<b-alert v-if="duplicatedFields.length" show variant="danger" dismissible>
							<img src="@/assets/alert.svg" class="alert-icon" alt="alert icon" />
							{{ $t('Additional fields should not contain repeated key names') }}
						</b-alert>
						<b-row v-for="({ key, value }, i) in additionalData" :key="i" class="align-items-baseline mb-2">
							<b-col md="3">
								<OfFormInput
									no-label
									:class="{ 'input-field': duplicatedFields.includes(key) }"
									:placeholder="$t('Key')"
									:value="key"
									@input="newKey => onAdditionalDataKeyChange(i, newKey, key)"
								/>
							</b-col>
							<b-col>
								<OfFormInput
									no-label
									:placeholder="$t('Value')"
									:value="value"
									@input="newValue => onAdditionalDataValueChange(i, key, newValue)"
								/>
							</b-col>
							<b-col class="col-auto">
								<b-button variant="light" size="sm" @click="removeAdditionalData(i)">
									<icon name="trash" />
								</b-button>
							</b-col>
						</b-row>
						<b-button
							variant="outline-primary"
							class="AddFieldBtn mt-4 btn-block"
							@click="addAdditionalDataLine"
						>
							<icon name="plus" />
							{{ $t('Add') }}
						</b-button>
					</b-tab>
				</b-tabs>

				<template #actions>
					<b-button v-t="$t('Cancel')" class="mr-2" @click="redirectToListPage" />
					<b-button
						v-t="$t('Save')"
						variant="primary"
						:disabled="!canSubmit || duplicatedFields.length !== 0"
						@click="onSave"
					/>
				</template>
			</Page>
		</b-form>
		<RecipeDeleteModal
			:is-modal-visible="isRecipeDeleteModalVisible"
			:delete-results="deleteResults"
			@hideModal="hideRecipeDeleteModal"
		/>
	</Layout>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { maxLength, url, requiredIf, required, or } from 'vuelidate/lib/validators';
import {
	OfFormInput,
	OfFormTextarea,
	OfMultiSelect,
	OfFormCheckbox,
	withForm,
	ContentHeader,
	validateWithMessage
} from '@oneflow/ofs-vue-layout';
import RecipeIngredients from '../../../components/RecipeEdit/RecipeIngredients';
import RecipeInstructions from '../../../components/RecipeEdit/RecipeInstructions';
import Codemirror from '../../../components/Codemirror';
import MediaUploader from '../../../components/MediaUploader';
import Thumbnail from '../../../components/Thumbnail';
import {
	defaultRecipeSchema,
	documentSizesOptions,
	documentSizes,
	imageOrientationOptions,
	imageOrientations,
	sourceOptions,
	itemTypes
} from '../../../constants';
import Layout from '../../../components/Layout';
import Page from '../../../components/Page';
import Section from '../../../components/Section';
import RecipeDeleteModal from '../../../components/RecipeDeleteModal.vue';
import notifications from '../../../mixins/notifications';
import generateSourceId from '../../../lib/generateRecipeSourceId';
import InfoCircle from '../../../assets/info-circle.svg';
import { validateImage, minImageWidth, minImageHeight } from '@/lib/imageValidation';
import WarningAlert from '../../../components/WarningAlert';

export default {
	components: {
		OfFormInput,
		OfMultiSelect,
		OfFormTextarea,
		OfFormCheckbox,
		RecipeIngredients,
		RecipeInstructions,
		Layout,
		Codemirror,
		MediaUploader,
		Thumbnail,
		Page,
		ContentHeader,
		Section,
		WarningAlert,
		RecipeDeleteModal
	},
	mixins: [withForm('recipeForm'), notifications],
	data() {
		return {
			InfoCircle,
			minImageWidth,
			minImageHeight,
			documentSizesOptions,
			imageOrientationOptions,
			imageOrientations,
			sourceOptions,
			isMediaUploaderShown: false,
			additionalData: [],
			imagesValidationStatuses: [],
			isRecipeDeleteModalVisible: false,
			linkedBooks: [],
			yieldOptions: [
				{ text: this.$t('Makes'), value: 'Makes' },
				{ text: this.$t('Serves'), value: 'Serves' }
			],
			duplicatedFields: [],
			deleteResults: null
		};
	},
	computed: {
		...mapGetters({
			document: 'document/document',
			features: 'feature/features'
		}),
		validationRules() {
			return {
				formData: {
					name: {
						maxLength: validateWithMessage(
							this.$t('Recipe Name should be less than 65 characters'),
							maxLength(65)
						)
					},
					author: {
						maxLength: validateWithMessage(
							this.$t('Author should be less than 30 characters'),
							maxLength(30)
						)
					},
					category: {
						maxLength: validateWithMessage(
							this.$t('Category should be less than 30 characteers'),
							maxLength(30)
						)
					},
					cuisine: {
						maxLength: validateWithMessage(
							this.$t('Cuisine should be less than 30 characters'),
							maxLength(30)
						)
					},
					description: {
						maxLength: validateWithMessage(
							this.$t('Description should be less than 350 characters'),
							maxLength(350)
						)
					},
					tags: {
						correctItemsLength: validateWithMessage(
							this.$t('Tags text length should be less than 15 characters'),
							tags => {
								const itemsLength = _.reduce(tags, (acc, tag) => acc + _.size(tag), 0);
								return itemsLength < 15;
							}
						)
					},
					yield: {
						maxLength: validateWithMessage(
							this.$t('Number of servings should be less than 15 characters'),
							maxLength(15)
						),
						value: {
							maxLength: validateWithMessage(
								this.$t('Number of servings should be less than 15 characters'),
								maxLength(15)
							)
						}
					},
					notes: {
						maxLength: validateWithMessage(
							this.$t('Notes should be less than 300 characters'),
							maxLength(300)
						)
					},
					qrCode: {
						url: validateWithMessage(this.$t('QR Code should be a valid URL'), url)
					},
					nutrition: {
						correctPropertiesLength: validateWithMessage(
							this.$t('Nutrition text should be less than 15 characters'),
							nutrition => {
								const valuesLength = _.values(nutrition).reduce((acc, value) => acc + _.size(value), 0);
								return valuesLength < 15;
							}
						)
					},
					prepTime: {
						maxLength: validateWithMessage(
							this.$t('Preparation Time should be less than 15 characters'),
							maxLength(15)
						)
					},
					cookTime: {
						maxLength: validateWithMessage(
							this.$t('Cook Time should be less than 15 characters'),
							maxLength(15)
						)
					},
					totalTime: {
						maxLength: validateWithMessage(
							this.$t('Total time should be less than 15 characters'),
							maxLength(15)
						)
					},
					size: {
						required: validateWithMessage(
							this.$t('Recipe size is required'),
							requiredIf(() => !_.get(this.formData, 'calculateSize', ''))
						)
					},
					imageParagraph: {
						maxLength: validateWithMessage(
							this.$t('Paragraph should be less than 400 characters'),
							maxLength(400)
						)
					},
					imageOrientation: {
						required: validateWithMessage(
							this.$t('Image orientation is required'),
							requiredIf(() => !_.get(this.formData, 'imageOrientation', '') && this.isRecipeShort)
						)
					},
					ingredients: {
						elements: {
							correctIngredientsLength: validateWithMessage(
								this.$t('Ingredients should contain less than 20 items'),
								function(items) {
									const ingredientsLength = _.reduce(
										items,
										(acc, item) => {
											const itemLength = item.elements ? item.elements.length : 1;
											return acc + itemLength;
										},
										0
									);
									return ingredientsLength < 20;
								}
							),
							$each: {
								name: {
									correctPropertiesLength: validateWithMessage(
										this.$t('Ingredient should be less than 75 characters'),
										function(name, item) {
											if (item.elements) return true;

											const valuesLength = _.values(item).reduce(
												(acc, value) => acc + _.size(_.toString(value)),
												0
											);
											return valuesLength < 75;
										}
									)
								},
								elements: {
									$each: {
										name: {
											correctPropertiesLength: validateWithMessage(
												this.$t('Ingredient should be less than 75 characters'),
												function(name, ingredient) {
													const valuesLength = _.values(ingredient).reduce(
														(acc, value) => acc + _.size(_.toString(value)),
														0
													);
													return valuesLength < 75;
												}
											)
										}
									}
								}
							}
						}
					},
					instructions: {
						elements: {
							correctItemsLength: validateWithMessage(
								this.$t('Instruction steps length should be less than 1500 characters'),
								instructionElements => {
									const itemsLength = _.reduce(
										instructionElements,
										(acc, instructionElement) => {
											if (!instructionElement.elements)
												return acc + _.size(instructionElement.text);
											const elementsLength = _.reduce(
												instructionElement.elements,
												(acc, element) => acc + _.size(_.get(element, 'text')),
												0
											);
											return acc + elementsLength;
										},
										0
									);
									return itemsLength < 1500;
								}
							)
						}
					}
				}
			};
		},
		detailsValidationMessages() {
			const pathes = [
				'name',
				'author',
				'category',
				'cuisine',
				'description',
				'tags',
				'yield',
				'yield.value',
				'notes',
				'qrCode',
				'nutrition',
				'prepTime',
				'cookTime',
				'totalTime',
				'size',
				'imageParagraph',
				'imageOrientation'
			];
			const filteredValidationMessages = this.validationErrors.filter(e => pathes.includes(e.path));

			return this.buildValidationMessages(filteredValidationMessages);
		},
		ingredientsValidationMessages() {
			const ingredientsPathRegex = /ingredients\.elements\.\d+.elements\.\d+|ingredients\.elements/;
			const filteredValidationMessages = this.validationErrors.filter(e => ingredientsPathRegex.test(e.path));

			return this.buildValidationMessages(filteredValidationMessages);
		},
		instructionsValidationMessages() {
			const filteredValidationMessages = this.validationErrors.filter(e => e.path === 'instructions.elements');

			return this.buildValidationMessages(filteredValidationMessages);
		},
		canSubmit() {
			const isSizeSelected = this.formData.calculateSize || this.formData.size;
			return isSizeSelected;
		},
		headerTitle() {
			return this.documentId ? _.get(this.formData, 'name') : this.$t('New recipe');
		},
		breadcrumbs() {
			const listRoute = this.$router.resolve({ name: 'recipes.list' });
			return [
				{ text: this.$t('Recipes'), href: listRoute.href },
				{ text: this.headerTitle, href: '#' }
			];
		},
		documentId() {
			const id = _.get(this.$route, 'params.id', 'new');
			return id !== 'new' ? id : null;
		},
		images() {
			return _.get(this.formData, 'images', []);
		},
		mediaUploaderPath() {
			return this.documentId ? `pimienta/documents/${this.documentId}` : 'pimienta/documents';
		},
		imagesValidationErrors() {
			return this.imagesValidationStatuses.filter(el => !el);
		},
		imagesValidationErrorCount() {
			return this.imagesValidationErrors.length;
		},
		isStringYeild() {
			return _.isString(this.formData.yield);
		},
		getYieldPath() {
			return this.isStringYeild ? 'yield' : 'yield.value';
		},
		yieldTitle: {
			get() {
				return _.get(this.formData, 'yield.title', '');
			},
			set(value) {
				if (!this.formData?.yield?.title) {
					_.set(this.formData, 'yield.title', value);
				} else {
					this.formData.yield.title = value;
				}
			}
		},
		isRecipeStandardWithImage() {
			return this.formData.size === documentSizes.STANDARD_WITH_IMAGE;
		},
		isRecipeShort() {
			return this.formData.size === documentSizes.SHORT;
		},
		accountId() {
			return this.document?.accountId;
		}
	},
	async mounted() {
		await this.initialize();
	},
	methods: {
		get: _.get,
		...mapActions({
			fileDownloadUrl: 'media-uploader/fileDownloadUrl',
			findDocumentById: 'document/findById',
			updateDocument: 'document/update',
			createDocument: 'document/create',
			bulkDelete: 'document/bulkDelete'
		}),
		async initialize() {
			let data = {
				calculateSize: false,
				size: documentSizes.STANDARD_WITH_IMAGE,
				isImageWithParagraph: false,
				imageOrientation: imageOrientations.landscape
			};
			if (this.documentId) {
				await this.findDocumentById({ id: this.documentId });
				this.initFormData({
					...data,
					..._.get(this.document, 'record', {})
				});
				this.additionalData = this.getAdditionalData();
				this.validateImages(this.images);
			} else {
				this.initFormData(data);
			}
		},
		updateData(data) {
			this.updateFormData({ ...data });
		},
		async onRemove() {
			const isConfirmed = await this.confirmAction(this.$t('Are you sure you want to delete the recipe?'));
			if (!isConfirmed) return;

			try {
				this.deleteResults = await this.bulkDelete([this.documentId]);

				if (!_.size(_.get(this.deleteResults, 'warningDocuments', []))) {
					this.$router.push({ name: 'recipes.list' });
					this.notifySuccess({
						title: this.$t('Success'),
						text: this.$t('Successfully removed', { count: `1 recipe` })
					});
					return;
				}

				this.isRecipeDeleteModalVisible = true;
			} catch (error) {
				this.notifyError(error, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while removing recipe')
				});
			}
		},
		async onSave() {
			try {
				let record = this.formData;
				_.forEach(this.additionalData, data => {
					if (_.keys(defaultRecipeSchema).includes(data.key)) {
						return;
					}
					record[data.key] = data.value;
				});
				if (!this.isStringYeild && !_.get(this.formData, 'yield.value')) {
					record = _.omit(record, ['yield']);
				}
				if (this.documentId) {
					await this.dispatchSubmit(this.updateDocument({ id: this.documentId, data: { record } }));
				} else {
					const sourceId = generateSourceId(_.get(record, 'name'));
					record = {
						...record,
						sourceURL: sourceId
					};
					const document = await this.dispatchSubmit(
						this.createDocument({ sourceId, record, type: itemTypes.recipe })
					);
					this.$router.push({ name: 'recipes.edit', params: { id: document.id } });
				}
				await this.initialize();

				this.notifySuccess({
					title: this.$t('Success'),
					text: this.$t('Recipe has been saved successfully')
				});
			} catch (error) {
				this.notifyError(error);
			}
		},
		async onImageFilePicked(file) {
			const image = {
				name: file.name,
				fileURL: file.urlData.url,
				thumbnailURL: file.thumbnailUrlData.url
			};
			this.addImage(image);
		},
		onImageUrlSubmitted(url) {
			const image = { url };
			this.addImage(image);
		},
		addImage(image) {
			const images = _.get(this.formData, 'images', []);
			this.updateField('images', [...images, image]);
			this.validateImages(this.images);
		},
		removeImage(index) {
			const images = _.filter(this.formData.images, (_, idx) => idx !== index);
			this.updateField('images', images);
			this.validateImages(this.images);
		},
		redirectToListPage() {
			this.$router.push({ name: 'recipes.list' });
		},
		onAdditionalDataKeyChange(i, newKey, oldKey) {
			const { value } = this.additionalData[i];
			this.additionalData[i] = { key: newKey, value };
			this.checkDuplicatedFields();
			if (_.keys(defaultRecipeSchema).includes(oldKey)) {
				this.initFormData({ ..._.omit(this.formData), [newKey]: value });
				return;
			}
			if (_.keys(defaultRecipeSchema).includes(newKey)) {
				this.initFormData({ ..._.omit(this.formData, [oldKey]), [newKey]: this.formData[newKey] });
				return;
			}
			this.initFormData({ ..._.omit(this.formData, [oldKey]), [newKey]: value });
		},
		onAdditionalDataValueChange(i, key, value) {
			this.additionalData[i] = { key, value };
			if (_.keys(defaultRecipeSchema).includes(key)) {
				return;
			}
			this.updateFormData({ [key]: value });
		},
		removeAdditionalData(i) {
			const { key } = this.additionalData[i];
			this.additionalData.splice(i, 1);
			this.checkDuplicatedFields();
			if (_.keys(defaultRecipeSchema).includes(key)) {
				return;
			}
			this.initFormData(_.omit(this.formData, [key]));
		},
		addAdditionalDataLine() {
			this.additionalData.push({});
		},
		getAdditionalData() {
			const keys = _.difference(_.keys(this.formData), _.keys(defaultRecipeSchema));
			return _.map(keys, key => ({ key, value: this.formData[key] }));
		},
		checkDuplicatedFields() {
			const additionalFieldsDuplicates = _.filter(_.map(this.additionalData, 'key'), (key, idx, keys) =>
				_.includes(keys, key, idx + 1)
			);

			const mainFieldsDuplicates = _.intersectionWith(
				_.keys(defaultRecipeSchema),
				this.additionalData.map(item => item.key),
				_.isEqual
			);
			this.duplicatedFields = [...additionalFieldsDuplicates, ...mainFieldsDuplicates];
		},
		buildValidationMessages(validationErrors) {
			return validationErrors.map(e => ({ text: e.errorMessage, id: `${e.path}/${e.name}` }));
		},
		async validateImages(images) {
			this.imagesValidationStatuses = await Promise.all(
				images.map(image => validateImage(image.fileURL || image.url))
			);
		},
		hideRecipeDeleteModal() {
			this.isRecipeDeleteModalVisible = false;
		}
	}
};
</script>

<style lang="scss">
@import '../../../style/_variables.scss';
.input-field input {
	border-color: #d83737 !important;
}

.alert-primary {
	border-color: $color-hp-blue !important;
}

.alert-icon {
	height: 25px;
	width: 25px;
	margin-right: 10px;
}

.alert-danger {
	background-color: rgba(216, 55, 55, 0.1) !important;
	border-color: #d83737 !important;
	color: #36363f !important;
}

.alert-warning {
	background: rgba(250, 144, 75, 0.1) !important;
	border: 1px solid #f1693d !important;
	color: #36363f !important;
}

.AddFieldBtn {
	.fa-icon {
		margin-right: 7px;
		vertical-align: -0.25em;
	}
}
.RecipeEdit {
	.CodeMirror {
		.CodeMirror-code {
			background-color: $color-hp-light;
		}

		.CodeMirror-lines {
			padding: 0;
		}

		.CodeMirror-gutters,
		.CodeMirror-activeline-background {
			background-color: $color-hp-light-blue;
		}
	}
	.list-group-item:hover {
		cursor: pointer;
	}
	.form-group {
		& > .col {
			padding-top: 0;
			padding-bottom: 0;
		}

		.col-form-label {
			height: 30px;
		}
	}

	.ThumbnailItem {
		position: relative;
		cursor: pointer;

		&-RemoveBtn {
			position: absolute;
			top: -6px;
			right: 14px;
			z-index: 2;
			cursor: pointer;

			&:hover {
				color: $of-color-red;
			}
		}
	}

	.add-media-btn {
		width: 150px;
		height: 150px;
		cursor: pointer;

		&--disabled {
			cursor: not-allowed;

			.card-body {
				background-color: $of-color-grey-3;

				&:hover {
					background-color: $of-color-grey-3 !important;
				}
			}
		}

		.d-flex {
			flex-wrap: wrap;
		}

		.card-body {
			display: flex;
			justify-content: center;
			align-items: center;

			&:hover {
				background-color: $of-color-light;
			}
		}
	}

	.serves-radio {
		color: $of-color-dark;
	}

	.size-dropdown {
		min-width: 260px;
		.col-form-label:after {
			content: '' !important;
		}
	}

	.links-tab {
		&__remove-btn {
			display: flex;
			align-items: flex-end;
			margin-bottom: 1rem;
		}
	}
}
</style>
