import Vue from 'vue';
import config from '@/config';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'product-content',
		pluralName: 'product-contents',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		actions: {
			async findSpecifications({ dispatch, commit }) {
				const path = `${config.apiBase}/public/productSpecifications.json`;
				const response = await dispatch('request', { path, method: 'GET' });
				return _.pickBy(response.data, { active: true });
			},
			async getBookTemplate({ dispatch }, { id }) {
				return dispatch('request', { path: `product-contents/${id}/template`, method: 'GET' }).then(
					r => r.data
				);
			},
			async getBookContent({ dispatch }, { id }) {
				return dispatch('request', { path: `product-contents/${id}/content`, method: 'GET' }).then(r => r.data);
			}
		}
	});
}
