<template>
	<b-overlay v-if="overlay" show no-wrap opacity="0.3">
		<template #overlay>
			<b-row class="d-flex justify-content-center w-150">
				<div class="loader" :class="loaderClass"></div>
			</b-row>
		</template>
	</b-overlay>

	<b-row v-else class="d-flex justify-content-center w-150">
		<div class="loader" :class="loaderClass"></div>
	</b-row>
</template>

<script>
export default {
	props: {
		overlay: {
			type: Boolean,
			default: false
		},
		loaderClass: {
			type: Array,
			default: () => []
		}
	}
};
</script>

<style lang="scss">
.loader {
	margin: 20px 0 50px;
	border: 10px solid #f3f3f3;
	border-top: 10px solid #3498db;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
