module.exports = {
    "name": "Cover Back",
    "template": [
        {
            "name": "Cover Back Page",
            "type": "container",
            "props": {
                "width": {
                    "?": {
                        "var": "$theme.coverPageWidth"
                    }
                },
                "height": {
                    "?": {
                        "var": "$theme.coverPageHeight"
                    }
                }
            },
            "children": [{
                "name": "Outer",
                "type": "container",
                "props": {
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "padding": {
                        "?": {
                            "var": "$theme.pagePadding"
                        }
                    },
                    "backgroundColor": {
                        "?": {
                            "var": "$theme.cover.back.backgroundColor"
                        }
                    },
                    "height": {
                        "?": {
                            "var": "$theme.coverPageHeight"
                        }
                    }
                },
                "children": [{
                    "name": "Inner",
                    "type": "container",
                    "children": [{
                        "name": "Title Container",
                        "type": "container",
                        "props": {
                            "position": "relative",
                            "width": {
                                "?": {
                                    "var": "$theme.cover.titleContainerSize"
                                }
                            },
                            "height": {
                                "?": {
                                    "var": "$theme.cover.titleContainerSize"
                                }
                            },
                            "display": "flex",
                            "justifyContent": "center",
                            "alignItems": "center",
                            "borderRadius": "50%",
                            "backgroundColor": {
                                "?": {
                                    "cond": [
                                        [{
                                            "!": {
                                                "firstValid": [{
                                                    "var": "$theme.cover.back.textBackgroundImage"
                                                }, {
                                                    "var": "$theme.cover.textBackgroundImage"
                                                }]
                                            }
                                        }, {
                                            "firstValid": [{
                                                "var": "$theme.cover.back.textBackgroundColor"
                                            }, {
                                                "var": "$theme.cover.textBackgroundColor"
                                            }]
                                        }]
                                    ]
                                }
                            }
                        },
                        "children": [{
                            "name": "Background image",
                            "type": "image",
                            "props": {
                                "position": "absolute",
                                "width": "100%",
                                "height": "99.9%",
                                "objectFit": "cover",
                                "zIndex": "10",
                                "src": {
                                    "?": {
                                        "firstValid": [{
                                            "var": "$theme.cover.back.textBackgroundImage"
                                        }, {
                                            "var": "$theme.cover.textBackgroundImage"
                                        }]
                                    }
                                },
                                "visible": {
                                    "?": {
                                        "firstValid": [{
                                            "var": "$theme.cover.back.textBackgroundImage"
                                        }, {
                                            "var": "$theme.cover.textBackgroundImage"
                                        }]
                                    }
                                }
                            }
                        }, {
                            "type": "container",
                            "props": {
                                "zIndex": "20",
                                "display": "flex",
                                "flexDirection": "column",
                                "justifyContent": "space",
                                "alignItems": "center"
                            },
                            "children": [{
                                "name": "Customer Logo",
                                "type": "image",
                                "props": {
                                    "width": "75px",
                                    "height": "75px",
                                    "backgroundSize": "contain",
                                    "backgroundPosition": "center",
                                    "backgroundRepeat": "no-repeat",
                                    "marginBottom": "40px",
                                    "src": {
                                        "?": {
                                            "var": "$theme.cover.back.customerLogo"
                                        }
                                    },
                                    "visible": {
                                        "?": {
                                            "var": "$theme.cover.back.customerLogo"
                                        }
                                    }
                                }
                            }, {
                                "name": "Back title",
                                "type": "text",
                                "props": {
                                    "value": {
                                        "?": {
                                            "var": "$props.title"
                                        }
                                    },
                                    "fontFamily": {
                                        "?": {
                                            "var": "$theme.cover.titleFont"
                                        }
                                    },
                                    "color": {
                                        "?": {
                                            "var": "$theme.cover.back.titleColor"
                                        }
                                    },
                                    "visible": {
                                        "?": {
                                            "var": "$theme.cover.back.hasTitle"
                                        }
                                    },
                                    "fontSize": "38pt",
                                    "textTransform": "uppercase",
                                    "width": "400px",
                                    "textAlign": "center",
                                    "margin": "30px 0"
                                }
                            }, {
                                "type": "text",
                                "props": {
                                    "value": {
                                        "?": {
                                            "var": "$props.backText"
                                        }
                                    },
                                    "color": {
                                        "?": {
                                            "var": "$theme.cover.back.textColor"
                                        }
                                    },
                                    "fontFamily": {
                                        "?": {
                                            "var": "$theme.cover.back.textFont"
                                        }
                                    },
                                    "fontSize": "12pt",
                                    "lineHeight": "140%",
                                    "textAlign": "center",
                                    "width": "430px"
                                }
                            }]
                        }]
                    }]
                }]
            }]
        }
    ]
}