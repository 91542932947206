<template>
	<div class="PimientaMobilePanel" :class="{ 'PimientaMobilePanel--opened': isOpened }">
		<component :is="component" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserMobilePanel from './UserMobilePanel.vue';

export default {
	components: {
		UserMobilePanel
	},
	computed: {
		...mapGetters('mobile-panel', ['isOpened', 'component'])
	}
};
</script>

<style lang="scss">
.PimientaMobilePanel {
	display: none;
	position: fixed;
	width: 100vw;
	left: 40vw;
	height: 100%;
	background: #fff;
	overflow: auto;
	transition: left 0.2s, opacity 0.2s;
	opacity: 0;
	pointer-events: none;
	z-index: 99;

	&--opened {
		left: 0vw;
		opacity: 1;
		pointer-events: all;
	}

	@media (max-width: 767px) {
		display: block;
	}
}
</style>
