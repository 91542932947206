const chapter = require('./chapter');
const introduction = require('./introduction');
const notes = require('./notes');
const recipeWithImage = require('./recipeWithImage');
const tableOfContent = require('./tableOfContent');
const titlePage = require('./titlePage');
const acknowledgementsPage = require('./acknowledgementsPage');
const thankYouPage = require('./thankYouPage');
const pageNumber = require('./pageNumber');
const coverWithSpine = require('./coverWithSpine');
const coverFront = require('./coverFront');
const coverBack = require('./coverBack');
const coverSpine = require('./coverSpine');

module.exports = {
	chapter,
	introduction,
	notes,
	recipeWithImage,
	tableOfContent,
	titlePage,
	acknowledgementsPage,
	thankYouPage,
	pageNumber,
	coverWithSpine,
	coverFront,
	coverBack,
	coverSpine
};
