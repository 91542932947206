import InfoCircle from '../assets/info-circle.svg';

const $t = str => str;

export default {
	methods: {
		notifySuccess(options) {
			this.$notify({ type: 'success', ...options });
		},
		notifyError(error, options) {
			if (_.get(error, 'response.data.code') === 500) {
				return;
			}
			this.$notify({
				type: 'error',
				title: _.get(error, 'response.data.name', error.name),
				text: _.get(error, 'response.data.message', error.message),
				...options
			});
		},
		notifyWarning(options) {
			this.$notify({ type: 'warn', title: $t('Warning'), ...options });
		},
		async confirmAction(message) {
			const messages = Array.isArray(message) ? message : [message];

			// customize confirmation modal using VNodes
			const h = this.$createElement;
			const messageVNode = h('div', { class: ['foobar'] }, [
				h('b-img', {
					class: ['mb-3'],
					props: {
						src: InfoCircle,
						center: true,
						fluid: true,
						width: 55,
						height: 55
					}
				}),
				messages.map(message => h('p', { class: ['text-center', 'm-0'] }, [message]))
			]);

			const options = {
				okTitle: $t('Confirm'),
				cancelTitle: $t('Cancel'),
				size: 'sm',
				footerClass: 'justify-content-center'
			};
			return this.$bvModal.msgBoxConfirm([messageVNode], options);
		}
	}
};
