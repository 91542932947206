import { render, staticRenderFns } from "./BookCreateV2.vue?vue&type=template&id=2f7faf86&"
import script from "./BookCreateV2.vue?vue&type=script&lang=js&"
export * from "./BookCreateV2.vue?vue&type=script&lang=js&"
import style0 from "./BookCreateV2.vue?vue&type=style&index=0&id=2f7faf86&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports