<template>
	<b-badge pill :variant="variant" class="Badge" :class="`Badge--${variant}`">
		<slot></slot>
	</b-badge>
</template>

<script>
export default {
	props: {
		variant: {
			type: String,
			default: 'primary'
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../style/variables.scss';

.Badge {
	height: 16px;
	width: 16px;
	font-size: 11px;
	font-weight: bold;
	color: white;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
	border-radius: 50px;
	padding: 0;
	line-height: 14px;

	&--warning {
		background-color: $color-hp-orange;
	}
}
</style>
