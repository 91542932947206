import * as actions from './actions';
import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'account',
		transport: 'davinci',
		options: {
			namespaced: true
		},
		state: {
			currentAccount: null,
			userAccounts: []
		},
		getters: {
			currentAccount: state => state.currentAccount,
			accounts: state => state.userAccounts
		},
		actions,
		mutations: {
			SET_USER_ACCOUNTS: (state, accounts) => {
				state.userAccounts = accounts;
			},
			SET_CURRENT_ACCOUNT: (state, account) => {
				state.currentAccount = account;
			}
		}
	});
}
