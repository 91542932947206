<template>
	<div class="UserCard" :class="{ 'UserCard--mobile': isMobile }">
		<b-dropdown v-if="!isMobile" right>
			<template #button-content>
				<div>
					<icon name="user" class="mr-2" />
					<span>{{ user.username }}</span>
				</div>
			</template>
			<b-dropdown-item @click="onLogout">
				<icon name="sign-out-alt" class="mr-2" />
				{{ $t('Sign Out') }}
			</b-dropdown-item>
		</b-dropdown>
		<template v-else>
			<div>
				<b-img :src="placeholder" width="40" height="40" rounded="circle" />
			</div>
			<div class="UserCard--details">
				<div class="UserCard--username">{{ user.username }}</div>
				<div class="UserCard--email">{{ user.email }}</div>
			</div>
			<div>
				<b-button @click="onLogout"><icon name="sign-out-alt"/></b-button>
			</div>
		</template>
	</div>
</template>

<script>
import placeholder from '../assets/placeholder.svg';

export default {
	props: {
		isMobile: {
			type: Boolean,
			default: false
		},
		user: {
			type: Object,
			required: true
		}
	},
	data() {
		return { placeholder };
	},
	methods: {
		onLogout() {
			this.$emit('logout');
		}
	}
};
</script>

<style lang="scss">
@import '../style/variables.scss';

.UserCard {
	display: flex;
	align-items: center;
	height: 100%;

	&--details {
		flex: 1;
	}

	&--username {
		font-size: 18px;
	}

	&--email {
		font-size: 16px;
		line-height: 1;
		color: $color-hp-grey-2;
	}

	img {
		margin-right: 15px;
		background: $color-hp-grey-2;
	}

	.dropdown {
		.btn.dropdown-toggle {
			display: flex;
			align-items: center;
			padding: 15px 30px;
			border: none;
			text-transform: none;

			&::after {
				transform: translateY(2px);
			}
		}
	}

	&--mobile {
		margin: 10px 0 25px;
	}
}

@media (max-width: 767px) {
	.UserCard:not(.UserCard--mobile) {
		display: none;
	}
}
</style>
