<template>
	<b-table class="FileList" :fields="fields" :items="content" hover @row-clicked="onRowClick">
		<template #cell(thumbnail)="{ item }">
			<Thumbnail :src="item.thumbnailUrlData.url" size="50" />
		</template>
	</b-table>
</template>

<script>
import last from 'lodash/last';
import Thumbnail from '../Thumbnail';
import filters from './filters';

export default {
	components: {
		Thumbnail
	},
	props: {
		content: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			fields: [
				{ label: 'Thumbnail', key: 'thumbnail' },
				{ label: this.$t('Name'), key: 'name' },
				{
					label: this.$t('Type'),
					key: 'contentType',
					formatter: contentType => last(contentType.split('/'))
				},
				{
					label: this.$t('Size'),
					key: 'metadata',
					formatter: metadata => {
						const fileSize = metadata.fileSize || 0;
						return filters.fileSize(fileSize);
					}
				},
				{
					label: this.$t('Uploaded'),
					key: 'createdAt',
					formatter: value => filters.dateTimeFormat(value),
					thClass: 'FileList_Uploaded'
				}
			]
		};
	},
	methods: {
		onRowClick(content) {
			this.$emit('content-clicked', content);
		}
	}
};
</script>

<style lang="scss">
@import '~@oneflow/ofs-vue-layout/src/styles/variables.scss';

.FileList {
	&.table {
		width: calc(100% + 40px);
		margin: 0 -20px;

		td {
			border-bottom: 1px solid $of-color-grey-3;
		}
	}

	&_Uploaded {
		width: 520px;
	}
}
</style>
