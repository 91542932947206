<template>
	<transition name="fade">
		<div v-if="isVisible" class="selected-recipes-notification">
			<div class="selected-recipes-info">
				<img
					src="@/assets/images/selected-recipes.png"
					alt="selected-recipes"
					class="selected-recipes-info__icon"
				/>
				<div class="selected-recipes-info__text">
					<span>{{ selectedRecipesText }}</span>
					<b-button variant="link" class="text-decoration-none text-left p-0" @click="onDeselectAllRecipes">
						{{ $t('Deselect all') }}
					</b-button>
				</div>
			</div>
			<div class="selected-recipes-actions">
				<b-button @click="$emit('removeRecipes')">
					<img src="@/assets/trash.svg" alt="trash-icon" />
				</b-button>
				<b-button variant="primary" :disabled="true">Download</b-button>
			</div>
		</div>
	</transition>
</template>

<script>
import _ from 'lodash';

export default {
	props: {
		isVisible: {
			type: Boolean,
			required: true
		},
		selectedItems: {
			type: Array,
			required: true
		}
	},
	computed: {
		selectedRecipesText() {
			return `${_.size(this.selectedItems)} ${this.$t(
				` ${_.size(this.selectedItems) === 1 ? 'recipe' : 'recipes'} selected`
			)}`;
		}
	},
	methods: {
		onDeselectAllRecipes() {
			this.$emit('deselectAllRecipes');
		}
	}
};
</script>

<style lang="scss">
.selected-recipes {
	&-notification {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute !important;
		left: 50%;
		transform: translate(-50%, 0);
		bottom: 30px;
		z-index: 9;
		padding: 10px 10px;
		border: none;
		box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.2);
		border-radius: 7px;
		width: 750px;
		background: #fff;
		transition: all 0.5s;

		@media (max-width: 767px) {
			width: 95%;
		}
	}

	&-info {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__icon {
			height: 40px;
			object-fit: contain;
		}

		&__text {
			display: flex;
			flex-direction: column;
			margin-left: 14px;
		}
	}

	&-actions {
		display: flex;
		justify-content: center;
		button {
			margin-left: 10px;
		}
	}
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
