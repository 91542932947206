<template>
	<div class="ImageUploader">
		<div
			v-if="!currentFile && !imageUrl"
			class="DropArea"
			@drop.prevent.stop="onInput"
			@dragover.prevent="onDragOver"
			@dragleave.prevent="onDragLeave"
		>
			<label
				:for="`DropAreaInput-${uid}`"
				:class="[
					'DropArea_Label',
					'm-0',
					disabled ? 'DropArea_Label--disabled' : '',
					dragging ? 'DropArea_Label--dragging' : '',
					invalid ? 'DropArea_Label--invalid' : ''
				]"
			>
				<div class="d-flex align-items-center">
					<template v-if="!uploading"> <icon name="plus" class="mr-2" /> {{ $t('Add Image') }}</template>
					<template v-else>{{ $t('Uploading...') }}</template>
				</div>
			</label>
		</div>
		<template v-else>
			<div class="UploadedFile">
				<div v-if="currentFile" class="UploadedFile-image">
					<Thumbnail :size="43" :src="thumbnailUrl" :error="invalid" />
				</div>
				<div v-else class="UploadedFile-image">
					<b-col>
						<Thumbnail :size="43" :src="imageUrl" :error="invalid" />
						<Loader overlay :loader-class="['ImageOverlay']" />
					</b-col>
				</div>
				<div>
					<div v-if="currentFile" class="UploadedFile-title">{{ currentFile.name }}</div>
					<div v-else class="UploadedFile-title">{{ imageName }}</div>
					<div class="UploadedFile-actions">
						<b-button variant="link" @click="openPicker">{{ $t('Change') }}</b-button>
						<b-button variant="link" @click="onRemove">{{ $t('Remove') }}</b-button>
					</div>
				</div>
			</div>
		</template>
		<input
			:id="`DropAreaInput-${uid}`"
			ref="fileInput"
			class="d-none"
			:class="{ 'is-invalid': invalid }"
			type="file"
			accept="image/jpeg,image/png"
			:disabled="disabled || uploading"
			@input="onInput"
			@drop.prevent.stop="onInput"
		/>
		<div v-if="isChecking" class="default-feedback">{{ $t('Checking...') }}</div>
		<div v-else class="invalid-feedback">{{ invalidFeedback }}</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { withBaseInputConfig } from '@oneflow/ofs-vue-layout';
import Loader from '@/components/Loader';
import Thumbnail from './Thumbnail';
import notifications from '../mixins/notifications';
import { getImageResolutionFromUrl, getImageResolutionFromFile } from '../lib/imageValidation';
import FileUploader from '../lib/FileUploader';

export default {
	components: {
		Thumbnail,
		Loader
	},
	mixins: [notifications, withBaseInputConfig()],
	props: {
		name: {
			type: String,
			default: ''
		},
		path: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			fileUploader: null,
			dragging: false,
			file: null,
			image: null,
			currentFile: null,
			uploading: false,
			imageUrl: null,
			imageName: null
		};
	},
	computed: {
		...mapGetters({ currentAccount: 'account/currentAccount' }),
		uid() {
			return this['_uid'];
		},
		thumbnailUrl() {
			let thumbnailUrl = _.get(this.currentFile, 'thumbnailUrlData.url');
			if (!thumbnailUrl && this.file) {
				thumbnailUrl = URL.createObjectURL(this.file);
			}
			return thumbnailUrl || this.imageUrl;
		},
		invalid() {
			return this.valid === false;
		},
		isChecking() {
			return Boolean(this.value && (!this.value.width || !this.value.height || !this.value.thumbnailUrl));
		}
	},
	mounted() {
		this.$watch('value.fileId', this.initialise);

		this.fileUploader = new FileUploader({
			path: this.path
		});

		if (this.value) {
			this.initialise();
		}
	},
	methods: {
		...mapActions('media-uploader', ['getFileById']),
		async initialise() {
			this.imageUrl = _.get(this.value, 'imageUrl');
			this.imageName = this.getImageName(this.imageUrl);

			const id = _.get(this.value, 'fileId');
			if (!id) {
				this.currentFile = null;
				return;
			}

			try {
				this.currentFile = await this.getFileById({ id });
			} catch {
				this.$notify({ type: 'error', message: this.$t('Saved image is unavailable') });
				this.currentFile = null;
			}

			if (!this.value.width || !this.value.height) {
				const { width, height } = await getImageResolutionFromUrl(this.imageUrl);
				this.onInputChange({ ...this.value, width, height });
			}
			if (!this.value.thumbnailUrl) {
				const thumbnailUrl = this.currentFile.thumbnailUrlData.url;
				this.onInputChange({ ...this.value, thumbnailUrl });
			}
		},
		async upload() {
			this.uploading = true;
			if (!['image/jpeg', 'image/png'].includes(this.file.type)) {
				this.$notify({
					type: 'error',
					text: this.$t('Unsupported file type')
				});
				this.onRemove();
				return;
			}

			try {
				const [{ width, height }, file] = await Promise.all([
					getImageResolutionFromFile(this.file),
					this.fileUploader.upload(this.file, this.currentAccount.authAccountId)
				]);

				const thumbnailUrl = file?.thumbnailUrlData?.url;
				const imageUrl = file?.urlData?.url;
				const fileId = file?._id;
				this.image = { fileId, imageUrl, thumbnailUrl, width, height };

				this.onInputChange(this.image);
			} catch (error) {
				this.notifyError(error);
			} finally {
				this.uploading = false;
			}
		},
		onInput(event) {
			this.dragging = false;
			const file = _.get(event, 'target.files.0', _.get(event, 'dataTransfer.files.0'));
			this.fileSelected(file);
			this.$refs.fileInput.value = null;
		},
		onDragOver() {
			this.dragging = true;
		},
		onDragLeave() {
			this.dragging = false;
		},
		openPicker() {
			this.$refs.fileInput.click();
		},
		fileSelected(file) {
			if (!file) return;

			this.file = file;
			this.currentFile = null;
			this.upload();
		},
		onRemove() {
			const file = this.currentFile;

			this.file = null;
			this.image = null;
			this.currentFile = null;
			this.uploading = false;

			this.onInputChange(undefined);
		},
		getImageName(url) {
			if (!url) {
				return null;
			}
			const filteredName = new URL(url).pathname.split('/').pop();
			return decodeURIComponent(filteredName);
		}
	}
};
</script>

<style lang="scss">
@import '../style/variables';
@import '~@oneflow/ofs-vue-layout/src/styles/variables.scss';
@import '~@oneflow/ofs-vue-layout/src/styles/mixins.scss';

.ImageUploader {
	.DropArea {
		&_Label {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			border: 1px dashed $color-hp-grey-3;
			border-radius: 3px;
			color: $color-pimienta;

			&--disabled {
				background-color: $color-hp-highlights;
				color: $color-hp-grey-2;
				cursor: not-allowed;
			}

			&--invalid {
				border-color: $color-hp-orange;
			}

			&--dragging {
				background-color: $of-color-grey-4;
				border-color: $color-pimienta;
			}
		}
	}

	.ImageOverlay {
		width: 20px;
		height: 20px;
		margin: 0;
	}

	.UploadedFile {
		display: flex;

		&-image {
			margin-right: 18px;
		}

		&-actions {
			margin-top: 3px;
			font-size: 14px;
			line-height: 18px;

			.btn-link {
				padding: 0 12px;
				border-right: 1px solid $color-pimienta;
				color: $color-pimienta;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					border-right: none;
				}
			}
		}
	}

	.default-feedback {
		width: 100%;
		margin-top: 0.25rem;
		font-size: 80%;
	}
}
</style>
