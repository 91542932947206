<template>
	<b-tab :title="$t('Contributors')">
		<div class="d-flex justify-content-between align-items-center mb-2">
			<h2 class="mb-0">{{ $t('Contributors') }}</h2>
			<b-button variant="primary" @click="toggleContributorModalVisibility">
				{{ $t('Add contributor') }}
			</b-button>
		</div>
		<Datatable
			:items="contributors.data"
			:total-items="contributors.total"
			:fields="contributorFields"
			:current-page="currentPage"
			:per-page="perPage"
			:fetch-data="fetchContributorsData"
			@table-change="handleContributorsTableChange"
		>
			<template #cell(actions)="{ item }">
				<b-dropdown size="sm" toggle-class="p-1" right no-caret>
					<template #button-content>
						<icon name="ellipsis-h" />
					</template>
					<b-dropdown-item @click.stop="handleRemoveContributor(item.authAccountId)">
						<icon name="trash" class="mr-2" /> {{ $t('Remove') }}
					</b-dropdown-item>
				</b-dropdown>
			</template>
		</Datatable>
		<BookContributorModal
			:is-visible="isContributorModalVisible"
			:contributor-ids="contributorIds"
			@hidden="toggleContributorModalVisibility"
			@add-contributor="handleAddContributor"
		/>
	</b-tab>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Datatable } from '@oneflow/ofs-vue-layout';
import BookContributorModal from '../BookContributorModal.vue';
import notifications from '@/mixins/notifications';

export default {
	components: {
		Datatable,
		BookContributorModal
	},
	mixins: [notifications],
	props: {
		contributorIds: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			currentPage: 1,
			perPage: 10,
			contributorFields: [
				{ label: this.$t('Account name'), key: 'name' },
				{ label: this.$t('Display name'), key: 'displayName' },
				{ label: this.$t('Actions'), key: 'actions' }
			],
			isContributorModalVisible: false
		};
	},
	computed: {
		...mapGetters({
			contributors: 'contributor/contributors'
		})
	},
	watch: {
		contributorIds: {
			immediate: true,
			async handler(newValue, value) {
				if (!_.isEqual(newValue, value)) {
					await this.fetchContributorsData();
				}
			}
		}
	},
	methods: {
		...mapActions({
			getBookContributors: 'contributor/getBookContributors'
		}),
		toggleContributorModalVisibility() {
			this.isContributorModalVisible = !this.isContributorModalVisible;
		},
		async fetchContributorsData() {
			const query = {
				$where: { authAccountId: { $in: this.contributorIds } },
				$limit: this.perPage,
				$skip: this.perPage * (this.currentPage - 1)
			};
			await this.getBookContributors({ query: { query } });
		},
		handleContributorsTableChange({ currentPage, perPage }) {
			this.currentPage = currentPage;
			this.perPage = perPage;

			this.fetchContributorsData();
		},
		async handleRemoveContributor(contributorId) {
			const isConfirm = await this.confirmAction([
				this.$t('Are you sure to remove this contributor?'),
				this.$t('All recipes from this account will be removed from the book.')
			]);
			if (!isConfirm) return;

			const newContributorIds = _.filter(this.contributorIds, id => id !== contributorId);
			this.$emit('update-contributors', newContributorIds);
		},
		async handleAddContributor(contributorId) {
			const newContributorIds = [...this.contributorIds, contributorId];
			this.$emit('update-contributors', newContributorIds);
		}
	}
};
</script>
