const template = `
<html>
<head>
<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
img {
  width: 100%;
}
.recipe-container {
  page-break-after: always;
  margin: 20px;
  width: 1000px;
}
.bold-text {
  font-weight: bold;
}
.instruction-element {
  list-style: none;
  margin-bottom: 10px;
}
.section {
  margin-bottom: 20px;
}
.image-info {
  display: inline-block;
  width: 30%;
  margin-bottom: 20px;
}
.ingredient-element {
  list-style: none;
}
.images-section {
  margin-bottom: 20px;
}
</style>
</head>
<body>
<div class="recipe-container">
<h1>{{recipe.record.name}}</h1>

<div class="section">
<p><em>Description: {{recipe.record.description}}</em></p>
<p><em>Author: {{recipe.record.author}}</em></p>
<p><em>Category: {{recipe.record.category}}</em></p>
<p><em>Cuisine: {{recipe.record.cuisine}}</em></p>
<p><em>Serving size: {{recipe.record.yield}}</em></p>
</div>

<div class="section">
<h3>Ingredients:</h3>
<ul>
{{#each recipe.record.ingredients.elements}}                
  {{#if this.elements}}
    <li class="bold-text ingredient-element">{{this.name}}</li>
    {{#each this.elements}}
      <li class="ingredient-element">{{this.name}}</li>
    {{/each}}
  {{else}}
    <li class="ingredient-element">{{this.name}}</li>
  {{/if}}
{{/each}}
</ul>
</div>

<div class="section">
<h3>Instructions:</h3>
<ol>
{{#each recipe.record.instructions.elements}}                
  {{#if this.elements}}
    <li class="bold-text instruction-element">{{this.name }}</li>
      {{#each this.elements}}
        <li class="instruction-element">{{this.text}}</li>
      {{/each}}
    {{else}}
      <li class="instruction-element">{{this.text}}</li>
  {{/if}}
{{/each}}
</ol>
<p>NOTE: {{recipe.record.notes}}</p>
</div>

<div class="section">
<h3>Nutrition:</h3>
<p>Calories: {{#if recipe.record.nutrition.calories}}{{recipe.record.nutrition.calories}}{{/if}}</p>
<p>Fat: {{#if recipe.record.nutrition.fatContent}}{{recipe.record.nutrition.fatContent}}g{{/if}}</p>
<p>Protein: {{#if recipe.record.nutrition.proteinContent}}{{recipe.record.nutrition.proteinContent}}g{{/if}}</p>
<p>Carbs: {{#if recipe.record.nutrition.carbohydrateContent}}{{recipe.record.nutrition.carbohydrateContent}}g{{/if}}</p>
</div>

<div class="section">
<h3>Cooking time:</h3>
<p>COOK: {{recipe.record.cookTime}}</p>
<p>PREP: {{recipe.record.prepTime}}</p>
<p>TOTAL: {{recipe.record.totalTime}}</p>
</div>

<div class="section images-section">
<h3>Images:</h3>
{{#each recipe.record.images}}
<div>{{inc @index}}) Resolution: {{resolution}}</div>
{{/each}}
</div>

<div class="section">
<h3>IGNORE THIS DIV</h3>
</div>

</div>
</body>
</html>`;

module.exports = template;
