<template>
	<OfFormInput
		class="SearchInput"
		:placeholder="placeholder"
		:value="value"
		no-vuex
		no-label
		@input="value => $emit('input', value)"
	>
		<template #append>
			<icon :name="appendIcon" @click="onAppendClick" />
		</template>
	</OfFormInput>
</template>

<script>
import { OfFormInput } from '@oneflow/ofs-vue-layout';

export default {
	components: {
		OfFormInput
	},
	props: {
		placeholder: {
			type: String,
			default: null
		},
		value: {
			type: String,
			default: ''
		}
	},
	computed: {
		appendIcon() {
			return this.value ? 'times-circle' : 'search';
		}
	},
	methods: {
		onAppendClick() {
			if (this.value) {
				this.$emit('input', '');
			}
		}
	}
};
</script>

<style lang="scss">
.SearchInput {
	max-width: 345px;

	input {
		border-radius: 3px !important;
	}

	.input-group-append {
		position: absolute;
		top: 10px;
		right: 17px;
		z-index: 10;

		.fa-icon {
			cursor: pointer;
		}
	}
}
</style>
