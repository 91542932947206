<template>
	<div class="position-relative d-inline-block">
		<b-img
			thumbnail
			:src="theSrc"
			:style="!fluid ? `width: ${size}px; height: ${size}px` : ''"
			class="thumbnail"
			@error="handleError"
		/>
		<div
			v-if="warning || error"
			class="position-absolute d-flex justify-content-center align-items-center color-overlay"
			:class="overlayClasses"
		>
			<b-img :src="AlertSolidWhite" class="thumbnail-alert-icon" alt="info icon" />
		</div>
	</div>
</template>

<script>
import placeholder from '../assets/placeholder.svg';
import AlertSolidWhite from '../assets/alert-solid-white.svg';

export default {
	props: {
		src: {
			type: String,
			default: null
		},
		size: {
			type: [Number, String],
			default: 40
		},
		fluid: {
			type: Boolean,
			default: false
		},
		warning: { type: Boolean, default: false },
		error: { type: Boolean, default: false }
	},
	data() {
		return {
			hasError: false,
			AlertSolidWhite
		};
	},
	computed: {
		theSrc() {
			return this.hasError || !this.src ? placeholder : this.src;
		},
		overlayClasses() {
			return {
				'overlay-warning': this.warning,
				'overlay-error': this.error
			};
		}
	},
	watch: {
		src(newValue, oldValue) {
			if (newValue && newValue !== oldValue) {
				this.hasError = false;
			}
		}
	},
	methods: {
		handleError() {
			this.hasError = true;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '~@oneflow/ofs-vue-layout/src/styles/variables.scss';
@import '../style/variables.scss';

.thumbnail {
	padding: 0;
	object-fit: contain;
	border: 1px solid #d2ddf4;
	border-radius: $of-border-radius;
}

.thumbnail-alert-icon {
	height: 25px;
	width: 25px;
}

.color-overlay {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.overlay-warning {
	background: $overlay-warning-color;
}

.overlay-error {
	background: $overlay-error-color;
}
</style>
