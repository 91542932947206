<template>
	<b-col class="narrative-section">
		<draggable
			:value="sections"
			ghost-class="ghost"
			handle=".narrative-section__drag-icon"
			@input="handleDragAndDrop"
		>
			<div
				v-for="(section, sectionIndex) in sections"
				:key="sectionIndex"
				class="narrative-section__container d-flex mb-2"
			>
				<div class="w-100">
					<div class="narrative-section__header d-flex align-items-center pr-2 py-1">
						<OfFormInput
							class="narrative-section__input w-100"
							no-label
							:placeholder="$t('Subtitle')"
							:name="getName(sectionIndex)"
						/>
						<b-button
							class="narrative-section__delete-btn"
							size="sm"
							variant="light"
							@click="handleDeleteSection(sectionIndex)"
						>
							<icon name="trash" />
						</b-button>
					</div>

					<div class="m-0 pr-2 d-flex">
						<div class="narrative-section__drag mr-2">
							<img
								src="../../../../assets/grip-vertical-lg.svg"
								class="narrative-section__drag-icon"
								alt="grip-icon"
								width="10"
							/>
						</div>

						<div class="narrative-section__item w-100 mb-2 mt-2">
							<div class="narrative-section__item-title mb-1">{{ $t('Paragraphs') }}</div>
							<div class="w-100">
								<div
									v-for="(paragraph, paragraphIndex) in section.paragraphs"
									:key="paragraphIndex"
									class="d-flex w-100"
								>
									<OfFormTextarea
										class="narrative-section__textarea w-100"
										required
										show-errors
										:name="getName(sectionIndex, paragraphIndex)"
									/>
									<b-button
										class="narrative-section__delete-btn ml-2 mt-3"
										size="sm"
										variant="light"
										@click="handleDeleteParagraph(paragraphIndex, sectionIndex)"
									>
										<icon name="trash" />
									</b-button>
								</div>

								<b-button
									variant="outline-primary"
									class="mt-1 btn-block"
									@click="handleAddParagraph(sectionIndex)"
								>
									<icon name="plus" />
									{{ $t('Add Paragraph') }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</draggable>

		<b-button class="mt-1 btn-block" variant="outline-primary" @click="handleAddSection">
			<icon class="mr-1" name="plus" />
			{{ $t('Add Section') }}
		</b-button>

		<div class="narrative-section__spacer mt-3" />
	</b-col>
</template>

<script>
import Draggable from 'vuedraggable';
import { OfFormInput, OfFormTextarea } from '@oneflow/ofs-vue-layout';

export default {
	components: {
		Draggable,
		OfFormInput,
		OfFormTextarea
	},
	props: {
		sections: {
			type: Array,
			default: () => []
		},
		path: {
			type: String,
			required: true
		}
	},
	methods: {
		getName(sectionIndex, paragraphIndex) {
			const base = `${this.path}.sections.`;
			return paragraphIndex === undefined
				? base + `${sectionIndex}.subtitle`
				: base + `${sectionIndex}.paragraphs.${paragraphIndex}.text`;
		},
		handleDragAndDrop(sections) {
			this.$emit('sections', sections);
		},
		handleAddSection() {
			const newSection = {
				subtitle: '',
				paragraphs: []
			};
			this.$emit('sections', _.concat(this.sections, [newSection]));
		},
		handleDeleteSection(index) {
			const sections = [...this.sections];
			sections.splice(index, 1);
			this.$emit('sections', sections);
		},
		handleDeleteParagraph(index, sectionIndex) {
			const paragraphs = [...this.sections[sectionIndex].paragraphs];
			paragraphs.splice(index, 1);
			this.$emit('paragraphs', { paragraphs, sectionIndex, index });
		},
		handleAddParagraph(sectionIndex) {
			this.$emit('paragraphs', {
				paragraphs: _.concat(this.sections[sectionIndex].paragraphs, [{ text: '' }]),
				sectionIndex
			});
		}
	}
};
</script>

<style lang="scss">
@import '../../../../style/_variables.scss';

.narrative-section {
	&__container {
		border-radius: 3px;
		border: 1px solid $color-hp-grey-3;
	}

	&__header {
		background-color: $color-hp-highlights;
		border-bottom: 1px solid $color-hp-grey-3;
	}

	&__item {
		flex-direction: column;

		&-title {
			font-weight: 600;
			color: $color-hp-grey-1;
		}
	}

	&__input {
		input {
			&:not(.is-invalid) {
				border: none;
			}

			background-color: transparent;

			&:focus {
				box-shadow: none;
				background-color: transparent;
			}

			&.is-invalid {
				&:focus {
					box-shadow: none;
				}
			}
		}
	}

	&__drag {
		background-color: $color-hp-light;
		width: 30px;
		display: flex;
		justify-content: center;
		align-items: center;

		&-icon {
			cursor: pointer;
		}
	}

	&__delete-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 30px;
		width: 30px;
	}

	&__spacer {
		background-color: $color-hp-highlights;
		width: 100%;
		height: 5px;
	}

	&__textarea {
		label {
			display: none;
		}
	}
}
</style>
