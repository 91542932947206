<template>
	<b-row class="BookAppearance">
		<BookAppearanceAlert v-if="coverInnerSeparationEnabled" />
		<b-col md="3" class="BookAppearance-OptionsPanel">
			<div class="OptionsPanel-Inner">
				<TemplateSelect
					v-model="activeLayout"
					track-by="_id"
					:label="$t('Template')"
					:placeholder="$t('Select template')"
					:options="filteredLayouts"
					:disabled="coverInnerSeparationEnabled"
				/>

				<TemplateSelect
					v-model="activeVariant"
					track-by="name"
					:label="$t('Cover variant')"
					:placeholder="$t('Select cover variant')"
					:options="variants"
				/>

<!--				<Section :title="$t('Cover')" class="mt-4" />-->

<!--				<b-form-group :label="$t('Headers Font')" class="mt-3">-->
<!--					<OfFormSelect-->
<!--						v-if="isLayoutVersionNew"-->
<!--						name="source.theme.customization.coverHeadersFont"-->
<!--						:options="coverTitleFonts"-->
<!--						no-label-->
<!--						class="mb-1"-->
<!--					/>-->
<!--					<OfFormSelect-->
<!--						v-else-->
<!--						name="source.theme.base.coverHeadersFontFamily"-->
<!--						:options="themeOptions.coverHeadersFontFamily"-->
<!--						no-label-->
<!--						class="mb-1"-->
<!--					/>-->
<!--					<div class="d-flex align-items-start">-->
<!--						<ColorPicker name="source.theme.base.coverHeadersColor" class="mr-1" />-->
<!--						<OfFormInput name="source.theme.base.coverHeadersColor" class="no-label w-100" show-errors />-->
<!--					</div>-->
<!--				</b-form-group>-->

<!--				<template v-if="isLayoutVersionNew">-->
<!--					<b-form-group :label="$t('Subheaders Font')" class="mt-3">-->
<!--						<OfFormSelect-->
<!--							name="source.theme.customization.coverSubheaderFont"-->
<!--							:options="coverSubtitleFonts"-->
<!--							no-label-->
<!--							class="mb-1"-->
<!--						/>-->
<!--					</b-form-group>-->

<!--					<b-form-group :label="$t('Author Fonts')" class="mt-3">-->
<!--						<OfFormSelect-->
<!--							name="source.theme.customization.coverAuthorFont"-->
<!--							:options="coverAuthorFonts"-->
<!--							no-label-->
<!--							class="mb-1"-->
<!--						/>-->
<!--					</b-form-group>-->
<!--				</template>-->

<!--				<b-form-group :label="$t('Overlay Background Colour')">-->
<!--					<div class="d-flex align-items-start">-->
<!--						<ColorPicker-->
<!--							name="source.theme.base.coverOverlayBackgroundColor"-->
<!--							class="mr-1"-->
<!--							:options="themeOptions.coverOverlayBackgroundColor"-->
<!--						/>-->
<!--						<OfFormInput-->
<!--							class="no-label w-100"-->
<!--							name="source.theme.base.coverOverlayBackgroundColor"-->
<!--							show-errors-->
<!--						/>-->
<!--					</div>-->
<!--				</b-form-group>-->

<!--				<b-form-group :label="$t('Background Colour')">-->
<!--					<div class="d-flex align-items-start">-->
<!--						<ColorPicker-->
<!--							name="source.theme.base.coverBackgroundColor"-->
<!--							class="mr-1"-->
<!--							:options="themeOptions.coverBackgroundColor"-->
<!--						/>-->
<!--						<OfFormInput name="source.theme.base.coverBackgroundColor" class="no-label w-100" show-errors />-->
<!--					</div>-->
<!--				</b-form-group>-->


				<Section :title="$t('Content')" class="mt-4" />

				<b-form-group :label="$t('Inside header font')" class="mt-3">
					<OfFormSelect
						class="mb-1"
						name="source.theme.base.contentHeadersFontFamily"
						:options="themeOptions.contentHeadersFontFamily"
						no-label
					/>
					<div class="d-flex align-items-start">
						<ColorPicker name="source.theme.base.contentHeadersColor" class="mr-1" />
						<OfFormInput name="source.theme.base.contentHeadersColor" class="no-label w-100" show-errors />
					</div>
				</b-form-group>
			</div>
		</b-col>
		<BookAppearancePreview
			:source="formData ? formData.source : {}"
			:product-content="productContent"
			:preview-options="previewOptions"
		/>
	</b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, OfFormSelect, withForm } from '@oneflow/ofs-vue-layout';
import Section from '../../../../../components/Section.vue';
import ColorPicker from '../../../../../components/ColorPicker.vue';
import TemplateSelect from '../../../../../components/BookEdit/TemplateSelect.vue';
import { coverFields, coverTypes, featureFlags, templateFormat } from '../../../../../constants';
import { optionValidationRules, getTemplateOptions, getDefaultAppearance } from '../helpers';
import BookAppearancePreview from '../../../../../components/BookAppearance/BookAppearancePreview.vue';
import BookAppearanceAlert from '../../../../../components/BookAppearance/BookAppearanceAlert';
export const appearanceFormName = 'bookAppearance';

export default {
	components: {
		OfFormInput,
		OfFormSelect,
		Section,
		ColorPicker,
		TemplateSelect,
		BookAppearancePreview,
		BookAppearanceAlert
	},
	mixins: [withForm(appearanceFormName, { preserveDataOnMount: true })],
	props: {
		template: {
			type: Object,
			required: true
		},
		productContent: {
			type: Object,
			required: true
		},
		product: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			previewOptions: {}
		};
	},
	computed: {
		...mapGetters({
			layouts: 'layout/layouts',
			features: 'feature/features'
		}),
		coverInnerSeparationEnabled() {
			return this.features?.[featureFlags.coverInnerSeparation];
		},
		validationRules() {
			const $t = this.$t.bind(this);
			return {
				formData: {
					source: {
						theme: {
							base: optionValidationRules($t)
						}
					}
				}
			};
		},
		activeLayout: {
			get() {
				const parentLayoutId = this.formData?.parentLayoutId;
				return _.find(this.filteredLayouts, ['id', parentLayoutId]);
			},
			set(layout) {
				const { id, previewImageUrl, template } = layout;
				if (template?.theme?.customisationOptions) {
					this.$emit('layoutChange', id);
					return;
				}
				this.initFormData({
					...this.formData,
					parentLayoutId: id,
					previewImageUrl: previewImageUrl,
					source: _.cloneDeep(template)
				});
			}
		},
		activeVariant: {
			get() {
				const variantName = _.get(this.formData, 'source.theme.selectedVariant');
				return _.find(this.variants, ['name', variantName]);
			},
			set(variant) {
				this.updateField('source.theme.selectedVariant', variant.name);
			}
		},
		themeOptions() {
			if (!_.has(this.formData, 'source')) return {};
			return getTemplateOptions(this.formData.source);
		},
		coverTitleFonts() {
			if (!_.has(this.formData, 'source')) return [];
			const titleFonts = _.get(this.formData, 'source.theme.typography');
			return _.reduce(
				titleFonts,
				(arr, font) => {
					if (font.type === coverTypes.primary) {
						arr.push({
							text: font.fontFamily,
							value: _.omit(font, 'type')
						});
					}
					return arr;
				},
				[]
			);
		},
		coverSubtitleFonts() {
			if (!_.has(this.formData, 'source')) return [];
			const relatedFonts = this.formData?.source?.theme?.customization?.coverHeadersFont?.relatedFonts;
			const subtitleFonts = _.get(this.formData, 'source.theme.typography');
			if (relatedFonts && relatedFonts?.some(font => font?.fields?.includes(coverFields.subtitle))) {
				return _.concat(
					this.prepareFontsStructure(relatedFonts, coverTypes.secondary, coverFields.subtitle),
					this.prepareFontsStructure(subtitleFonts, coverTypes.secondary, coverFields.subtitle)
				);
			}
			return this.prepareFontsStructure(subtitleFonts, coverTypes.secondary, coverFields.subtitle);
		},
		coverAuthorFonts() {
			if (!_.has(this.formData, 'source')) return [];
			const subtitleFonts = _.get(this.formData, 'source.theme.typography');
			const relatedFonts = this.formData?.source?.theme?.customization?.coverHeadersFont?.relatedFonts;
			if (relatedFonts && relatedFonts?.some(font => font?.fields?.includes(coverFields.author))) {
				return _.concat(
					this.prepareFontsStructure(relatedFonts, coverTypes.secondary, coverFields.author),
					this.prepareFontsStructure(subtitleFonts, coverTypes.secondary, coverFields.author)
				);
			}
			return this.prepareFontsStructure(subtitleFonts, coverTypes.secondary, coverFields.author);
		},
		variants() {
			return _.get(this.formData, 'source.theme.variants', []);
		},
		isLayoutVersionNew() {
			const layoutVersion = _.get(this.formData, 'source.props.templateVersion', 1);
			return layoutVersion > 1;
		},
		filteredLayouts() {
			return _.filter(this.layouts, layout => !layout.name.includes('separation'));
		}
	},
	watch: {
		template: {
			immediate: true,
			async handler(template, oldTemplate) {
				if (!_.isEqual(template, oldTemplate)) {
					await this.initialize();
				}
			}
		},
		activeLayout(layout, oldLayout) {
			if (!layout || !oldLayout || layout._id === oldLayout._id) return;
			this.resetThemeBaseOptions();
		},
		activeVariant(variant, oldVariant) {
			if (!variant || !oldVariant || variant.name === oldVariant.name) return;
			this.resetThemeBaseOptions();
		}
	},
	async mounted() {
		await this.initialize();
	},
	methods: {
		...mapActions({
			getPreviewOptions: 'template/getPreviewOptions'
		}),
		async initialize() {
			const templateId = _.get(this.template, '_id');
			const formDataTemplateId = _.get(this.formData, '_id');

			if (!_.isEqual(templateId, formDataTemplateId)) {
				const source = this.getThemeBaseOptions();
				this.setFormData(_.merge(this.template, { source }));
			}
			await this.setPreviewOptions();
		},
		getThemeBaseOptions() {
			const { template } = this;
			const source = _.cloneDeep(template.source);
			const variantName = _.get(template, 'source.theme.selectedVariant');
			const variants = _.get(template, 'source.theme.variants', []);
			const activeVariant = _.find(variants, ['name', variantName]);
			source.theme.base = { ...getDefaultAppearance(activeVariant), ...source.theme.base };
			return source;
		},
		resetThemeBaseOptions() {
			const source = _.cloneDeep(this.formData.source);
			source.theme.base = { ...source.theme.base, ...getDefaultAppearance(this.activeVariant) };
			this.updateField('source', source);
		},
		async setPreviewOptions() {
			const { _id: productId, type: productType } = this.product;

			this.previewOptions = await this.getPreviewOptions({
				productId: productId,
				productContent: this.productContent,
				productType
			});
		},
		prepareFontsStructure(fonts, type, coverField) {
			return _.reduce(
				fonts,
				(arr, font) => {
					if (font.type === type && font?.fields?.includes(coverField)) {
						arr.push({
							text: font.fontFamily,
							value: _.omit(font, 'type', 'fields')
						});
					}
					return arr;
				},
				[]
			);
		}
	}
};
</script>

<style lang="scss">
@import '../../../../../style/variables.scss';

.BookAppearance {
	margin-top: -30px;

	&-OptionsPanel {
		overflow: scroll;
		max-height: 100vh;
		padding-top: 30px;
		padding-right: 30px;

		@media (min-width: 768px) {
			border-right: 1px solid $color-hp-grey-3;
		}

		.col-form-label {
			font-weight: $of-font-weight-semi-bold;
			color: $color-hp-black;
		}

		.no-label label {
			display: none;
		}

		.form-group {
			input,
			select {
				border-color: $color-hp-grey-3;
			}
		}
	}
}
</style>
