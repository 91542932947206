<template>
	<div class="d-flex flex-column m-5">
		<b-row class="d-flex">
			<b-col>
				<p v-if="!_.size(_.get(recipe, 'record.images'))">
					<em>{{ $t('No images') }}</em>
				</p>

				<carousel v-else :per-page="1" :navigation-enabled="true" class="recipe-carousel">
					<slide v-for="(image, index) in recipe.record.images" :key="index" class="recipe-slider">
						<b-img :src="`${image.fileURL || image.url}`" :alt="`${image.name}`" fluid></b-img>
					</slide>
				</carousel>

				<b-container v-if="recipe.errors" class="recipe-warnings">
					<b-row v-for="(error, index) in recipe.errors" :key="index">
						{{ parseError(error) }}
					</b-row>
				</b-container>
			</b-col>
			<b-col class="ml-3">
				<h1>{{ recipe.record.name }}</h1>
				<h3 v-if="recipe.record.author">
					{{ 'by ' + recipe.record.author }}
				</h3>
				<p>
					<b>{{ $t('Size') }}:</b> {{ recipe.record.size }}
				</p>
				<b-row v-if="_.size(_.get(recipe, 'record.tags'))" class="m-0">
					<div v-for="(tag, index) in recipe.record.tags" :key="index" class="recipe-tag">
						{{ tag }}
					</div>
				</b-row>
				<p>{{ recipe.record.description }}</p>
				<p v-if="recipe.record.category">
					<em>{{ $t('Category: ') + recipe.record.category }}</em>
				</p>
				<p v-if="recipe.record.cuisine">
					<em>{{ $t('Cuisine: ') + recipe.record.cuisine }}</em>
				</p>
				<p v-if="recipeYield">
					<em>{{ $t('Serving size: ') + recipeYield }}</em>
				</p>
				<b-row class="m-0">
					<b-col v-if="_.get(recipe, 'record.prepTime')" class="p-0 mb-2">{{
						'PREP: ' + parsePTTime(recipe.record.prepTime)
					}}</b-col>
					<b-col v-if="_.get(recipe, 'record.cookTime')" class="p-0 mb-2">{{
						'COOK: ' + parsePTTime(recipe.record.cookTime)
					}}</b-col>
					<b-col v-if="_.get(recipe, 'record.totalTime')" class="p-0 mb-2">{{
						'TOTAL: ' + parsePTTime(recipe.record.totalTime)
					}}</b-col>
				</b-row>
			</b-col>
		</b-row>

		<div>
			<h2>{{ $t('INGREDIENTS') }}</h2>
			<ul v-if="_.get(recipe, 'record.ingredients.elements')" class="p-0">
				<li
					v-for="(item, index) in recipe.record.ingredients.elements"
					:key="index"
					:class="
						getIngredientClass(_.has(item, 'unit') && _.has(item, 'quantity') ? 'INGREDIENT' : 'SECTION')
					"
				>
					<template v-if="_.has(item, 'unit') && _.has(item, 'quantity')">
						{{ `${item.quantity || ''} ${item.unit} ${item.name || ''}` }}
					</template>
					<template v-else>
						{{ item.name }}
						<ul>
							<li
								v-for="(ingredient, elementIndex) in item.elements"
								:key="elementIndex"
								class="list-ingredients-item"
							>
								{{ `${ingredient.quantity || ''} ${ingredient.unit} ${ingredient.name || ''}` }}
							</li>
						</ul>
					</template>
				</li>
			</ul>
			<h2>{{ $t('INSTRUCTIONS') }}</h2>
			<ul v-if="_.get(recipe, 'record.instructions.elements')" class="p-0">
				<li
					v-for="(item, index) in recipe.record.instructions.elements"
					:key="index"
					:class="getInstructionClass(_.has(item, 'text') ? 'INSTRUCTION' : 'SECTION')"
				>
					<template v-if="_.has(item, 'name')">
						{{ item.name }}
						<ul>
							<li v-for="(instruction, idx) in item.elements" :key="idx" class="list-instructions-item">
								{{ instruction.text }}
							</li>
						</ul>
					</template>
					<template v-else>
						{{ item.text }}
					</template>
				</li>
			</ul>
			<p v-if="recipe.record.notes">
				<em>{{ $t('NOTE: ') + recipe.record.notes }}</em>
			</p>
			<h2>{{ $t('Nutrition') }}</h2>
			<p v-if="_.get(recipe, 'record.nutrition.calories')">
				{{ recipe.record.nutrition.calories + ' calories' }}
			</p>
			<p v-if="_.get(recipe, 'record.nutrition.fatContent')">
				{{ recipe.record.nutrition.fatContent + 'g fat' }}
			</p>
			<p v-if="_.get(recipe, 'record.nutrition.proteinContent')">
				{{ recipe.record.nutrition.proteinContent + 'g protein' }}
			</p>
			<p v-if="_.get(recipe, 'record.nutrition.carbohydrateContent')">
				{{ recipe.record.nutrition.carbohydrateContent + 'g carbs' }}
			</p>
			<p v-if="recipe.record.qrCode">
				<b>{{ $t('QR code link') }}</b> {{ ':  ' + recipe.record.qrCode }}
			</p>
			<h2>{{ $t('Additional details') }}</h2>
			<ul>
				<li v-for="(key, index) in additionalDataKeys" :key="index">
					<b>{{ key }}</b> : {{ recipe.record[key] }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { defaultRecipeSchema } from '@/constants'; // TODO: move this entity to static assert in pimienta-api

export default {
	props: {
		recipe: {
			type: Object,
			default: () => {},
			required: true
		}
	},
	computed: {
		_() {
			return _;
		},
		additionalDataKeys() {
			return _.difference(_.keys(this.recipe.record), _.keys(defaultRecipeSchema));
		},
		recipeYield() {
			const isStringYield = _.isString(this.recipe?.record?.yield);
			return isStringYield
				? _.get(this.recipe, 'record.yield', '')
				: _.get(this.recipe, 'record.yield.value', '');
		}
	},
	methods: {
		getIngredientClass(type) {
			return type === 'SECTION' ? 'list-section' : 'list-ingredients-item';
		},
		getInstructionClass(type) {
			return type === 'SECTION' ? 'list-section' : 'list-instructions-item';
		},
		parsePTTime(ptTime) {
			let time = ptTime;
			if (time.includes('PT')) {
				time = time.replace('PT', '');
				time = time.replace('H', ' hr ');
				time = time.replace('M', ' min ');
				time = time.replace('S', ' s ');
			}
			return time;
		},
		parseError(error) {
			if (_.last(error.instancePath.split('/')) === 'elements') return error.instancePath.split('/')[1] + ' ???';

			return _.last(error.instancePath.split('/')) + ' ???';
		}
	}
};
</script>

<style lang="scss">
.recipe-tag {
	font-style: italic;
	color: #23b7e5;
	padding-right: 10px;
	width: fit-content;
	margin-bottom: 10px;
}

.recipe-carousel {
	margin-bottom: 20px;
}

.recipe-slider {
	padding: 10px;
}

.list-section {
	font-weight: 600;
	list-style-type: none;
	margin: 10px 0;
}

.list-ingredients-item {
	list-style-type: circle;
	font-weight: normal;
}

.list-instructions-item {
	list-style-type: none;
	margin-bottom: 10px;
	font-weight: normal;
}

.recipe-carousel {
	width: 100%;
	text-shadow: 1px 1px 2px #333;
}

.recipe-warnings {
	width: auto;
	color: red;
	margin: 0 10px;
	margin-bottom: 20px;
	padding: 20px;
	border: 1px solid #d9d9d9;
}

.recipe-buttons {
	padding: 0 20px 20px;
}
</style>
