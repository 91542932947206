import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'root-book-section',
		pluralName: 'root-book-sections',
		transport: 'davinci',
		options: {
			namespaced: true
		}
	});
}
