import { render, staticRenderFns } from "./ScrapingRuleEdit.vue?vue&type=template&id=bf5c2d06&"
import script from "./ScrapingRuleEdit.vue?vue&type=script&lang=js&"
export * from "./ScrapingRuleEdit.vue?vue&type=script&lang=js&"
import style0 from "./ScrapingRuleEdit.vue?vue&type=style&index=0&id=bf5c2d06&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports