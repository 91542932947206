<template>
	<b-modal
		:title="$t('Add contributor')"
		size="md"
		:ok-title="$t('Add')"
		:ok-disabled="!canSubmit"
		:visible="isVisible"
		@ok="onSubmit"
		@hidden="$emit('hidden')"
	>
		<b-row>
			<b-col>
				<OfMultiSelect
					name="contributor"
					:options="accountOptions"
					:placeholder="$t(`Select account`)"
					:is-loading="isFetching"
					no-label
					show-errors
					required
					@search-change="onAccountSearchChange"
				/>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { withForm, OfMultiSelect } from '@oneflow/ofs-vue-layout';

export default {
	components: {
		OfMultiSelect
	},
	mixins: [withForm('addBookContributorForm')],
	props: {
		isVisible: {
			type: Boolean,
			required: true
		},
		contributorIds: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			accounts: [],
			isFetching: false
		};
	},
	computed: {
		...mapGetters({
			currentAccount: 'account/currentAccount'
		}),
		accountOptions() {
			const options = _.map(this.accounts, account => {
				return { value: account.authAccountId, text: account.name };
			});

			return _.uniqBy(options, 'value');
		}
	},
	async mounted() {
		try {
			await this.onAccountSearchChange();
		} catch (err) {
			this.$notify({
				type: 'error',
				title: this.$t('Error'),
				text: this.$t('An error occurred while fetching accounts')
			});
		}
	},
	methods: {
		...mapActions({
			getContributorOptions: 'contributor/getContributorOptions'
		}),
		async onAccountSearchChange(search) {
			const query = {
				$where: {
					authAccountId: { $nin: [this.currentAccount._id, ...this.contributorIds] },
					...(!_.isEmpty(search?.text) ? { name: { $regex: `^${search.text}`, $options: 'i' } } : {})
				},
				$limit: 10
			};
			await this.searchAccounts({ query: { query } });
		},
		searchAccounts: _.debounce(async function(...args) {
			this.isFetching = true;
			const { data } = await this.getContributorOptions(...args);
			this.accounts = data;
			this.isFetching = false;
		}, 500),
		async onSubmit() {
			this.$emit('add-contributor', this.formData.contributor);
			this.resetFormData();
		}
	}
};
</script>
