<template>
	<DraggableTreeview v-model="value" tab="methods" @input="onInputChange" />
</template>

<script>
import _ from 'lodash';
import DraggableTreeview from './DraggableTreeview';

export default {
	components: {
		DraggableTreeview
	},
	props: {
		data: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			value: []
		};
	},
	watch: {
		data(value) {
			this.value = _.get(value, 'elements');
		}
	},
	methods: {
		onInputChange(elements) {
			const instructions = { ...this.data, elements };
			this.$emit('input', { instructions });
		}
	}
};
</script>
