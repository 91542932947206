<template>
	<Layout>
		<Page class="RecipesList">
			<ListTable
				bordered
				hover
				:table-title="$t('Recipes')"
				:config="config"
				:items="documents.data"
				:fields="fields"
				:sort="sort"
				:total-items="documents.total"
				:current-page="currentPage"
				:per-page="perPage"
				:fetch-data="fetchData"
				:is-busy="isDocumentsFetching"
				@row-clicked="handleRowClick"
				@table-change="handleTableChange"
			>
				<template #head(checked)="{}">
					<div class="select-all-checkbox-container">
						<of-form-checkbox :value="allActiveItemsSelected" @input="toggleSelectAllActiveItems()" />
					</div>
				</template>
				<template #cell(checked)="{ item }">
					<of-form-checkbox :value="checkIsItemSelected(item._id)" @input="toggleSelectedItem(item)" />
				</template>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<SearchInput
						v-model="searchString"
						:placeholder="$t('Search recipes by name...')"
						class="col-lg-6 col-md-4 col-sm-5"
					/>
				</template>

				<template #cell(validationErrors)="{ item }">
					<ItemStatus :item="item" />
				</template>

				<template #cell(updatedAt)="{ item }">
					{{ fromNow(item.updatedAt) }}
				</template>

				<template slot="TableButtons-Slot-right" slot-scope="{}">
					<div class="TableButtons-Right col-auto ml-2">
						<b-dropdown :text="$t('Download')" variant="light" right class="Dropdown-buttons">
							<b-dropdown-item href="#" @click="handleSave({ exportType: exportTypes.JSON })">
								<icon name="file-code" class="mr-2" /> {{ $t('Download JSON') }}
							</b-dropdown-item>
							<b-dropdown-item href="#" @click="handleSave({ exportType: exportTypes.PDF })">
								<icon name="file-pdf" class="mr-2" /> {{ $t('Download PDF') }}
							</b-dropdown-item>
						</b-dropdown>
						<b-dropdown :text="$t('New Recipe')" right variant="primary" class="Dropdown-buttons">
							<b-dropdown-item href="#" @click="handleImportRecipe">
								<icon name="file-upload" class="mr-2" /> {{ $t('Import') }}
							</b-dropdown-item>
							<b-dropdown-item href="#" @click="handleManualEntry">
								<icon name="plus-circle" class="mr-1" /> {{ $t('Manual entry') }}
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</template>

				<template #cell(actions)="{ item }">
					<b-dropdown size="sm" toggle-class="p-1" right no-caret>
						<template #button-content>
							<icon name="ellipsis-h" />
						</template>

						<b-dropdown-item
							v-for="{ icon, label, handler } in rowActions"
							:key="label"
							@click="handler({ item, exportType: exportTypes.PDF })"
						>
							<icon :name="icon" class="mr-2" /> {{ label }}
						</b-dropdown-item>
					</b-dropdown>
				</template>

				<template #cell(button)="{ item }">
					<b-button @click="handleEditRecipe({ item })">{{ $t('Edit') }}</b-button>
				</template>
			</ListTable>

			<ItemPreviewOverlay
				:is-visible="!!selectedDocumentId"
				:recipe="selectedDocument"
				@hide="() => (selectedDocumentId = null)"
			/>
		</Page>
		<ImportModal modal-id="importRecipesModal" :visible="false"></ImportModal>
		<DownloadModal
			:is-visible="downloadModalData.showDownloadModal"
			:total="downloadModalData.documentsTotal"
			:downloaded="downloadModalData.downloadedDocuments.length"
			:download-status="downloadModalData.downloadStatus"
			:valid-items="downloadModalData.validItems"
			:invalid-items="downloadModalData.invalidItems"
			:recipes-with-processed-images="downloadModalData.recipesWithProcessedImages"
			:recipes-wrote="downloadModalData.recipesWrote"
			@hidden="resetDownloadModalData"
		/>
		<ImportModal modal-id="importRecipesModal" :visible="false"></ImportModal>
		<RecipeDeleteModal
			:is-modal-visible="isRecipeDeleteModalVisible"
			:delete-results="deleteResults"
			@hideModal="hideRecipeDeleteModal"
		/>
		<SelectedRecipesNotification
			:is-visible="showSelectedRecipesNotification"
			:selected-items="selectedItems"
			@removeRecipes="handleRemoveManyRecipes"
			@deselectAllRecipes="handleDeselectAllRecipes"
		/>
		<Loader v-if="areRecipesRemoving" overlay />
	</Layout>
</template>

<script type="text/javascript">
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { ListTable, OfFormCheckbox } from '@oneflow/ofs-vue-layout';
import Layout from '../../../components/Layout';
import Page from '../../../components/Page';
import ItemPreviewOverlay from '../../../components/ItemPreview/ItemPreviewOverlay';
import SearchInput from '../../../components/SearchInput';
import notifications from '../../../mixins/notifications';
import { fromNow } from '../../../lib/dateFormatters';
import generateSourceId from '../../../lib/generateRecipeSourceId';
import { saveToJSON, saveToPDF } from '../../../lib/RecipeDownloader';
import ItemStatus from '../../../components/ItemStatus.vue';
import ImportModal from '../../../components/ImportModal';
import RecipeDeleteModal from '../../../components/RecipeDeleteModal.vue';
import DownloadModal from './DownloadModal.vue';
import SelectedRecipesNotification from './SelectedRecipesNotification.vue';
import Loader from '../../../components/Loader';
import router from '@/router';
import { exportTypes, downloadStatuses } from '../../../constants';
import * as bluebird from 'bluebird';

const config = {
	refresh: { visible: true }
};

const downloadModalData = {
	showDownloadModal: false,
	downloadStatus: '',
	documentsDownloadLimit: undefined,
	documentsTotal: 0,
	downloadedDocuments: [],
	validItems: [],
	invalidItems: [],
	recipesWithProcessedImages: 0,
	recipesWrote: 0
};

export default {
	components: {
		Layout,
		Page,
		ListTable,
		SearchInput,
		ItemPreviewOverlay,
		ItemStatus,
		ImportModal,
		DownloadModal,
		OfFormCheckbox,
		SelectedRecipesNotification,
		Loader,
		RecipeDeleteModal
	},
	mixins: [notifications],
	data() {
		// TODO: Confirm the list of filters/sortable columns and add indexes
		const fields = [
			{ label: '', key: 'checked' },
			{ label: this.$t('Name'), key: 'record.name', sortable: true, tdClass: 'col-4' },
			{ label: this.$t('Category'), key: 'record.category' },
			{ label: this.$t('Author'), key: 'record.author' },
			{ label: this.$t('Status'), key: 'validationErrors' },
			{ label: this.$t('Last modified'), key: 'updatedAt', sortable: true },
			{ label: '', key: 'actions', tdClass: 'text-right' },
			{ label: '', key: 'button', tdClass: 'text-right' }
		];

		return {
			currentPage: 1,
			perPage: 10,
			fields,
			sort: {
				updatedAt: -1
			},
			config,
			searchString: '',
			selectedDocumentId: null,
			rowActions: [
				{
					icon: 'edit',
					label: this.$t('Edit'),
					handler: this.handleEditRecipe
				},
				{
					icon: 'clone',
					label: this.$t('Duplicate'),
					handler: this.handleDuplicateRecipe
				},
				{
					icon: 'download',
					label: this.$t('Download'),
					handler: this.handleSave
				},
				{
					icon: 'trash',
					label: this.$t('Remove'),
					handler: this.handleRemoveRecipe
				}
			],
			downloadModalData: _.cloneDeep(downloadModalData),
			selectedItems: [],
			areRecipesRemoving: false,
			isRecipeDeleteModalVisible: false,
			deleteResults: null
		};
	},
	computed: {
		...mapGetters({
			documents: 'document/documents',
			isDocumentsFetching: 'document/isFetching'
		}),
		selectedDocument() {
			return _.find(this.documents.data, ['id', this.selectedDocumentId]);
		},
		exportTypes() {
			return exportTypes;
		},
		showSelectedRecipesNotification() {
			return this.selectedItems.length > 0;
		},
		allActiveItemsSelected() {
			const allIActiveItemsIds = _.map(this.documents.data, '_id');
			const selectedItemsIds = _.map(this.selectedItems, '_id');
			return _.difference(allIActiveItemsIds, selectedItemsIds).length === 0;
		}
	},
	watch: {
		searchString: _.debounce(async function(searchString, oldSearchString) {
			if (searchString !== oldSearchString) {
				this.currentPage = 1;
				await this.fetchData();
			}
		}, 800)
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		...mapActions({
			findDocuments: 'document/find',
			findAllDocuments: 'document/findAll',
			createDocument: 'document/create',
			deleteDocumentById: 'document/deleteById',
			bulkDelete: 'document/bulkDelete'
		}),
		fromNow,
		async fetchData() {
			try {
				const query = {
					$limit: this.perPage,
					$skip: this.perPage * (this.currentPage - 1),
					$where: { $or: [{ collectionId: { $exists: false } }, { collectionId: null }] }
				};
				if (!_.isEmpty(this.filterValues)) {
					query.$where = _.reduce(
						this.filterValues,
						($where, value, key) => ({
							...$where,
							[key]: Array.isArray(value) ? { $in: value } : value
						}),
						{}
					);
				}
				if (!_.isEmpty(this.searchString)) {
					query.$where['record.name'] = { $regex: this.searchString, $options: 'i' };
					// TODO: Should add an index for the field
				}

				if (!_.isEmpty(this.sort)) {
					query.$sort = { ...this.sort, _id: 1 };
				}
				await this.findDocuments({ query: { query } });
			} catch (err) {
				this.notifyError(err, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching recipes')
				});
			}
		},
		async handleGetDocuments() {
			try {
				const query = {
					$limit: this.downloadModalData.documentsDownloadLimit,
					$skip: this.downloadModalData.downloadedDocuments.length,
					$where: { $or: [{ collectionId: { $exists: false } }, { collectionId: null }] },
					$sort: {}
				};
				if (!_.isEmpty(this.searchString)) {
					// TODO: Should add an index for the field
					query.$where['record.name'] = { $regex: this.searchString, $options: 'i' };
				}
				return this.findDocuments({ query: { query }, options: { skipMutations: true } });
			} catch (err) {
				this.notifyError(err, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while fetching recipes')
				});
			}
		},
		handleTableChange({ currentPage, perPage, filter, sort }) {
			this.currentPage = currentPage;
			this.perPage = perPage;
			if (filter !== undefined) {
				this.filter = filter;
			}
			if (sort) {
				this.sort = sort;
			}
		},
		handleRowClick(item) {
			this.selectedDocumentId = item.id;
		},
		handleManualEntry() {
			router.push({ name: 'recipes.edit', params: { id: 'new' } });
		},
		handleImportRecipe() {
			this.$bvModal.show('importRecipesModal');
		},
		handleEditRecipe({ item }) {
			this.$router.push({ name: 'recipes.edit', params: { id: item.id } });
		},
		async handleDuplicateRecipe({ item }) {
			try {
				const copiedRecipe = _.cloneDeep(item);
				let { record } = copiedRecipe;
				const name = _.get(record, 'name', '');
				const newDocument = {
					sourceId: generateSourceId(name),
					record: _.merge(record, { name: `Copy ${name}` })
				};
				const document = await this.createDocument(newDocument);
				this.$router.push({ name: 'recipes.edit', params: { id: document.id } });
			} catch (error) {
				this.notifyError(error);
			}
		},
		async handleRemoveRecipe({ item }) {
			const isConfirmed = await this.confirmAction(this.$t('Are you sure you want to delete the recipe?'));
			if (!isConfirmed) return;

			try {
				this.areRecipesRemoving = true;
				this.deleteResults = await this.bulkDelete([_.get(item, 'id')]);

				if (!_.size(_.get(this.deleteResults, 'warningDocuments', []))) {
					this.notifySuccess({
						title: this.$t('Success'),
						text: this.$t('Successfully removed', { count: `1 recipe` })
					});
					return;
				}

				this.isRecipeDeleteModalVisible = true;
			} catch (error) {
				this.notifyError(error, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while removing recipe')
				});
			} finally {
				this.selectedItems = [];
				this.areRecipesRemoving = false;
				await this.fetchData();
			}
		},
		async handleSave({ exportType, item }) {
			try {
				this.downloadModalData.downloadStatus = downloadStatuses.DOWNLOADING;
				this.downloadModalData.showDownloadModal = true;
				const items = await this.buildItemsToSave(exportType, item);
				const fileName = _.isEmpty(item) ? 'recipes' : _.get(item, 'record.name', item.id);
				const { validItems, invalidItems } = items.reduce(
					(acc, item) => {
						if (item.record) {
							acc.validItems.push(item);
						} else {
							acc.invalidItems.push(item);
						}
						return acc;
					},
					{ validItems: [], invalidItems: [] }
				);
				this.downloadModalData.validItems = validItems;
				this.downloadModalData.invalidItems = invalidItems;
				this.downloadModalData.downloadStatus = downloadStatuses.PROCESSING_IMAGES;
				if (exportType === exportTypes.PDF) {
					await saveToPDF(validItems, fileName, this.onRecipeImagesProcessed, this.onRecipeWroteToPdf);
				} else {
					saveToJSON(validItems, fileName);
				}
			} catch (error) {
				this.notifyError(error, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while downloading recipes')
				});
			} finally {
				this.downloadModalData.downloadStatus = '';
			}
		},
		async buildItemsToSave(exportType, item) {
			if (!_.isEmpty(item)) {
				this.downloadModalData.documentsTotal = 1;
				this.downloadModalData.downloadedDocuments = [item];
			} else if (exportType === exportTypes.PDF) {
				const documents = await this.findAllDocuments({
					query: {
						query: { $where: { $or: [{ collectionId: { $exists: false } }, { collectionId: null }] } }
					},
					options: { skipMutations: true }
				});
				this.downloadModalData.documentsTotal = documents.length;
				this.downloadModalData.downloadedDocuments = documents;
			} else {
				await this.handleDownloadAll();
				// delay to let progress bar animation to be finished
				await bluebird.delay(700);
			}

			return this.downloadModalData.downloadedDocuments;
		},
		async handleDownloadAll() {
			const { total, data } = await this.handleGetDocuments();
			this.downloadModalData.documentsTotal = total;
			this.downloadModalData.downloadedDocuments.push(...data);
			if (!this.downloadModalData.documentsDownloadLimit) {
				this.downloadModalData.documentsDownloadLimit = Math.max(100, Math.ceil(total / 10));
			}
			const allDocumentsDownloaded =
				this.downloadModalData.documentsDownloadLimit &&
				this.downloadModalData.downloadedDocuments.length === this.downloadModalData.documentsTotal;
			if (allDocumentsDownloaded) {
				return;
			}
			await this.handleDownloadAll();
		},
		resetDownloadModalData() {
			this.downloadModalData = _.cloneDeep(downloadModalData);
		},
		onRecipeImagesProcessed() {
			this.downloadModalData.recipesWithProcessedImages += 1;
			if (this.downloadModalData.downloadStatus !== downloadStatuses.PROCESSING_IMAGES) {
				this.downloadModalData.downloadStatus = downloadStatuses.PROCESSING_IMAGES;
			}
		},
		onRecipeWroteToPdf() {
			this.downloadModalData.recipesWrote += 1;
			if (this.downloadModalData.downloadStatus !== downloadStatuses.WRITING_TO_PDF) {
				this.downloadModalData.downloadStatus = downloadStatuses.WRITING_TO_PDF;
			}
		},
		checkIsItemSelected(itemId) {
			return this.selectedItems.some(({ _id }) => _id === itemId);
		},
		toggleSelectedItem(item) {
			const { _id: itemId } = item;
			if (this.checkIsItemSelected(itemId)) {
				this.selectedItems = this.selectedItems.filter(({ _id }) => _id !== itemId);
			} else {
				this.selectedItems.push(item);
			}
		},
		async handleRemoveManyRecipes() {
			const isConfirmed = await this.confirmAction(this.$t('Are you sure you want to delete the recipes?'));
			if (!isConfirmed) return;

			const documentIds = _.map(this.selectedItems, 'id');

			try {
				this.areRecipesRemoving = true;
				this.deleteResults = await this.bulkDelete(documentIds);
				const blockedDocsCount = _.size(_.get(this.deleteResults, 'warningDocuments', []));

				if (!blockedDocsCount) {
					const deletedDocsCount = _.size(_.get(this.deleteResults, 'deletedDocuments', []));
					this.notifySuccess({
						title: this.$t('Success'),
						text: this.$t('Successfully removed', { count: `${deletedDocsCount} recipe` })
					});
					return;
				}

				this.isRecipeDeleteModalVisible = true;
			} catch (error) {
				this.notifyError(error, {
					title: this.$t('Error'),
					text: this.$t('An error occurred while removing recipes')
				});
			} finally {
				this.selectedItems = [];
				this.areRecipesRemoving = false;
				await this.fetchData();
			}
		},
		toggleSelectAllActiveItems() {
			if (this.allActiveItemsSelected) {
				const allIActiveItemsIds = _.map(this.documents.data, '_id');
				this.selectedItems = this.selectedItems.filter(({ _id }) => {
					return !allIActiveItemsIds.includes(_id);
				});
			} else {
				this.selectedItems = _.uniqBy([...this.selectedItems, ...this.documents.data], '_id');
			}
		},
		handleDeselectAllRecipes() {
			this.selectedItems = [];
		},
		hideRecipeDeleteModal() {
			this.isRecipeDeleteModalVisible = false;
		}
	}
};
</script>
<style lang="scss">
.TableButtons-Right {
	display: flex;
	padding: 0 !important;

	@media (max-width: 576px) {
		flex-direction: column;
	}
}

.Dropdown-buttons {
	width: 100%;
	margin-left: 10px;

	@media (max-width: 576px) {
		margin-left: 0;
		margin-bottom: 5px;
	}

	svg.fa-icon {
		display: inline-block !important;
	}
}

.select-all-checkbox-container {
	.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
		background-image: url('../../../../public/images/select-all-mark.svg');
	}

	.custom-control-input:checked ~ .custom-control-label::before {
		background-color: #fff;
	}

	.custom-control-label {
		cursor: pointer;
		&::before {
			box-shadow: none !important;
			outline: none !important;
			border: 1px solid #d0dbe9 !important;
		}
	}
}
</style>
